import React from 'react'
import camelCase from 'camelcase'
import { testId } from 'utils/testHelper'

import classes from './styles.module.css'

export const Counters = ({ counters = [], testIdPrefix }) => {
  return (
    <ul className={classes.countersContainer}>
      {counters.map((counter) => {
        return (
          <li key={counter.value} data-test-id={testId(testIdPrefix, camelCase(counter.label))}>
            <div className={classes.border}></div>
            <div className={classes.content}>
              <div className={classes.value}>{counter.value}</div>
              <div className={classes.label}>{counter.label}</div>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default Counters
