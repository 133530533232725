import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toLower } from 'lodash'

import { setNewShipmentOutAction, sendNewShipmentOutAction } from 'store/actions'
import {
  selectNewShipmentOut,
  selectShipmentsOutStaticFilters,
  selectShipmentOutValidationStatuses,
} from 'store/selectors'
import showNotification from 'containers/Notifications/controller'
import UnlockSingleModal from 'containers/UnlockSingleModal'
import UnlockMultipleModal from 'containers/UnlockMultipleModal'
import { Button, Counter, Table, SubNav, StatusLabel, ActionsBar } from 'components/system'
import { CheckboxCell } from 'components/system/TableElements'
import { formatNumber, getTotalCaratsWeight, getCaratsRange, filterOutEmptyDiamonds, resolveProp } from 'utils/diamond'
import { getActualType } from 'utils'
import { testIds } from 'config/testIds'

import Nav from '../../containers/Nav'
import Counters from '../../components/Counters'
import { DiamondPropCell } from '../../components/DiamondPropCell'
import Filters from './Filters'
import Buttons from './Buttons'
import RemoveDiamondsModal from './RemoveDiamondsModal'
import ShipmentNameModal from './ShipmentNameModal'
import ConfirmShipmentModal from './ConfirmShipmentModal'
import classes from './styles.module.css'

export const NewShipment = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const appliedFilters = useSelector(selectShipmentsOutStaticFilters('preview'))
  const shipment = useSelector(selectNewShipmentOut)
  const statuses = useSelector(selectShipmentOutValidationStatuses)
  const [points, setPoints] = useState([])
  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])
  const [selection, setSelection] = useState([])
  const [diamondsInAction, setDiamondsInAction] = useState([])
  const [filteredOutIds, setFilteredOutIds] = useState([])
  const [modalShown, setModalShown] = useState('')
  const [actions, setActions] = useState([])

  const sorter = (rows, key, order) => {
    return rows.sort((a, b) => {
      const propA = toLower(a.data[key] || resolveProp(a.data, key) || '-')
      const propB = toLower(b.data[key] || resolveProp(b.data, key) || '-')

      if (propA === '-' && propB !== '-') return 1
      else if (propA !== '-' && propB === '-') return -1
      else if (propA && propB === '-') return 0

      if (propA > propB) return order[0]
      else if (propA < propB) return order[1]
      else return 0
    })
  }

  const columnsList = [
    {
      key: 'checkbox',
      resizable: false,
      sortable: false,
      minWidth: '40px',
      width: '40px',
      headerComponent: CheckboxCell,
      cellComponent: CheckboxCell,
      props: {
        onSelectionChange: (newSelection) => {
          updateSelection(newSelection)
        },
      },
    },
    {
      key: 'diamond_id',
      title: 'Tracr ID',
      sorter,
    },
    {
      key: 'participant_id',
      title: 'Participant ID',
      sorter,
    },
    {
      key: 'inscription_numbers',
      title: 'Inscription Number',
      cellComponent: DiamondPropCell,
      props: {
        customMapper: (value) => `${value.join(', ')}`,
      },
    },
    {
      key: 'carats',
      title: 'Carat',
      rightAlign: true,
      cellComponent: DiamondPropCell,
      props: {
        customMapper: (value) => (getActualType(value) !== 'null' ? `${formatNumber(value)}ct` : '-'),
      },
      sorter,
    },
    {
      key: 'colour',
      title: 'Colour',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'model',
      title: 'Model',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'shape',
      title: 'Shape',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'clarity',
      title: 'Clarity',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'cut_grade',
      title: 'Cut',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'quality',
      title: 'Quality',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'state',
      title: 'Status',
      cellComponent: StatusLabel,
      sorter: (rows, key, order) => {
        return rows.sort((a, b) => {
          const propA = a.data[key].text
          const propB = b.data[key].text

          if (propA > propB) return order[0]
          else if (propA < propB) return order[1]
          else return 0
        })
      },
    },
    {
      key: 'actions',
      title: 'Actions',
      cellComponent: Buttons,
      minWidth: '200px',
      sortable: false,
      props: {
        testId: testIds.newShipment.tableActionButtons,
        onClick: (row, key) => {
          if (key === 'remove') {
            setDiamondsInAction([row.data.diamond_id])

            setModalShown('removeDiamonds')
          }
        },
      },
    },
  ]

  const actionsList = [
    {
      key: 'remove',
      label: 'Remove',
      icon: 'cancel',
      onClick: () => {
        setDiamondsInAction(rows.filter((row) => row.selected).map((row) => row.data.diamond_id))
        setModalShown('removeDiamonds')
      },
    },
    /*
    {
      key: 'unlock',
      label: 'Unlock',
      icon: 'lock',
      onClick: () => {
        const lockedIds = rows
          .filter((row) => row.selected && row.data.status === 'diamond_is_locked')
          .map((row) => row.data.diamond_id)

        dispatch(setTargetAction('shipmentOut'))
        dispatch(selectDiamondsToUnlockAction(lockedIds))

        if (lockedIds.length > 1) {
          dispatch(setUnlockModalVisibleAction('multiple'))
        } else {
          dispatch(setUnlockModalVisibleAction('singular'))
        }
      },
    },
    */
  ]

  const updateSelection = (newSelection = []) => {
    setRows(
      rows.map((row) => {
        if (newSelection.includes(row.id)) row.selected = true
        else row.selected = false

        return row
      })
    )
    setSelection(newSelection)

    setActions(getActions(shipment, newSelection))
  }

  const filterOut = (rows, ids) => {
    return rows.filter((row) => !ids.includes(row.data.diamond_id))
  }

  const resolveKeysToObjectItems = (keys, list) => {
    return keys
      .map((key) => {
        return list.find((item) => item.key === key)
      })
      .filter((item) => item)
  }

  const resolveStatusToState = (status, shipmentType) => {
    const state = statuses[shipmentType].find((statusData) => statusData.key === status)

    if (!state) return { status: 'not-found', text: 'icon not found' }

    switch (state.key) {
      case 'success':
        return { status: state.icon, text: state.label, color: '#00B707' }
      default:
        return { status: state.icon, text: state.label, color: '#979797', fill: '#979797', stroke: '#979797' }
    }
  }

  const getPoints = (shipment) => {
    if (!shipment) return []

    return [
      { key: 'To', value: shipment.receiver },
      { key: 'Shipment name', value: shipment.shipment_name },
    ]
  }

  const getCounters = (shipment) => {
    if (!shipment || shipment.diamonds.length === 0) return []

    const range = getCaratsRange(filterOutEmptyDiamonds(shipment.diamonds))

    return [
      {
        label: 'Total diamonds',
        value: shipment.diamonds.length,
        testId: testIds.newShipment.header.counter,
      },
      {
        label: 'Total carat weight',
        value: getTotalCaratsWeight(filterOutEmptyDiamonds(shipment.diamonds)),
        testId: testIds.newShipment.header.totalCaratWeight,
      },
      {
        label: 'Carat range',
        value: range.length ? `${formatNumber(range[0])}ct - ${formatNumber(range[1])}ct` : '0.000ct',
        testId: testIds.newShipment.header.caratRange,
      },
    ]
  }

  const getColumns = (shipment) => {
    const list = {
      rough: [
        'checkbox',
        'diamond_id',
        'participant_id',
        'provenance',
        'carats',
        'colour',
        'model',
        'quality',
        'state',
        'actions',
      ],
      polished: [
        'checkbox',
        'diamond_id',
        'inscription_number',
        'provenance',
        'shape',
        'carats',
        'clarity',
        'colour',
        'cut_grade',
        'state',
        'actions',
      ],
    }

    return list[shipment.shipment_type]
  }

  const getRows = (shipment) => {
    if (!shipment) return []

    return shipment.diamonds.map((diamond) => {
      return {
        id: diamond.diamond_id,
        selected: selection.includes(diamond.diamond_id),
        data: {
          ...diamond,
          state: resolveStatusToState(diamond.status, shipment.shipment_type),
        },
      }
    })
  }

  const getActions = (shipment, selection = []) => {
    if (!shipment) return []

    const lockedDiamonds = rows.filter((row) => selection.includes(row.id) && row.data.status === 'diamond_is_locked')

    if (lockedDiamonds.length) return ['remove', 'unlock']

    return ['remove']
  }

  const getAvailableDiamondIds = () => {
    if (!shipment) return []

    const availableStatuses = statuses[shipment.shipment_type]
      .filter((status) => status.available)
      .map((status) => status.key)

    return shipment.diamonds
      .filter((diamond) => availableStatuses.includes(diamond.status))
      .map((diamond) => diamond.diamond_id)
  }

  const canContinue = () => {
    if (!shipment) return false

    const diamondsCount = shipment.diamonds.length

    return diamondsCount && getAvailableDiamondIds().length === diamondsCount
  }

  const handleContinueClick = () => {
    setModalShown('shipmentName')
  }

  const handleRemoveAllClick = () => {
    const availableDiamondIds = getAvailableDiamondIds()
    const unavailableDiamondIds = shipment.diamonds
      .filter((diamond) => !availableDiamondIds.includes(diamond.diamond_id))
      .map((diamond) => diamond.diamond_id)

    setDiamondsInAction(unavailableDiamondIds)

    setModalShown('removeDiamonds')
  }

  const handleModalCancel = () => {
    setModalShown('')
  }

  const handleModalConfirm = (type, payload) => {
    switch (type) {
      case 'shipmentName':
        const newShipment = { ...shipment, ...payload }

        dispatch(setNewShipmentOutAction(newShipment))

        setModalShown('confirmShipment')
        break
      case 'removeDiamonds': {
        const remainingDiamonds = shipment.diamonds.filter((diamond) => !diamondsInAction.includes(diamond.diamond_id))
        const remainingIds = remainingDiamonds.map((diamond) => diamond.diamond_id)

        dispatch(setNewShipmentOutAction({ ...shipment, diamonds: remainingDiamonds }))

        setSelection(
          rows.filter((row) => row.selected && !diamondsInAction.includes(row.data.diamond_id)).map((row) => row.id)
        )

        setFilteredOutIds(filteredOutIds.filter((id) => remainingIds.includes(id)))

        setModalShown('')
        break
      }
      case 'confirmShipment':
        dispatch(
          sendNewShipmentOutAction({
            ...shipment,
            diamonds: shipment.diamonds.map((diamond) => {
              return { diamond_id: diamond.diamond_id }
            }),
          })
        ).then((response) => {
          if (!response || response.status !== 200) {
            const error =
              response && response.data ? response.data.error || response.data.message || response.message : ''
            const message = Object.prototype.toString.call(error).slice(8, -1) === 'Array' ? error[0].msg : error

            showNotification({
              title: 'New shipment error',
              bodyText: message ? message : 'Unknown error',
              variant: 'error',
            })
          } else {
            navigate(`/shipments/outbound`)
          }
        })

        break
      default:
        break
    }
  }

  const handleFiltersChange = (filteredOutIds) => {
    setFilteredOutIds(filteredOutIds)
  }

  useEffect(() => {
    if (!shipment) return

    setPoints(getPoints(shipment))
    setColumns(getColumns(shipment))
    setRows(getRows(shipment))
  }, [JSON.stringify(shipment)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.newShipmentContainer}>
      <div className={classes.wrapper}>
        <Nav title={'New shipment'} backTestId={testIds.newShipment.backButton}>
          <Button
            disabled={!canContinue()}
            onClick={handleContinueClick}
            data-test-id={testIds.newShipment.continueButton}
          >
            Continue
          </Button>
        </Nav>
        <div className={classes.counters}>
          <Counters counters={getCounters(shipment)} />
        </div>
        <div className={classes.filters}>
          <Filters
            testIdPrefix={testIds.newShipment.prefix}
            rows={rows}
            shipmentType={shipment ? shipment.shipment_type : null}
            onChange={handleFiltersChange}
          />
        </div>
        <div className={classes.counter}>
          <Counter
            total={shipment ? shipment.diamonds.length : null}
            filtered={Object.keys(appliedFilters).length ? rows.length - filteredOutIds.length : null}
            testId={testIds.newShipment.diamondsCounter}
          />
        </div>

        <div className={classes.metaTab}>
          <SubNav points={points} testId={testIds.newShipment.shipmentDetails}>
            <div className={classes.action}>
              <Button
                variant="secondary"
                disabled={shipment && getAvailableDiamondIds().length === shipment.diamonds.length}
                onClick={handleRemoveAllClick}
                data-test-id={testIds.newShipment.removeAllButton}
              >
                Remove all unavailable diamonds
              </Button>
            </div>
          </SubNav>
        </div>
        <div className={classes.list}>
          <div className={classes.border}></div>
          <Table
            testIdPrefix={testIds.newShipment.prefix}
            columns={resolveKeysToObjectItems(columns, columnsList)}
            rows={filterOut(rows, filteredOutIds)}
          />
        </div>
      </div>
      <RemoveDiamondsModal
        visible={modalShown === 'removeDiamonds'}
        onCancel={() => handleModalCancel('removeDiamonds')}
        onConfirm={() => handleModalConfirm('removeDiamonds')}
      >
        Are you sure?
        <br />
        <br />
        Once confirmed, {diamondsInAction.length > 1 ? 'all unavailable diamonds' : 'the diamond'} will be removed from
        this shipment.
      </RemoveDiamondsModal>
      <ShipmentNameModal
        visible={modalShown === 'shipmentName'}
        shipment={shipment}
        onConfirm={(payload) => handleModalConfirm('shipmentName', payload)}
        onCancel={() => setModalShown('')}
      />
      <ConfirmShipmentModal
        visible={modalShown === 'confirmShipment'}
        shipment={shipment}
        onConfirm={() => handleModalConfirm('confirmShipment')}
        onCancel={() => setModalShown('')}
      />
      <UnlockSingleModal />
      <UnlockMultipleModal />
      <ActionsBar
        label="Selected"
        selectionLength={selection.length}
        actions={resolveKeysToObjectItems(actions, actionsList)}
        onClear={updateSelection}
        testIdPrefix={testIds.newShipment.actionBar}
      />
    </div>
  )
}

export default NewShipment
