import React from 'react'
import classnames from 'classnames'

import { Button } from 'components/system'
import testids from 'config/testIds'

import classes from './styles.module.css'

export const EmptyStateOverlay = ({
  icon,
  label,
  subLabel,
  buttonText,
  onClick,
  withTabs = false,
  fullHeight = true,
}) => {
  const testIds = testids.monitoring

  return (
    <div
      className={classes.emptyStateOverlayContainer}
      style={{
        height: withTabs
          ? 'calc(100vh - 72px - 64px - 56px - 48px - 64px)'
          : 'calc(100vh - 72px - 64px - 64px - 59px - 48px)',
      }}
    >
      <div
        className={classnames(classes.elementsContainer, {
          [classes.persistent]: true,
          fullHeight: fullHeight,
        })}
      >
        <div className={classes.overlayInfo}>
          <div>{icon}</div>
          <div className={classes.label}>{label}</div>
          <div className={classes.subLabel}>{subLabel}</div>
          <div className={classes.button}>
            <Button onClick={onClick} variant="secondary" data-test-id={testIds.clearAllFiltersTableBtn}>
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmptyStateOverlay
