import React from 'react'

/**
 * @param {Number} value : 0 - unchecked, 1 - partial, 2 - checked
 */
export const CheckboxSVG = ({ value, isHovered, isDisabled }) => {
  if (isDisabled) {
    if (!value) {
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3">
            <path
              d="M0 0V-1H-1V0H0ZM16 0H17V-1H16V0ZM16 16V17H17V16H16ZM0 16H-1V17H0V16ZM0 1H16V-1H0V1ZM15 0V16H17V0H15ZM16 15H0V17H16V15ZM1 16V0H-1V16H1Z"
              fill="white"
            />
          </g>
        </svg>
      )
    } else {
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3">
            <rect width="16" height="16" fill="white" />
            <path d="M11.9999 5.30581L6.34718 10.89L3.9999 8.69419" stroke="black" />
          </g>
        </svg>
      )
    }
  } else {
    if (!value) {
      if (!isHovered) {
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
              <path
                d="M0 0V-1H-1V0H0ZM16 0H17V-1H16V0ZM16 16V17H17V16H16ZM0 16H-1V17H0V16ZM0 1H16V-1H0V1ZM15 0V16H17V0H15ZM16 15H0V17H16V15ZM1 16V0H-1V16H1Z"
                fill="white"
              />
            </g>
          </svg>
        )
      } else {
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 0V-1H-1V0H0ZM16 0H17V-1H16V0ZM16 16V17H17V16H16ZM0 16H-1V17H0V16ZM0 1H16V-1H0V1ZM15 0V16H17V0H15ZM16 15H0V17H16V15ZM1 16V0H-1V16H1Z"
              fill="white"
            />
          </svg>
        )
      }
    } else if (value === 1) {
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="16" height="16" fill="#18BC98" />
          <path d="M4 8L12 8" stroke="black" />
        </svg>
      )
    } else {
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="16" height="16" fill="#18BC98" />
          <path d="M11.9999 5.30581L6.34718 10.89L3.9999 8.69419" stroke="black" />
        </svg>
      )
    }
  }
}
