import messages from 'text/messages'

/**
 * @param {path to entity} path
 * @param {Error or response object} data
 */
const findMessage = (path, data) => {
  let message
  let by

  switch (true) {
    case data.response !== undefined:
      // known error raised by portal-api
      if (data.response.data) {
        by = data.response.data.error ? data.response.data.error.code : data.response.status
        message = messages[path] ? messages[path][by] : null
      } else {
        // unknown error
        by = ''
        message = data.message
      }
      break
    default:
      message = null
      break
  }

  return message
}

export default findMessage
