import http from '../utils/http'
import { toISOString } from '../utils/dates'

const namespace = 'monitoring'

const selectMonitoringDiamonds = ({ sort, filters }) => {
  const filtersStringified = filters.reduce((prev, next) => {
    const filterSeparator = '&'

    if (Array.isArray(next.values)) {
      const filterOptions = next.values
        .filter((value) => value)
        .reduce((prev2, next2) => {
          return prev2 + `${prev2.length ? ',' : ''}${next2}`
        }, [])

      if (filterOptions.length) return `${prev}${prev.length ? '&' : ''}${next.name}=${filterOptions}`
      else return prev
    } else if (next.name === 'date') {
      if (!next.value.from) return prev

      const min_upload_date = toISOString(next.value.from)
      const max_upload_date = next.value.to
        ? toISOString(new Date(new Date(next.value.to.getTime()).setHours(23, 59, 59)))
        : toISOString(new Date(new Date(next.value.from.getTime()).setHours(23, 59, 59)))

      return `${prev}${
        prev.length ? '&' : ''
      }min_upload_date=${min_upload_date}${filterSeparator}max_upload_date=${max_upload_date}`
    } else {
      if (next.value) return `${prev}${prev.length ? '&' : ''}${next.name}=${next.value}`
      else return prev
    }
  }, '')

  const querystring = encodeURI(filtersStringified)

  const sortKey = Object.keys(sort)[0]
  const order = sort[sortKey]
  return [sortKey, order, querystring]
}

const getMonitoringDiamonds = ({ page, limit, sort, filters }) => {
  const [sortKey, order, querystring] = selectMonitoringDiamonds({ sort, filters })

  return http.get(
    `${namespace}/?page=${page}&limit=${limit}&order_by=${sortKey}&order=${order}${
      querystring ? '&' + querystring : ''
    }`
  )
}

const getMonitoringFilters = () => {
  return http.get(`${namespace}/filters`)
}

const downloadMonitoringReport = ({ page, limit, sort, filters }) => {
  const [sortKey, order, querystring] = selectMonitoringDiamonds({ sort, filters })
  let locale

  if (Intl) {
    locale = Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  return http.downloadFile(
    `${namespace}/download/?page=${page}&limit=${limit}&order_by=${sortKey}&order=${order}${
      locale ? '&locale=' + locale : ''
    }${querystring ? '&' + querystring : ''}&format=xlsx`,
    'IntegrationReport.xlsx'
  )
}

const endpoints = {
  getMonitoringDiamonds,
  getMonitoringFilters,
  downloadMonitoringReport,
}

export default endpoints
