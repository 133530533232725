import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import API from 'api'
import { selectMonitoringDiamonds, selectMonitoringFilters, selectMonitoringDiamondsCount } from 'store/selectors'
import showNotification from 'containers/Notifications/controller'
import { ClickOutsideListener, Counter, Tooltip } from 'components/system'
import { UpArrowSVG, DownArrowSVG, DownloadIcon } from 'components/Svg'
import testids from 'config/testIds'

import classes from './styles.module.css'

const ExportReport = ({ sort }) => {
  const filters = useSelector(selectMonitoringFilters)
  const count = useSelector(selectMonitoringDiamondsCount)
  const diamonds = useSelector(selectMonitoringDiamonds)
  const [isOpen, setIsOpen] = useState(false)
  const testIds = testids.monitoring

  const handleToggleClick = () => {
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  const handleClick = () => {
    API.downloadMonitoringReport({ page: 1, limit: count.filtered, sort: sort, filters: filters })
      .then(() => {
        showNotification({
          title: 'File downloaded',
          variant: 'success',
        })
      })
      .catch(() => {
        showNotification({
          title: 'File download failed',
          variant: 'error',
        })
      })
    setIsOpen(false)
  }

  const isMonitoringEmpty = diamonds.length === 0

  const tooltipTitle = isMonitoringEmpty ? 'Export is enabled when events are displayed' : ''

  return (
    <div className={classes.exportReportContainer}>
      <div className={classes.diamondCounter}>
        <Counter
          total={count.total}
          filtered={filters.length ? count.filtered : null}
          name="Integration event"
          testId={testIds.counter}
        />
      </div>
      <ClickOutsideListener onClickOutside={() => setIsOpen(false)}>
        <Tooltip title={tooltipTitle} minWidth={130}>
          <div
            className={classNames(classes.button, classes.hasMargin, { [classes.disabled]: isMonitoringEmpty })}
            onClick={() => handleToggleClick(isOpen)}
            disabled={isMonitoringEmpty}
            data-test-id={testIds.exportBtn}
          >
            <span>Export report</span>
            <span className={classes.dropDownIcon}>
              {!isOpen && <DownArrowSVG />}
              {isOpen && <UpArrowSVG />}
            </span>
          </div>
        </Tooltip>
        {isOpen && (
          <div className={classes.listContainer}>
            <div className={classes.listHeader}>Export details of all events currently displaying</div>
            <div className={classes.list}>
              <div className={classes.listItem} onClick={handleClick} data-test-id={testIds.downloadXLSXBtn}>
                <DownloadIcon /> xlsx file
              </div>
            </div>
          </div>
        )}
      </ClickOutsideListener>
    </div>
  )
}

export default ExportReport
