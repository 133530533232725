import env from '@beam-australia/react-env'

import { parseCsvEnvVar } from 'utils/index'

const PLATFORM_TYPE = (env('PLATFORM_TYPE') || 'Manufacturer').toLowerCase()
const FEATURES_INCLUDED = parseCsvEnvVar('FEATURES_INCLUDED')
const FEATURES_EXCLUDED = parseCsvEnvVar('FEATURES_EXCLUDED')
const FEATURES_DISABLED = parseCsvEnvVar('FEATURES_DISABLED')

// console.log('PLATFORM_TYPE', PLATFORM_TYPE)
// console.log('FEATURES_INCLUDED', FEATURES_INCLUDED)
// console.log('FEATURES_EXCLUDED', FEATURES_EXCLUDED)
// console.log('FEATURES_DISABLED', FEATURES_DISABLED)

const features = [
  {
    name: 'list',
    belongsTo: ['inventory', 'dam', 'producer', 'manufacturer', 'retailer'],
  },
  {
    name: 'cards',
    belongsTo: ['inventory', 'dam', 'producer', 'manufacturer', 'retailer'],
  },
  {
    name: 'rough',
    belongsTo: ['inventory', 'dam', 'producer', 'manufacturer'],
  },
  {
    name: 'split',
    belongsTo: ['inventory', 'dam', 'producer', 'manufacturer'],
  },
  {
    name: 'planning',
    belongsTo: ['inventory', 'dam', 'manufacturer'],
  },
  {
    name: 'sawing',
    belongsTo: ['inventory', 'dam', 'manufacturer'],
  },
  {
    name: 'bruting',
    belongsTo: ['inventory', 'dam', 'manufacturer'],
  },
  {
    name: 'polishing',
    belongsTo: ['inventory', 'dam', 'manufacturer'],
  },
  {
    name: 'polished',
    belongsTo: ['inventory', 'dam', 'manufacturer', 'retailer'],
  },
  {
    name: 'graded',
    belongsTo: ['inventory', 'dam', 'manufacturer', 'retailer'],
  },
  {
    name: 'share',
    belongsTo: ['inventory', 'dam', 'producer', 'manufacturer', 'retailer'],
  },
  {
    name: 'inventory',
    belongsTo: ['dam', 'producer', 'manufacturer', 'retailer'],
  },
  {
    name: 'outbox',
    belongsTo: ['dam', 'share', 'producer', 'manufacturer', 'retailer'],
  },
  {
    name: 'monitoring',
    belongsTo: ['integration', 'producer', 'manufacturer'],
  },
  {
    name: 'dit',
    belongsTo: ['integration', 'producer', 'manufacturer'],
  },
  {
    name: 'integration',
    belongsTo: ['producer', 'manufacturer'],
  },
  {
    name: 'shipments',
    belongsTo: ['dam', 'producer', 'manufacturer', 'retailer'],
  },
  {
    name: 'shipmentsIn',
    belongsTo: ['shipments', 'dam', 'manufacturer', 'retailer'],
  },
  {
    name: 'shipmentsOut',
    belongsTo: ['shipments', 'dam', 'producer', 'manufacturer', 'retailer'],
  },
  {
    name: 'support',
    belongsTo: ['dam', 'producer', 'manufacturer', 'retailer'],
  },
  {
    name: 'wizard',
    belongsTo: ['support', 'dam', 'producer', 'manufacturer', 'retailer'],
  },
  {
    name: 'dam',
    belongsTo: ['producer', 'manufacturer', 'retailer'],
  },
]

/**
 * Check if feature or one if its parents is in the FEATURES_EXCLUDED list
 */
const isFeatureExcluded = (feature) => {
  if (FEATURES_EXCLUDED.includes(feature.name)) {
    return true
  } else if (feature.belongsTo) {
    return !!feature.belongsTo.find((parentFeatureName) => {
      const parentFeature = features.find((feature) => feature.name === parentFeatureName)

      // ignore features that are not in the list
      if (!parentFeature) return false

      // check this feature
      return isFeatureExcluded(parentFeature)
    })
  }
  return false
}

/**
 * Check if feature is included in the UI using FEATURES_INCLUDED
 * or the PLATFORM_TYPE
 */
const isFeatureIncluded = (feature) => {
  if (FEATURES_INCLUDED.includes(feature.name)) {
    return true
  } else if (feature.belongsTo.includes(PLATFORM_TYPE)) {
    // check if the platform type includes this feature
    return true
  }
  return false
}

/**
 * Check if the feature or one of its parents is disabled using
 * FEATURES_DISABLED
 */
const isFeatureDisabled = (feature) => {
  if (FEATURES_DISABLED.includes(feature.name)) {
    return true
  } else if (feature.belongsTo) {
    return !!feature.belongsTo.find((parentFeatureName) => {
      const parentFeature = features.find((feature) => feature.name === parentFeatureName)

      // ignore features that are not in the list
      if (!parentFeature) return false

      // check parent feature
      return isFeatureDisabled(parentFeature)
    })
  }
  return false
}

const getFeatureState = (feature) => {
  let state
  /**
   * 0 for excluded
   * 1 for disabled
   * 2 for included
   */

  if (isFeatureExcluded(feature)) {
    // feature is explicitly excluded from the UI
    return 0
  }

  // if feature is not excluded, check if its included using the FEATURES_INCLUDED
  // or the PLATFORM_TYPE
  if (isFeatureIncluded(feature)) {
    if (isFeatureDisabled(feature)) {
      /**
       * Even if feature is included, it can be still disabled, meaning it will show in the UI
       * but will be disabled/greyed out i.e. unavailable for interactions
       */
      state = 1
    } else {
      state = 2
    }
  } else {
    state = 0
  }

  return state
}

const featureToggles = {}

features.forEach((feature) => {
  /**
   * Check the strongest rule -> feature is excluded
   */

  const state = getFeatureState(feature)

  featureToggles[feature.name] = state
})

export { PLATFORM_TYPE }

export default featureToggles
