import React, { useEffect, useState, useRef } from 'react'
import { clone } from 'ramda'
import classnames from 'classnames'

import { ClickOutsideListener } from 'components/system'
import { DownArrowActiveSVG, UpArrowActiveSVG } from 'components/Svg'

import Item from './Item'
import classes from './styles.module.css'

import { testIds } from 'config';
import { testId } from 'utils/testHelper';

const Columnpicker = ({ columns = [], testIdPrefix, onReset, onChange }) => {
  const picker = useRef()
  const itemsWrapper = useRef()
  const [items, setItems] = useState(columns)
  const [hasChanged, setHasChanged] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [height, setHeight] = useState(40)
  const minHeight = 40
  const maxHeight = 380

  const handleToggle = () => {
    setIsExpanded(!isExpanded)
  }

  const handleRestore = () => {
    setHasChanged(false)
    setIsExpanded(false)
    setItems(columns)
    onReset()
  }

  const handleApply = () => {
    if (hasChanged) onChange(items)
    setHasChanged(false)
    setIsExpanded(false)
  }

  const handleItemClick = (path, value) => {
    const newItems = clone(items)
    const updatedItem = newItems.find((item) => item.path === path)

    updatedItem.isEnabled = value

    if (JSON.stringify(newItems) !== JSON.stringify(columns)) {
      setHasChanged(true)
    } else {
      setHasChanged(false)
    }

    setItems(newItems)
  }

  const onPickerRef = (node) => {
    if (!picker.current) picker.current = node

    onResize()
  }

  const onResize = () => {
    if (!picker.current) return
    const height = picker.current.offsetHeight
    const itemsHeight = itemsWrapper.current.offsetHeight
    const top = picker.current.getBoundingClientRect().top
    const viewHeight = window.innerHeight
    const marginBottom = 24
    const nonListHeight = height - itemsHeight
    const freeSpace = viewHeight - top - marginBottom

    if (freeSpace < height) {
      const newItemsHeight = freeSpace - nonListHeight

      if (newItemsHeight < minHeight) setHeight(minHeight)
      else setHeight(newItemsHeight)
    } else if (freeSpace > height) {
      if (height < maxHeight) setHeight(freeSpace - nonListHeight)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setItems(columns)
  }, [JSON.stringify(columns)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.columnpickerContainer}>
      <ClickOutsideListener
        onClickOutside={() => {
          if (isExpanded) handleToggle()
          if (hasChanged) {
            setHasChanged(false)
            onChange(items)
          }
        }}
      >
        <div className={classes.wrapper}>
          <div data-test-id={testId(testIdPrefix, testIds.columnPicker.buttonPostfix)} className={classnames(classes.toggler, { expanded: isExpanded })} onClick={handleToggle}>
            Columns {isExpanded ? <UpArrowActiveSVG /> : <DownArrowActiveSVG />}
          </div>
          <div className={classnames(classes.picker, { [classes.visible]: isExpanded })} ref={onPickerRef}>
            <div className={classes.header}>
              <div className={classes.restoreBtn} onClick={handleRestore} data-test-id={testId(testIdPrefix, 'columnPicker', 'restoreButton')}>
                Restore columns to default
              </div>
            </div>
            <div className={classes.separator}></div>
            <div className={classes.items}>
              <div className={classes.itemsWrapper} style={{ minHeight, maxHeight, height }} ref={itemsWrapper}>
                {items.map((item) => (
                  <Item {...item} key={item.path} testIdPrefix={testId(testIdPrefix, 'columnPicker')} onClick={handleItemClick} />
                ))}
              </div>
            </div>
            <div className={classes.actions}>
              <div
                className={classnames(classes.action, { [classes.applyAction]: true, [classes.enabled]: hasChanged })}
                onClick={handleApply}
                data-test-id={testId(testIdPrefix, 'columnPicker', 'applyButton')}
              >
                Apply
              </div>
            </div>
          </div>
        </div>
      </ClickOutsideListener >
    </div >
  )
}

export default Columnpicker
