import React from 'react'
import classnames from 'classnames'

import Tooltip from '../../Tooltip'
import classes from './styles.module.css'

const Tab = ({ id, label, disabled, active, tooltip, onClick, ...props }) => {
  return (
    <Tooltip title={tooltip}>
      <div
        className={classnames(classes.tabContainer, { [classes.disabled]: disabled })}
        data-test-id={props.testId}
        onClick={() => !disabled && onClick(id)}
      >
        <div className={classnames(classes.wrapper, { [classes.selected]: active, [classes.disabled]: disabled })}>
          {label && (
            <div className={classnames(classes.label, { [classes.selected]: active, [classes.disabled]: disabled })}>
              {label}
            </div>
          )}
          {props.icon && (
            <div className={classnames(classes.icon, { [classes.selected]: active, [classes.disabled]: disabled })}>
              <props.icon />
            </div>
          )}
        </div>
        {Number.isFinite(props.counter) && (
          <div
            className={classnames(classes.counter, {
              [classes.selected]: active,
              [classes[props.color]]: !!props.color,
              [classes.noColor]: !props.color,
            })}
          >
            {props.counter}
          </div>
        )}
      </div>
    </Tooltip>
  )
}

export default Tab
