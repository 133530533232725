// import React from 'react'

//export const Shallow = Child => shallow(<Child />)
//
// export function renderer(node) {
//   return renderer(node, {
//     wrappingComponent: Component,
//     wrappingComponentProps: {
//       locale: 'en',
//       defaultLocale: 'en',
//     },
//   })
// }
//
// export function shallowWithIntl(node) {
//   return shallow(node, {
//     wrappingComponent: Component,
//     wrappingComponentProps: {
//       locale: 'en',
//       defaultLocale: 'en',
//     },
//   })
// }
//

export function testId(...items) {
  return items.filter((item) => item).join('.')
}
