import { times } from 'ramda'
import { nanoid } from 'nanoid'

import API from 'api'

class Uploader {
  constructor() {
    this.maxRequests = 5
    this.inProgress = false
    this.sessionId = ''
    this.files = []
    this.requests = []
    this.subscriber = null
  }

  setSessionId(sessionId) {
    this.sessionId = sessionId
  }

  setSubscriber(subscriber) {
    this.subscriber = subscriber
  }

  upload(files) {
    this.files = this.files.concat(files)

    const availableRequests = this.maxRequests - this.requests.length

    this.inProgress = true

    if (availableRequests > 0) this.requests.push(this.matchFilesRequest())
  }

  dispatchEvent(type, data) {
    if (!this.subscriber) return

    this.subscriber(type, data)
  }

  scheduleUploadFile(numOfRequests) {
    times(() => {
      const firstAvailableFile = this.files.find((file) => file.matchResult && !file.uploadStarted)

      firstAvailableFile.uploadStarted = true

      this.requests.push(this.uploadFileRequest(firstAvailableFile))
    }, numOfRequests)
  }

  uploadFileRequest(file) {
    const requestId = nanoid()
    const uploadRequest = API.uploadFile(file.file, file.participantId, this.sessionId)
      .then((response) => {
        // console.log('File upload data', response)

        this.dispatchEvent('uploadComplete', { file, success: response.status === 201, data: response.data })
      })
      .catch((err) => {
        const response = (err && err.response) || {}

        // console.log('File upload err', err)

        this.dispatchEvent('uploadComplete', { file, success: response.status === 201, data: response.data })
      })
      .finally(() => {
        this.completeRequest(requestId)
      })

    return { id: requestId, request: uploadRequest }
  }

  matchFilesRequest() {
    const files = this.files.map((file) => file.file)
    const requestId = nanoid()
    const matchRequest = API.matchFiles(files, this.sessionId)
      .then((data) => {
        // console.log('matchRequest response', data)

        const results = []

        data.forEach((fileMatch) => {
          const fileIndex = this.files.findIndex(
            (file) => file.file.name.toLowerCase() === fileMatch.fileName.toLowerCase()
          )
          const file = this.files[fileIndex]

          file.matchResult = fileMatch.success

          if (!fileMatch.success) {
            // remove file from files as it doesn't need to be uploaded
            this.files.splice(fileIndex, 1)
          }

          results.push(file)
        })

        this.dispatchEvent('matchComplete', results)

        this.completeRequest(requestId)
      })
      .catch((err) => {
        this.flush()
        this.dispatchEvent('matchComplete', [])
        this.dispatchEvent('complete', err)
      })

    return { id: requestId, request: matchRequest }
  }

  completeRequest(requestId) {
    // remove request from requests list
    const requestIndex = this.requests.findIndex((request) => request.id === requestId)
    this.requests.splice(requestIndex, 1)

    const availableRequests = this.maxRequests - this.requests.length
    const remainingFiles = this.files.filter((file) => file.matchResult && !file.uploadStarted).length
    const requestsToSchedule = remainingFiles > availableRequests ? availableRequests : remainingFiles

    if (requestsToSchedule) {
      this.scheduleUploadFile(requestsToSchedule)
    } else if (this.requests.length === 0 && this.inProgress) {
      this.flush()
      this.dispatchEvent('complete', null)
    }
  }

  flush() {
    this.inProgress = false
    this.files = []
    this.requests = []
  }
}

export const uploader = new Uploader()

export default uploader
