import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { selectInactiveShipmentsOut, selectShipmentOutDiamondStatuses } from 'store/selectors'
import { getShipmentsOutAction } from 'store/actions'
import { StatusLabel, Table } from 'components/system'
import { timestampFormatter } from 'utils/dates'
import { getTotalCaratsWeight, getCaratsRange, formatNumericalValueDefault } from 'utils/diamond'
import { resolveOutboundShipmentStatusToState } from 'utils/shipments'
import { isPlural } from 'utils'
import { testIds } from 'config/testIds'

import Counter from '../../../../components/Counter'
import Buttons from './Buttons'
import classes from './styles.module.css'

const ArchiveTab = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const DEFAULT_PAGINATION = { page: 1, limit: 1000 }
  const DEFAULT_SORTING = { created_at: 'desc' }
  const shipments = useSelector(selectInactiveShipmentsOut)
  const statuses = useSelector(selectShipmentOutDiamondStatuses)
  const [page, setPage] = useState(DEFAULT_PAGINATION.page)
  const [limit] = useState(DEFAULT_PAGINATION.limit)
  const [sort] = useState(DEFAULT_SORTING)
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState([])
  const columns = [
    {
      key: 'receiver',
      title: 'Recipient',
      sortable: false,
    },
    {
      key: 'id',
      title: 'Shipment ID',
    },
    {
      key: 'created_at',
      title: 'Date sent',
      mapper: timestampFormatter,
    },
    {
      key: 'name',
      title: 'Shipment name',
    },
    {
      key: 'diamonds_count',
      title: 'Total diamonds',
      rightAlign: true,
      sortable: false,
    },
    {
      key: 'carats_weight',
      title: 'Total carats',
      rightAlign: true,
      sortable: false,
    },
    {
      key: 'carats_range',
      title: 'Carat range',
      rightAlign: true,
      mapper: (val) => `${formatNumericalValueDefault(val[0])}ct - ${formatNumericalValueDefault(val[1])}ct`,
      sortable: false,
    },
    {
      key: 'shipment_type',
      title: 'Integration',
      sortable: false,
    },
    {
      key: 'state',
      title: 'Status',
      cellComponent: StatusLabel,
      sortable: false,
    },
    {
      key: 'actions',
      title: 'Actions',
      cellComponent: Buttons,
      minWidth: '200px',
      sortable: false,
      props: {
        testId: testIds.shipmentsOutbound.archived.row.prefix,
        onClick: (row, key) => {
          if (key === 'view') {
            navigate(`/shipments/outbound/archive/${row.data.id}`)
          }
        },
      },
    },
  ]

  const getProgress = (shipment) => {
    const completeStatuses = statuses[shipment.shipment_type]
      .filter((status) => !status.active)
      .map((status) => status.key)

    const completeDiamondsCount = shipment.diamonds.filter((diamond) =>
      completeStatuses.includes(diamond.transfer_state)
    ).length

    return {
      done: completeDiamondsCount,
      total: shipment.diamonds.length,
      progress: (completeDiamondsCount / shipment.diamonds.length) * 100,
    }
  }

  const getRows = (shipments) => {
    if (!shipments) return []

    return shipments.map((shipment) => {
      return {
        id: shipment.id,
        data: {
          ...shipment,
          carats_weight: getTotalCaratsWeight(shipment.diamonds),
          carats_range: getCaratsRange(shipment.diamonds),
          progress: getProgress(shipment),
          shipment_type: shipment.shipment_type.slice(0, 1).toUpperCase() + shipment.shipment_type.slice(1),
          state: resolveOutboundShipmentStatusToState(shipment.status),
        },
      }
    })
  }

  const loadShipments = ({ page, limit, sort }) => {
    setIsLoading(true)

    dispatch(getShipmentsOutAction({ page, limit, sort })).then((response) => {
      if (response.data.content.pages <= page) {
        setHasMore(false)
      }

      setIsLoading(false)
    })
  }

  const handleSort = (sort) => {
    if (isLoading) return

    setPage(DEFAULT_PAGINATION.page)

    loadShipments({ page, limit, sort })
  }

  const handleLoadMore = () => {
    if (isLoading) return

    setPage(page + 1)

    loadShipments({ page: page + 1, limit, sort })
  }

  useEffect(() => {
    setRows(getRows(shipments))
  }, [JSON.stringify(shipments)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.archiveTabContainer}>
      <div className={classes.wrapper}>
        <div className={classes.counter}>
          <Counter
            testId={testIds.shipmentsOutbound.archived.counter}
            count={shipments.length}
            text={`Shipment${isPlural(shipments.length) ? 's' : ''}`} />
        </div>
        <div className={classes.list}>
          <Table
            columns={columns}
            rows={rows}
            sorted={{ created_at: 'desc' }}
            paginated
            hasMore={hasMore}
            onSort={handleSort}
            onLoadMore={handleLoadMore}
            testIdPrefix={testIds.shipmentsOutbound.archived.prefix}
          />
        </div>
      </div>
    </div>
  )
}

export default ArchiveTab
