import React from 'react'

import DragAndDropWrapper from '../../../DragAndDropWrapper'
import classes from './styles.module.css'

export const DropView = ({ onDrop, onFileInput }) => {
  return (
    <div className={classes.dropViewContainer}>
      <DragAndDropWrapper persistent onDrop={onDrop} onFileInput={onFileInput}>
        <div className={classes.content}></div>
      </DragAndDropWrapper>
    </div>
  )
}

export default DropView
