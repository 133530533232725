const namespace = 'NOTIFICATION'

const ADD_NOTIFICATION = `${namespace}_ADD_NOTIFICATION`
const REMOVE_NOTIFICATION = `${namespace}_REMOVE_NOTIFICATION`
const CLOSE_NOTIFICATION = `${namespace}_CLOSE_NOTIFICATION`

export const NOTIFICATION_ACTION_TYPES = {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  CLOSE_NOTIFICATION,
}
