const labels = {
  rough: 'Rough',
  planning: 'Planning',
  sawing: 'Sawing',
  bruting: 'Bruting',
  polishing: 'Polishing',
  polished: 'Polished',
  graded: 'Graded',

  held: 'In Stock',
  known: 'Transferred',
  staged: 'Staged',

  clearFilters: 'Clear filters',
  applyFilters: 'Apply Filters',

  cancel: 'Cancel',
  confirm: 'Confirm',

  all: 'All',
  complete: 'Complete',
  pending: 'Pending',
  error: 'Error',

  to: 'to',

  monday: 'Mo',
  tuesday: 'Tu',
  wednesday: 'We',
  thursday: 'Th',
  friday: 'Fr',
  saturday: 'Sa',
  sunday: 'Su',

  allTime: 'Date range',

  footer: '&copy; Terms &amp; More',

  activeShares: 'Active',
  archiveShares: 'Archive',
}

export const footer = {
  collapsed: '\u00A9 Terms \u0026 more',
  copyRight: (year) => `\u00A9 Tracr ${year}. All rights reserved.`,
  terms: 'Terms \u0026 Conditions',
  close: 'X Close',
}

export const benchmarking = {}

export const navigation = {
  bottomLink: 'Configure',
  benchmark: {
    title: 'Benchmark',
  },
  inventory: {
    rough: 'Rough',
    planning: 'Planning',
    sawing: 'Sawing',
    bruting: 'Bruting',
    polishing: 'Polishing',
    polished: 'Polished',
    graded: 'Graded',
  },
  sharing: {
    outbox: 'Outbox',
  },
  left: {
    totalDiamonds: 'Total diamonds',
    pipeline: 'INSIGHTS',
    Inventory: 'INVENTORY',
    sharing: 'SHARING',
    InventoryOverview: 'Inventory Overview',
    inventoryBrowser: 'Inventory Browser',
    Benchmarking: 'Benchmarking',
    InventoryInsights: 'Inventory Insights',
    InventoryInMotion: 'Inventory In Motion',
  },
}

export const table = {
  integration: {
    date: 'Date',
    platformId: 'Platform ID',
    state: 'State',
    status: 'Status',
    eventType: 'Event type',
    platformTimestamp: 'Platform Timestamp',
    diamondIdServiceTimestamp: 'Diamond ID Timestamp',
    diamondId: 'Diamond ID',
    ditSessionId: 'DIT Session',
    errorCode: 'Error code',
    loadingMore: 'Loading...',
    noMore: '',
    nothingFound: '',
    markAsRead: 'Mark as Read',
    markAsUnread: 'Mark as Unread',
    downloadAsCSV: 'Download as CSV',
    viewAllDetails: 'View all Details',
  },
  inventory: {
    participant_timestamp: 'Date',
    provenance: 'Provenance',
    'rough.carats': 'Carat',
    'rough.colour': 'Colour',
    'rough.model': 'Model',
    'rough.fluorescence_intensity': 'Flu Intensity',
    'rough.fluorescence_colour': 'Flu Colour',
    'rough.stress': 'Stress',
    'rough.quality': 'Quality',
    'rough.files': 'Files',

    'intermediate.carats': 'Carat',
    'intermediate.colour': 'Colour',
    'intermediate.model': 'Model',
    'intermediate.fluorescence_intensity': 'Flu Intensity',
    'intermediate.fluorescence_colour': 'Flu Colour',
    'intermediate.stress': 'Stress',
    'intermediate.quality': 'Quality',
    'intermediate.files': 'Files',

    'polished.carats': 'Carat',
    'polished.shape': 'Shape',
    'polished.colour': 'Colour',
    'polished.clarity': 'Clarity',
    'polished.cut_grade': 'Cut',
    'polished.fluorescence_intensity': 'Flu Intensity',
    'polished.fluorescence_colour': 'Flu Colour',
    'polished.files': 'Files',

    benchmark: 'Benchmark',
    diamond_id: 'ID',
    participant_id: 'Participant ID',
    possession_state: 'Status',
    actions: 'Actions',
    transferDiamonds: 'Transfer diamonds',
    viewProfiles: 'View Profiles',
    loadingMore: 'Loading...',
    noMore: '',
  },
  sharing: {
    createdAt: 'Shared on',
    endedOn: 'Ended on',
    diamondsCount: 'Diamonds shared',
    provenances: 'Provenance',
    sharedState: 'Shared state',
    sharedVia: 'Shared via',
    actions: 'Actions',
    loadingMore: 'Loading...',
    noMore: '',
    twc: 'twc',
  },
}

export const posessionStates = {
  held: {
    tooltip: 'In Stock',
    value: 'In Stock',
  },
  in_transit: {
    tooltip: 'Pending Out',
    value: 'Pending Out',
  },
  staged: {
    tooltip: 'Pending In',
    value: 'Pending In',
  },
  known: {
    tooltip: 'Transferred',
    value: 'Transferred',
  },
}

export const roughPosessionStates = {
  held: 'Owned',
  in_transit: 'Pending Transfer',
  transferred: 'Transferred',
}

export default labels
