export const selectSettingsStates = (state) => {
  return state.settings.data.states
}

export const selectSettingsRanges = (state) => {
  return state.settings.data.ranges
}

export const selectChartsSettings = (state) => {
  return state.settings.data.charts
}

export const selectChartsSettingsPerStage = (stage) => {
  return (state) => {
    const charts = state.settings.data.charts
    const perStage = charts.filter((chart) => chart.current_stage === stage)

    return perStage
  }
}

export const selectViewColumns = (lifecycle) => {
  return (state) => {
    const key = Object.keys(state.settings.data.viewColumns).find((key) => key.includes(lifecycle))

    return state.settings.data.viewColumns[key]
  }
}

export const selectPeers = (state) => {
  return state.settings.data.peers
}

export const selectClassificationPopup = (state) => {
  return state.settings.data.classificationPopup
}

export const selectNavState = (state) => {
  return state.settings.data.nav
}

export const selectNavIsCollapsed = (state) => {
  return state.settings.data.nav.isCollapsed
}

export const selectNavExpandedItems = (state) => {
  return state.settings.data.nav.expandedItems
}

export const selectDITTab = (state) => {
  return state.settings.data.dit.tab
}

export const selectDITHideSubmittedDiamonds = (state) => {
  return state.settings.data.dit.hideSubmittedDiamonds
}

export const selectShipmentsInTab = (state) => {
  return state.settings.data.shipmentsIn.tab
}

export const selectShipmentsOutTab = (state) => {
  return state.settings.data.shipmentsOut.tab
}
