import React from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import API from 'api'
import showNotification from 'containers/Notifications/controller'
import { Button } from 'components/system'
import { DITSessionCompleteCircleIcon } from 'components/Svg'
import { completeSessionAction } from 'store/actions'
import { selectDITDiamonds, selectDITSessionId } from 'store/selectors'
import testids from 'config/testIds'

import classes from './styles.module.css'

export const SessionCompletePage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const diamonds = useSelector(selectDITDiamonds)
  const sessionId = useSelector(selectDITSessionId)
  const testIds = testids.dit

  const countSubmittedExcludedDiamonds = () => {
    const updatedStats = { excluded: 0, submitted: 0 }

    diamonds.forEach((diamond) => {
      if (!diamond.has_files || diamond.skipped) updatedStats.excluded++
      else updatedStats.submitted++
    })

    return updatedStats
  }

  const handleViewHistoryClick = () => {
    dispatch(completeSessionAction())

    navigate('/integration/dit')
  }

  const handleDownloadSessionReport = () => {
    API.downloadSessionReport(sessionId, 'report', `transaction-report-${sessionId}.xlsx`)
      .then(() => {
        showNotification({
          title: 'File downloaded',
          variant: 'success',
        })
      })
      .catch(() => {
        showNotification({
          title: 'File download failed',
          variant: 'error',
        })
      })
  }

  const stats = countSubmittedExcludedDiamonds()

  if (diamonds.length === 0) return <Navigate to="/integration/dit" />

  return (
    <div className={classes.sessionCompletePageContainer}>
      <div className={classes.content}>
        <div className={classes.billboard}>
          <div className={classes.svg}>
            <DITSessionCompleteCircleIcon />
          </div>
          <div className={classes.title}>Data submitted successfully</div>
          <div className={classes.description}>The diamonds in this session will now be processed through Tracr</div>
          <div className={classes.statsdiv}>
            <div className={classes.stats} data-test-id={testIds.submittedDiamonds}>
              <div className={classes.statName}>Diamonds Submitted</div>
              <div className={classes.statCount}>{stats.submitted}</div>
            </div>
            <div className={classes.stats} data-test-id={testIds.excludedDiamonds}>
              <div className={classes.statName}>Diamonds Excluded</div>
              <div className={classes.statCount}>{stats.excluded}</div>
            </div>
          </div>
          <div className={classes.actions}>
            <div className={classes.action}>
              <Button
                className={classNames(classes.button, classes.bordered)}
                variant="secondary"
                onClick={handleDownloadSessionReport}
                data-test-id={testIds.downloadReportButton}
              >
                Download report
              </Button>
            </div>
            <div className={classes.action}>
              <Button
                className={classes.button}
                onClick={handleViewHistoryClick}
                data-test-id={testIds.viewDiamondHistoryButton}
              >
                View in history
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionCompletePage
