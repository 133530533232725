import React from 'react'

import { Modal } from 'components/system'

import classes from './styles.module.css'
import { testIds } from 'config/testIds'

const RemoveDiamondsModal = ({ visible, onCancel, onConfirm, children, testId }) => {
  return (
    <Modal
      isOpen={visible}
      title={'Remove diamonds'}
      confirmLabel="Confirm"
      cancelTestId={testIds.newShipment.removeDiamondsModal.cancelButton}
      confirmTestId={testIds.newShipment.removeDiamondsModal.confirmButton}
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm()}
    >
      <div className={classes.removeDiamondsModalContainer}>{children}</div>
    </Modal>
  )
}

export default RemoveDiamondsModal
