import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { CheckboxSVG } from 'components/Svg/form-system'

import classes from './styles.module.css'
/**
 * @param {Number} value : 0 - unchecked, 1 - partial, 2 - checked
 */
export const Checkbox = ({ value = 0, label, isDisabled, alignTop, onChange, ...rest }) => {
  const [state, setState] = useState(value)
  const [isHovered, setIsHovered] = useState(false)

  const toggleState = () => {
    if (isDisabled) return

    let newState

    if (state === 0) newState = 2
    else if (state === 1) newState = 0
    else newState = 0

    setState(newState)

    if (onChange) onChange(newState)
  }

  const toggleHover = () => {
    if (isDisabled) return

    setIsHovered(!isHovered)
  }

  useEffect(() => {
    if (value !== state) setState(value)
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.checkboxContainer} {...rest}>
      <div
        className={classnames(classes.wrapper, { [classes.disabled]: isDisabled, [classes.alignTop]: alignTop })}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        <div className={classnames(classes.checkbox, { checkbox: true })} onClick={toggleState}>
          <CheckboxSVG value={state} isDisabled={isDisabled} isHovered={isHovered} />
        </div>
        {label && (
          <div className={classnames(classes.label, { [classes.visible]: !!label })} onClick={toggleState}>
            {label}
          </div>
        )}
      </div>
    </div>
  )
}

Checkbox.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  alignTop: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Checkbox
