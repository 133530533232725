import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import classes from './styles.module.css'

export const Button = ({ children, variant = 'primary', size = 'm', onClick, disabled, className, ...rest }) => {
  return (
    <button
      className={classnames(
        classes.button,
        classes[variant],
        {
          [classes.sizeS]: size === 's',
          [classes.sizeM]: size === 'm',
          [classes.disabled]: disabled,
        },
        className
      )}
      {...(disabled ? {} : { onClick })}
      {...rest}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  size: PropTypes.oneOf(['m', 's']),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
}

export default Button
