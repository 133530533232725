import React, { useEffect, useState, useRef } from 'react'
import { uniq } from 'ramda'
import { debounce } from 'lodash'
import classnames from 'classnames'

import { Spinner } from 'components/system'

import Header from './Header'
import Body from './Body'
import classes from './styles.module.css'
import { testId } from 'utils/testHelper'

export const Table = ({
  columns = [],
  rows = [],
  sorted = {},
  groupBy = '',
  grow,
  centered,
  multiline,
  height,
  heightSub = 0,
  hasMore,
  isLoading,
  isEmpty,
  virtualized,
  paginated,
  emptyState,
  children,
  onLoadMore,
  onSort,
  onRowHover,
  onRowLeave,
  onRowClick,
  testIdPrefix = ''
}) => {
  const tableRef = useRef()
  const headerRef = useRef()
  const bodyRef = useRef()
  const heightRef = useRef()
  const [minTableWidth, setMinTableWidth] = useState(0)
  const [maxTableHeight, setMaxTableHeight] = useState(0)
  // const [bodyHeight, setBodyHeight] = useState('')
  const bodyHeight = useRef(0)
  const [columnsWidth, setColumnsWidth] = useState([])
  const [sortOrder, setSortOrder] = useState(sorted)
  const defalutMinWidth = 90

  const handleLoadMore = debounce(() => {
    if (onLoadMore) onLoadMore()
  }, 150)

  const handleSortClick = (title) => {
    if (isLoading) return

    let newOrder

    if (sortOrder[title]) {
      newOrder = sortOrder[title] === 'desc' ? 'asc' : 'desc'
    } else {
      newOrder = 'desc'
    }

    const newSort = { [title]: newOrder }

    if (bodyRef.current) {
      bodyRef.current.querySelector('.tableBody').scrollTop = 0
    }

    setSortOrder(newSort)

    if (paginated) {
      onSort(newSort)
    }
  }

  const handleScroll = (evt) => {
    if (!paginated || !hasMore) return

    if (isLoading) return evt.preventDefault()

    const target = evt.target
    const scrollTop = target.scrollTop
    const scrollMax = target.scrollHeight - target.offsetHeight
    const scrolledPrc = scrollTop / scrollMax

    if (scrolledPrc > 0.85) handleLoadMore()
  }

  const updateColumnsWidth = () => {
    if (!columns.length || !tableRef.current) return

    /**
     * Small note about total width. Currently it includes scrollbar width (8px)
     * if scrollbar is present, therefore it distributes more space than actually available.
     * However the content does not overflow because each row is a flex and each cell shrinks
     * a little after width is applied.
     */
    const totalWidth = tableRef.current.offsetWidth
    const newColumnsWidth = []

    /**
     * Rules are -> minWidth has the biggest priority
     * a cell will never scale down below that value,
     * then a defalutMinWidth
     * then a width of the cell
     * then layout value which is a % of remaining space
     */

    let totalRemainingSpace = totalWidth
    // first go and set all minWidth cells

    columns.forEach((column, index) => {
      const minWidth = column.minWidth || `${defalutMinWidth}px`

      const unit = minWidth.indexOf('%') === -1 ? 'px' : '%'
      const cleanMinValue = parseInt(minWidth.replace(/%|px/, ''))

      if (unit === 'px') {
        newColumnsWidth[index] = cleanMinValue * 1
      } else {
        newColumnsWidth[index] = totalWidth * (cleanMinValue / 100)
      }

      if (newColumnsWidth[index] < cleanMinValue) {
        newColumnsWidth[index] = cleanMinValue
      }

      totalRemainingSpace -= newColumnsWidth[index]
    })

    const minTableWidth = newColumnsWidth.reduce((a, b) => a + b, 0)
    setMinTableWidth(minTableWidth)

    // now remaining space can be destributed between cells with set width
    // set width is can be adjusted between minWidth and maxWidth.
    // maxWidth is calculated from remaining space
    columns.forEach((column, index) => {
      if (!column.width) return

      const width = column.width
      const unit = width.indexOf('%') === -1 ? 'px' : '%'
      const cleanValue = parseInt(width.replace(/%|px/, ''))
      const cleanMinValue = column.minWidth ? parseInt(column.minWidth.replace(/%|px/, '')) : defalutMinWidth

      // give back the minimum width that was already
      // allocated this this cell in the prev cycle.
      // This cell will have its width redestributed from scratch
      totalRemainingSpace += cleanMinValue

      if (unit === 'px') {
        newColumnsWidth[index] = cleanValue * 1
      } else {
        newColumnsWidth[index] = totalWidth * (cleanValue / 100)
      }

      // if set width is bigger than there is space available
      // limit it to totalRemainingSpace
      if (newColumnsWidth[index] > totalRemainingSpace) {
        newColumnsWidth[index] = totalRemainingSpace
      } else if (newColumnsWidth[index] < cleanMinValue) {
        newColumnsWidth[index] = cleanMinValue
      }

      totalRemainingSpace -= newColumnsWidth[index]
    })

    const hasScrollbar = bodyHeight.current - rows.length * 48 < 0

    if (hasScrollbar) totalRemainingSpace -= 8

    // last step
    // calculate layout widths, in this case all cells with no set width
    // should split remaining space equally
    if (totalRemainingSpace > 0) {
      let widths = columns.map((column, index) => (column.width ? 0 : newColumnsWidth[index]))
      let distributeToCells = widths.filter((width) => width)
      let isEvened = false
      const distinctWidths = uniq(distributeToCells).sort((a, b) => b - a)

      // try to even out starting from the biggest
      distinctWidths.forEach((width) => {
        if (isEvened) return

        const widthNeeded = distributeToCells.length * width
        const widthAvailable = distributeToCells.reduce((a, b) => a + b, 0) + totalRemainingSpace

        if (widthAvailable >= widthNeeded) {
          const extraAvailableWidth = (widthAvailable - widthNeeded) / distributeToCells.length

          newColumnsWidth.forEach((_preWidth, index) => {
            if (widths[index] === 0) return

            newColumnsWidth[index] = width + extraAvailableWidth
          })

          isEvened = true
        } else {
          // filter out (mark as 0) cells with that width as they we cannot
          // equal to their width and those cells cannot go below
          // their min width, so instead try to even out other cells
          widths = widths.map((givenWidth) => (givenWidth === width ? 0 : givenWidth))
          distributeToCells = widths.filter((width) => width)
        }
      })
    }

    setColumnsWidth(newColumnsWidth)
  }

  const updateBodyHeight = () => {
    if (!headerRef.current || !bodyRef.current) return (bodyHeight.current = 0)

    if (heightRef.current) {
      const headerHeight = headerRef.current.offsetHeight || 59
      const remainingHeight = heightRef.current - headerHeight

      bodyHeight.current = remainingHeight
    } else {
      const height = document.body.offsetHeight - bodyRef.current.getBoundingClientRect().top - 24 - heightSub

      bodyHeight.current = height
    }
  }

  const updateMaxTableHeight = () => {
    if (!tableRef.current) return null

    const maxHeight =
      heightRef.current || document.body.offsetHeight - tableRef.current.getBoundingClientRect().top - 24

    setMaxTableHeight(maxHeight)
  }

  const onTableRef = (node) => {
    if (!node) return

    tableRef.current = node
  }

  const onBodyRef = (node) => {
    if (!node) return

    bodyRef.current = node

    // resizeTable()
  }

  const getColumnWidthUnit = (width) => {
    return width.indexOf('%') === -1 ? 'px' : '%'
  }

  const getColumnWidthInPX = (width, totalWidth) => {
    const unit = getColumnWidthUnit(width)
    const cleanValue = parseInt(width.replace(/%|px/, ''))

    if (unit === 'px') {
      return cleanValue * 1
    } else {
      return totalWidth * (cleanValue / 100)
    }
  }

  const onColumnResize = (key, distanceX) => {
    if (isLoading) return

    const totalWidth = tableRef.current.offsetWidth
    /**
     * Rules are -> minWidth has the biggest priority
     * a cell will never scale down below that value,
     * then a defalutMinWidth
     * then a width of the cell
     * then layout value which is a % of remaining space
     */
    const columnIndex = columns.findIndex((column) => column.key === key)
    const nextColumns = columns.slice(columnIndex + 1)
    let newColumnsWidth = []

    if (distanceX > 0) {
      // total width from resized column to right border
      // minus columns with min width applied
      const availableWidthPerColumn = columns
        .map((column, index) => {
          const currentColumnWidth = columnsWidth[index]

          if (!column.minWidth) return currentColumnWidth - defalutMinWidth

          const minValueInPX = getColumnWidthInPX(column.minWidth, totalWidth)

          let value

          value = currentColumnWidth - minValueInPX

          if (value < 0) value = 0

          return value
        })
        .slice(columnIndex + 1)
      const totalAvailableWidth = availableWidthPerColumn.reduce((sum, columnWidth) => sum + columnWidth, 0)
      const actualDistance = distanceX > totalAvailableWidth ? totalAvailableWidth : distanceX

      const calculateColumnDeductions = (distanceRemaining, availablePerColumn, deductionsGiven) => {
        const resizePerColumn = distanceRemaining / availablePerColumn.filter((width) => width).length
        const deductions = deductionsGiven || new Array(availablePerColumn.length).fill(0)
        let newDistanceRemaining = distanceRemaining

        const newAvailablePerColumn = availablePerColumn.map((widthAvailable, index) => {
          if (!widthAvailable) return 0

          let subtracted = 0

          if (widthAvailable >= resizePerColumn) {
            subtracted = resizePerColumn
          } else {
            subtracted = widthAvailable
          }

          deductions[index] += subtracted

          newDistanceRemaining -= subtracted

          return widthAvailable - subtracted
        })

        if (newDistanceRemaining <= 0.1) return deductions

        return calculateColumnDeductions(newDistanceRemaining, newAvailablePerColumn, deductions)
      }

      const eachColumnDeduction = calculateColumnDeductions(actualDistance, availableWidthPerColumn)

      newColumnsWidth = columnsWidth.map((value, index) => {
        if (index <= columnIndex) return value

        return value - eachColumnDeduction[index - columnIndex - 1]
      })

      newColumnsWidth[columnIndex] += actualDistance
    } else {
      const column = columns[columnIndex]

      let availableDistance = 0

      if (column.minWidth) {
        const value = getColumnWidthInPX(column.minWidth, totalWidth)

        availableDistance = columnsWidth[columnIndex] - value
      } else {
        availableDistance = columnsWidth[columnIndex] - defalutMinWidth
      }

      const actualDistance = availableDistance >= Math.abs(distanceX) ? distanceX : -availableDistance

      // decreasing column size, evenly distribute width that was freed
      const widthPerColumn = Math.abs(actualDistance / nextColumns.length)

      newColumnsWidth = columnsWidth.map((value, index) => {
        if (index <= columnIndex) return value

        return value + widthPerColumn
      })

      newColumnsWidth[columnIndex] += actualDistance
    }

    setColumnsWidth(newColumnsWidth)
  }

  const resizeTable = () => {
    updateBodyHeight()
    updateColumnsWidth()
    updateMaxTableHeight()
  }

  // reset sort order when sorted changes
  useEffect(() => {
    setSortOrder(sorted)
  }, [Object.keys(sorted)[0]])

  useEffect(() => {
    if (!height) return

    heightRef.current = height

    resizeTable()
  }, [height]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const onResize = debounce(() => {
      resizeTable()
    }, 50)

    window.addEventListener('resize', onResize)

    onResize()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [JSON.stringify(columns), rows.length]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classnames(classes.tableContainer, { container: true })} ref={onTableRef}>
      <div className={classes.content} style={{ minWidth: minTableWidth, maxHeight: maxTableHeight }}>
        <div className={classes.tableHeader} ref={headerRef}>
          <Header
            columns={columns}
            rows={rows}
            grow={grow}
            centered={centered}
            widths={columnsWidth}
            sorted={sortOrder}
            showSort
            onSort={handleSortClick}
            onColumnResize={onColumnResize}
            onRender={resizeTable}
            testIdPrefix={testId(testIdPrefix, 'column')}
          />
        </div>
        {!isEmpty && (
          <div className={classes.tableBody} style={{ height: bodyHeight.current }} ref={onBodyRef}>
            <Body
              columns={columns}
              rows={rows}
              widths={columnsWidth}
              height={bodyHeight.current}
              cellHeight={48}
              virtualized={virtualized}
              paginated={paginated}
              multiline={multiline}
              groupBy={groupBy}
              sortedBy={sortOrder}
              onScroll={handleScroll}
              onRowClick={onRowClick}
              onRowHover={onRowHover}
              onRowLeave={onRowLeave}
              testIdPrefix={testIdPrefix}
            />
            {isLoading && (
              <div className={classes.loader}>
                <Spinner />
              </div>
            )}
          </div>
        )}
        {isEmpty && <div>{emptyState || children}</div>}
      </div>
    </div>
  )
}

export default Table
