import { DIT_ACTION_TYPES } from 'store/actionTypes'
import { resolveActionType } from '../utils'

export const selectDITSession = (state) => {
  return state.dit.data.session
}

export const selectDITSessions = (state) => {
  return state.dit.data.sessions
}

export const selectDITFilters = (state) => {
  return state.dit.data.filters
}

export const selectIntegrationTypes = (state) => {
  return state.dit.data.integrationTypes
}

export const selectCSVFile = (state) => {
  return state.dit.data.csvFile
}

export const selectDITSessionId = (state) => {
  return state.dit.data.session ? state.dit.data.session.session_id : null
}

export const selectDITDiamonds = (state) => {
  return state.dit.data.diamonds
}

export const selectMissingFiles = (state) => {
  return state.dit.data.missingFiles
}

export const selectFailedFiles = (state) => {
  return state.dit.data.failedFiles
}

export const selectUnexpectedFiles = (state) => {
  return state.dit.data.unexpectedFiles
}

export const selectCSVIsUploading = (state) => {
  const requestType = resolveActionType('UPLOAD_CSV_FILE', DIT_ACTION_TYPES.UPLOAD_CSV_FILE.REQUEST)

  const request = state.dit.requests[requestType]

  if (!request) return false

  return request.loading
}

export const selectDITupdatedAt = (state) => {
  return state.dit.data.updatedAt
}

export const selectDITCSVErrors = (state) => {
  return state.dit.data.csvErrors
}
