import React from 'react'

import DiamondGallery from 'containers/Gallery'

import Prop from './Prop'
import classes from './styles.module.css'

export const Section = ({ diamond, lifecycle, testIdPrefix, props }) => {
  return (
    <div className={classes.sectionContainer}>
      <div className={classes.wrapper}>
        <h3 className={classes.title}>{lifecycle} diamond details</h3>
        <div className={classes.gallery}>
          <DiamondGallery testIdPrefix={`${testIdPrefix}.${lifecycle}`} diamond={diamond} section={lifecycle} />
        </div>
        <div className={classes.props}>
          {props.map((prop) => {
            if (!prop.label || !prop.value) return null

            return (
              <div className={classes.prop} key={`prop-${prop.label}`}>
                <Prop testIdPrefix={`${testIdPrefix}.${lifecycle}`} title={prop.label} value={prop.value} renderer={prop.renderer} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Section
