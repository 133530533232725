import React from 'react'

import classes from './styles.module.css'

export const SectionTitle = ({ title = '', children }) => {
  return (
    <div className={classes.sectionTitleContainer}>
      <div className={classes.wrapper}>
        <h3 className={classes.title}>{title}</h3>
        <div className={classes.rest}>{children}</div>
      </div>
    </div>
  )
}

export default SectionTitle
