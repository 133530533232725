import React from 'react'
import env from '@beam-australia/react-env'

import classes from './styles.module.css'

export const DevBanner = () => {
  const envType = env('ENV_TYPE')

  if (!envType || /^prod/i.test(envType)) return null

  return (
    <div className={classes.bannerContainer}>
      <span>{envType}</span> ENVIRONMENT
    </div>
  )
}

export default DevBanner
