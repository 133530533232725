import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import { getAvailableLifecylclesAction, getTotalDiamondsCountAction, updateNavIsCollapsedAction } from 'store/actions'
import { selectNavIsCollapsed, selectTotalDiamondsCount, selectAvailableLifecycles } from 'store/selectors'
import { useWindowResize } from 'components/utils/hooks'
import { LogoSVG } from 'components/Svg'
import { MinimizedLogoIcon } from 'components/Svg/nav'
import { delay } from 'utils'
import routes from 'config/routes/index.js'
import featureToggles from 'config/featureToggles'
import navConfig from 'config/nav'
import copy from 'text/en'
import version from 'version.json'

import TopLevelLink from './TopLevelLink'
import classes from './styles.module.css'

const NavigationContainer = () => {
  const dispatch = useDispatch()
  const totalDiamondCount = useSelector(selectTotalDiamondsCount)
  const propsNavIsCollapsed = useSelector(selectNavIsCollapsed)
  const availableLifecycles = useSelector(selectAvailableLifecycles)
  const [navIsCollapsed, setNavIsCollapsed] = useState(propsNavIsCollapsed)
  const isMouseOver = useRef(false)
  const isMouseOverDisabled = useRef(false)
  const mouseOverDelayId = useRef(null)

  // Filters out routes that do not belong to the logged in section
  // routes like /login or *
  // Agree, don't like this approach at all, too complicated,
  // better redo with a standard static Routes file and a static nav file.
  const getMenuRoutes = (routes) => {
    let menuRoutes

    routes.find((route) => {
      if (route.menuRoot) menuRoutes = route.children
      else if (route.children) menuRoutes = getMenuRoutes(route.children)

      return menuRoutes
    })

    return menuRoutes
  }

  const triggerResizeEvent = () => {
    window.dispatchEvent(new Event('resize'))
  }

  const toggleNav = () => {
    if (!propsNavIsCollapsed) {
      isMouseOverDisabled.current = true

      if (mouseOverDelayId.current) delay.cancel(mouseOverDelayId.current)

      // hide the green toggle box
      isMouseOver.current = false
    }

    dispatch(updateNavIsCollapsedAction(!propsNavIsCollapsed)).then(() => {
      // then is needed to give collapse animation time to finish
      if (!propsNavIsCollapsed) {
        isMouseOverDisabled.current = false
      }

      // needed for Table component to recalculate width
      triggerResizeEvent()
    })
  }

  const formatTotalDiamonds = (number, isCollapsed) => {
    if (isCollapsed) {
      if (number > 1000) {
        return Math.floor(number / 1000) + 'k'
      } else {
        return number
      }
    } else {
      return totalDiamondCount.toLocaleString()
    }
  }

  const mouseEnterManager = () => {
    if (isMouseOverDisabled.current) return

    if (mouseOverDelayId) delay.cancel(mouseOverDelayId)

    mouseOverDelayId.current = delay.start(() => {
      isMouseOver.current = true

      if (propsNavIsCollapsed) setNavIsCollapsed(false)
      mouseOverDelayId.current = null
    }, navConfig.mouseEnterLeaveDelay)
  }

  const mouseLeaveManager = () => {
    if (isMouseOverDisabled.current) return

    if (mouseOverDelayId) delay.cancel(mouseOverDelayId)

    // no need to change the state is mouse over was never set to true
    if (!isMouseOver.current) return

    mouseOverDelayId.current = delay.start(() => {
      isMouseOver.current = false

      if (propsNavIsCollapsed) setNavIsCollapsed(true)

      mouseOverDelayId.current = null
    }, navConfig.mouseEnterLeaveDelay)
  }

  const size = useWindowResize()
  const scrollContainer = useRef()

  const showShadows =
    size.height !== undefined &&
    scrollContainer &&
    scrollContainer.current &&
    scrollContainer.current.offsetHeight < scrollContainer.current.scrollHeight

  useEffect(() => {
    dispatch(getTotalDiamondsCountAction())
    dispatch(getAvailableLifecylclesAction())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setNavIsCollapsed(propsNavIsCollapsed)

    if (!propsNavIsCollapsed) {
      isMouseOverDisabled.current = true
    }
  }, [propsNavIsCollapsed])

  return (
    <div
      className={classnames(classes.navigationContainer, {
        [classes.navigationContainerCollapsed]: navIsCollapsed,
      })}
      onMouseEnter={mouseEnterManager}
      onMouseLeave={mouseLeaveManager}
    >
      <div className={classes.mask}>
        <div className={classes.content}>
          <div className={classes.logo}>{navIsCollapsed ? <MinimizedLogoIcon /> : <LogoSVG />}</div>
          <div
            className={classnames(classes.counter, {
              [classes.counterCollapsed]: navIsCollapsed,
              [classes.hidden]: featureToggles.dam === 0,
            })}
            data-test-id="navigationBar.totalDiamonds"
          >
            <div className={classes.count}>{formatTotalDiamonds(totalDiamondCount, navIsCollapsed)}</div>
            <div className={classes.copy}>
              {navIsCollapsed ? copy.navigation.total_diamonds_short : copy.navigation.total_diamonds}
            </div>
          </div>

          <div className={classnames(classes.nav, { [classes.extraMargin]: featureToggles.dam === 0 })}>
            {showShadows && <div className={classes.shadowTop} />}
            <div className={classes.topSection} ref={scrollContainer}>
              <ul className={classes.topLevelList}>
                {getMenuRoutes(routes)
                  .filter((route) => !route.placeAtBottom)
                  .filter((route) => route.isMenuItem !== false)
                  .filter((route) => route.feature && featureToggles[route.feature] !== 0)
                  .map((route) => (
                    <TopLevelLink
                      key={route.path || route.title}
                      route={route}
                      availableLifecycles={availableLifecycles}
                      navIsCollapsed={navIsCollapsed}
                    />
                  ))}
              </ul>
            </div>
            <div className={classes.bottomSection}>
              {showShadows && <div className={classes.shadowBottom} />}
              <ul className={classes.topLevelList}>
                {getMenuRoutes(routes)
                  .filter((route) => route.placeAtBottom)
                  .map((route) => (
                    <TopLevelLink
                      key={route.path || route.title}
                      route={route}
                      navIsCollapsed={navIsCollapsed}
                      propsNavIsCollapsed={propsNavIsCollapsed}
                      toggleNav={toggleNav}
                    />
                  ))}
              </ul>
            </div>
          </div>
          <div className={classnames(classes.version, { [classes.versionCollapsed]: navIsCollapsed })}>
            {version.version}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavigationContainer
