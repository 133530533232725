import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { omit, clone, pick } from 'ramda'
import classnames from 'classnames'

import { getFiltersAction, applyFiltersAction, clearFiltersAction, selectFiltersAction } from 'store/actions'
import { selectAvailableFilters, selectSelectedFilters, selectAppliedFilters, selectLifecycle } from 'store/selectors'
import Filter from 'containers/Filters/Filter'
import MoreFiltersPopup from 'containers/Filters/MoreFiltersPopup'
import { Search, DateRangePicker, Button, FilterButtons } from 'components/system'
import { featureToggles } from 'config'
import testids from 'config/testIds'

import schema from './schema'
import classes from './styles.module.css'
import { formatNumericalValue } from 'utils/diamond'
import { testId } from 'utils/testHelper'

const FiltersContainer = ({ testIdPrefix = '' }) => {
  const dispatch = useDispatch()
  const [buttonsState, setButtonsState] = useState([false, false])
  const availableFilters = clone(useSelector(selectAvailableFilters))
  const selectedFilters = useSelector(selectSelectedFilters)
  const appliedFilters = useSelector(selectAppliedFilters)
  const lifecycle = useSelector(selectLifecycle)
  const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState(false)
  const testIds = testids.filters

  const featureControlFilter = (list) => {
    if (!list) return []

    return list.filter((filter) => {
      switch (filter) {
        case 'benchmark':
          return featureToggles.benchmarking === 2
        default:
          return true
      }
    })
  }

  const filtersPerState = {
    rough: ['id', 'provenance', 'carat_range', 'rough.colour', 'rough.model', 'rough.quality', 'matched'],
    split: ['id', 'provenance', 'carat_range', 'rough.colour', 'rough.model', 'rough.quality', 'matched'],
    'planning|sawing|bruting|polishing': [
      'id',
      'provenance',
      'carat_range',
      'intermediate.shape',
      'intermediate.colour',
      'intermediate.quality',
      'benchmark',
      'matched',
    ],
    'polished|graded': [
      'id',
      'provenance',
      'unlock_status',
      'assurance_level',
      'carat_range',
      'polished.clarity',
      'polished.colour',
      'polished.cut_grade',
      'polished.polish_quality',
      'polished.shape',
      'matched',
    ],
  }

  const moreFiltersPerState = {
    rough: [
      {
        title: 'Fancy',
        filters: [
          { title: 'Colour', key: 'rough.fancy_colour' },
          { title: 'Colour intensity', key: 'rough.fancy_colour_intensity' },
          { title: 'Colour overtone', key: 'rough.fancy_colour_overtone' },
        ],
      },
      {
        title: 'Fluorescence',
        filters: [
          { title: 'Colour', key: 'rough.fluorescence_colour' },
          { title: 'Intensity', key: 'rough.fluorescence_intensity' },
        ],
      },
      { title: 'Media', key: 'rough.media' },
      { title: 'Sight number', key: 'sight_no' },
      { title: 'Sight year', key: 'sight_year' },
      { title: 'Stress', key: 'rough.stress' },
      { title: 'Supplementary files', key: 'rough.supplementary_files' },
    ],
    split: [
      {
        title: 'Fancy',
        filters: [
          { title: 'Colour', key: 'rough.fancy_colour' },
          { title: 'Colour intensity', key: 'rough.fancy_colour_intensity' },
          { title: 'Colour overtone', key: 'rough.fancy_colour_overtone' },
        ],
      },
      {
        title: 'Fluorescence',
        filters: [
          { title: 'Colour', key: 'rough.fluorescence_colour' },
          { title: 'Intensity', key: 'rough.fluorescence_intensity' },
        ],
      },
      { title: 'Media', key: 'rough.media' },
      { title: 'Sight number', key: 'sight_no' },
      { title: 'Sight year', key: 'sight_year' },
      { title: 'Stress', key: 'rough.stress' },
    ],
    'planning|sawing|bruting|polishing': [
      {
        title: 'Fluorescence',
        filters: [
          { title: 'Colour', key: 'intermediate.fluorescence_colour' },
          { title: 'Intensity', key: 'intermediate.fluorescence_intensity' },
        ],
      },
      { title: 'Model', key: 'intermediate.model' },
      { title: 'Media', key: 'intermediate.media' },
      { title: 'Sight number', key: 'sight_no' },
      { title: 'Sight year', key: 'sight_year' },
    ],
    'polished|graded': [
      {
        title: 'Crown',
        filters: [
          { title: 'Height', key: 'polished.crown_height', slider: true, unit: '%', mapper: formatNumericalValue },
          { title: 'Angle ', key: 'polished.crown_angle', slider: true, unit: '°', mapper: formatNumericalValue },
        ],
      },
      {
        title: 'Culet',
        filters: [
          { title: 'Size', key: 'polished.culet_size' },
          { title: 'Condition ', key: 'polished.culet_condition' },
        ],
      },
      { title: 'Depth %', key: 'polished.depth_percent', slider: true, unit: '%', mapper: formatNumericalValue },
      {
        title: 'Diameter',
        filters: [
          { title: 'Minimum', key: 'polished.diameter_minimum', slider: true, mapper: formatNumericalValue },
          { title: 'Maximum ', key: 'polished.diameter_maximum', slider: true, mapper: formatNumericalValue },
        ],
      },
      {
        title: 'Fancy',
        filters: [
          { title: 'Colour', key: 'polished.fancy_colour' },
          { title: 'Colour intensity', key: 'polished.fancy_colour_intensity' },
          { title: 'Colour overtone', key: 'polished.fancy_colour_overtone' },
        ],
      },
      {
        title: 'Fluorescence',
        filters: [
          { title: 'Colour', key: 'polished.fluorescence_colour' },
          { title: 'Intensity', key: 'polished.fluorescence_intensity' },
        ],
      },
      {
        title: 'Girdle',
        filters: [
          { title: 'Condition', key: 'polished.girdle_condition' },
          { title: 'Thickness from', key: 'polished.girdle_thickness_from' },
          { title: 'Thickness to', key: 'polished.girdle_thickness_to' },
        ],
      },
      { title: 'Grading Certificates', key: 'polished.grading_certificates' },
      {
        title: 'Length to Width Ratio',
        key: 'polished.length_to_width',
        slider: true,
        step: 0.0001,
        unit: '',
        mapper: formatNumericalValue,
      },
      {
        title: 'Length, Width, Depth',
        filters: [
          { title: 'Length', key: 'polished.length', slider: true, mapper: formatNumericalValue },
          { title: 'Width', key: 'polished.width', slider: true, mapper: formatNumericalValue },
          { title: 'Depth', key: 'polished.depth', slider: true, mapper: formatNumericalValue },
        ],
      },
      { title: 'Media', key: 'polished.media' },
      {
        title: 'Pavilion',
        filters: [
          { title: 'Angle', key: 'polished.pavillion_angle', slider: true, unit: '°', mapper: formatNumericalValue },
          {
            title: 'Percentage ',
            key: 'polished.pavillion_percent',
            slider: true,
            unit: '%',
            mapper: formatNumericalValue,
          },
        ],
      },
      { title: 'Sight number', key: 'sight_no' },
      { title: 'Sight year', key: 'sight_year' },
      { title: 'Symmetry', key: 'polished.symmetry' },
      { title: 'Table %', key: 'polished.table_percent', slider: true, unit: '%', mapper: formatNumericalValue },
    ],
  }

  const currentState = Object.keys(filtersPerState).find((key) => new RegExp(key).test(lifecycle.stage))
  const currentFilters = featureControlFilter(filtersPerState[currentState])
  const filtersToGet = featureControlFilter(schema[currentState])

  const applyFilters = () => {
    dispatch(applyFiltersAction())
  }

  const onFilterValueChange = (name, value, checked) => {
    if (checked) {
      dispatch(
        selectFiltersAction({
          ...selectedFilters,
          [name]: selectedFilters[name] ? [...selectedFilters[name], value] : [value],
        })
      )
    } else {
      dispatch(
        selectFiltersAction({
          ...selectedFilters,
          [name]: selectedFilters[name].filter((item) => value !== item),
        })
      )
    }
  }

  const clearFilters = (name) => {
    const applied = omit(['lifecycle_state', 'possession_state'], appliedFilters)

    if (name) {
      dispatch(selectFiltersAction({ ...selectedFilters, [name]: [] }))
    } else {
      dispatch(selectFiltersAction({}))
    }

    // check if there are any applied filters to clear
    if (Object.keys(selectedFilters).length > 0 || Object.keys(applied).length > 0) {
      dispatch(clearFiltersAction(name))
    }
  }

  const filterComponents = currentFilters.map((filterName, index) => {
    const options = availableFilters[filterName]
    if (!options || options.length === 0) return null

    const selectedOptions = selectedFilters[filterName] || []
    const appliedOptions = appliedFilters[filterName] || []

    return (
      <Filter
        testIdPrefix={testIdPrefix}
        key={`filter-components-${index}`}
        name={filterName}
        options={options}
        className={classes.filter}
        selectedOptions={selectedOptions}
        appliedOptions={appliedOptions}
        onFilterApply={applyFilters}
        onFilterValueChange={onFilterValueChange}
        onFilterClear={clearFilters}
      />
    )
  })

  const moreFiltersCount = () => {
    if (moreFiltersPerState[currentState]) {
      let applied = omit(['lifecycle_state', 'possession_state', 'date'], appliedFilters)
      applied = omit(currentFilters, applied)

      return Object.values(applied)
        .filter((val) => {
          return !moreFiltersPerState[currentState].includes(val)
        })
        .flat().length
    }
  }

  const handleIdFilterChange = debounce((value) => {
    const newSelectedFilters = clone(selectedFilters)

    if (!value) {
      newSelectedFilters.id = null
    } else {
      newSelectedFilters.id = value
    }

    dispatch(selectFiltersAction(newSelectedFilters))

    dispatch(applyFiltersAction())
  }, 250)

  const onDateChange = (date) => {
    dispatch(
      selectFiltersAction({
        ...selectedFilters,
        date,
      })
    )
    dispatch(applyFiltersAction())
  }

  useEffect(() => {
    // Used to resize the grid in listview
    window.dispatchEvent(new Event('resize'))
  }, [JSON.stringify(availableFilters)]) // eslint-disable-line react-hooks/exhaustive-deps

  // Load filters when new are applied
  useEffect(() => {
    if (!lifecycle.stage) return
    // get all possible filters for the current lifecycle_state
    // therefore ignore the non-mandatory filters
    const mandatory = pick(['lifecycle_state', 'possession_state'], appliedFilters)

    dispatch(getFiltersAction({ list: filtersToGet, appliedFilters: mandatory }))
  }, [lifecycle.stage]) // eslint-disable-line react-hooks/exhaustive-deps

  // update clear & apply buttons visible state on filters applied change or filters selection
  useEffect(() => {
    // exclude mandatory filters
    const applied = omit(['lifecycle_state', 'possession_state'], appliedFilters)
    const enableClear = Object.keys(applied).length > 0
    const enableApply = JSON.stringify(applied) !== JSON.stringify(selectedFilters)

    setButtonsState([enableClear, enableApply])
  }, [JSON.stringify(selectedFilters), JSON.stringify(appliedFilters)]) // eslint-disable-line react-hooks/exhaustive-deps

  // do not render component if state is undefined
  if (!currentState) return null

  return (
    <div id={'filters'} className={classes.filtersContainer}>
      <div className={classes.filters}>
        <div className={classes.idFilter}>
          <Search
            value={selectedFilters.id}
            testIdPrefix={testId(testIdPrefix, testids.filters.infix)}
            onChange={handleIdFilterChange}
            onClear={() => handleIdFilterChange('')}
            placeholder="Search by ID..."
          />
        </div>
        <div className={classes.datePicker}>
          <DateRangePicker
            label="Last updated"
            onClear={clearFilters}
            onChange={onDateChange}
            selected={appliedFilters.date}
            returnFormat="string"
            testIdPrefix={testIdPrefix}
            testIdLeftCalender={testIds.calenderLeft}
            testIdRightCalender={testIds.calenderRight}
          />
        </div>
        {filterComponents}
        <Button
          className={classnames(classes.moreFiltersButton, {
            [classes.moreFiltersButtonActive]: moreFiltersCount() > 0,
          })}
          onClick={() => setIsMoreFiltersOpen(!isMoreFiltersOpen)}
          data-test-id={testId(testIdPrefix, testIds.moreFiltersButton)}
        >
          More filters {moreFiltersCount() > 0 && `- ${moreFiltersCount()}`}
        </Button>
        <FilterButtons
          enableClear={buttonsState[0]}
          onClear={clearFilters}
          testIdPrefix={testId(testIdPrefix, testids.filters.infix)}
        />
      </div>

      <MoreFiltersPopup
        show={isMoreFiltersOpen}
        moreFiltersPerState={moreFiltersPerState}
        onClose={() => setIsMoreFiltersOpen(!isMoreFiltersOpen)}
        testIdPrefix={testIdPrefix}
      />
    </div>
  )
}

export default FiltersContainer
