import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { clone } from 'ramda'

import {
  selectAvailableFilters,
  selectSelectedFilters,
  selectDiamondsCount,
  selectLifecycleStage,
} from 'store/selectors'
import { selectFiltersAction, applyFiltersAction, getDiamondsCountAction } from 'store/actions'
import MoreFiltersPopupComponent from './MoreFiltersPopupComponent'
import testids from 'config/testIds'

const MoreFiltersContainer = ({ show, onClose, moreFiltersPerState, testIdPrefix }) => {
  const dispatch = useDispatch()
  const stage = useSelector(selectLifecycleStage)
  const currentState = Object.keys(moreFiltersPerState).find((key) => new RegExp(key).test(stage))
  const currentFilters = moreFiltersPerState[currentState]
  const availableFilters = useSelector(selectAvailableFilters)
  const selectedFilters = useSelector(selectSelectedFilters)
  const { count, filteredCount } = useSelector(selectDiamondsCount)
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [localFilters, setLocalFilters] = useState(selectedFilters)

  const mapValues = (filters) => {
    const clonned = clone(filters)

    Object.keys(clonned).forEach((key) => {
      clonned[key] = clonned[key].map((option) => {
        return { value: option.value, label: option.title || valueMapper(key, option.value) || null }
      })
    })

    return clonned
  }

  const valueMapper = (filterName, value) => {
    switch (filterName) {
      case 'polished.grading_certificates': {
        switch (value) {
          case 'Grader Certificate No':
            return 'Certificate No'
          case 'Grader Inscription No':
            return 'Inscription No'
          case 'Grader Report No':
            return 'Report No'
          default:
            return value
        }
      }
      case "matched": {
        return value ? "Checked" : "Not checked"
      }
      default:
        return value
    }
  }

  const applyFilters = () => {
    dispatch(selectFiltersAction(localFilters))
    dispatch(applyFiltersAction())
    onClose()
  }

  const getDiamondsCount = (filters) => {
    // clean empty values from filter array
    const filtersToCount = clone(filters)
    Object.keys(filtersToCount).forEach((key) => {
      return !filtersToCount[key].from && !filtersToCount[key].length && delete filtersToCount[key]
    })
    dispatch(getDiamondsCountAction({ ...filtersToCount, lifecycle_state: stage, possession_state: 'held' }))
  }

  const clearAll = () => {
    const flattened = currentFilters
      .map((filter) => {
        if (Array.isArray(filter.filters)) {
          return filter.filters.map((elem) => elem.key)
        }
        return filter.key
      })
      .flat()

    const newLocalFilters = clone(localFilters)

    flattened.forEach((key) => {
      if (localFilters[key]) {
        delete newLocalFilters[key]
      }
    })

    setLocalFilters(newLocalFilters)
    setIsFormChanged(false)
    getDiamondsCount(newLocalFilters)
  }

  const closePopup = () => {
    clearAll()
    getDiamondsCount(selectedFilters)
    onClose()
  }

  const onSliderChange = (option, filter) => {
    let newData
    if (availableFilters[filter][0].value === option[0] && availableFilters[filter][1].value === option[1]) {
      newData = {
        ...localFilters,
        [filter]: {},
      }
    } else {
      newData = {
        ...localFilters,
        [filter]: { from: option[0], to: option[1] },
      }
    }

    setLocalFilters(newData)
    setIsFormChanged(true)
    getDiamondsCount(newData)
  }

  const onChange = (checked, option, filter) => {
    let newData

    if (checked) {
      newData = {
        ...localFilters,
        [filter]: localFilters[filter] ? [...localFilters[filter], option] : [option],
      }
    } else {
      newData = {
        ...localFilters,
        [filter]: localFilters[filter].filter((item) => option !== item),
      }
    }

    setLocalFilters(newData)
    setIsFormChanged(true)
    getDiamondsCount(newData)
  }

  useEffect(() => {
    setLocalFilters(selectedFilters)
  }, [JSON.stringify(selectedFilters), show]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MoreFiltersPopupComponent
      show={show}
      currentFilters={currentFilters}
      availableFilters={mapValues(availableFilters)}
      localFilters={localFilters}
      closePopup={closePopup}
      onChange={onChange}
      onSliderChange={onSliderChange}
      isFormChanged={isFormChanged}
      clearAll={clearAll}
      applyFilters={applyFilters}
      filteredCount={filteredCount}
      count={count}
      testIdPrefix={testIdPrefix}
    />
  )
}

export default MoreFiltersContainer
