import React, { useState } from 'react'
import classnames from 'classnames'

import { Tooltip } from 'components/system'
import { ExcludeDiamondSVG, IncludeDiamondSVG } from 'components/Svg'
import testids from 'config/testIds'

import classes from './styles.module.css'

export const SessionIdCell = ({ value, row, onClick }) => {
  const [tooltipTitle, setTooltipTitle] = useState('')
  const testIds = testids.dit

  // only render tooltips when content doesn't fit into container
  const handleMouseEnter = (evt) => {
    const target = evt.target

    if (target.offsetWidth < target.scrollWidth) setTooltipTitle(value)
    else if (tooltipTitle) setTooltipTitle('')
  }

  const resolveIcon = (skipped) => {
    if (skipped) return <IncludeDiamondSVG />
    else return <ExcludeDiamondSVG />
  }

  return (
    <div className={classnames(classes.sessionIdCellContainer)} onMouseEnter={handleMouseEnter}>
      {tooltipTitle ? (
        <Tooltip title={value}>
          <div className={classes.value}>{value}</div>
        </Tooltip>
      ) : (
        <div className={classes.value}>{value}</div>
      )}
      {row.hovered && (
        <Tooltip title={row.skipped ? 'Include diamond' : 'Exclude diamond'}>
          <div
            className={classes.icon}
            onClick={() => (onClick ? onClick(row) : null)}
            data-test-id={testIds.hideUnhindeDiamond}
          >
            {resolveIcon(row.skipped)}
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default SessionIdCell
