import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { featureToggles } from 'config'
import routes from 'config/routes'

// TODO: Find better solution for routing as this is complex and hard to read :(
export const DefaultNavigator = () => {
  const { pathname } = useLocation()

  const getPathInfo = (path, root) => {
    const key = path[0]
    // in ase a route is using `/` and looks like this `something/something`
    const remainingPath = path.join('/')
    let result = { renderable: true, enabled: 2 }

    root.find((route, index) => {
      if (route.path && (route.path === key || route.path === remainingPath)) {
        // edit here for better match
        if (route.feature && Number.isFinite(featureToggles[route.feature])) {
          result.enabled = featureToggles[route.feature]
        }

        if (!result.enabled) return true

        if (path.length > 1) {
          if (route.children) result = getPathInfo(path.slice(1), route.children)
          // only return false when there are no routes left on this level
          // since the route could be not as a child but as a sibling
          // when path value is looking like this `something/something`
          else if (index === route.length - 1) result.renderable = false
        } else {
          // route could be in the list of children if it's path is empty string
          if (route.children) result = getPathInfo(path.slice(1), route.children)
          else if (!route.hasView) result.renderable = false
        }
      } else if (route.children) {
        result = getPathInfo(path, route.children)
      }

      // stop loop when match is found
      if (!result.renderable || !result.enabled) return true
      else return false
    })

    return result
  }

  const getAllRenderableUnderPath = (path, fullpath, root) => {
    const currentPath = fullpath ? fullpath : ''
    const key = path[0]
    let renderable = []

    root.find((route) => {
      const routePath = route.path ? route.path : ''
      const nextPath = (currentPath + '/' + routePath).replace(/\/+/, '/')

      // modify here for better match
      if (route.path === key || !key) {
        if (!route.feature || featureToggles[route.feature] === 2) {
          if (route.hasView) {
            // add route
            renderable.push(nextPath)
          }

          if (route.children) {
            renderable = renderable.concat(getAllRenderableUnderPath(path.slice(1), nextPath, route.children))
          }
        }
      } else if (route.children && (!route.feature || featureToggles[route.feature] === 2)) {
        renderable = renderable.concat(getAllRenderableUnderPath(path, nextPath, route.children))
      }

      return false
    })

    return renderable
  }

  const getNavigationPath = (path, routes) => {
    const parts = ['/'].concat(path.split('/').filter((part) => part))

    // first get current path and check if it's renderable
    // meaning it was not disabled by feature toggles and has a view
    const currentPathInfo = getPathInfo(parts, routes)

    // if current path has a view and is not disabled by feature toggles return null for 0 redirect
    if (currentPathInfo.renderable && currentPathInfo.enabled === 2 && path !== '/') return null
    else {
      // get all renderable paths under current and try to render
      // otherwise go 1 level above and try to render there
      const renderable = getAllRenderableUnderPath(parts, null, routes)

      // return first available route under current path
      if (renderable.length) {
        return renderable[0]
      } else {
        // return first available route under root
        const renderable = getAllRenderableUnderPath('/', null, routes)

        return renderable[0]
      }
    }
  }

  const redirectPath = getNavigationPath(pathname, routes)

  // console.log('Redirect path', redirectPath)

  if (redirectPath) return <Navigate to={redirectPath} />

  return <Outlet />
}

export default DefaultNavigator
