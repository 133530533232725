import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import {
  selectAppliedFilters,
  selectDiamonds,
  selectSelectedDiamonds,
  selectDiamondsCount,
  selectDiamondsHasMore,
  selectDiamondsLoading,
  selectLifecycle,
} from 'store/selectors'
import {
  getDiamondsAction,
  setDiamondsForTransfer,
  updateSelectedDiamonds,
  selectDiamondsToShareAction,
} from 'store/actions'

import urls from 'config/urls'
import { featureToggles } from 'config'

import CardTable from '../CardTable'
import { mapDataToTable } from './mapper'
import { testIds } from 'config'

const CardViewTable = () => {
  const DEFAULT_PAGINATION = { page: 1, limit: 40 }
  const DEFAULT_SORTING = { participant_timestamp: 'desc' }
  const dispatch = useDispatch()
  const navigate = useNavigate(0)
  const lifecycle = useSelector(selectLifecycle)
  const diamonds = useSelector(selectDiamonds)
  const diamondCount = useSelector(selectDiamondsCount)
  const hasMore = useSelector(selectDiamondsHasMore)
  const isLoading = useSelector(selectDiamondsLoading)
  const appliedFilters = useSelector(selectAppliedFilters)
  const selectedItems = useSelector(selectSelectedDiamonds)
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [sorting, setSorting] = useState(DEFAULT_SORTING)
  const [display, setDisplay] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)

  let loading = false

  useEffect(() => {
    if (isLoading) return

    loadDiamonds()
  }, [pagination.page, JSON.stringify(sorting)]) // eslint-disable-line react-hooks/exhaustive-deps

  // reset table use effect
  useEffect(() => {
    setPagination(DEFAULT_PAGINATION)
    setSorting(DEFAULT_SORTING)

    loadDiamonds(DEFAULT_PAGINATION)
  }, [lifecycle.stage, JSON.stringify(appliedFilters)]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadDiamonds = (givenPagination) => {
    if (loading) return
    loading = true

    dispatch(
      getDiamondsAction({
        filters: appliedFilters,
        sort: sorting,
        ...(givenPagination || pagination),
      })
    ).then(() => {
      loading = false
    })
  }

  const onTransfer = (ids) => dispatch(setDiamondsForTransfer(ids))

  const onView = (ids) => {
    const id = Array.isArray(ids) ? ids[0] : ids

    if (!selectedItems.includes(id)) dispatch(updateSelectedDiamonds([]))

    navigate(`${urls.inventory.root}/${lifecycle.stage}/diamond/${id}`)
  }

  const onLoadMore = () => {
    if (isLoading) return

    if (hasMore) {
      setPagination({ ...pagination, page: pagination.page + 1 })
    }
  }

  const data = mapDataToTable(diamonds, lifecycle, onTransfer, onView)

  /*
  const transferAction = {
    title: 'Transfer',
    onClick: (ids) => onTransfer(ids),
    feature: 'transfer',
    disabled: featureToggles.transfer === 1,
    tooltip: 'Transfer out is disabled in this version of the platform',
  }
  */

  const viewAction = {
    title: 'View',
    onClick: (ids) => onView(ids),
    disabled: false,
    tooltip: null,
    testId: testIds.inventory.card.viewButton,
  }

  const shareAction = {
    title: 'Share',
    feature: 'share',
    disabled: featureToggles.share === 1,
    testId: testIds.inventory.card.shareButton,
    onClick: (ids) => {
      dispatch(selectDiamondsToShareAction(ids))
    },
  }

  const actions = () => {
    return [viewAction, shareAction].filter((action) => !action.feature || featureToggles[action.feature] !== 0)
  }

  useEffect(() => {
    let mounted = true

    const handleResize = debounce(() => {
      if (mounted) {
        setDisplay(true)
      }
    }, 200)

    const hide = () => setDisplay(false)
    window.addEventListener('resize', handleResize)
    window.addEventListener('resize', hide)

    handleResize()

    return () => {
      mounted = false
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('resize', hide)
    }
  }, [])

  if (!display) {
    return null
  }

  if (firstLoad === true) {
    if (data.length > 0) {
      setFirstLoad(false)
    }
  }

  const onSelect = (ids) => dispatch(updateSelectedDiamonds(ids))

  return (
    <CardTable
      firstLoad={firstLoad}
      sortKey={`${JSON.stringify(appliedFilters)}`}
      data={data}
      selectedItems={selectedItems}
      isLoading={!!isLoading}
      total={diamondCount}
      page={pagination.page}
      actions={actions}
      hasMore={hasMore}
      onLoadMore={onLoadMore}
      onCardClick={onView}
      targetCardWidth={250}
      targetCardHeight={444}
      onSelect={onSelect}
    />
  )
}

export default CardViewTable
