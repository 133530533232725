import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams, Outlet } from 'react-router-dom'

import API from 'api'
import { getIntegrationTypesAction } from 'store/actions'
import { selectDITupdatedAt } from 'store/selectors'
import { PageTitle, HelpButton } from 'components/system'
import { DITToolkitIcon, DITSavingIcon, DITAutoSaveIcon } from 'components/Svg'
import { formatShortDate, toTime } from 'utils/dates'
import { testIds as testids, helpLinks } from 'config'

import IntegrationToolkitModal from './IntegrationToolkitModal'
import classes from './styles.module.css'

const DITContainer = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { id } = useParams()
  const updatedAt = useSelector(selectDITupdatedAt)
  const [showIntegrationToolkit, setShowIntegrationToolkit] = useState(false)
  const [sessionSaving, setSessionSaving] = useState(false)
  const [updatedAtFormatted, setUpdatedAtFormatted] = useState('')
  const testIds = testids.dit

  const handleCancelClick = () => {
    setShowIntegrationToolkit(false)
  }
  const handleShowToolkitModal = () => {
    setShowIntegrationToolkit(true)
  }

  const getHelpLink = () => {
    const { pathname } = location
    switch (true) {
      case /\/dit$/.test(pathname):
        return helpLinks.integrationToolSessions
      case /\/new-session$/.test(pathname):
      case /\/new-session\/validation$/.test(pathname):
        return helpLinks.integrationToolNewSession
      case /\/complete$/.test(pathname):
        return helpLinks.integrationToolSessionComplete
      case /\/dit/.test(pathname):
        return helpLinks.integrationToolSession
      default:
        return null
    }
  }

  const formatUpdatedAt = (timestamp) => {
    if (!timestamp) return ''

    // conver timestamp to local timestamp
    const updateDate = new Date(timestamp)
    const nowDate = new Date()

    let formatted = ''

    const monthDate = updateDate.getMonth()
    const monthNow = nowDate.getMonth()
    const dayDate = updateDate.getDate()
    const dayNow = nowDate.getDate()

    if (monthDate === monthNow && dayNow === dayDate) {
      formatted = `Auto-saved today at ${toTime(updateDate)}`
    } else if (monthDate === monthNow && dayNow - dayDate === 1) {
      formatted = `Auto-saved yesterday at ${toTime(updateDate)}`
    } else if (monthNow - monthDate === 1 && dayNow === 1) {
      formatted = `Auto-saved yesterday at ${toTime(updateDate)}`
    } else {
      formatted = `Auto-saved on ${formatShortDate(updateDate).slice(0, -3)} at ${toTime(updateDate)}`
    }

    return formatted
  }

  const showAutoSave = id && updatedAtFormatted && !location.pathname.includes('complete')

  useEffect(() => {
    dispatch(getIntegrationTypesAction())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updatedAtFormatted) setSessionSaving(true)

    setTimeout(
      () => {
        setUpdatedAtFormatted(formatUpdatedAt(updatedAt))
        setSessionSaving(false)
      },
      updatedAtFormatted ? 1000 : 0 // skip save animation for first run
    )
  }, [updatedAt]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.ditContainer}>
      <PageTitle title="INTEGRATION TOOL">
        {showAutoSave ? (
          <div className={classes.autoSave}>
            {sessionSaving ? <DITSavingIcon /> : <DITAutoSaveIcon />}
            <span>{sessionSaving ? 'Saving...' : updatedAtFormatted}</span>
          </div>
        ) : null}
        <button
          className={classes.button}
          onClick={handleShowToolkitModal}
          data-test-id={`${testIds.integrationToolkit}`}
        >
          <span>Integration toolkit</span>
          <DITToolkitIcon />
        </button>
        <HelpButton link={getHelpLink()} onClick={() => API.tracking.logHelp('dit', getHelpLink())} />
      </PageTitle>
      <Outlet />
      <IntegrationToolkitModal isOpen={showIntegrationToolkit} onCancel={handleCancelClick} />
    </div>
  )
}

export default DITContainer
