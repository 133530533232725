import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import API from 'api'
import showNotification from 'containers/Notifications/controller'
import { ModalWrapper, Button } from 'components/system'
import { CloseSVG, DownloadIcon } from 'components/Svg'
import testids from 'config/testIds'
import { PLATFORM_TYPE } from 'config/featureToggles'

import classes from './styles.module.css'

const IntegrationToolkitModal = ({ isOpen, onCancel }) => {
  const testIds = testids.dit
  const type = PLATFORM_TYPE

  const contentsArray = [
    {
      title: 'CSV TEMPLATES',
      types: [
        { name: 'Rough', file: 'CSV', filename: 'TracrRoughTemplate.csv', hidden: type === 'retailer' },
        {
          name: 'Split',
          file: 'CSV',
          filename: 'TracrSplitTemplate.csv',
          hidden: type === 'retailer' || type === 'producer',
        },
        { name: 'Polished', file: 'CSV', filename: 'TracrPolishedTemplate.csv', hidden: type === 'producer' },
      ],
    },
    {
      title: 'TRACR LOADER',
      types: [
        { name: 'Windows application', file: 'EXE', filename: 'TracrLoaderWindows.exe' },
        { name: 'User guide', file: 'PDF', filename: 'TracrLoaderUserGuide.pdf' },
      ],
    },
    {
      title: 'HELP & GUIDES',
      types: [
        { name: 'Data dictionary', file: 'XLSX', filename: 'TracrDataDictionary.xlsx' },
        {
          name: 'Sarine rough & split exporter template',
          file: 'TXT',
          filename: 'TracrRoughSplitSarineExporterTemplate.txt',
          hidden: type === 'retailer',
        },
        {
          name: 'Sarine rough & split exporter guide ',
          file: 'PDF',
          filename: 'TracrRoughSplitSarineExporterGuide.pdf',
          hidden: type === 'retailer',
        },
        {
          name: 'Sarine polished exporter template',
          file: 'TXT',
          filename: 'TracrPolishedSarineExporterTemplate.txt',
          hidden: type === 'producer',
        },
        {
          name: 'Sarine polished exporter guide',
          file: 'PDF',
          filename: 'TracrPolishedSarineExporterGuide.pdf',
          hidden: type === 'producer',
        },
      ],
    },
  ]

  const handleDownloadToolkit = (filename) => {
    var downloadUrl = API.getToolkitFileUrl(filename)

    API.downloadToolkitFile(downloadUrl.href, filename)
      .then(() => {
        showNotification({
          title: 'File downloaded',
          variant: 'success',
        })
      })
      .catch(() => {
        showNotification({
          title: 'File download failed',
          variant: 'error',
        })
      })
  }

  return (
    <ModalWrapper isOpen={isOpen}>
      <div className={classes.integrationToolkitModalContainer}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <div className={classes.headerTitle}>INTEGRATION TOOLKIT</div>
            <div className={classes.close} onClick={onCancel} data-test-id={`${testIds.closeToolkitIcon}`}>
              <CloseSVG />
            </div>
          </div>
        </div>
        <div className={classes.body}>
          <div className={classes.text}>
            <span>Get the complete integration toolkit</span>
            <Button
              variant="secondary"
              onClick={() => handleDownloadToolkit('TracrIntegrationToolkit.zip')}
              data-test-id={`${testIds.downloadToolkitButton}`}
            >
              Download toolkit
            </Button>
          </div>
          <div className={classes.scrollable}>
            <Fragment>
              {contentsArray.map((item, index) => {
                return (
                  <div key={item.title}>
                    <div key={index} className={classes.subHeader}>
                      <div className={classes.subHeaderTitle}>{item.title}</div>
                    </div>
                    {item.types.map((type, i) => {
                      return (
                        <div
                          className={classNames(classes.fileList, { [classes.hidden]: type.hidden })}
                          onClick={() => handleDownloadToolkit(type.filename)}
                          key={i}
                          data-test-id={`${testIds.downloadToolkitRow}`}
                        >
                          <div>{type.name}</div>
                          <div>
                            <DownloadIcon />
                            <span>{type.file}</span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </Fragment>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

IntegrationToolkitModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
}

export default IntegrationToolkitModal
