import env from '@beam-australia/react-env'
import http from '../utils/http'

const apiUrl = env('INTEGRATION_BASE_URL') || 'http://localhost:5000'

const getDITVersion = () => {
  const url = new URL(`${apiUrl}/health/`)

  return http.requestWithResponse('get', '', { url })
}

const getIntegrationTypes = () => {
  const url = new URL(`${apiUrl}/csvw/`)

  return http.requestWithResponse('get', '', { url })
}

const getSessionHistory = ({ page, limit, sort, filters }) => {
  const filtersStringified = filters.reduce((prev, next, index) => {
    const filterSeparator = ';'

    if (Array.isArray(next.values)) {
      return prev + `${prev.length > 0 ? filterSeparator : ''}${next.name}|${next.values.join(',')}`
    } else if (next.name === 'date') {
      if (!next.value.from) return prev

      const from = next.value.from.toDateString()
      const to = next.value.to ? `,${next.value.to.toDateString()}` : ''

      return prev + `${prev.length > 0 ? filterSeparator : ''}${next.name}|${from}${to}`
    } else {
      return prev + `${prev.length > 0 ? filterSeparator : ''}${next.name}|${next.value}`
    }
  }, '')

  const sortKey = Object.keys(sort)[0]
  const order = sort[sortKey]
  const url = new URL(
    `${apiUrl}/session/?page=${page}&limit=${limit}&sort=${sortKey}&order=${order}&filters=${encodeURIComponent(
      filtersStringified
    )}`
  )

  return http.requestWithResponse('get', '', { url })
}

const uploadCSV = (file) => {
  const data = new FormData()

  data.append('file', file)

  const { name } = file
  const url = new URL(`${apiUrl}/csv/${name}`)

  return http.requestWithResponse('post', '', { url, data })
}

const validateCSV = (sessionId, integrationType) => {
  const url = new URL(`${apiUrl}/session/${sessionId}/validate/${integrationType}`)

  return http.requestWithResponse('put', '', { url })
}

const parseCSV = (sessionId) => {
  const url = new URL(`${apiUrl}/session/${sessionId}/parse`)

  return http.requestWithResponse('put', '', { url })
}

const matchFiles = (files, sessionId) => {
  const url = new URL(`${apiUrl}/files/match/${sessionId}`)
  const data = JSON.stringify(files.map((file) => file.name))

  return http
    .requestWithResponse('put', '', { url, data, headers: { 'Content-Type': 'application/json' } })
    .then((response) => response.data.map((file) => ({ fileName: file.filename, success: file.success })))
    .catch(() => files.map((file) => ({ fileName: file.filename, success: false })))
}

const uploadFile = (file, participantId, sessionId) => {
  const { name } = file
  const url = new URL(`${apiUrl}/files/upload/${sessionId}/${name}`)
  const data = new FormData()

  data.append('file', file)
  data.append('participant_id', participantId)

  return http.requestWithResponse('post', '', { url, data })
}

const getDITSession = (sessionId) => {
  const url = new URL(`${apiUrl}/session/${sessionId}`)

  return http.requestWithResponse('get', '', { url })
}

const hideUnhideDiamond = (action, sessionId, diamondId) => {
  const url = new URL(`${apiUrl}/session/${sessionId}?action=${action}&internal_id=${diamondId}`)

  return http.requestWithResponse('patch', '', { url })
}

const updateSession = (sessionId, action, internalId) => {
  const url = new URL(`${apiUrl}/session/${sessionId}`)

  url.searchParams.append('action', action)

  return http.requestWithResponse('patch', '', { url })
}

const downloadFile = (method, config, fileName) => {
  return http.requestWithResponseBlob(method, '', config).then((response) => {
    const url = window.URL.createObjectURL(response.data)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  })
}

const getToolkitFileUrl = (title) => {
  return new URL(`${apiUrl}/toolkit-files/${title}/`)
}

const downloadToolkitFile = (href, fileName) => {
  const url1 = new URL(href)
  const urlString = url1.toString()
  const url = urlString.replace(/\/$/, '')

  return downloadFile('get', { url }, fileName)
}

const downloadSessionReport = (sessionId, sessionStatus, fileName) => {
  const url = new URL(`${apiUrl}/session/${sessionId}/${sessionStatus}`)

  return downloadFile('get', { url }, fileName)
}

const downloadFullSessionReport = (sessionId, fileName) => {
  const url = new URL(`${apiUrl}/session/${sessionId}/report-full`)

  return downloadFile('get', { url }, fileName)
}

const downloadSessionFilesReport = (files, sessionId, filename) => {
  const url = new URL(`${apiUrl}/session/${sessionId}/session-report`)
  const data = files

  return downloadFile('post', { url, data }, filename)
}

const endpoints = {
  getDITVersion,
  getIntegrationTypes,
  getSessionHistory,
  getDITSession,
  uploadCSV,
  validateCSV,
  parseCSV,
  matchFiles,
  uploadFile,
  hideUnhideDiamond,
  updateSession,
  getToolkitFileUrl,
  downloadToolkitFile,
  downloadSessionReport,
  downloadFullSessionReport,
  downloadSessionFilesReport,
}

export default endpoints
