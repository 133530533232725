import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { CloseSVG } from 'components/Svg'

import classes from './styles.module.css'

export const Tag = ({ id, value, type, isValid, isEditable, delimiters, onChange, onDelete, ...rest }) => {
  const wrapperRef = useRef()
  const inputRef = useRef()
  const [isEditing, setIsEditing] = useState(false)

  const onEditFinish = () => {
    setIsEditing(false)

    if (onChange) onChange(id, inputRef.current.value)
  }

  const setInputRef = (node) => {
    if (!node) return

    inputRef.current = node

    node.focus()
  }

  const handleKeyDown = (event) => {
    if (!delimiters.map(d => d.value).includes(event.key)) return

    event.preventDefault()

    onEditFinish()
  }

  const handleBlur = (evt) => {
    if (!isEditing) return

    onEditFinish()
  }

  const handleDoubleClick = () => {
    if (isEditing || !isEditable) {
      return false
    }

    setIsEditing(true)
  }

  const handleDeleteClick = () => {
    if (onDelete) onDelete(id)
  }

  return (
    <div className={classnames(classes.tagContainer, {
        [classes.isEditing]: isEditing,
      })} {...rest}>
      <div
        className={classnames(classes.wrapper, {
          [classes.invalid]: !isValid && !isEditing,
        })}
        ref={wrapperRef}
      >
        <input
          className={classnames(classes.input, { [classes.visible]: isEditing })}
          defaultValue={value}
          type={type}
          autoComplete="off"
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          ref={setInputRef}
        />
        <span
          className={classnames(classes.value, { [classes.visible]: !isEditing })}
          onDoubleClick={handleDoubleClick}
        >
          {value}
        </span>
        <div className={classnames(classes.deleteBtn, { [classes.visible]: !isEditing })} onClick={handleDeleteClick}>
          <CloseSVG />
        </div>
      </div>
    </div>
  )
}

Tag.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  isValid: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
}

export default Tag
