import React, { Fragment, useState, useEffect } from 'react'
import classNames from 'classnames'

import {
  LeftArrowSVG,
  RightArrowSVG,
  UpArrowActiveSVG,
  DownArrowSVG,
  DownArrowActiveSVG,
  UpArrowSVG,
} from 'components/Svg' // TODO: update path to relative
import ClickOutsideListener from 'utils/ClickOutsideListener'
import labels from 'text/labels'

import classes from './styles.module.css'
import { testId } from 'utils/testHelper'
import { testIds } from 'config';

// TODO: move to utils
const getNextMonth = (date) => {
  if (date.getMonth() === 11) {
    return new Date(date.getFullYear() + 1, 0, 1)
  } else {
    return new Date(date.getFullYear(), date.getMonth() + 1, 1)
  }
}
const getPrevMonth = (date) => {
  if (date.getMonth() === 0) {
    return new Date(date.getFullYear() - 1, 11, 1)
  } else {
    return new Date(date.getFullYear(), date.getMonth() - 1, 1)
  }
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

function RangePicker({
  range,
  confirmed,
  rangeSetter,
  visible,
  disablePastDates,
  onChange,
  onClear,
  onClose,
  testIdLeftCalender,
  testIdRightCalender,
}) {
  const today = new Date(new Date().setHours(0, 0, 0, 0))
  const initialMonth = range.from ? range.from : today
  const [months, setMonths] = useState({ left: initialMonth, right: getNextMonth(initialMonth) })

  const populateDayNames = () => {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

    return days.map((day, index) => (
      <div key={index} className={classes.dayName}>
        {labels[day]}
      </div>
    ))
  }

  const populateMonth = (month) => {
    const firstDayNameOfMonth = new Date(month.getFullYear(), month.getMonth()).getDay()
    const totalDaysInMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate()

    const leftOffset = firstDayNameOfMonth // we start from Monday instead of Sunday
    const rightOffset = (leftOffset + totalDaysInMonth) % 7 ? 7 - ((leftOffset + totalDaysInMonth) % 7) : 0

    let monthDay = 0

    const days = Array(leftOffset + totalDaysInMonth + rightOffset)
      .fill(0)
      .map((ignore, index) => {
        if (index < leftOffset) return <div key={`${leftOffset}_offSet_${index}`} className={classes.dayFiller} />
        if (index >= leftOffset + totalDaysInMonth)
          return <div key={`${totalDaysInMonth}_offSet_${index}`} className={classes.dayFiller} />

        monthDay++

        const dayTimestamp = new Date(month).setDate(monthDay)
        const isPastDay = disablePastDates ? dayTimestamp < today.getTime() : false
        const isSelectionStartOrEnd = isSelectionDay(month, monthDay)
        const isDayWithinSelection = isWithinSelection(month, monthDay)

        if (index % 7 === 0) {
          return (
            <Fragment key={monthDay}>
              <div className={classes.daysNewLine} />
              <div
                key={monthDay}
                className={classNames({
                  [classes.day]: true,
                  [classes.dayPast]: isPastDay,
                  [classes.daySelectionStartOrEnd]: isSelectionStartOrEnd,
                  [classes.daySelected]: isDayWithinSelection,
                })}
                data-timestamp={isPastDay ? null : dayTimestamp}
              >
                {monthDay}
              </div>
            </Fragment>
          )
        } else {
          return (
            <div
              key={monthDay}
              className={classNames({
                [classes.day]: true,
                [classes.dayPast]: isPastDay,
                [classes.daySelectionStartOrEnd]: isSelectionStartOrEnd,
                [classes.daySelected]: isDayWithinSelection,
              })}
              data-timestamp={isPastDay ? null : dayTimestamp}
            >
              {monthDay}
            </div>
          )
        }
      })

    return days
  }

  const isSelectionDay = (month, day) => {
    const selectionStart =
      range.from &&
      month.getFullYear() === range.from.getFullYear() &&
      month.getMonth() === range.from.getMonth() &&
      day === range.from.getDate()

    const selectionEnd =
      confirmed &&
      range.to &&
      month.getFullYear() === range.to.getFullYear() &&
      month.getMonth() === range.to.getMonth() &&
      day === range.to.getDate()

    return selectionStart || selectionEnd
  }

  const isWithinSelection = (month, day) => {
    if (!range.from || !range.to) return false

    const dateClone = new Date(new Date(month).setDate(day))

    if (confirmed) {
      return dateClone > range.from && dateClone < range.to
    } else {
      return dateClone > range.from && dateClone <= range.to
    }
  }

  const startFinishSelection = (event) => {
    // days in the past have no timestamp
    if (!event.target.dataset.timestamp) return

    const clickedDate = new Date(parseInt(event.target.dataset.timestamp))

    rangeSetter(clickedDate, true)
  }

  const changeMonths = (incOrDec) => {
    if (incOrDec === 1) {
      setMonths({
        left: months.right,
        right: getNextMonth(months.right),
      })
    } else {
      setMonths({
        left: getPrevMonth(months.left),
        right: months.left,
      })
    }
  }

  const onLocalClear = () => {
    rangeSetter(null, true)
    onClear('date')
  }

  if (!visible) return null
  return (
    <div className={classes.rangePicker} onClick={startFinishSelection}>
      <div className={classes.rangePickerInside}>
        <div
          data-test-id={testIdLeftCalender}
          className={classNames({ [classes.monthBox]: true, [classes.leftMonth]: true })}
        >
          <div className={classNames({ [classes.header]: true })}>
            <div
              className={classNames({ [classes.navigationArrow]: true, [classes.leftArrow]: true })}
              onClick={() => changeMonths(-1)}
            >
              <LeftArrowSVG />
            </div>
            <h3 className={classNames({ [classes.title]: true })}>
              {monthNames[months.left.getMonth()]} {months.left.getFullYear()}
            </h3>
          </div>
          <div className={classes.body}>
            <div className={classes.dayNamesContainer}>{populateDayNames()}</div>
            <div className={classes.daysContainer}>{populateMonth(months.left)}</div>
          </div>
        </div>

        <div
          data-test-id={testIdRightCalender}
          className={classNames({ [classes.monthBox]: true, [classes.rightMonth]: true })}
        >
          <div className={classNames({ [classes.header]: true })}>
            <div
              className={classNames({ [classes.navigationArrow]: true, [classes.rightArrow]: true })}
              onClick={() => changeMonths(1)}
            >
              <RightArrowSVG />
            </div>
            <h3 className={classNames({ [classes.title]: true })}>
              {monthNames[months.right.getMonth()]} {months.right.getFullYear()}
            </h3>
          </div>
          <div className={classes.body}>
            <div className={classes.dayNamesContainer}>{populateDayNames()}</div>
            <div className={classes.daysContainer}>{populateMonth(months.right)}</div>
          </div>
        </div>
      </div>

      <div className={classes.footer}>
        <div className={range.from ? classes.clearButton : classes.clearButtonDisabled} onClick={onLocalClear}>
          Clear
        </div>
        <div
          className={range.from ? classes.applyButton : classes.applyButtonDisabled}
          onClick={
            range.from
              ? () => {
                onChange(range)
                onClose()
              }
              : null
          }
        >
          Apply
        </div>
      </div>
    </div>
  )
}

// { from: new Date(2020, 1, 5), to: new Date(2020, 2, 3) }

export const DateRangePicker = ({
  selected = {},
  label = labels.allTime,
  disablePastDates,
  returnFormat = 'date',
  testIdPrefix = 'dateFilter',
  testIdLeftCalender,
  testIdRightCalender,
  onChange,
  onClear,
}) => {
  const [selectedRange, setSelectedRange] = useState({})
  const [isConfirmed, setIsConfirmed] = useState(true)
  const [pickerVisible, setPickerVisible] = useState(false)

  const formatDate = (date) => {
    if (!date) return

    const parsedDate = typeof date === 'string' ? new Date(date) : date

    const year = parsedDate.getFullYear().toString().substr(2, 2)
    const month = monthNamesShort[parsedDate.getMonth()]
    const day = parsedDate.getDate().toString()

    return `${day} ${month} ${year}`
  }

  const formatProvidedRange = (date) => {
    if (!date || Object.keys(date).length === 0) return {}

    const formattedDate = {}

    formattedDate.from = new Date(date.from)

    if (date.to) formattedDate.to = new Date(date.to)

    return formattedDate
  }

  const rangeSetter = (date, confrimSelection) => {
    let newRange = {}
    let newIsConfirmed = false

    // 4 possible scenarios:
    //    1) No date is provided, confrimSelection is undefined - revert to defaultRange
    //    2) No date is porivded, confirmSelection is true - revert to No date
    //    3) Date is provided and it's from a click event - Start a new selection or completed existing one
    //    4) Date is provided but it's from a mouse move event - Set 'to' date but not complete the selection

    if (date) {
      if (confrimSelection) {
        if (isConfirmed || selectedRange.from >= date) {
          newRange = { from: date }
          newIsConfirmed = false
        } else {
          newRange = { from: selectedRange.from, to: date }
          newIsConfirmed = true
        }
      } else {
        newRange = { from: selectedRange.from, to: date }
        newIsConfirmed = false
      }
    } else {
      if (confrimSelection) {
        newRange = {}
        newIsConfirmed = true
      } else {
        newRange = selectedRange
        newIsConfirmed = true
      }
    }

    setSelectedRange(newRange)
    setIsConfirmed(newIsConfirmed)
  }

  const formatResponse = (range) => {
    if (returnFormat === 'string') {
      const result = {}

      if (range.from) result.from = range.from.toString()

      if (range.to) result.to = range.to.toString()

      return result
    } else return range
  }

  const toggleRange = () => {
    if (pickerVisible) {
      setPickerVisible(false)
      revertToDefault()
    } else {
      setPickerVisible(true)
    }
  }

  const hideRange = () => {
    if (pickerVisible) {
      onChange(formatResponse(selectedRange))
      setPickerVisible(false)
    }
  }

  const revertToDefault = () => {
    // if full range was not provided by user, restore to devault value
    if (!selectedRange.to) rangeSetter()
  }

  const ArrowComponent = () => {
    if (pickerVisible) {
      if (selectedRange.from) return <UpArrowActiveSVG />
      else return <UpArrowSVG />
    } else {
      if (selectedRange.from && isConfirmed) return <DownArrowActiveSVG />
      else return <DownArrowSVG />
    }
  }

  useEffect(() => {
    if (!pickerVisible) rangeSetter()
  }, [selected.from, selected.to]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedRange(formatProvidedRange(selected))
  }, [JSON.stringify(selected)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ClickOutsideListener onClickOutside={hideRange}>
      <div className={classes.dateRangePickerContainer} data-test-id={testId(testIdPrefix, 'dateFilter.container')}>
        <div className={classes.input} onClick={toggleRange}>
          {selectedRange.from ? (
            <div className={classNames({ [classes.value]: true, [classes.nonDefaultValue]: true })}>
              <div className={classNames({ [classes.box]: true, [classes.startDate]: true })}>
                {formatDate(selectedRange.from)}
              </div>
              {selectedRange.to && (
                <>
                  <div className={classes.separator}>{labels.to}</div>
                  <div className={classNames({ [classes.box]: true, [classes.endDate]: true })}>
                    {isConfirmed && formatDate(selectedRange.to)}
                  </div>
                </>
              )}
              <div className={classes.icon}>
                <ArrowComponent />
              </div>
            </div>
          ) : (
            <div
              className={classNames({
                [classes.value]: true,
                [classes.defaultValue]: true,
                [classes.valueActive]: pickerVisible,
              })}
            >
              <div className={classes.valueText}>{label}</div>
              <div className={classes.icon}>
                <ArrowComponent />
              </div>
            </div>
          )}
        </div>

        <RangePicker
          range={selectedRange}
          confirmed={isConfirmed}
          onChange={onChange}
          onClear={onClear}
          onClose={hideRange}
          rangeSetter={rangeSetter}
          visible={pickerVisible}
          disablePastDates={disablePastDates}
          testIdLeftCalender={testId(testIdPrefix, testIdLeftCalender ?? testIds.filters.calenderLeft)}
          testIdRightCalender={testId(testIdPrefix, testIdRightCalender ?? testIds.filters.calenderRight)}
        />
      </div>
    </ClickOutsideListener>
  )
}

export default DateRangePicker
