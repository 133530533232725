import React from 'react'

import { Tooltip } from 'components/system'

import copy from 'text/en'

const WithOrWithoutTooltip = ({ isDisabled = false, isUnavailable = false, children }) => {
  if (isDisabled) {
    return <Tooltip title={copy.tooltips.common.featureDisabled}>{children}</Tooltip>
  } else if (isUnavailable) {
    return <Tooltip title={copy.tooltips.common.noLifecycleData}>{children}</Tooltip>
  } else {
    return children
  }
}

export default WithOrWithoutTooltip
