import createActionSet from 'utils/createActionSet'

const namespace = 'SHIPMENTS_OUT'
const GET_SHIPMENTS = createActionSet(namespace, 'GET_SHIPMENTS')
const GET_SHIPMENT = createActionSet(namespace, 'GET_SHIPMENT')
const VALIDATE_CSV = createActionSet(namespace, 'VALIDATE_CSV')
const SEND_NEW_SHIPMENT = createActionSet(namespace, 'SEND_NEW_SHIPMENT')
const SET_CANCEL_DIAMOND_ID = `${namespace}_SET_CANCEL_DIAMOND_ID`
const CANCEL_DIAMONDS = createActionSet(namespace, 'CANCEL_DIAMONDS')
const SET_NEW_SHIPMENT = `${namespace}_SET_NEW_SHIPMENT`
const SET_STATIC_FILTERS = `${namespace}_SET_STATIC_FILTERS`

export const SHIPMENTS_OUT_ACTION_TYPES = {
  GET_SHIPMENTS,
  GET_SHIPMENT,
  SET_NEW_SHIPMENT,
  VALIDATE_CSV,
  SEND_NEW_SHIPMENT,
  SET_CANCEL_DIAMOND_ID,
  CANCEL_DIAMONDS,
  SET_STATIC_FILTERS,
}
