import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import API from 'api'
import { PageTitle, HelpButton } from 'components/system'
import { helpLinks } from 'config'

import classes from './styles.module.css'

const ShipmentsOut = () => {
  const location = useLocation()

  const getHelpLink = () => {
    const { pathname } = location
    switch (true) {
      case /\/outbound$/.test(pathname):
        return helpLinks.transferOutShipments
      case /\/new/.test(pathname):
      case /\/preview/.test(pathname):
        return helpLinks.transferOutNewTransfer
      case /\/pending/.test(pathname):
        return helpLinks.transferOutPendingTransfer
      case /\/archive/.test(pathname):
        return helpLinks.transferOutArchiveStatus
      default:
        return null
    }
  }

  return (
    <div className={classes.shipmentsOutContainer}>
      <PageTitle title={`Shipments`} help={getHelpLink()}>
        <HelpButton link={getHelpLink()} onClick={() => API.tracking.logHelp('transfer-out', getHelpLink())} />
      </PageTitle>
      <Outlet />
    </div>
  )
}

export default ShipmentsOut
