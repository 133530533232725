import React, { useState, useEffect } from 'react'

import { Modal } from 'components/system'
import { RoughDiamondIcon, PolishedDiamondIcon } from 'components/system/SVG/icons'
import { makeFirstCap } from 'utils/index'

import classes from './styles.module.css'
import { testIds } from 'config/testIds'

const ConfirmShipmentModal = ({ visible, shipment, testId, onCancel, onConfirm }) => {
  const [points, setPoints] = useState([])

  const preparePoints = (shipment) => {
    if (!shipment) return []

    const list = [
      { label: 'To', value: shipment.receiver },
      { label: 'Shipment name', value: shipment.shipment_name },
      { label: 'Total diamonds', value: shipment.diamonds.length },
    ]

    return list
  }

  const resolveIcon = (shipment_type) => {
    switch (shipment_type) {
      case 'rough':
        return <RoughDiamondIcon />
      case 'polished':
      default:
        return <PolishedDiamondIcon />
    }
  }

  useEffect(() => {
    setPoints(preparePoints(shipment))
  }, [JSON.stringify(shipment)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isOpen={visible}
      width={560}
      title={'send shipment'}
      confirmLabel="Confirm"
      cancelTestId={testIds.newShipment.confirmShipmentModal.cancelButton}
      confirmTestId={testIds.newShipment.confirmShipmentModal.confirmButton}
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm()}
    >
      <div className={classes.confirmShipmentModalContainer}>
        <div className={classes.wrapper}>
          <div className={classes.type}>
            <div className={classes.icon}>{resolveIcon(shipment ? shipment.shipment_type : null)}</div>
            <div className={classes.text}>{shipment ? makeFirstCap(shipment.shipment_type) : null} shipment</div>
          </div>
          <ul className={classes.points}>
            {points.map((point) => {
              return (
                <li key={point.label} className={classes.point}>
                  <div className={classes.label}>{point.label}:&nbsp;</div>
                  <div className={classes.value}>{point.value}</div>
                </li>
              )
            })}
          </ul>
          <div className={classes.message}>
            After sending, you can cancel until your recipient accepts this shipment.
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmShipmentModal
