import React from 'react'
import classnames from 'classnames'
import { ItemArrowRightIcon } from 'components/Svg/nav'

import classes from './styles.module.css'

const NavLink = ({ id, label, isActive, disabled, onClick, ...rest }) => {
  return (
    <div className={classes.navLinkContainer} {...rest}>
      <div
        className={classnames(classes.wrapper, {
          [classes.active]: isActive,
          [classes.disabled]: disabled,
        })}
        onClick={!disabled ? () => onClick && onClick(id) : null}
      >
        <div className={classes.label}>{label}</div>
        <div className={classes.arrow}>
          <ItemArrowRightIcon />
        </div>
      </div>
    </div>
  )
}

export default NavLink
