import React, { useState } from 'react'
import { createPortal } from 'react-dom'

import { notificationsController } from './controller'
import Notification from './Notification'
import classes from './styles.module.css'

const NotificationsContainer = () => {
  const [list, setList] = useState([])
  // eslint-disable-next-line
  const [, setReRender] = useState(0)

  notificationsController.registerListener((newList) => {
    setList(newList)
    setReRender(Math.random())
  })

  return createPortal(
    <div className={classes.notificationsContainer}>
      {list.map((notification) => {
        return (
          <Notification
            id={notification.id}
            title={notification.title}
            bodyText={notification.bodyText}
            variant={notification.variant}
            config={notification.config}
            onClose={notification.onClose}
            key={notification.id}
          />
        )
      })}
    </div>,
    document.getElementsByTagName('body')[0]
  )
}

export default NotificationsContainer
