import { clone } from 'ramda'
import { DIAMONDS_ACTION_TYPES, LIFECYCLE_ACTION_TYPES } from '../actionTypes'
import { resolveActionType } from '../utils'

const initialState = {
  data: {
    totalCount: null, // overall number of diamonds in the database
    count: null, // diamonds in selected lifecycle_state
    filteredCount: null, // diamonds in selected lifecycle_state with filters
    list: [], // contains shallow records of diamonds
    hasMore: null,
    selected: [],
    fullDiamonds: [], // contains a list of full diamond records
    fullDiamond: null,
  },
  requests: {
    // example request record
    // DIAMOND_GET_FULL_DIAMOND: {loading: true, error: null}
    GET_DIAMONDS: {},
  },
}

const diamondsReducer = (state = initialState, { payload, type }) => {
  const requestType = resolveActionType('DIAMONDS_ACTION_TYPES', type)

  switch (type) {
    case DIAMONDS_ACTION_TYPES.GET_DIAMONDS_COUNT.REQUEST:
    case DIAMONDS_ACTION_TYPES.GET_TOTAL_DIAMONDS_COUNT.REQUEST:
    case DIAMONDS_ACTION_TYPES.GET_FULL_DIAMOND.REQUEST:
    case DIAMONDS_ACTION_TYPES.GET_DIAMOND_BLUENILE.REQUEST:
    case DIAMONDS_ACTION_TYPES.GET_DIAMONDS.REQUEST:
    case DIAMONDS_ACTION_TYPES.GET_FULL_DIAMONDS.REQUEST:
    case DIAMONDS_ACTION_TYPES.SHARE_DIAMOND.REQUEST:
    case DIAMONDS_ACTION_TYPES.TRANSFER_DIAMOND.REQUEST:
    case DIAMONDS_ACTION_TYPES.UNLOCK_DIAMONDS.REQUEST: {
      const newState = clone(state)

      return {
        ...newState,
        requests: {
          ...newState.requests,
          [requestType]: { loading: true, error: null },
        },
      }
    }
    case DIAMONDS_ACTION_TYPES.GET_TOTAL_DIAMONDS_COUNT.FAILURE:
    case DIAMONDS_ACTION_TYPES.GET_DIAMONDS_COUNT.FAILURE:
    case DIAMONDS_ACTION_TYPES.GET_FULL_DIAMOND.FAILURE:
    case DIAMONDS_ACTION_TYPES.GET_DIAMOND_BLUENILE.FAILURE:
    case DIAMONDS_ACTION_TYPES.GET_DIAMONDS.FAILURE:
    case DIAMONDS_ACTION_TYPES.GET_FULL_DIAMONDS.FAILURE:
    case DIAMONDS_ACTION_TYPES.SHARE_DIAMOND.FAILURE:
    case DIAMONDS_ACTION_TYPES.TRANSFER_DIAMOND.FAILURE:
    case DIAMONDS_ACTION_TYPES.UNLOCK_DIAMONDS.FAILURE: {
      const newState = clone(state)

      return {
        ...newState,
        requests: {
          ...newState.requests,
          [requestType]: { loading: false, error: payload.error },
        },
      }
    }

    case DIAMONDS_ACTION_TYPES.GET_FULL_DIAMOND.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      if (!newState.data.list.find((diamond) => diamond.diamond_id === payload.current.diamond_id))
        newState.data.list.push(payload.current)

      return {
        ...newState,
        data: {
          ...newState.data,
          fullDiamond: payload,
        },
      }
    }

    case DIAMONDS_ACTION_TYPES.GET_DIAMOND_BLUENILE.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      return {
        ...newState,
        data: {
          ...newState.data,
          diamondBlueNile: payload,
        },
      }
    }

    case DIAMONDS_ACTION_TYPES.GET_DIAMONDS.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      return {
        ...newState,
        data: {
          ...newState.data,
          list: payload.page > 1 ? newState.data.list.concat(payload.list) : payload.list,
          hasMore: payload.hasMore,
        },
      }
    }
    case DIAMONDS_ACTION_TYPES.GET_FULL_DIAMONDS.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      return {
        ...newState,
        data: {
          ...newState.data,
          fullDiamonds: payload,
        },
      }
    }
    case DIAMONDS_ACTION_TYPES.GET_DIAMONDS_COUNT.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      return {
        ...newState,
        data: {
          ...newState.data,
          count: payload.count,
          filteredCount: payload.filteredCount,
        },
      }
    }
    case DIAMONDS_ACTION_TYPES.GET_TOTAL_DIAMONDS_COUNT.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      return {
        ...newState,
        data: {
          ...newState.data,
          totalCount: payload.totalCount,
        },
      }
    }
    case DIAMONDS_ACTION_TYPES.TRANSFER_DIAMOND.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      const newList = clone(newState.data.list)

      newList.forEach((diamond) =>
        payload.indexOf(diamond.id) !== -1 ? (diamond.possession_state = 'in_transit') : null
      )

      return {
        ...newState,
        data: {
          ...newState.data,
          list: newList,
        },
      }
    }

    case DIAMONDS_ACTION_TYPES.UNLOCK_DIAMONDS.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      const newList = clone(newState.data.list)
      const newFullDiamond = clone(newState.data.fullDiamond)
      const newFullDiamonds = clone(newState.data.fullDiamonds)

      // payload [{diamond_id, status}]

      payload.forEach((unlockResult) => {
        // "diamond_does_not_exist",
        // "diamond_cannot_be_unlocked",
        // "success"
        if (unlockResult.status !== 'success') return
        // update grid list, de list, and single de diamond
        let diamond = newList.find((diamond) => diamond.diamond_id === unlockResult.diamond_id)
        if (diamond) diamond.unlock_status = 'Available'
        diamond = newFullDiamonds.find((fullDiamond) => fullDiamond.current.diamond_id === unlockResult.diamond_id)
        if (diamond) diamond.current.unlock_status = 'Available'

        if (newFullDiamond && newFullDiamond.current.diamond_id === unlockResult.diamond_id)
          newFullDiamond.current.unlock_status = 'Available'
      })

      return {
        ...newState,
        data: {
          ...newState.data,
          list: newList,
          fullDiamond: newFullDiamond,
          fullDiamonds: newFullDiamonds,
        },
      }
    }

    case DIAMONDS_ACTION_TYPES.SHARE_DIAMOND.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      return {
        ...newState,
        data: {
          ...newState.data,
          shared: payload,
        },
      }
    }

    case DIAMONDS_ACTION_TYPES.GET_SHARED_DIAMONDS.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      return {
        ...newState,
        data: {
          ...newState.data,
          sharedDiamonds: payload,
        },
      }
    }

    case DIAMONDS_ACTION_TYPES.UPDATE_SELECTED_DIAMONDS: {
      const newState = clone(state)

      delete newState.requests[requestType]

      return {
        ...newState,
        data: {
          ...newState.data,
          selected: payload,
        },
      }
    }

    case LIFECYCLE_ACTION_TYPES.STAGE_CHANGE: {
      return {
        ...initialState,
        data: {
          ...initialState.data,
          totalCount: state.data.totalCount, // totalCount is only requested once, at initial load
        },
        requests: {},
      }
    }
    default:
      return state
  }
}

export default diamondsReducer
