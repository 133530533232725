import React, { useEffect, useState } from 'react'

import API from 'api'
import MediaContent from 'containers/MediaContent'

const Media = ({ media, onLoad }) => {
  const [data, setData] = useState()

  const loadMedia = () => {
    API.getSignedUrl(media.id, media.hash)
      .then((res) => {
        if (res.status === 200 && res.data.content) {
          setData(res.data.content)
        }
      })
      .catch(() => {
        // TODO: error handler and placeholder
        setData(null)
      })
      .finally(() => {
        if (onLoad) onLoad()
      })
  }

  useEffect(() => {
    loadMedia()
  }, [JSON.stringify(media)]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!media) return null

  return <MediaContent asset={{ ...media, data }} />
}

export default Media
