const schema = {
  rough: [
    'carat_range',
    'rough.model',
    'rough.colour',
    'rough.quality',
    'rough.stress',
    'rough.fancy_colour',
    'rough.fancy_colour_intensity',
    'rough.fancy_colour_overtone',
    'rough.fluorescence_intensity',
    'rough.fluorescence_colour',
    'rough.media',
    'sight_year',
    'sight_no',
    'provenance',
    'matched'
  ],
  split: [
    'carat_range',
    'rough.model',
    'rough.colour',
    'rough.quality',
    'rough.stress',
    'rough.fancy_colour',
    'rough.fancy_colour_intensity',
    'rough.fancy_colour_overtone',
    'rough.fluorescence_intensity',
    'rough.fluorescence_colour',
    'rough.media',
    'sight_year',
    'sight_no',
    'provenance',
    'matched'
  ],
  'planning|sawing|bruting|polishing': [
    'carat_range',
    'intermediate.shape',
    'intermediate.colour',
    'intermediate.quality',
    'benchmark',
    'intermediate.model',
    'intermediate.fluorescence_intensity',
    'intermediate.fluorescence_colour',
    'intermediate.media',
    'sight_year',
    'sight_no',
    'provenance',
    'matched'
  ],
  'polished|graded': [
    'unlock_status',
    'assurance_level',
    'carat_range',
    'polished.shape',
    'polished.colour',
    'polished.clarity',
    'polished.cut_grade',
    'polished.symmetry',
    'polished.polish_quality',
    'polished.fancy_colour',
    'polished.fancy_colour_intensity',
    'polished.fancy_colour_overtone',
    'polished.fluorescence_intensity',
    'polished.fluorescence_colour',
    'polished.girdle_thickness_from',
    'polished.girdle_thickness_to',
    'polished.girdle_condition',
    'polished.pavillion_angle',
    'polished.pavillion_percent',
    'polished.crown_height',
    'polished.crown_angle',
    'polished.diameter_minimum',
    'polished.diameter_maximum',
    'polished.culet_size',
    'polished.culet_condition',
    'polished.media',
    'polished.length',
    'polished.width',
    'polished.depth',
    'polished.depth_percent',
    'polished.table_percent',
    'polished.length_to_width',
    'polished.grading_certificates',
    'sight_year',
    'sight_no',
    'provenance',
    'matched'
  ],
}

export default schema
