import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import camelCase from 'camelcase'

import Checkbox from '../../Checkbox'
import classes from './styles.module.css'
import { testId } from 'utils/testHelper'

const ColumnpickerItem = ({ title, path, isEnabled, isDefault, testIdPrefix, onClick }) => {
  const [checked, setChecked] = useState(isEnabled)

  const handleChange = () => {
    const newValue = !checked

    setChecked(newValue)
    onClick(path, newValue)
  }

  useEffect(() => {
    setChecked(isEnabled)
  }, [isEnabled]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.columnpickerItemContainer}>
      <div
        className={classnames(classes.wrapper, { [classes.default]: isDefault })}
        onClick={isDefault ? null : handleChange}
        data-test-id={testId(testIdPrefix, 'row', camelCase(title))}
      >
        {isDefault ? null : (
          <div className={classes.checkbox}>
            <Checkbox value={checked ? 2 : 0} onChange={handleChange} />
          </div>
        )}
        <div className={classnames(classes.title, { [classes.default]: isDefault })}>
          {title}
          {isDefault ? <span>default</span> : null}
        </div>
      </div>
    </div>
  )
}

export default ColumnpickerItem
