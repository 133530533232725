import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import API from 'api'
import { selectDiamondsToShare } from 'store/selectors'
import { selectDiamondsToShareAction, removeDiamondFromShareAction } from 'store/actions'
import showNotification from 'containers/Notifications/controller'
import { ModalWrapper, Diamond, HelpButton, DevBanner } from 'components/system'
import { CloseSVG, LeftArrowSVG, RightArrowSVG } from 'components/Svg'
import { helpLinks } from 'config'

import ListItem from './ListItem'
import Form from './Form'
import Bar from './Bar'
import classes from './styles.module.css'
import { testIds } from 'config'

export const ShareModal = () => {
  const dispatch = useDispatch()
  const listRef = useRef()
  const diamonds = useSelector(selectDiamondsToShare)
  const [selectedId, setSelectedId] = useState(null)
  const [diamondToRemove, setDiamondToRemove] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const getSelectedIndex = () => {
    return diamonds.findIndex((diamond) => diamond.diamond_id === selectedId)
  }

  const handleCloseClick = () => {
    dispatch(selectDiamondsToShareAction([]))

    setSelectedId(null)
  }

  const handleRemove = (id) => {
    if (diamonds.length === 1) return

    dispatch(removeDiamondFromShareAction(id))

    if (id === selectedId) handleNavigateClick(-1)

    setDiamondToRemove(null)
  }

  const handleRemoveCancel = () => {
    setDiamondToRemove(null)
  }

  const handleRemoveDiamond = (id) => {
    if (diamonds.length === 1) return

    setDiamondToRemove(id)
  }

  const handleSelectDiamond = (id) => {
    if (diamonds.length === 1) return

    setSelectedId(id)
  }

  const handleNavigateClick = (direction) => {
    const nextIndex = getSelectedIndex() + direction

    if (nextIndex > diamonds.length - 1) {
      setSelectedId(diamonds[0].diamond_id)
    } else if (nextIndex < 0) {
      setSelectedId(diamonds[diamonds.length - 1].id)
    } else {
      setSelectedId(diamonds[nextIndex].diamond_id)
    }
  }

  const handleSubmit = (values) => {
    const ids = diamonds.map((diamond) => diamond.id)

    setIsLoading(true)

    API.shareDiamonds({
      ids,
      ...values,
    })
      .then((result) => {
        if (result)
          showNotification({
            title: `Diamond${ids.length > 1 ? 's' : ''} sent`,
            variant: 'success',
          })

        setIsLoading(false)

        handleCloseClick()
      })
      .catch(() => {
        showNotification({
          title: `Diamond${ids.length > 1 ? 's' : ''} could not be sent`,
          bodyText: 'Please re-enter the information and try to send again.',
          variant: 'error',
        })

        setIsLoading(false)
      })
  }

  useEffect(() => {
    const selectedIndex = diamonds.findIndex((d) => d.diamond_id === selectedId)
    const list = listRef.current

    if (!list) return

    /**
     * Next bit of code checks if selected list item is not in view area of the container.
     * If so, it sets container's scroll top value so a value would become visible.
     */
    const li = list.querySelectorAll('li')

    if (!li || li.length === 0) return

    const listHeight = list.offsetHeight
    const liHeight = li[0].offsetHeight + 16

    // first check is if selected item is below the container view area
    const outOfBoundsBottom = listHeight + list.scrollTop - 32 - (selectedIndex + 1) * liHeight

    if (outOfBoundsBottom < 0) {
      return list.scrollTo({ top: list.scrollTop + Math.abs(outOfBoundsBottom), behavior: 'smooth' })
    }

    // second check is if selected item is above the container view area
    const outOfBoundsTop = list.scrollTop - (selectedIndex + 1) * liHeight

    // if less than 50% of list item is visible
    if (outOfBoundsTop > liHeight / 2) {
      return list.scrollTo({ top: selectedIndex * liHeight, behavior: 'smooth' })
    }
  }, [selectedId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!diamonds.length) return

    if (!selectedId) {
      setSelectedId(diamonds[0].diamond_id)
    }
  }, [JSON.stringify(diamonds)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalWrapper isOpen={!!diamonds.length}>
      <div className={classes.shareModalContainer}>
        <div className={classes.header}>
          <div className={classes.title}>Share</div>
          <DevBanner />

          <HelpButton
            link={helpLinks.share}
            className={classes.helpLink}
            onClick={() => API.tracking.logHelp('share-modal', helpLinks.share)}
          />
          <div data-test-id={testIds.share.closeButton} className={classes.closeBtn} onClick={handleCloseClick}>
            <CloseSVG />
          </div>
        </div>
        <div className={classes.body}>
          <div className={classes.diamondViewer}>
            <div className={classes.info}>Below is a preview of how your diamonds will be shared</div>
            {diamonds.length > 1 ? (
              <div data-test-id={testIds.share.paginationNav} className={classes.nav}>
                <div className={classes.arrow} onClick={() => handleNavigateClick(-1)}>
                  <LeftArrowSVG />
                </div>
                <div className={classes.counter}>
                  {getSelectedIndex() + 1}/{diamonds.length} DIAMONDS
                </div>
                <div className={classes.arrow} onClick={() => handleNavigateClick(1)}>
                  <RightArrowSVG />
                </div>
              </div>
            ) : null}
            <div className={classnames(classes.diamondWrapper, { [classes.noNav]: diamonds.length < 2 })}>
              <Diamond id={selectedId} isShare />
            </div>
          </div>
          <div className={classes.listAndForm}>
            <div className={classes.list}>
              <div className={classes.listHeader}>
                <div className={classes.text}>
                  {diamonds.length} diamond{diamonds.length > 1 ? 's' : ''} selected to share
                </div>
              </div>
              <ul className={classes.items} ref={listRef}>
                {diamonds.map((diamond) => (
                  <ListItem
                    key={diamond.diamond_id}
                    diamond={diamond}
                    isSelected={diamond.diamond_id === selectedId}
                    onSelect={handleSelectDiamond}
                    onRemove={diamonds.length > 1 ? handleRemoveDiamond : null}
                    data-test-id={testIds.share.diamondListCard}
                  />
                ))}
              </ul>
            </div>
            <div className={classes.form}>
              <Form isLoading={isLoading} onSubmit={handleSubmit} />
            </div>
          </div>
        </div>
        {diamondToRemove ? <Bar id={diamondToRemove} onRemove={handleRemove} onCancel={handleRemoveCancel} /> : null}
      </div>
    </ModalWrapper>
  )
}

export default ShareModal
