import { clone } from 'ramda'
import { SHIPMENTS_IN_ACTION_TYPES } from '../actionTypes'
import { resolveActionType } from '../utils'

const diamondStatuses = [
  {
    key: 'transfer_in_pending',
    label: 'Processing',
    type: 'warning',
    active: true,
  },
  {
    key: 'transfer_in_confirmed',
    label: 'Action required',
    type: 'default',
    active: true,
    actionable: true,
  },
  {
    key: 'accepted',
    label: 'Processing',
    type: 'warning',
    complete: true,
  },
  {
    key: 'owned',
    label: 'Processing',
    type: 'warning',
    active: true,
    actionable: false,
  },
  {
    key: 'pending_verification',
    label: 'Processing',
    type: 'warning',
    active: true,
    actionable: false,
  },
  {
    key: 'verified',
    label: 'Processing',
    type: 'warning',
    active: true,
  },
  {
    key: 'verification_failed',
    label: 'Verification failed',
    type: 'error',
    active: true,
  },
  {
    key: 'transferred',
    label: 'Transferred',
    type: 'success',
    complete: true,
  },
  {
    key: 'pending_reject',
    label: 'Processing',
    type: 'warning',
    active: true,
  },
  {
    key: 'rejected',
    label: 'Rejected',
    type: 'error',
    actionable: false,
  },
  {
    key: 'cancelled',
    label: 'Cancelled',
    type: 'disabled',
    complete: true,
  },

  /**
   * Temporary statuses which are reset after page refresh
   */
  {
    key: 'inscription_number_invalid',
    type: 'error',
  },
]

const initialState = {
  data: {
    page: 1,
    shipments: [],
    filters: {
      static: {
        confirm: {},
        archive: {},
      },
      dynamic: {},
    },
    statuses: {
      diamond: {
        rough: diamondStatuses,
        polished: diamondStatuses,
      },
    },
  },
  requests: {
    // example request record
    // GET_SHIPMENTS: {loading: true, error: null}
    GET_SHIPMENTS: {},
  },
}

const shipmentsInReducer = (state = initialState, { payload, type }) => {
  const requestType = resolveActionType('SHIPMENTS_IN_ACTION_TYPES', type)

  switch (type) {
    case SHIPMENTS_IN_ACTION_TYPES.GET_SHIPMENT.REQUEST:
    case SHIPMENTS_IN_ACTION_TYPES.GET_SHIPMENTS.REQUEST:
    case SHIPMENTS_IN_ACTION_TYPES.ACCEPT_DIAMONDS.REQUEST:
    case SHIPMENTS_IN_ACTION_TYPES.REJECT_DIAMONDS.REQUEST: {
      const newState = clone(state)

      return {
        ...newState,
        requests: {
          ...newState.requests,
          [requestType]: { loading: true, error: null },
        },
      }
    }
    case SHIPMENTS_IN_ACTION_TYPES.GET_SHIPMENT.FAILURE:
    case SHIPMENTS_IN_ACTION_TYPES.GET_SHIPMENTS.FAILURE:
    case SHIPMENTS_IN_ACTION_TYPES.ACCEPT_DIAMONDS.FAILURE:
    case SHIPMENTS_IN_ACTION_TYPES.REJECT_DIAMONDS.FAILURE: {
      const newState = clone(state)

      return {
        ...newState,
        requests: {
          ...newState.requests,
          [requestType]: { loading: false, error: payload.error },
        },
      }
    }

    case SHIPMENTS_IN_ACTION_TYPES.GET_SHIPMENT.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      const existsAtIndex = newState.data.shipments.findIndex((s) => s.id === payload.id)

      if (existsAtIndex > -1) newState.data.shipments[existsAtIndex] = payload
      else newState.data.shipments.push(payload)

      return {
        ...newState,
        data: {
          ...newState.data,
          shipments: [...newState.data.shipments],
        },
      }
    }

    case SHIPMENTS_IN_ACTION_TYPES.GET_SHIPMENTS.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      const shipments = clone(payload.results).map((shipment) => {
        let lastUpdated = ''

        shipment.diamonds.forEach((diamond) => {
          const updatedAt = new Date(diamond.updated_at).getTime()

          if (lastUpdated < updatedAt) lastUpdated = updatedAt
        })

        return {
          ...shipment,
          diamonds_count: shipment.diamonds.length,
          updated_at: lastUpdated,
        }
      })

      return {
        ...newState,
        data: {
          ...newState.data,
          page: payload.page,
          shipments: payload.page !== 1 ? newState.data.shipments.concat(shipments) : shipments,
        },
      }
    }

    case SHIPMENTS_IN_ACTION_TYPES.ACCEPT_DIAMONDS.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      const shipment = newState.data.shipments.find((shipment) => shipment.id === payload.id)

      payload.diamonds.forEach((pDiamond) => {
        if (pDiamond.status !== 'success') return

        const stateDiamond = shipment.diamonds.find((d) => d.diamond_id === pDiamond.diamond_id)

        if (!stateDiamond) return

        // accepted means processing
        stateDiamond.transfer_state = 'accepted'
      })

      if (shipment.shipment_type === 'rough') shipment.status = 'incomplete'

      return {
        ...newState,
        data: {
          ...newState.data,
        },
      }
    }

    case SHIPMENTS_IN_ACTION_TYPES.REJECT_DIAMONDS.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      const shipment = newState.data.shipments.find((shipment) => shipment.id === payload.id)

      payload.diamonds.forEach((pDiamond) => {
        if (pDiamond.status !== 'success') return

        const stateDiamond = shipment.diamonds.find((d) => d.diamond_id === pDiamond.diamond_id)

        if (!stateDiamond) return

        // pending_reject means processing
        stateDiamond.transfer_state = 'pending_reject'
      })

      if (shipment.shipment_type === 'rough') shipment.status = 'rejecting'

      return {
        ...newState,
        data: {
          ...newState.data,
        },
      }
    }

    case SHIPMENTS_IN_ACTION_TYPES.SET_STATIC_FILTERS: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          filters: {
            ...newState.data.filters,
            static: {
              ...newState.data.filters.static,
              [payload.page]: payload.filters,
            },
          },
        },
      }
    }

    default:
      return state
  }
}

export default shipmentsInReducer
