import React from 'react'
import classnames from 'classnames'

import { LeftArrowSVG, RightArrowSVG } from 'components/Svg'
import testIds from 'config/testIds'

import NavTitle from '../NavTitle'
import classes from './styles.module.css'

const Nav = ({ diamond, sticky, selectedCount, selectedIndex, onNavigate, children, testId }) => {
  return (
    <div data-test-id={testId} className={classes.navContainer}>
      <div className={classnames(classes.content, { [classes.sticky]: sticky })}>
        {selectedCount > 1 ? (
          <div className={classes.navAndLabel}>
            <div className={classes.prevNext}>
              <div
                className={classes.prevNextBtn}
                onClick={() => onNavigate(-1)}
                data-test-id={testIds.diamondsNav.leftArrow}
              >
                <LeftArrowSVG />
              </div>
              <div className={classes.prevNextCounter} data-test-id={testIds.diamondsNav.selectionCount}>
                {selectedIndex + 1}
                <span>/</span>
                {selectedCount}
              </div>
              <div
                className={classes.prevNextBtn}
                onClick={() => onNavigate(1)}
                data-test-id={testIds.diamondsNav.rightArrow}
              >
                <RightArrowSVG />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.filler}></div>
        )}
        <div className={classes.diamondTitle}>{diamond && <NavTitle diamond={diamond} />}</div>
        <div className={classes.actions}>{children}</div>
      </div>
    </div>
  )
}

export default Nav
