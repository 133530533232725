import { ShapeCell } from 'components/system/TableElements'
import { timestampFormatter } from 'utils/dates'
import { formatNumericalValueDefault } from 'utils/diamond'
import translation from 'text/en'

import Provenance from './Provenance'

import Status from './Status'
import { Matched } from './Matched'
import { defaultMapper, carats, lengthWidthRatio, gradingProp, media } from './mappers'

export const columnsMapper = {
  assurance_level: {
    title: 'Assurance level',
  },
  participant_id: {
    title: 'Participant ID',
  },
  participant_parent_id: {
    title: 'Participant parent ID',
  },
  diamond_id: {
    title: 'Tracr ID',
  },
  box_id: {
    title: 'Box ID',
  },
  sight_year: {
    title: 'Sight year',
    rightAlign: true,
  },
  sight_no: {
    title: 'Sight number',
    rightAlign: true,
  },
  box_description: {
    title: 'Box description',
  },
  participant_timestamp: {
    title: 'Last updated',
    tooltip: translation.listview.table.tooltips.dateAndTime,
    mapper: timestampFormatter,
  },
  unlock_status: {
    title: 'Status',
    tooltip: translation.listview.table.tooltips.status,
    minWidth: '120px',
    cellComponent: Status,
  },
  provenance: {
    title: 'Provenance',
    tooltip: translation.listview.table.tooltips.provenance,
    cellComponent: Provenance,
  },
  'provenance.provenance_name': {
    title: 'Provenance',
    tooltip: translation.listview.table.tooltips.provenance,
  },
  'supplementary_tags.sight_holder': {
    title: 'Sight holder',
  },
  'supplementary_tags.batch_id': {
    title: 'Batch ID',
  },
  'rough.carats': {
    title: 'Carat',
    tooltip: translation.listview.table.tooltips.carats,
    mapper: carats,
    rightAlign: true,
  },
  'rough.model': {
    title: 'Model',
    tooltip: translation.listview.table.tooltips.model,
    mapper: defaultMapper,
  },
  'rough.colour': {
    title: 'Colour',
    tooltip: translation.listview.table.tooltips.colour,
    mapper: defaultMapper,
  },
  'rough.quality': {
    title: 'Quality',
    tooltip: translation.listview.table.tooltips.quality,
    mapper: defaultMapper,
  },
  'rough.stress': {
    title: 'Stress',
    tooltip: translation.listview.table.tooltips.stress,
    mapper: defaultMapper,
  },
  'rough.fancy_colour': {
    title: 'Fancy colour',
  },
  'rough.fancy_colour_intensity': {
    title: 'Fancy colour intensity',
  },
  'rough.fancy_colour_overtone': {
    title: 'Fancy colour overtone',
  },
  'rough.fluorescence_intensity': {
    title: 'Fluorescence intensity',
    tooltip: translation.listview.table.tooltips.fluorescence_intensity,
  },
  'rough.fluorescence_colour': {
    title: 'Fluorescence colour',
    tooltip: translation.listview.table.tooltips.fluorescence_colour,
  },
  'rough.images': {
    title: 'Images',
    mapper: media,
    rightAlign: true,
    sortable: false,
  },
  'rough.videos': {
    title: 'Videos',
    mapper: media,
    rightAlign: true,
  },
  'rough.scan': {
    title: '3D Scans',
    mapper: media,
    rightAlign: true,
  },
  'rough.scan_reports': {
    title: '3D Scan reports',
    mapper: media,
    rightAlign: true,
  },
  'rough.plan': {
    title: 'Plans',
    mapper: media,
    rightAlign: true,
  },
  'rough.plan_reports': {
    title: 'Plan reports',
    mapper: media,
    rightAlign: true,
  },
  'rough.supplementary_files': {
    title: 'Supplementary files',
    mapper: media,
    rightAlign: true,
  },
  matched: {
    title: 'Rough Check',
    rightAlign: false,
    tooltip: translation.listview.table.tooltips.diamondMatch.column,
    cellComponent: Matched,
  },
  'polished.carats': {
    title: 'Carat',
    tooltip: translation.listview.table.tooltips.carats,
    mapper: carats,
    rightAlign: true,
  },
  'polished.shape': {
    title: 'Shape',
    tooltip: translation.listview.table.tooltips.shape,
    cellComponent: ShapeCell,
  },
  'polished.colour': {
    title: 'Colour',
    tooltip: translation.listview.table.tooltips.colour,
    mapper: defaultMapper,
  },
  'polished.clarity': {
    title: 'Clarity',
    tooltip: translation.listview.table.tooltips.clarity,
    mapper: defaultMapper,
  },
  'polished.cut_grade': {
    title: 'Cut',
    tooltip: translation.listview.table.tooltips.cutGrade,
    mapper: defaultMapper,
  },
  'polished.symmetry': {
    title: 'Symmetry',
    tooltip: translation.listview.table.tooltips.symmetry,
    mapper: defaultMapper,
  },
  'polished.polish_quality': {
    title: 'Polish',
    tooltip: translation.listview.table.tooltips.polishQuality,
    mapper: defaultMapper,
  },
  'polished.fluorescence_intensity': {
    title: 'Fluorescence intensity',
    tooltip: translation.listview.table.tooltips.fluorescence,
    mapper: defaultMapper,
  },
  'polished.fluorescence_colour': {
    title: 'Fluorescence colour',
    tooltip: translation.listview.table.tooltips.fluorescence,
    mapper: defaultMapper,
  },
  'polished.fancy_colour': {
    title: 'Fancy colour',
  },
  'polished.fancy_colour_intensity': {
    title: 'Fancy colour intensity',
  },
  'polished.fancy_colour_overtone': {
    title: 'Fancy colour overtone',
  },
  'polished.girdle_thickness_from': {
    title: 'Girdle thickness from',
  },
  'polished.girdle_thickness_to': {
    title: 'Girdle thickness to',
  },
  'polished.girdle_condition': {
    title: 'Girdle condition',
  },
  'polished.pavillion_angle': {
    title: 'Pavilion angle',
    rightAlign: true,
    mapper: formatNumericalValueDefault,
  },
  'polished.pavillion_percent': {
    title: 'Pavilion %',
    rightAlign: true,
    mapper: formatNumericalValueDefault,
  },
  'polished.length': {
    title: 'Length',
    rightAlign: true,
    mapper: formatNumericalValueDefault,
  },
  'polished.width': {
    title: 'Width',
    rightAlign: true,
    mapper: formatNumericalValueDefault,
  },
  'polished.depth': {
    title: 'Depth',
    rightAlign: true,
    mapper: formatNumericalValueDefault,
  },
  'polished.culet_size': {
    title: 'Culet size',
  },
  'polished.culet_condition': {
    title: 'Culet condition',
  },
  'polished.crown_height': {
    title: 'Crown height',
    rightAlign: true,
    mapper: formatNumericalValueDefault,
  },
  'polished.crown_angle': {
    title: 'Crown angle',
    rightAlign: true,
    mapper: formatNumericalValueDefault,
  },
  'polished.diameter_minimum': {
    title: 'Diameter minimum',
    rightAlign: true,
    mapper: formatNumericalValueDefault,
  },
  'polished.diameter_maximum': {
    title: 'Diameter maximum',
    rightAlign: true,
    mapper: formatNumericalValueDefault,
  },
  'polished.lengthWidthRatio': {
    title: 'L/W Ra',
    sortable: false,
    tooltip: translation.listview.table.tooltips.lengthWidthRatio,
    mapper: lengthWidthRatio,
    rightAlign: true,
  },
  'polished.depth_percent': {
    title: 'Depth %',
    tooltip: translation.listview.table.tooltips.depthPercent,
    mapper: formatNumericalValueDefault,
    rightAlign: true,
  },
  'polished.table_percent': {
    title: 'Table %',
    tooltip: translation.listview.table.tooltips.tablePercent,
    mapper: formatNumericalValueDefault,
    rightAlign: true,
  },
  'polished.grading_lab': {
    title: 'Grading lab',
    sortable: false,
    tooltip: translation.listview.table.tooltips.gradingLab,
    mapper: gradingProp,
  },
  'polished.graded_date': {
    title: 'Grading date',
    sortable: false,
    mapper: gradingProp,
  },
  'polished.grader_report_no': {
    title: 'Grading report number',
    sortable: false,
    mapper: gradingProp,
  },
  'polished.grader_certificate_no': {
    title: 'Grading certificate number',
    sortable: false,
    mapper: gradingProp,
  },
  'polished.grader_inscription_no': {
    title: 'Grading inscription number',
    sortable: false,
    mapper: gradingProp,
  },
  'polished.grading_certificates': {
    title: 'Grading certificates',
    mapper: media,
    rightAlign: true,
  },
  'polished.scan': {
    title: '3D Scans',
    mapper: media,
    rightAlign: true,
  },
  'polished.scan_reports': {
    title: '3D Scan reports',
    mapper: media,
    rightAlign: true,
  },
  'polished.images': {
    title: 'Images',
    mapper: media,
    rightAlign: true,
    sortable: false,
  },
  'polished.videos': {
    title: 'Videos',
    mapper: media,
    rightAlign: true,
  },
  'polished.supplementary_files': {
    title: 'Supplementary files',
    mapper: media,
    rightAlign: true,
  },
}
