import React, { useEffect, useState, useRef } from 'react'
import classnames from 'classnames'
import { forEach } from 'ramda'
import camelCase from 'camelcase'

import { Tooltip } from 'components/system'
import { DITSortUpIcon, DITSortDownIcon } from 'components/Svg'
import { testId } from 'utils/testHelper'

import classes from './styles.module.css'

export const HeaderCell = ({
  title,
  Component,
  rows,
  columnKey,
  sortable = true,
  resizable = true,
  tooltip,
  maxScale,
  sorted,
  minWidth,
  width,
  grow,
  centered,
  rightAlign,
  isResizing,
  isComponent,
  testIdPrefix = '',
  onCellClick,
  onCellResize,
  onResizeStartEnd,
  ...props
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [renderTooltip, setRenderTooltip] = useState(false)
  const container = useRef()
  const resizer = useRef()

  let startingPos = 0

  const isResizer = (target) => {
    if (!resizer.current) return false

    if (target === resizer.current) return true

    const children = resizer.current.children
    let result = false

    forEach((child) => {
      if (target === child) result = true
    }, children)

    return result
  }

  const handleOnCellClick = (evt) => {
    if (isResizer(evt.target)) return

    if (onCellClick) onCellClick(columnKey)
  }

  const handleMouseDown = () => {
    startingPos = resizer.current.getBoundingClientRect().x

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)

    setIsDragging(true)
    onResizeStartEnd(true)
  }

  const handleMouseUp = () => {
    window.removeEventListener('mousemove', handleMouseMove)
    window.removeEventListener('mouseup', handleMouseUp)

    setIsDragging(false)
    onResizeStartEnd(false)
  }

  const handleMouseMove = (evt) => {
    const distanceX = evt.pageX - startingPos

    onCellResize(columnKey, distanceX)
  }

  // only render tooltips when content doesn't fit into container
  const handleMouseEnterForTooltip = (evt) => {
    const title = evt.target.querySelector('.cellTitle')

    if (!title) return

    if (title.offsetWidth < title.scrollWidth) setRenderTooltip(true)
    else setRenderTooltip(false)
  }

  useEffect(() => { }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classnames(classes.headerCellContainer, {
        [classes.sortable]: sortable,
        [classes.resizable]: resizable,
        [classes.grow]: grow,
        [classes.centered]: rightAlign ? false : centered,
        [classes.rightAlign]: rightAlign,
        [classes.maxScale]: maxScale,
        [classes.isDragging]: isDragging || isResizing,
        [classes.customComponent]: isComponent,
        [classes.sorted]: sorted,
      })}
      style={{ minWidth, width }}
      data-test-id={testId(testIdPrefix, camelCase(columnKey.replace(/rough|polished|split/i, '')))}
      ref={container}
    >
      <div
        className={classnames(classes.content, { [classes.nonSortable]: !sortable })}
        onClick={sortable ? handleOnCellClick : null}
        onMouseEnter={handleMouseEnterForTooltip}
      >
        {!Component && (
          <Tooltip
            title={renderTooltip ? (tooltip ? tooltip.title : title) : ''}
            description={renderTooltip ? (tooltip ? tooltip.description : '') : ''}
          >
            <div className="cellTitle">{title}</div>
          </Tooltip>
        )}
        {Component && <Component {...props} rows={rows} isHeader testIdPrefix={testIdPrefix} />}

        {sortable ? (
          <div className={classes.sortKeys}>
            {sorted === 'asc' && <DITSortUpIcon />}
            {sorted === 'desc' && <DITSortDownIcon />}
            {!sorted && (
              <div className={classes.sortHover}>
                <DITSortDownIcon />
              </div>
            )}
          </div>
        ) : null}
      </div>

      {resizable && (
        <div className={classes.dragContainer} onMouseDown={handleMouseDown}>
          <div className={classes.resizer} ref={resizer}>
            <div className={classnames(classes.topLine, { [classes.highlighted]: isDragging })}></div>
          </div>
        </div>
      )}
    </div>
  )
}

export default HeaderCell
