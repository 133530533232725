import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox } from 'components/system'
import testIds from 'config/testIds'

import Status from './Status'
import classes from './styles.module.css'

const CardTop = ({ diamond_id, selected, unlock_status, onCheckboxChange }) => {
  const handleCheckboxChange = () => (onCheckboxChange ? onCheckboxChange(diamond_id) : null)

  return (
    <div className={classes.cardTopContainer}>
      <div className={classes.wrapper}>
        <Checkbox
          value={selected ? 2 : 0}
          data-test-id={testIds.listView.checkbox}
          onClick={(e) => e.stopPropagation()}
          onChange={handleCheckboxChange}
        />

        <div test-data-id={testIds.inventory.card.status} className={classes.status}>
          <Status value={unlock_status} />
        </div>
      </div>
    </div>
  )
}

CardTop.propTypes = {
  diamond_id: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  status: PropTypes.shape({
    value: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    tooltip: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onCheckboxChange: PropTypes.func,
}

export default CardTop
