export const selectActiveShipmentsOut = (state) => {
  const activeStatuses = ['sending', 'sent', 'in_progress', 'accepting', 'cancelling', 'rejecting']

  return state.shipmentsOut.data.shipments.filter((shipment) => activeStatuses.includes(shipment.status))
}

export const selectInactiveShipmentsOut = (state) => {
  const inactiveStatuses = ['cancelled', 'rejected', 'complete']

  return state.shipmentsOut.data.shipments.filter((shipment) => inactiveStatuses.includes(shipment.status))
}

export const selectNewShipmentOut = (state) => {
  return state.shipmentsOut.data.newShipment
}

export const selectShipmentOutById = (id) => {
  return (state) => {
    return state.shipmentsOut.data.shipments.find((shipment) => shipment.id === id)
  }
}

export const selectShipmentOutDiamondStatuses = (state) => {
  return state.shipmentsOut.data.statuses.diamond
}

export const selectShipmentOutValidationStatuses = (state) => {
  return state.shipmentsOut.data.statuses.validation
}

export const selectShipmentsOutStaticFilters = (page) => {
  return (state) => {
    return state.shipmentsOut.data.filters.static[page]
  }
}
