import navConfig from 'config/nav'

import { SETTINGS_ACTION_TYPES } from '../actionTypes'

export function updateNavIsCollapsedAction(payload) {
  return (dispatch) => {
    dispatch({
      type: SETTINGS_ACTION_TYPES.UPDATE_NAV_IS_COLLAPSED,
      payload,
    })

    return new Promise((resolve) => {
      // time needed for collapse animation to finish
      setTimeout(() => resolve(), navConfig.collapseExpandAnimationTime)
    })
  }
}

export function navToggleItemAction(payload) {
  return {
    type: SETTINGS_ACTION_TYPES.NAV_TOGGLE_ITEM,
    payload,
  }
}

export function DITSelectTabAction(payload) {
  return {
    type: SETTINGS_ACTION_TYPES.DIT_SELECT_TAB,
    payload,
  }
}

export function DITHideSubmittedDiamondsAction(payload) {
  return {
    type: SETTINGS_ACTION_TYPES.DIT_HIDE_SUBMITTED_DIAMONDS,
    payload,
  }
}

export function DITResetSettingsAction() {
  return {
    type: SETTINGS_ACTION_TYPES.DIT_RESET_SETTINGS,
  }
}

export function updateViewColumnsAction(payload) {
  return {
    type: SETTINGS_ACTION_TYPES.UPDATE_VIEW_COLUMNS,
    payload,
  }
}

export function resetViewColumnsAction(payload) {
  return {
    type: SETTINGS_ACTION_TYPES.RESET_VIEW_COLUMNS,
    payload,
  }
}

export function updateShipmentsInTabAction(id) {
  return {
    type: SETTINGS_ACTION_TYPES.UPDATE_SHIPMENTS_IN_TAB,
    payload: id,
  }
}

export function updateShipmentsOutTabAction(id) {
  return {
    type: SETTINGS_ACTION_TYPES.UPDATE_SHIPMENTS_OUT_TAB,
    payload: id,
  }
}
