import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useHistory } from 'react-router-dom'
import classnames from 'classnames'

import { searchDiamondAction, clearSearchAction } from 'store/actions'
import { selectSearchResult, selectSearchError, selectSearchedValue } from 'store/selectors'
import { SearchIcon } from 'components/Svg/form'
import { CloseSVG } from 'components/Svg'
import ClickOutsideListener from 'utils/ClickOutsideListener'

import classes from './styles.module.css'

const Search = () => {
  const dispatch = useDispatch()
  // const history = useHistory()
  const result = useSelector(selectSearchResult)
  const error = useSelector(selectSearchError)
  const searchedFor = useSelector(selectSearchedValue)
  const [hovered, setHovered] = useState(false)
  const [focused, setFocused] = useState(false)
  const [value, setValue] = useState('')
  const inputRef = useRef()

  const searchDiamond = (id) => {
    dispatch(searchDiamondAction(id))
  }

  const handleMouseOver = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }

  const handleFocus = () => {
    setFocused(true)
  }

  const handleKeyPress = (event) => {
    if (['Enter'].includes(event.key)) {
      if (inputRef.current.value.trim() !== '') {
        event.preventDefault()

        searchDiamond(inputRef.current.value)
      }
    }
  }

  const handleChange = () => {
    setValue(inputRef.current.value)
  }

  const handleClear = (refocus) => {
    inputRef.current.value = ''
    setValue('')
    dispatch(clearSearchAction())

    if (refocus) inputRef.current.focus()
  }

  const handleSearchClick = () => {
    if (inputRef.current.value.trim().length === 0) return

    searchDiamond(inputRef.current.value)
  }

  const handleClickOutside = () => {
    if (!focused) return

    setFocused(false)
    // handleClear()
  }

  useEffect(() => {
    if (result !== null) {
      // history.push(`/inventory/${result.current_stage}/diamond/${result.id}`)
    }
  }, [result]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchedFor) setValue(searchedFor)
  }, [searchedFor])

  return (
    <ClickOutsideListener onClickOutside={handleClickOutside}>
      <div className={classes.searchContainer}>
        <div
          className={classes.field}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          onFocus={handleFocus}
        >
          <div
            className={classnames(classes.input, {
              [classes.hover]: hovered && !focused,
              [classes.focus]: focused,
              [classes.error]: !!error,
            })}
          >
            <input
              type="text"
              placeholder="Search by ID..."
              value={value}
              onKeyPress={handleKeyPress}
              onChange={handleChange}
              ref={inputRef}
            />
          </div>
          <div
            className={classnames(classes.clearBtn, {
              [classes.visible]: value.length > 0,
              [classes.hover]: hovered && !focused,
              [classes.focus]: focused,
              [classes.error]: !!error,
            })}
            onClick={() => handleClear(true)}
          >
            <CloseSVG />
          </div>
          <div
            className={classnames(classes.searchBtn, {
              [classes.hover]: hovered && !focused,
              [classes.focus]: focused,
              [classes.error]: !!error,
            })}
            onClick={handleSearchClick}
          >
            <SearchIcon />
          </div>
        </div>
        <div className={classnames(classes.statusMessage, { [classes.visible]: !!error })}>{error}</div>
      </div>
    </ClickOutsideListener>
  )
}

export default Search
