import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { locationAction } from 'store/actions'

export const LocationObserver = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(locationAction({ pathname }))
  }, [dispatch, pathname])

  return null
}

export default LocationObserver
