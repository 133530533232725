import React from 'react'

import { Tooltip } from 'components/system'
import { resolveShapeToSVG } from 'utils/svgResolver'

import classes from './styles.module.css'

export const ShapeCell = ({ row }) => {
  const value = row.data.lifecycle_state === 'polished' ? row.data.polished.shape : row.data.rough.shape
  const ShapeSVG = resolveShapeToSVG(value)

  return (
    <Tooltip title={value || ''}>
      <div className={classes.shapeCellContainer}>
        {value ? (
          <div className={classes.icon}>
            <ShapeSVG size="m" />
          </div>
        ) : null}
        <div className={classes.text}>{value || '-'}</div>
      </div>
    </Tooltip>
  )
}

export default ShapeCell
