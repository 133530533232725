import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { omit } from 'ramda'

import { selectAppliedFilters } from 'store/selectors'
import { clearFiltersAction } from 'store/actions'
import {
  RoughDiamondSVG,
  PolishingDiamondSVG,
  PolishedDiamondSVG,
  PlanningDiamondSVG,
  SawingDiamondSVG,
  GradedDiamondSVG,
  SplitDiamondSVG,
} from 'components/Svg'

import classes from './styles.module.css'

const Empty = ({ children }) => {
  const dispatch = useDispatch()
  const appliedFilters = useSelector(selectAppliedFilters)
  const currentFiltersCount = Object.keys(omit(['lifecycle_state', 'possession_state'], appliedFilters)).length

  const clearFilters = () => {
    dispatch(clearFiltersAction())
  }

  const Icon = () => {
    switch (appliedFilters.lifecycle_state) {
      case 'polished':
        return <PolishedDiamondSVG size="l" />
      case 'planning':
        return <PlanningDiamondSVG size="l" />
      case 'sawing':
        return <SawingDiamondSVG size="l" />
      case 'polishing':
        return <PolishingDiamondSVG size="l" />
      case 'graded':
        return <GradedDiamondSVG size="l" />
      case 'bruting':
        return <SplitDiamondSVG size="l" />
      default:
        return <RoughDiamondSVG size="l" />
    }
  }

  return (
    <div className={classes.emptyContainer}>
      <div className={classes.icons}>
        <div className={classes.leftIcon}>
          <Icon />
        </div>
        <div className={classes.rightIcon}>
          <Icon />
        </div>
      </div>
      <div className={classes.message}>
        <div className={classes.title}>No diamonds to display</div>
        <br />
        {appliedFilters.possession_state === 'known' && currentFiltersCount === 0 && (
          <>When you transfer diamonds they will show here</>
        )}
        {currentFiltersCount !== 0 && (
          <>
            <span className={classes.link} onClick={clearFilters}>
              Clear all filters
            </span>{' '}
            or refine your search manually to display results.
          </>
        )}
        {children}
      </div>
    </div>
  )
}

export default Empty
