import React from 'react'
import classnames from 'classnames'

import labels from 'text/labels'
import testIds from 'config/testIds'
import { testId } from 'utils/testHelper';

import classes from './styles.module.css'

export const FilterButtons = ({ enableClear, onClear, testIdPrefix }) => {
  return (
    <div className={classes.filterButtonsContainer}>
      <button
        className={classnames(classes.button, classes.inversed, {
          [classes.enabled]: enableClear,
        })}
        data-test-id={testId(testIdPrefix, 'clearButton')}
        onClick={() => {
          if (enableClear) onClear()
        }}
      >
        {labels.clearFilters}
      </button>
    </div>
  )
}
export default FilterButtons
