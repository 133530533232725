export const selectShowSecondaryFilters = state => state.uiState.showSecondaryFilters

export const selectPagination = state => {
  const { page, limit } = state.uiState
  return {
    page,
    limit,
  }
}

export const selectSorting = state => {
  return state.uiState.sort
}
