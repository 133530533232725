import React from 'react'
import PropTypes from 'prop-types'

import ImageAsset from './ImageAsset'
import ThreeDAsset from './ThreeDAsset'
import VideoAsset from './VideoAsset'

const MediaContent = ({ asset }) => {
  // can be improved by returning 404 template or Error template

  if (!asset || !asset.data) return null

  const extension = asset.extension ? asset.extension.toLowerCase() : null

  switch (extension) {
    case 'img':
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
      return <ImageAsset asset={asset} />
    case 'mp4':
    case 'webm':
    case 'ogg':
      return <VideoAsset asset={asset} extension={extension} isThumbnail={asset.thumb} />
    case 'stl':
    case 'ply':
      return <ThreeDAsset asset={asset} extension={extension} isThumbnail={asset.thumb} fov={asset.fov} />
    default:
      return null
  }
}

MediaContent.propTypes = {
  asset: PropTypes.object,
  onLoad: PropTypes.func,
}

export default MediaContent
