import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import API from 'api'
import {
  updateViewAction,
  selectDiamondsToShareAction,
  selectDiamondsToUnlockAction,
  setUnlockModalVisibleAction,
  updateSelectedDiamonds
} from 'store/actions'
import { selectLifecycleStage, selectSelectedDiamonds, selectDiamonds } from 'store/selectors'
import CounterAndViewSelector from 'containers/CounterAndViewSelector'
import Filters from 'containers/Filters'
import UnlockMultipleModal from 'containers/UnlockMultipleModal'
import ShareModal from 'containers/ShareModal'
import { PageTitle, HelpButton, ActionsBar } from 'components/system'
import { getLockedIds } from 'utils/diamond'
import { featureToggles, helpLinks, testIds } from 'config'

import CardViewTable from './CardViewTable'
import classes from './styles.module.css'

const CardView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const stage = useSelector(selectLifecycleStage)
  const selection = useSelector(selectSelectedDiamonds)
  const diamonds = useSelector(selectDiamonds)

  const actions = [
    {
      key: 'view',
      label: 'View',
      icon: 'eye',
      onClick: () => {
        const id = Array.isArray(selection) ? selection[0] : selection
        navigate(`/inventory/${stage}/diamond/${id}`)
      },
    },
    {
      key: 'share',
      label: 'Share',
      icon: 'share',
      disabled: featureToggles.share === 1,
      onClick: () => {
        const lockedIds = getLockedIds(diamonds, selection)

        if (lockedIds.length) {
          dispatch(selectDiamondsToUnlockAction(lockedIds))
          dispatch(setUnlockModalVisibleAction('multiple'))
        } else dispatch(selectDiamondsToShareAction(selection))
      },
    },
    {
      key: 'unlock',
      label: 'Unlock',
      icon: 'lock',
      check: () => {
        const lockedIds = getLockedIds(diamonds, selection)

        if (lockedIds.length) return true
        else return false
      },
      onClick: () => {
        const lockedIds = getLockedIds(diamonds, selection)

        dispatch(selectDiamondsToUnlockAction(lockedIds))
        dispatch(setUnlockModalVisibleAction('multiple'))
      },
    },
  ]

  useEffect(() => {
    dispatch(updateViewAction('cards'))
  })

  const handleClearSelectionClick = () => {
    dispatch(updateSelectedDiamonds([]))
    dispatch(selectDiamondsToUnlockAction([]))
    dispatch(selectDiamondsToShareAction([]))
  }

  return (
    <div className={classes.cardViewContainer}>
      <PageTitle title={`${stage} inventory`}>
        <HelpButton link={helpLinks.listView} onClick={() => API.tracking.logHelp('card-view', helpLinks.listView)} />
      </PageTitle>
      <Filters />
      <CounterAndViewSelector />
      <CardViewTable />
      <ActionsBar
        actions={actions.filter((action) => (action.check ? action.check() : true))}
        label="Selected"
        selectionLength={selection.length}
        testIdPrefix={testIds.inventory.actionBar.prefix}
        onClear={handleClearSelectionClick}
      />
      <UnlockMultipleModal />
      <ShareModal />
    </div>
  )
}

export default CardView
