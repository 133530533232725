import React, { useRef, useState, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { CloseSVG } from 'components/Svg'

import ModalWrapper from '../ModalWrapper'
import Button from '../Button'
import classes from './styles.module.css'

export const Modal = ({
  title = 'Modal',
  isOpen = false,
  resizable = false,
  width = 426,
  size = 'l',
  confirmLabel = 'Ok, got it',
  confirmTestId,
  cancelLabel = 'Cancel',
  cancelTestId,
  confirmDisabled,
  closeTestId,
  children,
  onCancel,
  onConfirm,
  onClose,
  onRef,
}) => {
  const wrapperRef = useRef()
  const [bodyHeight, setBodyHeight] = useState(0)
  const showActions = onCancel || onConfirm

  const handleClose = () => {
    if (onCancel && !onClose) onCancel()
    else if (onClose) onClose()
  }

  const onResize = () => {
    const windowHeight = window.innerHeight
    const modalHeight = wrapperRef.current ? wrapperRef.current.offsetHeight : 0
    const modalHeaderHeight = wrapperRef.current ? wrapperRef.current.children[0].offsetHeight : 0

    if (!modalHeight) return

    // 60 for 30 top/bottom padding
    const height = windowHeight - modalHeaderHeight - 60

    setBodyHeight(height)
  }

  const onWrapperRef = (node) => {
    if (!node || node === wrapperRef.current) return

    wrapperRef.current = node

    onResize()

    if (onRef) onRef(node)
  }

  useEffect(() => {
    if (!resizable) return

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalWrapper isOpen={isOpen} onHide={handleClose}>
      <div className={classes.wrapper} style={{ width: `${width}px` }} ref={onWrapperRef}>
        <div className={classnames(classes.header, { [classes.sizeL]: size === 'l' })}>
          <div className={classnames(classes.headerTitle, { [classes.sizeL]: size === 'l' })}>{title}</div>
          <div className={classes.close} onClick={handleClose} data-test-id={closeTestId}>
            <CloseSVG />
          </div>
        </div>
        <div className={classes.body} style={{ maxHeight: resizable && bodyHeight ? bodyHeight : null }}>
          <div className={classes.content}>{children}</div>
          {showActions && (
            <div className={classes.actions}>
              {onCancel && (
                <Button
                  variant="secondary"
                  size={size === 'l' ? 'm' : 's'}
                  onClick={onCancel}
                  data-test-id={cancelTestId}
                >
                  {cancelLabel}
                </Button>
              )}
              {onConfirm && (
                <Button
                  variant="primary"
                  size={size === 'l' ? 'm' : 's'}
                  disabled={confirmDisabled}
                  onClick={!confirmDisabled ? onConfirm : null}
                  data-test-id={confirmTestId}
                >
                  {confirmLabel}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </ModalWrapper>
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  width: PropTypes.number,
  confirmLabel: PropTypes.string,
  confirmTestId: PropTypes.string,
  cancelLabel: PropTypes.string,
  cancelTestId: PropTypes.string,
  confirmDisabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}

export default Modal
