/* eslint-disable */
import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

function useClickOutsideListener(ref, onClickOutside) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutside(event)
    }
  }

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  })
}

export function ClickOutsideListener(props) {
  const wrapperRef = useRef(null)
  useClickOutsideListener(wrapperRef, props.onClickOutside)

  return <div ref={wrapperRef}> {props.children} </div>
}

ClickOutsideListener.propTypes = {
  children: PropTypes.any,
  onClickOutside: PropTypes.func,
}

export default ClickOutsideListener
