import { DIAMONDS_ACTION_TYPES } from '../actionTypes'
import { resolveActionType } from '../utils'

export const selectDiamond = (state, id) => {
  return state.diamonds.data.list[id]
}

export const selectDiamonds = (state) => {
  return state.diamonds.data.list
}

export const selectFullDiamonds = (state) => {
  return state.diamonds.data.fullDiamonds
}

export const selectDiamondBlueNile = (state) => {
  return state.diamonds.data.diamondBlueNile
}

export const selectDiamondsCount = (state) => {
  return {
    count: state.diamonds.data.count,
    filteredCount: state.diamonds.data.filteredCount,
  }
}

export const selectTotalDiamondsCount = (state) =>
  isNaN(state.diamonds.data.totalCount) ? '-' : parseFloat(state.diamonds.data.totalCount)

export const selectDiamondById = (id) => {
  return (state) => state.diamonds.data.list.find((diamond) => diamond.diamond_id === id)
}

export const selectDiamondsById = (ids) => {
  return (state) => state.diamonds.data.list.filter((diamond) => ids.includes(diamond.diamond_id))
}

export const selectFullDiamond = (id) => {
  return (state) =>
    state.diamonds.data.fullDiamond && state.diamonds.data.fullDiamond.current.diamond_id === id
      ? state.diamonds.data.fullDiamond
      : null
}

export const selectFullDiamondById = (id) => {
  return (state) => {
    const fullDiamond = state.diamonds.data.fullDiamonds.find((diamond) => diamond.current.diamond_id === id)

    if (fullDiamond) return fullDiamond

    if (state.diamonds.data.fullDiamond && state.diamonds.data.fullDiamond.current.diamond_id === id)
      return state.diamonds.data.fullDiamond
  }
}

export const selectFullDiamondsById = (ids) => {
  return (state) => {
    const fullDiamonds = state.diamonds.data.fullDiamonds.filter((fullDiamond) =>
      ids.includes(fullDiamond.current.diamond_id)
    )

    return fullDiamonds
  }
}

export const selectSharedDiamonds = (state) => {
  return state.diamonds.data.sharedDiamonds
}

export const selectDiamondsLoading = (state) => {
  const requestType = resolveActionType('DIAMONDS_ACTION_TYPES', DIAMONDS_ACTION_TYPES.GET_DIAMONDS.REQUEST)

  const request = state.diamonds.requests[requestType]

  if (!request) return false

  return request.loading
}

export const selectSelectedDiamonds = (state) => {
  return state.diamonds.data.selected
}

export const selectSelectedShareDiamonds = (state) => {
  return state.diamonds.data.shareSelected
}

export const selectDiamondsHasMore = (state) => {
  return state.diamonds.data.hasMore
}
