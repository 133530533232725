import { STORE_INIT } from '../actionTypes'

export const localStorageVersion = 1.8

const versionReducer = (state = localStorageVersion, { type }) => {
  switch (type) {
    case STORE_INIT:
      return state
    default:
      return state
  }
}

export default versionReducer
