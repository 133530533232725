import React from 'react'

import { ButtonsCell } from 'components/system/TableElements'

export const Buttons = ({ row, ...props }) => {
  const getItems = () => {
    const items = []

    items.push({ key: 'view', label: 'View', type: 'primary' })

    return items
  }

  return <ButtonsCell row={row} buttons={getItems()} {...props} />
}

export default Buttons
