import API from 'api'
// import showNotification from 'containers/Notifications/controller'
// import { createErrorMessage, createSuccessMessage } from 'utils/notificationMessages'
import { MONITORING_ACTION_TYPES } from '../actionTypes'
import { sessionExpiredAction } from '.'

// TODO: Maria add params from filters/page to the action/API call
export function getMonitoringDiamondsAction({ page, limit, sort, filters }) {
  return (dispatch) => {
    dispatch({ type: MONITORING_ACTION_TYPES.GET_DIAMONDS.REQUEST })

    return API.getMonitoringDiamonds({ page, limit, sort, filters }).then(
      (response) => {
        dispatch({ type: MONITORING_ACTION_TYPES.GET_DIAMONDS.COMPLETE, payload: response.data, page: page })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: MONITORING_ACTION_TYPES.GET_DIAMONDS.FAILURE, payload: error })
        }
      }
    )
  }
}

export function applyMonitoringFiltersAction(payload) {
  return {
    type: MONITORING_ACTION_TYPES.APPLY_FILTERS,
    payload,
  }
}
