import React from 'react'

import { Heading } from 'components/system'

import classes from './styles.module.css'

const Conditions = () => {
  return (
    <div className={classes.conditionsContainer}>
      <Heading text="Terms and Conditions" />
      <div className={classes.contentContainer}>
        <div className={classes.mainHeading}>Please read these Terms carefully before using our website.</div>
        <div>
          <div class="rich-text-block w-richtext">
            <ol>
              <li>
                1. Introduction <br />
                <br />
                <br />
                1.1 These terms and conditions (the Terms) together with the Tracr order form which incorporates these
                Terms (the Order Form) signed by the entity specified on the Order Form (Participant, you, your) form
                the agreement (the Agreement) between the Participant and Tracr (as defined below). In the event that
                there is any conflict between the provisions of the Order Form and the Terms, the provisions of the
                Order Form shall take precedence. <br />
                <br />
                1.2 These Terms apply to the Participants’ use of the Tracr website and platform, accessible at
                www.tracr.com (the Tracr Platform). Unless any other specific terms and conditions are made available by
                us, these Terms also apply to any/all services, products and tools made available by Tracr either
                through the Tracr Platform or through a link or portal provided by us (such services, products and
                tools, together with the Tracr Platform, are referred to in these Terms as the Services). The Services
                to which these Terms apply also include any and all of the associated services, functionality,
                integrations and documentation made available by Tracr. <br />
                <br />
                1.3 The Services are provided by Tracr Limited, a company incorporated under the laws of England and
                Wales under company number 07816513 with principal place of business at 1 Giltspur Street London EC1A
                9DD (Tracr, we, us, our). Our VAT number is GB243565264. <br />
                <br />
                1.4 Please read these Terms carefully as they set out the basis on which you may access, and/or use the
                Services and all associated features, content, materials, data and information made available to you by
                us (Tracr Content). Please also see our Privacy Notice (
                <a href="https://www.tracr.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">
                  https://www.tracr.com/legal/privacy-policy
                </a>
                ) which sets out details of how we will process personal information. <br />
                <br />
                1.5 We may revise these Terms at any time by posting a revised version on the Tracr Platform or relevant
                log-in page for the Services, or by otherwise notifying you by email to the email address associated
                with your account. Any changes made to these Terms will be effective from the date stated at the end of
                these Terms, please therefore ensure you read these Terms each time you access the Tracr Platform and/or
                the applicable Services. It is your responsibility to check the Terms for any modifications. If you do
                not accept any new Terms we make available then you should not use the applicable Services. <br />
                <br />
                <br />
                1.6 By executing the Order Form, you are confirming to us that you have read these Terms and that you:
                (a) will only access and/or use the Services in your capacity as a representative of a business or
                company (where applicable being the specific legal entity you notified to us as part of any sign-up
                process required for the applicable Services) and not as a consumer; and (b) agree to be bound by these
                Terms. <br />
                <br />
                1.7 Where you notify us, as part of any sign-up process, that you are a representative of a particular
                business or company (for instance, by using your company email address), you warrant that you have the
                authority to bind the legal entity of such business and/or company to these Terms and that such business
                and/or company will comply with these Terms. <br />
                <br />
                1.8 To the extent that we have applicable pre-existing rights to use information already provided to an
                entity in our group by you, this will be used to satisfy KYC requirements. However, we reserve the right
                to request additional information to comply with applicable laws and regulations to the extent this is
                necessary. You shall notify us if any of the information previously provided is now out of date and/or
                has otherwise changed.
                <br />
                <br />
              </li>
              <li>
                2. Contacting Us If you would like to contact us for any reason in connection with the Services,
                including to make a complaint about the Services or anything contained in them, please contact us at
                enquiries@project-tracr.com.
                <br />
                <br />
              </li>
              <li>
                3. Age Limits The Services are intended to be accessed and/or used by persons who are at least 18 years
                old. If you are under 18 years old, please do not access and/or use the Services. By accessing and/or
                using the Services, you are confirming to us that you are at least 18 years old. Your ability to access
                and/or use the Services may be terminated without warning if we have any reason to believe that you are
                younger than 18 years old.
                <br />
                <br />
              </li>
              <li>
                4. Platform Operation <br />
                <br />
                4.1 DeBeers Global Sightholder Sales (trading as the Diamond Trading Company) (DTC) registers rough
                diamonds on the Tracr Platform at box level. This data includes box ID, carat weight and the number of
                diamonds as well as details of the customer. <br />
                <br />
                4.2 A Participant that is a manufacturer that: a) is BPP Registered (as defined in clause 5.4(a)); b)
                has opted in to the Pipeline Integrity from 1 January 2023; (c) is entitled to purchase rough diamonds
                from the Diamond Trading Company at its regularly scheduled sights; and (d) has entered into the DTC
                Licence (as defined in clause 5.4(d)), (Manufacturer) shall register the rough diamonds they have
                purchased on the Tracr Platform using Tracr’s data integration tool (Data Integration Tool) or an API
                that links to the Tracr systems (Tracr API) (as applicable). The Data Integration Tool will check that
                all mandatory data is provided in the correct formats and check the volume and number of diamonds loaded
                does not exceed the totals for the box number provided. Tracr will then run the box check reconciliation
                report and will communicate any incomplete boxes or inconsistencies. <br />
                <br />
                4.3 The Manufacturer shall also upload details of any polished diamond on the Tracr Platform. The
                diamond is then sent to a laboratory registered with the Responsible Jewellery Council for grading and
                inscription (Approved Laboratory). The Manufacturer shall then upload the grading certificate to confirm
                the 4C’s, natural and the inscription.
                <br />
                <br />
                4.4 Any retailer that enters into an Order Form (Retailer) must: a) hold RJC Membership (as defined in
                clause 5.5(a)); and (b) be pre-approved by Tracr (including that such Retailer must have successfully
                completed the relevant anti money laundering and KYC checks). <br />
                <br />
                4.5 You will be able to utilise your dedicated instance of the Tracr Platform once you have accepted
                these Terms. <br />
                <br />
                4.6 Manufacturers may share certain “look-up” functionality with Retailers, who may then share certain
                “look-up” functionality with end customers who purchase diamonds from Retailers (Consumers) in
                accordance with and subject to the restrictions set out in clause 8. Manufacturers may also transfer
                diamonds (i.e. transfer ownership of a diamond via the transfer of that diamond’s digital diamond
                record) to Retailers using the Tracr Platform and Retailers may accept the same, using the Tracr
                Platform. <br />
                <br />
                4.7 The “Inventory Management Screen” on the Tracr Platform displays diamonds uploaded to the Tracr
                Platform. A Manufacturer can utilise the “Inventory Management Screen” to view their inventory and see
                what information has been loaded against the diamonds in their inventory and their origin. A Retailer
                can similarly utilise the “Inventory Management Screen” in its own instance of the Tracr Platform.
                <br />
                <br />
              </li>
              <li>
                5. Access to and use of the services
                <br />
                <br />
                5.1 We will provide the Services for the duration of the Subscription Term (as defined in the Order
                Form).
                <br />
                <br />
                5.2 Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-assignable,
                non-transferable, revocable license to use the Services in accordance with these Terms. You may use the
                Services only as expressly permitted in these Terms unless, if applicable, any other Services specific
                terms and conditions are made available to you by us. Violation of these Terms may result in the
                suspension or termination of your use of the Services. <br />
                <br />
                5.3 If you are using the Tracr API, you may only use it to develop, test, and support your application,
                and allow users to use your integration of the API within your application.
                <br />
                <br />
                5.4 If you are a Manufacturer, you warrant and represent that you: (a) are in compliance with the De
                Beers “Best Practice Principles” and are registered under the associated assurance programme as
                published by De Beers from time to time (BPP Registered); (b) have opted into the Pipeline Integrity
                from 1 January 2023; (c) are entitled to purchase rough diamonds from the DTC at your regularly
                scheduled sights; and (d) have entered into the DTC licence which is available on request or accessible
                at<span> </span>
                <a href="https://sightholdersignature.debeersgroup.com/" target="_blank" rel="noopener noreferrer">
                  https://sightholdersignature.debeersgroup.com/
                </a>
                (DTC Licence). <br />
                <br />
                5.5 If you are a Retailer, you warrant and represent that you: (a) hold and maintain membership with the
                Responsible Jewellery Council (RJC Membership); and (b) have been pre-approved by Tracr (including that
                you have successfully completed the relevant anti money laundering and KYC checks). <br />
                <br />
                5.6 When using the Services you agree that you will: (a) only use the Services in accordance with any
                parameters and specifications that we have made available to you from time to time; (b) unless
                specifically requested by us (or except in relation to any personal information provided as part of
                registration on the platform and/or as part of our compliance and “KYC” checks), not use the Services to
                share with us any information (including as part of any content, information, data and/or other
                materials you may have uploaded to any of the Services) which directly or indirectly may identify
                yourself or any other individual (including without limitation any “personal data” and/or any
                “personally identifiable information” or “PII”); (c) not reverse engineer, decompile, disassemble or
                otherwise attempt to obtain the Services’ source code, other than as permitted by applicable law; (d)
                subject to the paragraph headed “Intellectual Property and Publicity”, not copy, display or otherwise
                use the Tracr Content outside of the applicable Services; (e) not use software (including any spider,
                scraper or bot) to monitor, harvest or copy any data or content (including but not limited to Tracr
                Content/Participant Content) from the Services; (f) not use, or encourage, promote, facilitate or
                instruct others to use, the Services for any illegal, harmful, fraudulent, infringing or offensive use,
                or to transmit, store, display, distribute or otherwise make available content that is illegal, harmful,
                fraudulent, infringing or offensive; (g) not use the Services to bully, insult, intimidate or humiliate
                any person; (h) not use the Services to transmit, or procure the sending of, any unsolicited or
                unauthorised advertising or promotional material or any other form of similar solicitation (spam); (i)
                not use the Services to violate the security or integrity of any network, computer or communications
                system, software application, or network or computing device; (j) not make network connections to any
                users, hosts, or networks unless you have permission to communicate with them; (k) not use an
                unreasonable amount of bandwidth, or adversely impact the stability of the Services or the behaviour of
                other applications using the Services; and/or (l) use the Services in a way that could create, in our
                sole discretion and judgment, an unreasonable risk to users from a security or privacy perspective.{' '}
                <br />
                <br />
                5.7 We reserve the right, but do not assume the obligation, to monitor and/or investigate any violation
                of these Terms or misuse of the Services and we may remove, disable access to, or modify any Tracr
                Content or Participant Content or resource that violates the Terms or any other agreement we have with
                you for use of the Services. We reserve the right to monitor the use of the Tracr Platform for
                compliance with these Terms.
                <br />
                <br />
                5.8 If you breach (or we reasonably suspect that you have breached) any provision of these Terms, your
                authorisation to access and/or use the Services may be suspended or terminated. You must promptly report
                any breach of these Terms to us. <br />
                <br />
                5.9 Subject to the paragraph headed “Intellectual Property and Publicity”, you acknowledge that all
                Tracr Content is confidential and proprietary to us and, as such, you will keep all such Tracr Content
                confidential and will not use or display any Tracr Content outside of the Services. <br />
                <br />
                5.10 You agree to comply with all reasonable instructions that we may give you regarding your use of the
                Services. You agree that we may delete any information, data and/or other materials you may have
                uploaded to any of the Services that does not comply with these Terms. <br />
                <br />
                5.11 You are responsible for obtaining and configuring (at your own cost) all necessary devices,
                computer programs, IT, platforms and telecommunications services required to access and use the
                Services. <br />
                <br />
                5.12 You are responsible for ensuring that no person uses your account and/or device to access the
                Services without your permission. We will be entitled to assume that anyone who accesses the Services
                using your account and/or device has your permission to do so and you will be responsible for any
                charges, costs, losses or liabilities that may be incurred by any such persons. You understand that all
                end users which are authorised by you to access and use your account will be able to view the entire
                inventory in the account and that there is currently no functionality to grant limited access to certain
                sub-sets of the account inventory. <br />
                <br />
                5.13 Please note that your use of the Tracr Platform does not replace the need for you to retain all
                necessary copies of invoices and documents evidencing ownership of any diamonds that are registered on
                the Tracr Platform as may be required to satisfy your obligations under any other agreements as well as
                any other audit, import/export and other compliance requirements that might be applicable to you from
                time to time. <br />
                <br />
                5.14 Platform usage analytics can be provided on request directly from the Tracr Support Team.
                <br />
                <br />
              </li>
              <li>
                6. The DTC Licence and DTC Origin If you are a Manufacturer, your continued access and use of the Tracr
                Platform and Services is conditional on your compliance with the terms and conditions of the DTC Licence
                (as defined in clause 5.4(d)).
                <br />
                <br />
              </li>
              <li>
                7. Restrictions on Sale Manufacturers cannot sell Tracr registered diamonds using the Tracr claim to
                refer to the diamonds in aggregate. Manufacturers can only make a Tracr claim in respect of selling
                stones registered on the Tracr Platform if the Manufacturer provides the individual Tracr references for
                each individual stone being sold.
                <br />
                <br />
              </li>
              <li>
                8. Look Up Functionality – Diamond Look Up <br />
                <br />
                8.1 As a Manufacturer you will have access to two types of “Look-Up” functionality on the Tracr
                Platform: (a) B2C “look-up” functionality consisting of 'Diamond Search' and 'Diamond Experience' (B2C
                version) (the B2C Diamond Look Up); and (b) B2B “look-up” functionality consisting of ‘Diamond Share’,
                ‘Diamond Search’ and ‘Diamond Experience’ (B2B version) (the B2B Diamond Look Up). <br />
                <br />
                8.2 If you are a Manufacturer sharing the B2C Diamond Look Up or B2B Diamond Look Up with a Retailer who
                has not signed up directly to the Tracr Platform, you shall procure that the relevant Retailer
                acknowledges and agrees to these Terms in relation to its use of B2C Diamond Look Up and/or B2B Diamond
                Look Up, in particular this clause 8. <br />
                <br />
                8.3 B2B Diamond Look Up. The B2B Diamond Look Up can be shared by Manufacturers with Retailers only, to
                show rough diamonds and the potential polished outcome for business to business use only. For the
                avoidance of doubt, B2B Diamond Look Up must never be shared with a Consumer. Manufacturers may access a
                URL which can be shared with and used by a Retailer for its internal business use only. If you are a
                Retailer, you acknowledge and agree that: (a) the B2B Diamond Look Up may be used to show rough diamonds
                and the potential polished outcome for business to business use only and (b) the B2B Diamond Look Up may
                be used for your internal business use only and must never be shared with a Consumer. <br />
                <br />
                8.4 B2C Diamond Look Up. B2C Diamond Look Up can be shared by Manufacturers with Retailers for
                Retailer’s use to show rough diamonds and the potential polished outcome to a Consumer only.
                Manufacturer can access a URL for the B2C Diamond Look Up which can be shared with a Retailer which can
                be shared solely for the purpose of Retailer’s use with a Consumer. If you are a Retailer, you
                acknowledge and agree that: (a) the B2C Diamond Look Up may be used to show rough diamonds and the
                potential polished outcome to a Consumer only; and (b) the B2C Diamond Look Up may be shared solely for
                the purpose of your use with a Consumer.
                <br />
                <br />
                8.5 To be eligible for B2C Diamond Look Up and B2B Diamond Look Up: (1) the diamond must be graded and
                marked with an inscription by an Approved Laboratory; and (2) the grading certificate must be uploaded
                together with the diamond’s inscription number on the Tracr Platform (Certified Diamond). <br />
                <br />
                8.6 You acknowledge and agree (and if you are a Manufacturer, shall procure that any Retailer using B2C
                Diamond Look Up and/or B2B Diamond Look Up that has not signed up directly to the Tracr Platform,
                acknowledges and agrees) that: (a) all use of B2C Diamond Look Up and/or B2B Diamond Look Up must
                reference Tracr in accordance with clause 14 of these Terms and Manufacturer and/or Retailer may add a
                hyperlink on Manufacturer’s and/or Retailer’s business facing sales channel, provided that such
                reference contains reference to Tracr and the Tracr logo, subject to the restrictions set out in these
                Terms (including but not limited to clause 14 of these Terms); (b) you must not use Tracr Content/B2C
                Diamond Look Up and/or B2B Diamond Look Up to misrepresent diamonds at sale; (c) where B2C Diamond Look
                Up and/or B2B Diamond Look Up is made available to by a Manufacturer to a Retailer as described above,
                such access will only be available for a limited time as Tracr’s discretion (subject to Tracr exercising
                its rights under clause 8.6(d) below), following which the Retailer will be required to sign up to the
                Tracr Platform, accept these Terms and enter into an Order Form with Tracr directly, for continued
                access; (d) Tracr reserves the right to change, edit, (permanently or temporarily) suspend access to, or
                delete B2C Diamond Look Up and/or B2B Diamond Look Up, the data and content contained therein or any
                part of it at any time; (e) B2C Diamond Look Up and B2B Diamond Look Up are provided on an “as-is” and
                “as-available” basis and no warranties are given in relation to accuracy, errors and availability; (f)
                the data and other content contained within the B2C Diamond Look Up and/or B2B Diamond Look Up are
                provided for general information only and do not constitute an offer for purchase of any kind; (g) the
                B2C Diamond Look Up and/or B2B Diamond Look Up and the data and content contained therein are not
                intended to amount to advice which should be relied upon and professional or specialist advice must be
                obtained before taking, or refraining from, any action on the basis of B2C Diamond Look Up and/or B2B
                Diamond Look Up or any part of it; (h) the B2C Diamond Look Up and/or B2B Diamond Look Up contains
                Participant Content and Tracr has no control over the Participant Content and the inclusion of
                Participant Content on B2C Diamond Look Up and/or B2B Diamond Look Up should not be interpreted as
                approval or endorsement by Tracr of the Participant Content or information you may obtain from it; (i)
                Tracr Content in B2C Diamond Look Up and/or B2B Diamond Look Up belongs to Tracr and/or its licensors
                (including other users and/or Participants on the Tracr Platform) and is protected by intellectual
                property laws (including copyright and trade mark rights) around the world. Tracr™ and its associated
                logos are trade marks of the De Beers Group of companies. You are not permitted to use them without our
                approval, unless otherwise permitted under these Terms and you are not permitted to modify or create
                derivative works from B2C Diamond Look Up and/or B2B Diamond Look Up or any part of it without our prior
                approval; and (j) Retailer may use B2C Diamond Look Up/B2B Diamond Look Up in promotion of its own
                diamonds, but Retailer may not sell on to other blockchains or retailers without Tracr’s prior approval.
                <br />
                <br />
              </li>
              <li>
                9. Accounts <br />
                <br />
                9.1 In order to access and/or use certain of the Services, you may need to register for an account (for
                example on a sign-up page). To register for such an account, you will generally need to complete a
                registration form which will require you to submit an original username and password. For certain of the
                Services, you will first send us user credentials which we will use to provision user access to a
                particular instance. We reserve the right to refuse to accept any user for any reason. Only one account
                per user is permitted for use of the Tracr Platform. <br />
                <br />
                9.2 Any account you or we create in relation to your use of any of the Services is personal to you and
                you are not entitled to transfer your account to any other person. You agree to take all steps necessary
                to protect any of your account log in details and keep them secret. You shall not give such log in
                details to anyone else or allow anyone else to use your login details or account (whether within or
                outside of your business or company). <br />
                <br />
                9.3 We will be entitled to assume that anyone logging into any account of yours using your log in
                details is you. If you fail to keep any login details secret, or if you share any login details or
                account with someone else (whether intentionally or unintentionally), you accept full responsibility for
                the consequences of this and agree to fully compensate us for any losses or harm that may result. We
                will not be responsible to you for any loss that you suffer as a result of an unauthorised person
                accessing any account and/or using the Services and we accept no responsibility for any losses or harm
                resulting from its unauthorised use, whether fraudulently or otherwise. <br />
                <br />
                9.4 For the Services where we manage the user set-up, you are responsible for informing us of any users
                who have left your organisation in order to arrange for the relevant accounts to be disabled.
                <br />
                <br />
              </li>
              <li>
                10. Payment <br />
                <br />
                10.1 Charges (as specified on the Order Form) will be charged in accordance with the Order Form. <br />
                <br />
                10.2 You shall pay each invoice within the time frame specified in the Order Form after the date of such
                invoice, by bank / wire transfer. <br />
                <br />
                10.3 If Tracr has not received payment within the time frame specified in the Order Form, and without
                prejudice to any other rights and remedies of Tracr: (a) Tracr may, without liability to you, disable
                your password, account and access to all or part of the Services and Tracr shall be under no obligation
                to provide any or all of the Services while the invoice(s) concerned remain unpaid and you shall remain
                responsible for all fees and Charges incurred during the period of suspension; and (b) Tracr reserves
                the right to charge interest accruing on a daily basis on such due amounts at a monthly rate of 1.5% (or
                the highest rate permitted by law, if less) above the base rate of the Bank of England from time to
                time, commencing on the due date and continuing until fully paid, whether before or after judgment.
                <br />
                <br />
                10.4 All amounts and Charges stated or referred to in these Terms are exclusive of value added tax
                (VAT), which shall be added to the invoice(s) at the appropriate rate where applicable. Where VAT (or a
                VAT equivalent) is due, either by way of the relevant VAT law or as directed by HMRC or a local tax
                authority, Tracr will invoice this to the customer and the customer will pay the VAT to Tracr. <br />
                <br />
                10.5 All sales and use taxes (Sales Taxes), if any, due under the laws of any U.S. state or local
                government authority imposed against or on the Tracr Platform Charges shall be paid by you. You
                indemnify and hold Tracr harmless from and against, any such Sales Taxes. <br />
                <br />
                10.6 In the event Tracr is required by law to pay a Sales Levy (as defined below) with regards to the
                Charges under these Terms, the Charges payable by the Participant shall be increased to an amount which
                ensures that the net amount received by Tracr (after the relevant Sales Levy is paid) is equal to the
                full amount Tracr would have received under these Terms in the absence of such a Sales Levy. <br />
                <br />
                10.7 Sales Levy means equalisation levy or any other financial levy imposed by a government and/or a
                regulatory authority on digital transactions undertaken in connection with the relevant jurisdiction,
                that will be added to the Charges, payable by the Participant (as applicable). For the avoidance of
                doubt, the Sales Levy does not refer to any income or withholding taxes that may be imposed on digital
                transactions by a government or regulatory authority.
                <br />
                <br />
                10.8 Any amounts paid/payable to Tracr shall be without any setoff, counterclaim, deduction or
                withholding. Where withholding taxes are applicable to a client due to their tax jurisdiction, then
                payments to Tracr shall be grossed up accordingly to ensure that monthly invoices are settled in full
                reflecting the amount invoiced. <br />
                <br />
                10.9 It will be the responsibility of the Participant to undertake all tax and regulatory compliances
                including but not limited to intimating/registering the Terms with the tax authorities in their country
                as applicable or required under the laws of that country and the Participant agrees to indemnify Tracr
                against any action taken or tax, interest or penalty demanded by the aforesaid tax authorities on
                account of any non-compliance. <br />
                <br />
                10.10 Tracr shall provide at least 30 days’ notice before revising any fees and Charges in relation to
                the Services. Tracr reserves the right to increase any fees and Charges by the percentage increase in
                the Retail Prices Index published by the Office for National Statistics (or any successor) (RPI) across
                the previous 12 month period.
                <br />
                <br />
              </li>
              <li>
                11. Uploading content and data to the services <br />
                <br />
                11.1 If you are a Manufacturer, you must register all stones for a given box within ten (10) days of
                receipt of the relevant box. Whenever the diamond changes form, you must upload and/or update the
                relevant data. For example, through the splitting and polishing process you must upload more information
                through the Data Integration Tool, data of which will be appended to the diamond. Where a stone is split
                into multiple pieces the resulting “child” stones are provided with new individual IDs which are linked
                to the parent, thus preserving the chain of traceability. <br />
                <br />
                11.2 Tracr reserves the right to conduct (and/or engage a third party service provider to conduct)
                independent verification checks of management systems and production processes for any reason, from time
                to time (including but not limited to investigate and/or support any provenance claim made in connection
                with stones registered on the Tracr Platform, with the DTC provenance, including but not limited to
                Pipeline Integrity standards, and participation in the annual Pipeline Integrity programme, for all
                relevant Pipeline Integrity Entities which is a requirement of all Participants registering diamonds
                with DTC provenance on Tracr as of 1 January 2023). <br />
                <br />
                11.3 You retain all of your ownership rights in any content or data (including but not limited to any
                content, information or data, images or text) that you upload via and to the Services and/or Tracr
                Platform (Participant Content), but you grant us and our service providers a worldwide, non-exclusive,
                royalty-free, transferable licence to use, reproduce, store, distribute, prepare derivative works of,
                display and copy Participant Content in connection with the Services and so that we can provide the
                Services and further develop the Services including the conduct of internal testing. You warrant that
                the Participant Content which concerns a physical diamond asset which you upload to the Services relates
                to a physical diamond asset which you legally own and which is a natural untreated diamond. Unless
                expressly agreed otherwise by Tracr in writing, the owner of the physical diamond asset and related
                Participant Content shall be the party to whom the Tracr Platform is provisioned. <br />
                <br />
                11.4 When you submit Participant Content to the Tracr platform it is registered as a digital asset on
                the Tracr ledger. Following submission of the Participant Content to the Tracr Platform, the subsequent
                transfer of the digital asset is managed by Tracr. Tracr uses its reasonable endeavours to assure
                accuracy, validity, and consistency of the original data at the point of their submission to the Tracr
                Platform by the Participants (including by only accepting certain files from certain systems). However,
                you acknowledge and agree that Tracr is unable to fully verify the accuracy and/or validity of the
                original Participant Content at the point of their submission to the Tracr Platform. You represent and
                warrant that any/all Participant Content will be lawful, accurate, valid and complete at the point of
                their submission by the Tracr platform in order to create the digital asset. No representation or
                warranty (express or implied) is therefore given by Tracr as to the accuracy, validity or completeness
                of the data at the point of their submission by the Participant to the Tracr Platform in order to create
                the digital asset. <br />
                <br />
                11.5 You are responsible for taking appropriate action to secure, protect and backup your accounts in
                connection with the Services and/or your Participant Content in a manner that will provide appropriate
                security and protection.
                <br />
                <br />
              </li>
              <li>
                12. Confidentiality <br />
                <br />
                12.1 To the extent access to any of Tracr (or its group companies) confidential information is given via
                the Services, you will hold such confidential information in confidence and not make such confidential
                information available to any third party, or use such confidential information for any purpose other
                than for the purposes of using the Services in accordance with these Terms. <br />
                <br />
                12.2 You will take all reasonable steps to ensure that such confidential information to which you have
                access via the Services is not disclosed or distributed by you, or your employees or agents in violation
                of these Terms. <br />
                <br />
                12.3 You may disclose such confidential information to the extent such confidential information is
                required to be disclosed by law, by any governmental or other regulatory authority or by a court or
                other authority of competent jurisdiction, provided that, to the extent you are legally permitted to do
                so, you give us as much notice of such disclosure as possible and, where notice of disclosure is not
                prohibited and is given in accordance with this paragraph, you take into account our reasonable requests
                in relation to the content of such disclosure.
                <br />
                <br />
              </li>
              <li>
                13. Maintenance and Support If you have an issue or need support with your use of the Services and the
                Tracr Platform please contact us at enquiries@project-tracr.com.
                <br />
                <br />
              </li>
              <li>
                14. Intellectual Property and Publicity
                <br />
                <br />
                14.1 Tracr™ and its associated logos are trade marks of the De Beers Group of Companies. <br />
                <br />
                14.2 The Services and any and all Content belong to us and/or our licensors and are protected by
                intellectual property laws (including copyright and trade mark rights) around the world. You are only
                permitted to access and use the Services and any and all Content in accordance with these Terms. <br />
                <br />
                14.3 You are not allowed to distribute or transfer or communicate to the public the Services or any
                Tracr Content or interfere with the normal operation of the Services (e.g. you are not permitted to
                modify or create derivative works from the Services or any updates or any part thereof (including Tracr
                Content)), other than as permitted by these Terms, applicable law or as specifically allowed by the
                functionality of the Services. Notwithstanding these restrictions, any open source software components
                included in the Services will continue to be governed by the terms on which such open source software
                components are generally made available to the public by the relevant open source licensing entity.
                <br />
                <br />
                14.4 You may access and display Tracr including Tracr Platform demonstrations, the logo, brand name,
                trade mark or creative assets solely for the purpose of demonstrations, promotional materials,
                advertising, marketing or similar collateral or content, whether online or offline, with prior approval,
                by emailing your request to enquiries@project-tracr.com, in each case and as specifically allowed by the
                functionality of the Services provided that you comply with the brand guidelines provided to you from
                time to time. The right to use the Tracr logo, brand name, trade marks and any other intellectual
                property is for the limited purposes permitted by these Terms. In the event that Tracr determines in its
                sole discretion that such demonstrations, promotional materials, advertising, marketing or similar
                collateral or content, whether online or offline do not comply with the brand guidelines and/or these
                Terms, Tracr reserves the right to require you to takedown and/or stop using the same. <br />
                <br />
                14.5 You will comply strictly with our directions regarding any permitted use of the logo, brand name,
                trade mark, creative assets or any other intellectual property of Tracr (or of any member of the group
                of companies to which Tracr belongs) (Tracr IP), including as may be specified in any brand manual or
                brand guidelines notified to you. You will ensure that any/all materials you provide which incorporate
                any Tracr IP will include any notices and/or statements regarding the Tracr IP as notified to you by us
                from time to time. <br />
                <br />
                14.6 Any goodwill derived from the use of any Tracr IP shall accrue to us. You will not do, or omit to
                do, or permit to be done, any act that will or may weaken, damage or be detrimental to any Tracr IP or
                the reputation or good will associated with the Tracr IP or that may invalidate or jeopardise any
                registration of any Tracr IP. <br />
                <br />
                14.7 If you elect to provide any suggestion, enhancement, recommendation, correction or other feedback
                to us regarding the Services (Feedback), you grant to us a worldwide, perpetual, irrevocable,
                royalty-free licence to use and incorporate such Feedback into the Services.
                <br />
                <br />
              </li>
              <li>
                15. You will not introduce viruses or other harmful code
                <br />
                <br />
                15.1 You must not misuse the Services by knowingly introducing viruses, trojans, worms, logic bombs,
                keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to
                adversely affect the operation of any computer software or hardware. <br />
                <br />
                15.2 You must not attempt to gain unauthorised access to the Services, the server on which the Services
                are made available or any server, computer or database connected to the Services. You must not attack
                the Services via a denial-of-service attack or a distributed denial-of service attack. By breaching this
                provision, you would commit a criminal offence under the Computer Misuse Act 1990. <br />
                <br />
                15.3 We will report any such breach to the relevant law enforcement authorities and we will co-operate
                with those authorities by disclosing your identity to them. In the event of such a breach, your right to
                use the Services will cease immediately.
                <br />
                <br />
              </li>
              <li>
                16. Liability <br />
                <br />
                16.1 The Tracr Content is provided for general information only. It is not intended to amount to advice
                on which you should rely. You must obtain professional or specialist advice before taking, or refraining
                from, any action on the basis of the Tracr Content. <br />
                <br />
                16.2 Although we make reasonable efforts to update the Tracr Content we make no representations,
                warranties or guarantees, whether express or implied, that the Tracr Content is accurate, complete or up
                to date. <br />
                <br />
                16.3 The Services and associated output are provided on an "as is" basis and we do not make, and
                expressly exclude, any representation or warranty of any kind, either express or implied in relation to
                the Services, including, without limitation, any warranties in relation to fitness for a particular
                purpose or in relation to quality, completeness, accuracy or reliability. We do not guarantee that the
                Services will always be available, be uninterrupted, secure or free from bugs or viruses, nor that the
                Services or any Content will be free from errors or omissions. <br />
                <br />
                16.4 We will not be liable to you for any loss or damage, whether in contract, tort (including
                negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection
                with: (i) use of, or inability to use, any of the Services (including but not limited to B2C Diamond
                Look Up and/or B2B Diamond Look Up) or any Tracr Content/Participant Content; or (ii) use of or reliance
                on any Content. <br />
                <br />
                16.5 In particular, we will not be liable for: (i) loss of profits, sales, business, or revenue; (ii)
                business interruption; (iii) loss of anticipated savings; (iv) loss of business opportunity, good will
                or reputation (v) loss of data (including without limitation the loss of any Participant Content); or
                (vi) any indirect or consequential loss or damage. <br />
                <br />
                16.6 Nothing in these Terms excludes or limits our liability for death or personal injury arising from
                our negligence, for fraud or fraudulent misrepresentation, or any other liability that cannot be
                excluded, disclaimed or limited by applicable law. <br />
                <br />
                16.7 Subject to the above, Tracr’s total aggregate liability in each 12 month period (the first of which
                commencing on the Commencement Date as defined in the Order Form) whether in contract, tort (including
                negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in
                connection with the performance or contemplated performance of these Terms shall be limited to the total
                annual Charges (as defined in the Order Form) paid by you to Tracr for the Services.
                <br />
                <br />
              </li>
              <li>
                17. Indemnity You will defend, indemnify, and hold harmless us, our affiliates and licensors, and each
                of their respective employees, officers, directors, and representatives from and against any losses
                arising out of or relating to any third-party claim concerning your use of the Services (including but
                not limited to use of B2C Diamond Look Up and/or B2B Diamond Look Up or any activities under any account
                you have and/or use by your employees and personnel).
                <br />
                <br />
              </li>
              <li>
                18. Links to and from the services <br />
                <br />
                18.1 Where the Services contain links to other sites, products, tools, services and resources provided
                by third parties (Third Party Services), these links are provided for your information only. Such links
                should not be interpreted as approval by us of those Third Party Services or information you may obtain
                from them. We have no control over the contents of the Third Party Services. <br />
                <br />
                18.2 You may link to the Website, provided you do so in a way that is fair and legal and does not damage
                or take advantage of our reputation. The website, platform or service from which you are linking must
                comply in all respects with applicable laws. You must not establish a link in such a manner that
                suggests endorsement, association or approval on our part where none exists. We reserve the right to
                withdraw linking permission without notice.
                <br />
                <br />
              </li>
              <li>
                19. Termination <br />
                <br />
                19.1 We reserve the right to change, edit, suspend, delete and/or cancel any part of the Services
                (and/or your access to it) at any time with or without notice to you. <br />
                <br />
                19.2 On termination of these Terms for any reason: (a) all rights granted to you under these Terms will
                immediately cease; (b) all outstanding balances and Charges shall fall due for payment; (c) you must
                immediately cease all activities authorised by these Terms; and (d) you acknowledge that we may restrict
                your access to the Services or any part of them. <br />
                <br />
                19.3 When your use of the Services cease, any provision of these Terms that expressly or by implication
                is intended to come into or continue in force on or after termination shall remain in full force and
                effect.
                <br />
                <br />
              </li>
              <li>
                20. General <br />
                <br />
                20.1 You acknowledge that we shall have no liability to you under these Terms if we are prevented from,
                hindered or delayed in performing our obligations under these Terms, or from carrying on our business,
                by acts, events, omissions or accidents beyond our reasonable control, including, without limitation,
                strikes, lock-outs or other industrial disputes (whether involving our workforce or any other party),
                failure of a utility service or transport or telecommunications network, act of God, epidemic or
                pandemic, war, riot, civil commotion, malicious damage, compliance with any law or governmental order,
                rule, regulation or direction, accident, breakdown of plant or machinery, fire, flood, storm or default
                of suppliers or sub-contractors. <br />
                <br />
                20.2 You acknowledge that these Terms are not intended to be for the benefit of, and will not be
                exercisable by, any person who is not a party to them. <br />
                <br />
                20.3 You may not assign, sub-license or otherwise transfer any of your rights or obligations under these
                Terms to any other person without our prior written consent. <br />
                <br />
                20.4 If we decide not to exercise or enforce any right that we have against you at a particular time,
                then this does not prevent us from later deciding to exercise or enforce that right. <br />
                <br />
                20.5 The Terms represents the entire agreement between you and us relating to its subject matter and
                shall supersede and replace all prior oral and written understandings regarding such subject matter.{' '}
                <br />
                <br />
                20.6 If any part of these Terms is found to be illegal, invalid or otherwise unenforceable by a court or
                regulator, then, where required, that part shall be deleted from these Terms and the remaining parts of
                these Terms will continue to be enforceable. <br />
                <br />
                20.7 These Terms and any dispute arising out of or in connection with them (including any
                non-contractual claims) shall be governed by and construed in accordance with English law and you agree
                to submit to the exclusive jurisdiction of the English courts.
                <br />
                <br />
              </li>
            </ol>
            <h5>
              <strong>Terms last updated on 6 March 2023.</strong>
            </h5>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Conditions
