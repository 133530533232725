import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import classes from './styles.module.css'

export const Scroller = ({ withPadding, children }) => {
  const [contentOffset, setContentOffset] = useState(0)
  const containerRef = useRef(null)

  const calcContentOffset = () => {
    const container = containerRef.current

    const offset = container.offsetTop + (withPadding ? 24 : 0)

    return offset
  }

  useEffect(() => {
    if (!containerRef.current) return

    setContentOffset(calcContentOffset)
  }, [containerRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!children) {
    return null
  }

  return (
    <div
      id="scroller"
      className={classes.scrollerContainer}
      style={{ height: `calc(100vh - ${contentOffset}px)` }}
      ref={containerRef}
    >
      {children}
    </div>
  )
}

Scroller.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Scroller
