import React, { createContext } from 'react'
import { BrowserRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

// import "rsuite/dist/styles/rsuite-default.css";
import './global-styles.css'

// import { LifecycleEpic } from "store/epics";
import NotificationsContainer from 'containers/Notifications'

import Router from './Router'

export const ConfigContext = createContext()

/**
 * Top level application component.
 * @reactProps {object} config Application config
 */
export const App = function ({ config = {} }) {
  // const APP_VERSION = env('VERSION') || 'NOT_SET'

  // INITIAL way version loggin, can be improved
  /*
  console.log(
    `%cApplication version: %c ${APP_VERSION}`,
    'background: #202020; color: #fff; font-size: 14px',
    'color: #202020; font-size: 14px'
  )
  */

  return (
    <ConfigContext.Provider value={config}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>

      <NotificationsContainer />
    </ConfigContext.Provider>
  )
}

App.propTypes = {
  config: PropTypes.object,
}

export default App
