import API from 'api'

import { sessionExpiredAction } from './'
import { LIFECYCLE_ACTION_TYPES } from '../actionTypes'

export function lifecycleStageChangeAction(stage) {
  return {
    type: LIFECYCLE_ACTION_TYPES.STAGE_CHANGE,
    payload: stage,
  }
}

export function getAvailableLifecylclesAction() {
  return (dispatch) => {
    dispatch({ type: LIFECYCLE_ACTION_TYPES.GET_AVAILABLE.REQUEST })

    return API.getAvailableLifecycles().then(
      (response) => {
        dispatch({ type: LIFECYCLE_ACTION_TYPES.GET_AVAILABLE.COMPLETE, payload: response.data.content })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: LIFECYCLE_ACTION_TYPES.GET_AVAILABLE.FAILURE, payload: error })
        }
      }
    )
  }
}
