import React from 'react'

import { MatchedLabel } from 'components/system'

/**
 * Column element to show matched or not state 
 * @param {value: boolean} param0 
 * @returns JSX.Element
 */
export function Matched({ value }) {
    if (!value) return (<div style={{
        padding: '16px 8px 14px 16px',
        textAlign: 'left'
    }}>-</div>)

    return (
        <MatchedLabel matched={value}/>
    )
}

