import React, { useEffect, useState, useRef } from 'react'
import classnames from 'classnames'

import { UploadCSVIcon } from 'components/Svg'

import classes from './styles.module.css'

export const DragAndDrop = ({ fileTypes = '.csv', multiple, withIcon = false, testId, onFiles }) => {
  const dropZone = useRef()
  const fileInput = useRef()
  const [isDraggedOver, setIsDraggedOver] = useState(false)

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDragEnter = () => {
    if (isDraggedOver) return

    setIsDraggedOver(true)
  }

  const handleDragLeave = (event) => {
    if (!isDraggedOver) return

    if (dropZone.current.contains(event.relatedTarget)) return

    setIsDraggedOver(false)
  }

  const handleDrop = (event) => {
    if (Array.from(event.dataTransfer.files).length === 0) return setIsDraggedOver(false)

    event.preventDefault()

    setIsDraggedOver(false)

    onFiles(event.dataTransfer.files)
  }

  const handleInputClick = () => {
    fileInput.current.click()
  }

  const handleInputChange = () => {
    const file = fileInput.current.files[0]

    if (!file) return

    if (multiple) {
      onFiles(fileInput.current.files)
    } else {
      onFiles(file)
    }

    // unset input value in case same file re-drop
    fileInput.current.value = null
  }

  useEffect(() => {}, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classes.dragAndDropContainer}
      {...(testId ? { 'data-test-id': `${testId}.dragAndDropContainer` } : null)}
    >
      <div className={classes.wrapper}>
        <div
          className={classnames(classes.dropArea, { [classes.draggedOver]: isDraggedOver })}
          ref={dropZone}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          {...(testId ? { 'data-test-id': `${testId}.dragAndDropZone` } : null)}
        >
          {withIcon ? (
            <div className={classes.icon}>
              <UploadCSVIcon />
            </div>
          ) : null}
          <div className={classes.label}>
            <div className={classes.description}>Drop your .CSV file here, or</div>
            <div
              className={classes.buttonSelect}
              onClick={handleInputClick}
              {...(testId ? { 'data-test-id': `${testId}.dragAndDropSelectButton` } : null)}
            >
              Select from device
            </div>
          </div>
        </div>
      </div>
      <input
        className={classes.dropFileInput}
        type="file"
        ref={fileInput}
        accept={fileTypes}
        onChange={handleInputChange}
        {...(testId ? { 'data-test-id': `${testId}.dragAndDropInput` } : null)}
      />
    </div>
  )
}

export default DragAndDrop
