import http from '../utils/http'

const namespace = 'diamond'

const getFullDiamond = (id) => {
  return http.get(`${namespace}/${id}/full`)
}

const getDiamondBlueNile = (id) => {
  return http.get(`${namespace}/bluenile/${id}`)
}

const getDiamonds = (body) => {
  return http.post(`${namespace}`, body)
}

const getFullDiamonds = (body) => {
  return http.post(`${namespace}/full`, body)
}

const getDiamondsCount = (body) => {
  return http.post(`${namespace}/count`, body)
}

const getTotalDiamondsCount = (body) => {
  return http.post(`${namespace}/total-count`, body)
}

const getAvailableLifecycles = () => {
  return http.post(`${namespace}/available-lifecycles`)
}

const transferDiamonds = (body) => {
  return http.post(`${namespace}/transfer`, body)
}

const findDiamond = (id) => {
  return http.get(`${namespace}/find/${id}`)
}

const getSignedUrl = (id, hash) => {
  return http.get(`${namespace}/asset/${id}/${hash}`, null)
}

const unlockDiamonds = (diamondIds) => {
  return http.post(`${namespace}/unlock`, { diamonds: diamondIds })
}

const downloadDiamonds = (body, filename) => {
  //Download the currently filtered list of diamonds as an excel spreadsheet
  //that's generated synchronously and sent to the client
  return http.downloadFile(`${namespace}/export`, filename, false, 'post', body)
}

const endpoints = {
  getFullDiamond,
  getDiamondBlueNile,
  getDiamonds,
  getFullDiamonds,
  getDiamondsCount,
  getTotalDiamondsCount,
  getAvailableLifecycles,
  transferDiamonds,
  findDiamond,
  getSignedUrl,
  unlockDiamonds,
  downloadDiamonds,
}

export default endpoints
