import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import API from 'api'
import { NavArrow } from 'components/Svg'
import { PageTitle, HelpButton } from 'components/system'
import { testIds, helpLinks } from 'config'

import classes from './styles.module.css'

const PageViewNav = ({ shareType, onBack }) => {
  const navigate = useNavigate()
  const handleBackClick = () => (shareType ? onBack() : navigate(-1))

  return (
    <div className={classes.viewNavContainer}>
      <div className={classes.titleAndButtonContainer}>
        <div
          className={classes.backButtonContainer}
          onClick={handleBackClick}
          data-test-id={testIds.diamondsNav.backButton}
        >
          <NavArrow />
          <div className={classes.backButton}>Back</div>
        </div>
        <PageTitle title={`DIAMOND EXPERIENCE`}>
          <HelpButton
            link={helpLinks.diamondExperience}
            onClick={() => API.tracking.logHelp('diamond-experience', helpLinks.diamondExperience)}
          />
        </PageTitle>
      </div>
    </div>
  )
}

PageViewNav.propTyes = {
  type: PropTypes.oneOf(['default', 'copy']),
  onBack: PropTypes.func.isRequired,
}

export default PageViewNav
