import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import ExportInventory from './ExportInventory'

import { getDiamondsCountAction, updateViewAction } from 'store/actions'
import { selectView, selectAppliedFilters, selectDiamondsCount } from 'store/selectors'
import { Counter } from 'components/system'
import { ListSVG, CardSVG } from 'components/Svg'
import { featureToggles } from 'config'
import urls from 'config/urls'
import testids from 'config/testIds'

import Columnpicker from './Columnpicker'
import ViewButton from './ViewButton'
import classes from './styles.module.css'
import testIds from 'config/testIds'

const CounterAndViewSelector = ({ testIdPrefix }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectedView = useSelector(selectView)
  const appliedFilters = useSelector(selectAppliedFilters)
  const { count, filteredCount } = useSelector(selectDiamondsCount)

  const views = [
    {
      title: 'List View',
      icon: ListSVG,
      feature: 'list',
      disabled: featureToggles.list === 1,
    },
    {
      title: 'Card View',
      icon: CardSVG,
      feature: 'cards',
      disabled: featureToggles.cards === 1,
    },
  ].filter((view) => featureToggles[view.feature] !== 0)

  const handleViewSelect = (view) => {
    if (view === selectedView) return

    dispatch(updateViewAction(view))

    navigate(`${urls.inventory.root}/${appliedFilters.lifecycle_state}/${view}`)
  }

  useEffect(() => {
    if (!appliedFilters.lifecycle_state) return

    dispatch(getDiamondsCountAction(appliedFilters))
  }, [JSON.stringify(appliedFilters)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.counterAndViewSelectorContainer}>
      <div className={classes.counter}>
        <Counter testId={testIds.inventory.totalDiamonds} total={count} filtered={filteredCount} />
      </div>
      {selectedView === 'list' && <ExportInventory testIdPrefix={testIdPrefix} />}
      {selectedView === 'list' ? (
        <div className={classes.columnPicker}>
          <Columnpicker testIdPrefix={testIdPrefix} />
        </div>
      ) : null}
      <div className={classnames(classes.viewsSelector, { margin: selectedView !== 'list' })}>
        {views.map((view) => (
          <ViewButton
            {...view}
            key={view.feature}
            selected={view.feature === selectedView}
            testIdPrefix={testIdPrefix}
            onClick={handleViewSelect}
          />
        ))}
      </div>
    </div>
  )
}

export default CounterAndViewSelector
