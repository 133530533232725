import { useDispatch } from 'react-redux'
import { logoutAction } from 'store/actions'

export const Logout = () => {
  const dispatch = useDispatch()

  dispatch(logoutAction())

  return null
}

export default Logout
