import React from 'react'
import classnames from 'classnames'

import { Slider } from 'components/system'

import classes from './styles.module.css'

export const SliderFilter = ({
  title,
  path,
  options = [],
  value = [],
  unit = 'mm',
  step = 0.01,
  mapper,
  testIdPrefix = '',
  onChange,
}) => {
  const handleSliderChange = (values) => {
    onChange(path, values)
  }

  const handleInputChange = (pos, newVal) => {
    const min = options[0].value
    const max = options[1].value
    const fromValue = Number.isFinite(value[0]) ? value[0] : min
    const toValue = Number.isFinite(value[1]) ? value[1] : max

    if (newVal > max || newVal < min) return

    if (pos === 0) {
      if (newVal > toValue) return

      onChange(path, [newVal, toValue])
    } else {
      if (newVal < fromValue) return

      onChange(path, [fromValue, newVal])
    }

    return false
  }

  const formatValue = (value) => {
    if (mapper) return mapper(value)

    return value
  }

  return (
    <div className={classes.sliderFilterContainer}>
      <div className={classes.wrapper} data-test-id={`${testIdPrefix}-${title}`}>
        <div className={classes.title}>{title}</div>
        <div className={classes.sliderWrapper}>
          <Slider
            min={options[0].value}
            max={options[1].value}
            step={step}
            value={value}
            onChange={handleSliderChange}
          />
        </div>
        <div className={classes.values}>
          <div className={classnames(classes.value, classes.from)}>
            <input
              type="number"
              value={formatValue(value.length ? value[0] : options[0].value)}
              className={classes.number}
              onChange={(evt) => handleInputChange(0, evt.target.value)}
            />
            <div className={classes.unit}>{unit}</div>
          </div>
          <div className={classes.separator}>to</div>
          <div className={classnames(classes.value, classes.to)}>
            <input
              type="number"
              value={formatValue(value.length ? value[1] : options[1].value)}
              className={classes.number}
              onChange={(evt) => handleInputChange(1, evt.target.value)}
            />
            <div className={classes.unit}>{unit}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SliderFilter
