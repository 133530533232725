import React from 'react'
import classnames from 'classnames'

import Button from '../../Button'
import classes from './styles.module.css'

export const ButtonsCell = ({ row, buttons = [], testId, onClick }) => {
  const handleClick = (key) => {
    if (row.disabled || row.actionDisabled) return

    if (onClick) onClick(row, key)
  }

  return (
    <div
      className={classnames(classes.buttonsCellContainer, {
        [classes.disabled]: row.rowDisabled || row.actionDisabled,
      })}
      data-actions={true}
    >
      <div className={classes.wrapper}>
        {buttons.map((button) => {
          return (
            <Button
              key={button.key}
              variant={button.type === 'primary' ? 'primary' : 'secondary'}
              size={'s'}
              disabled={row.rowDisabled}
              className={classes.button}
              onClick={() => handleClick(button.key)}
              {...(testId ? { 'data-test-id': `${testId}.${button.key}` } : null)}
              {...(button.testId ? { 'data-test-id': button.testId } : null)}
            >
              {button.label}
            </Button>
          )
        })}
      </div>
    </div>
  )
}

export default ButtonsCell
