export const testIds = {
  table: {
    selectAll: 'selectAll',
    checkbox: 'checkbox',

  },
  actionBar: {
    selectionCounterPostfix: 'selectionCounter',
  },
  share: {
    closeButton: 'share.closeButton',
    sendButton: 'share.sendButton',
    diamondListCard: 'share.diamondListCard',
    paginationNav: 'share.paginationNav',
    viewHideDiamondDetailsButton: 'share.viewHideDiamondDetailsButton',
  },
  gallery: {
    arrowLeftPostfix: 'arrowLeft',
    arrowRightPostfix: 'arrowRight',
    mainAssetPostfix: 'mainAsset',
  },
  settings: {},
  support: {
    navLink: 'support.navLink',
    platform: {
      text: 'support.platform.text',
      button: 'support.platform.button',
    },
  },

  topMenu: {
    inventoryInMontion: 'topmenu.inventoryInMontion',
    dataIntegration: 'topmenu.dataIntegration',
    user: 'topMenu.user',
    userLogout: 'topMenu.user.logout',
  },

  inventory: {
    nav: 'inventory.nav',
    totalDiamonds: 'inventory.totalDiamonds',
    actionBar: {
      prefix: 'inventory.actionBar',
      selectionCounter: 'inventory.actionBar.selectionCounter',
      clearSelectionButton: 'inventory.actionBar.clearSelectionButton',
      viewButton: 'inventory.actionBar.viewButton',
      shareButton: 'inventory.actionBar.shareButton',
      unlockButton: 'inventory.actionBar.unlockButton',
    },
    moreFilters: {
      closeButton: 'inventory.moreFilters.closeButton',
      clearAllButton: 'inventory.moreFilters.clearAllButton',
      viewDiamondsButton: 'inventory.moreFilters.viewDiamondsButton',
    },
    list: {
      prefix: 'inventory.list',
      columnPickerButton: 'inventory.list.columnPickerButton',
      actionsColumn: {
        viewButton: 'inventory.list.actionsColumn.viewButton',
        shareButton: 'inventory.list.actionsColumn.shareButton',
        unlockButton: 'inventory.list.actionsColumn.unlockButton',
      },
    },
    card: {
      prefix: 'inventory.card',
      status: 'inventory.card.status',
      viewButton: 'inventory.card.viewButton',
    },
  },

  searchBar: 'searchbar',
  footer: 'footer',
  popup: {
    title: 'popup.title',
    close: 'popup.close',
  },

  login: {
    checkbox: 'login.checkbox',
    privacy: 'login.privacy',
    terms: 'login.terms',
    continue: 'login.continue',
  },

  listView: {
    prefix: 'inventory.list',
    action: 'inventory.list.action',
    row: 'inventory.list.row',
    column: 'inventory.list.column',
    tooltip: 'inventory.list.tooltip',
    checkbox: 'inventory.list.checkbox',
    selectAll: 'inventory.list.selectAll',
    headers: 'inventory.list.headers',
    messages: {
      read: 'inventory.list.messages.read',
      markAsUnread: 'inventory.list.messages.markAsUnread',
      download: 'inventory.list.messages.download',
      viewall: 'inventory.list.messages.viewall',
    },
    files: {
      media: 'inventory.list.files.media',
      transfers: 'inventory.list.files.transfers',
      profiles: 'inventory.list.files.profiles',
    },
  },

  filters: {
    infix: 'filters',
    idSearch: 'filters.idSearch',
    dateFilter: 'filters.date',
    clear: 'filters.clear',
    apply: 'filters.apply',
    filterSelect: 'filters.filterSelect',
    moreFiltersButton: 'filters.moreFiltersButton',
    moreFilters: 'filters.moreFilters',
    calenderLeft: 'filters.date.leftMonth',
    calenderRight: 'filters.date.rightMonth',
  },
  columnPicker: {
    prefix: 'columnPicker',
    buttonPostfix: 'columnPickerButton',
  },
  viewSelector: {
    prefix: 'viewSelector',
  },

  LeftMenu: {
    logo: 'leftmenu.logo',
    diamondCount: 'leftmenu.diamondCount',
    navigation: {
      benchmark: 'leftmenu.navigation.benchmark',
      rough: 'leftmenu.navigation.rough',
      planning: 'leftmenu.navigation.planning',
      sawing: 'leftmenu.navigation.sawing',
      bruting: 'leftmenu.navigation.bruting',
      polishing: 'leftmenu.navigation.polishing',
      polished: 'leftmenu.navigation.polished',
      graded: 'leftmenu.navigation.graded',
      outbox: 'leftmenu.navigation.outbox',
      configure: 'leftmenu.navigation.configure',
    },
  },

  chartView: {
    provenance: 'inventory.provenance.map',
    chart: {
      configure: 'inventory.chart.configure',
      yaxis: 'inventory.chart.configure.yaxis',
      xaxis: 'inventory.chart.configure.xaxis',
      stack: 'inventory.chart.configure.stack',
      actions: 'inventory.chart.configure.actions',
    },
  },

  benchmark: {
    filter: 'benchmark.filter',
    slider: 'benchmark.filter.slider',
    container: 'benchmark.container',
    title: 'benchmark.title',
    tile: {
      container: 'benchmark.tile.container',
      totalDiamonds: 'benchmark.tile.totalDiamonds',
      optimal: 'benchmark.tile.optimal',
      suboptimal: 'benchmark.tile.suboptimal',
    },
    popup: {
      tab: 'benchmark.popup.tab',
      apply: 'benchmark.popup.apply',
      close: 'benchmark.popup.close',
      yesApply: 'benchmark.popup.yesApply',
      noApply: 'benchmark.popup.noApply',
      dropdown: 'benchmark.popup.dropdown',
    },
  },

  sharing: {
    stateFilter: 'sharing.stateFilter',
  },

  profile: {
    diamond: {
      back: 'profile.diamond.back',
      forward: 'profile.diamond.forward',
      toTop: 'profile.diamond.toTop',
      details: 'profile.diamond.details',
    },
  },

  preview: {
    share: {
      copy: 'preview.share.copy',
    },
    confirmation: {
      action: {
        outbox: 'preview.share.confirmation.actions.outbox',
        inventory: 'preview.share.confirmation.actions.inventory',
      },
    },
    shareTitle: {
      total: 'preview.shareTitle.total',
    },
    shareButton: 'preview.share.shareButton',
    shareButtonTotal: 'preview.share.shareButtonTotal',
    activeTemplate: 'preview.share.activeTemplate',
    templateActions: {
      changeTemplate: 'preview.share.templateActions.changeTemplate',
      viewInPreview: 'preview.share.templateActions.viewInPreview',
    },
    diamondList: {
      diamond: {
        container: 'preview.diamondList.diamond.container',
        media: {
          image: 'preview.diamondList.diamond.media.image',
        },
        provenance: 'preview.diamondList.diamond.provenance',
        description: 'preview.diamondList.diamond.description',
        remove: 'preview.diamondList.diamond.remove',
      },
    },
  },

  previewDrawer: {
    diamond: 'previewDrawer.diamond',
    actions: {
      prev: 'previewDrawer.prev',
      next: 'previewDrawer.next',
      close: 'previewDrawer.close',
    },
  },

  drawer: {
    root: 'drawer',
    close: 'drawer.close',
    content: 'drawer.content',
  },

  toast: {
    deselect: 'toast.deselect',
    transfer: 'toast.transfer',
    view: 'toast.view',
  },

  diamondsNav: {
    backButton: 'diamondsNav.backButton',
    leftArrow: 'diamondsNav.leftArrow',
    rightArrow: 'diamondsNav.rightArrow',
    selectionCount: 'diamondsNav.selectionCount',
    title: 'diamondsNav.title',
  },

  sharePopup: {
    title: 'sharePopup.title',
    close: 'sharePopup.close',
    intro: 'sharePopup.intro',
    listTitle: 'sharePopup.listTitle',
    list: 'sharePopup.list',
    listItem: 'sharePopup.listItem',
    cancel: 'sharePopup.cancel',
    continue: 'sharePopup.continue',
  },

  diamondExperience: {
    backToTop: 'diamondExperience.backToTopButton',
    viewHideDiamondDetailsButton: 'diamondExperience.viewHideDiamondDetailsButton',
    headerTitle: 'diamondExperience.diamondsNav.title',
    participantLogo: 'diamondExperience.participantLogo',
    title: 'diamondExperience.title',
    subtitle: 'diamondExperience.subtitle',
    prop: 'diamondExperience.prop',
    image: 'diamondExperience.image',
    provenanceStatement: 'diamondExperience.provenanceStatement',
    assuredByTracr: 'diamondExperience.assuredByTracr',
    tracrId: 'diamondExperience.tracrId',
    tracrIdSection: 'diamondExperience.tracrIdSection',
    tracrLogo: 'diamondExperience.tracrLogo',
    viewGradingReportButton: 'diamondExperience.viewGradingReportButton',
    backToTopButton: 'diamondExperience.backToTopButton',
    unlockButton: 'diamondExperience.unlockButton',
    heroImage: 'diamondExperience.heroImage',
    provenanceCopy: 'diamondExperience.provenanceCopy',
    inscriptionNumberSection: 'diamondExperience.inscriptionNumberSection',
    assuranceStatement: 'diamondExperience.assuranceStatement',
    actions: {
      management: 'diamondExperience.actions.management',
      share: 'diamondExperience.actions.share',
    },
    intermediate: {
      image: 'diamondExperience.intermediate.image',
    },
    provenance: {
      container: 'diamondExperience.provenance.container',
      title: 'diamondExperience.provenance.title',
      subTitle: 'diamondExperience.provenance.subTitle',
      label: 'diamondExperience.provenance.label',
      icon: 'diamondExperience.provenance.icon',
    },
    gallery: {
      canvas: 'diamondExperience.gallery.canvas',
      leftArrow: 'diamondExperience.gallery.leftArrow',
      rightArrow: 'diamondExperience.gallery.rightArrow',
      thumbnails: 'diamondExperience.gallery.thumbnails',
      thumbnail: 'diamondExperience.gallery.thumbnail',
    },
    details: {
      prefix: 'diamondExperience.details',
      label: 'diamondExperience.details.label',
      value: 'diamondExperience.details.value',
      title: 'diamondExperience.details.title',
    },
    measurements: {
      label: 'diamondExperience.measurements.label',
      value: 'diamondExperience.measurements.value',
      title: 'diamondExperience.measurements.title',
    },
    certificate: {
      date: 'diamondExperience.certificate.date',
      lab: 'diamondExperience.certificate.lab',
      certificateNumber: 'diamondExperience.certificate.certificateNumber',
      reportNumber: 'diamondExperience.certificate.reportNumber',
      inscriptionNumber: 'diamondExperience.certificate.inscriptionNumber',
      labLogo: 'diamondExperience.certificate.labLogo',
      tracrLogo: 'diamondExperience.certificate.tracrLogo',
      download: 'diamondExperience.certificate.download',
    },
    softlinks: {
      title: 'diamondExperience.softlinks.title',
      rough: 'diamondExperience.softlinks.rough',
      split: 'diamondExperience.softlinks.split',
      manufacturing: 'diamondExperience.softlinks.manufacturing',
      polished: 'diamondExperience.softlinks.polished',
    },
    carats: {
      rough: 'diamondExperience.carats.rough',
      manufacturing: 'diamondExperience.carats.manufacturing',
      polished: 'diamondExperience.carats.polished',
    },
    summary: {
      title: 'diamondExperience.summary.title',
      carats: 'diamondExperience.summary.carats',
      media: 'diamondExperience.summary.media',
      details: 'diamondExperience.summary.details',
      arrow: 'diamondExperience.summary.arrow',
    },
  },

  diamondEnhancement: {
    fourC: 'diamondEnhancement.4cs',
    provenance: 'diamondEnhancement.provenance',
    tracrId: 'diamondEnhancement.tracrId',
    roughToPolished: 'diamondEnhancement.roughToPolished',
    media: 'diamondEnhancement.media',
    caratWeight: 'diamondEnhancement.caratWeight',
    details: 'diamondEnhancement.details',
    measurements: 'diamondEnhancement.measurements',
    missingDataPoint: 'diamondEnhancement.missingDataPoint',
    assuredByTracr: 'diamondEnhancement.assuredByTracr',
  },

  shareTemplates: {
    pageTitle: 'shareTemplates.pageTitle',
    nav: {
      root: 'shareTemplates.nav',
      createNew: 'shareTemplates.createNew',
      template: 'shareTemplates.shareTemplatesRows',
    },
    form: {
      root: 'shareTemplates.form',
      templateNameInput: 'shareTemplates.templateNameInput',
      viewTemplate: 'shareTemplates.viewTemplate',
      section: 'shareTemplates.section',
      sectionToggle: 'shareTemplates.toggle',
      checkbox: 'shareTemplates.checkbox',
      selection: 'shareTemplates.selection',
      deleteTemplate: 'shareTemplates.deleteTemplate',
      saveTemplate: 'shareTemplates.saveTemplate',
    },
  },

  dit: {
    integrationToolkit: 'integrationTool.integrationToolkit',
    newSessionButton: 'integrationTool.newSession',
    quitSessionButton: 'integrationTool.quitSessionButton',
    sessionHistoryColumn: 'integrationTool.sessionHistory.columns',
    sessionHistoryRow: 'integrationTool.sessionHistoryRow',
    //report dropdown
    downloadSessionReport: 'integrationTool.downloadSessionReport',
    downloadFullReport: 'integrationTool.downloadFullReport',
    monitoringRedirect: 'integrationTool.monitoringRedirect',
    sessionHistory: {
      prefix: 'integartionTool.sessionHistory'
    },
    sessionHistoryFilters: {
      date: 'integrationTool.sessionHistory.dateFilter',
      leftCalender: 'integrationTool.sessionHistory.filters.date.leftMonth',
      rightCalender: 'integrationTool.sessionHistory.filters.date.rightMonth',
      'integrationTool.integration_type': 'dit.sessionHistory.integrationFilter',
      'integrationTool.status': 'dit.sessionHistory.statusFilter',
      clear: 'integrationTool.sessionHistory.clearFilters',
    },
    // upload CSV page
    uploadCSVQuitSession: 'integrationTool.uploadCSVQuiteSession',
    uploadCSVSubmitSession: 'integrationTool.uploadCSVSubmitSession',
    uploadCSVSelectFileButton: 'integrationTool.uploadCSVSelectFileButton',
    uploadCSVSelectFileText: 'integrationTool.uploadCSVSelectFileText',
    uploadCSVIntegration: 'integrationTool.uploadCSVIntegration',
    uploadCSVIntegrationCancel: 'integrationTool.uploadCSVIntegrationCancel',
    uploadCSVIntegrationContinue: 'integrationTool.uploadCSVIntegrationContinue',
    // session details page
    sessionOverviewTab: 'integrationTool.sessionOverviewTab',
    missingFilesTab: 'integrationTool.missingFilesTab',
    failedFilesTab: 'integrationTool.failedFilesTab',
    unexpectedFilesTab: 'integrationTool.unexpectedFilesTab',
    tabsCounterText: 'integrationTool.tabsCounterText',
    sessionSelectFilesButton: 'integrationTool.sessionSelectFilesButton',
    sessionSelectFilesText: 'integrationTool.sessionSelectFilesText',
    sessionDetailsColumn: 'integrationTool.sessionDetailsColumn',
    sessionDetailsRow: 'integrationTool.sessionDetailsRow',
    unexpectedFilesRow: 'integrationTool.unexpectedFilesRow',
    missingFilesRow: 'integrationTool.missingFilesRow',
    failedFilesRow: 'integrationTool.failedFilesRow',
    uploadingFilesButton: 'integrationTool.uploadingFilesButton',
    uploadingFilesHeader: 'integrationTool.uploadingFilesHeader',
    tooltips: 'integrationTool.tooltips',
    toggleDiamondsButton: 'integrationTool.toggleDiamondsButton',
    // integrationToolkit
    downloadToolkitButton: 'integrationTool.downloadToolkitButton',
    downloadToolkitRow: 'integrationTool.downloadToolkitRow',
    closeToolkitIcon: 'integrationTool.closeToolkitIcon',
    // submit session page
    submittedDiamonds: 'integrationTool.submittedDiamonds',
    hideUnhindeDiamond: 'integrationTool.eyeIcon',
    viewDiamondHistoryButton: 'integrationTool.viewDiamondHistoryButton',
    // error view page
    errorsSection: 'integrationTool.errorsSection',
    errorColumn: 'integrationTool.errorColumn',
    errorRow: 'integrationTool.errorRow',
    quitSessionModal: {
      selection: 'integrationTool.quitSessionModal.selection',
      cancel: 'integrationTool.quitSessionModal.cancel',
      quit: 'integrationTool.quitSessionModal.quit',
    },
    submitSessionModal: {
      cancel: 'integrationTool.submitSessionModal.cancel',
      confirm: 'integrationTool.submitSessionModal.confirm',
    },
    // export session files report
    exportSessionFilesButton: 'integrationTool.exportSessionFilesButton',
    newSessionModal: 'integrationTool.newSessionModal',
    newSessionModalRadio: 'integrationTool.newSessionModalRadio',
    newSessionModalFileUpload: 'integrationTool.newSessionModalFileUpload',
    newSessionModalUploadButton: 'integrationTool.newSessionModal.uploadButton',
    newSessionModalCloseButton: 'integrationTool.newSessionModal.closeButton',
  },

  monitoring: {
    prefix: 'monitoring',
    clearAllFiltersTableBtn: 'monitoring.clearAllFiltersTableBtn',
    dateRangePicker: 'monitoring.dateRangePicker',
    clearFiltersBtn: 'monitoring.clearFiltersBtn',
    integration_type: 'monitoring.integration_typeFilter',
    status: 'monitoring.statusFilter',
    counter: 'monitoring.counter',
    columnPrefix: 'monitoring.column',
    row: 'monitoring.row',
    exportBtn: 'monitoring.exportBtn',
    downloadXLSXBtn: 'monitoring.downloadXLSXBtn',
    leftCalender: 'monitoring.filters.date.leftMonth',
    rightCalender: 'monitoring.filters.date.rigthMonth',
  },

  transferOut: {
    quitModalCancel: 'transferOut.quitModalCancel',
    quitModalConfirm: 'transferOut.quitModalConfirm',
    transferModalCancel: 'transferOut.transferModalCancel',
    transferModalConfirm: 'transferOut.transferModalConfirm',
    selectFileButton: 'transferOut.selectFileButton',
  },

  export: {
    dropdownButton: 'export.dropdown',
    exportAllButton: 'export.all',
    exportFilteredButton: 'export.filtered',
  },

  shipmentsInbound: {
    activeButton: 'shipmentsInbound.activeButton',
    archivedButton: 'shipmentsInbound.archivedButton',
    shipmentsCounter: 'shipmentsInbound.shipmentsCounter',
    active: {
      prefix: 'shipmentsInbound.active',
      column: 'shipmentsInbound.active.column',
      counter: 'shipmentsInbound.active.shipmentsCounter',
      acceptButton: 'shipmentsInbound.active.acceptButton',
      rejectButton: 'shipmentsInbound.active.rejectButton',
      row: 'shipmentsInbound.active.row',
      uploadButton: 'shipmentsInbound.active.uploadDiamondsButton',
      viewButton: 'shipmentsInbound.active.viewButton',
    },
    archived: {
      prefix: 'shipmentsInbound.archived',
      column: 'shipmentsInbound.archived.column',
      counter: 'shipmentsInbound.archived.shipmentsCounter',
      row: 'shipmentsInbound.archived.row',
      viewButton: 'shipmentsInbound.archived.viewButton',
    },
    overview: {
      prefix: 'shipmentsInbound.overview',
      backButton: 'shipmentsInbound.overview.backButton',
      diamondsCounter: 'shipmentsInbound.overview.diamondsCounter',
      downloadButton: 'shipmentsInbound.overview.downloadButton',
      shipmentDetails: 'shipmentsInbound.overview.shipmentDetails',
      actionBar: 'shipmentsInbound.overview.actionBar',
      header: {
        caratRange: 'shipmentsInbound.overview.header.caratRange',
        prefix: 'shipmentsInbound.overview.header',
        totalCaratWeight: 'shipmentsInbound.overview.header.totalCaratWeight',
      },
      row: {
        acceptButton: 'shipmentsInbound.overview.acceptButton',
        rejectButton: 'shipmentsInbound.overview.rejectButton',
        prefix: 'shipmentsInbound.overview.row',
      },
    },
  },

  shipmentsOutbound: {
    activeButton: 'shipmentsOutbound.activeButton',
    archivedButton: 'shipmentsOutbound.archivedButton',
    newShipmentButton: 'shipmentsOutbound.newShipmentButton',
    shipmentsCounter: 'shipmentsOutbound.shipmentsCounter',
    tableActionButtons: 'shipmentsOutbound.tableActionButtons',
    active: {
      prefix: 'shipmentsOutbound.active',
      column: 'shipmentsOutbound.active.column',
      counter: 'shipmentsOutbound.active.counter',
      row: {
        prefix: 'shipmentsOutbound.active.row',
        cancelButton: 'shipmentsOutbound.active.cancelButton',
        viewButton: 'shipmentsOutbound.active.viewButton',
      },
    },
    archived: {
      prefix: 'shipmentsOutbound.archived',
      column: 'shipmentsOutbound.archived.column',
      counter: 'shipmentsOutbound.archived.counter',
      row: {
        prefix: 'shipmentsOutbound.archived.row',
        viewButton: 'shipmentsOutbound.archived.viewButton',
      },
    },
    newShipmentModal: {
      prefix: 'shipmentsOutbound.newShipmentModal',
      fileDropDragArea: 'shipmentsOutbound.newShipmentModal.fileUploadContainer',
      closeButton: 'shipmentsOutbound.newShipmentModal.closeButton',
      uploadButton: 'shipmentsOutbound.newShipmentModal.uploadButton',
    },
    overview: {
      prefix: 'shipmentsOutbound.overview',
      backButton: 'shipmentsOutbound.overview.backButton',
      diamondsCounter: 'shipmentsOutbound.overview.diamondsCounter',
      downloadButton: 'shipmentsOutbound.overview.downloadButton',
      cancelButton: 'shipmentsOutbound.overview.cancelButton',
      shipmentDetails: 'shipmentsOutbound.overview.shipmentDetails',
      header: {
        caratRange: 'shipmentsOutbound.overview.header.caratRange',
        counter: 'shipmentsOutbound.overview.header.counter',
        totalCaratWeight: 'shipmentsOutbound.overview.header.totalCaratWeight',
      },
      row: {
        prefix: 'shipmentsOubound.overview.row',
        cancelButton: 'shipmentsOutbound.overview.cancelButton',
        row: 'shipmentsOutbound.overview.row',
      },
    },
    column: {
      prefix: 'shipmentsOutbound.row.column',
    }
  },

  newShipment: {
    prefix: 'newShipment',
    row: 'newShipment.row',
    shipmentDetails: 'newShipment.shipmentDetails',
    diamondsCounter: 'newShipment.diamondsCounter',
    backButton: 'newShipment.backButton',
    tableActionButtons: 'newShipment.tableActionButtons',
    continueButton: 'newShipment.continueButton',
    removeAllButton: 'newShipment.removeAllButton',
    actionBar: 'newShipment.actionBar',
    nameModal: {
      confirmButton: 'newShipment.nameModal.confirmButton',
      cancelButton: 'newShipment.nameModal.cancelButton',
      nameInput: 'newShipment.nameModal.nameInput',
      recipientInput: 'newShipment.nameModal.recipientInput',
    },
    removeDiamondsModal: {
      confirmButton: 'newShipment.removeDiamondsModal.confirmButton',
      cancelButton: 'newShipment.removeDiamondsModal.cancelButton',
    },
    confirmShipmentModal: {
      confirmButton: 'newShipment.confirmShipmentModal.confirmButton',
      cancelButton: 'newShipment.confirmShipmentModal.cancelButton',
    },
    header: {
      counter: 'newShipment.header.counter',
      totalCaratWeight: 'newShipment.header.totalCaratWeight',
      caratRange: 'newShipment.header.caratRange',
    },
  },
}

export default testIds
