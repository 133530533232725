const filterFormatter = (name) => {
  const formatList = {
    benchmark: {
      Above: 'Optimal',
      Below: 'Sub-Optimal',
    },
    assurance_level: {
      ASSURED: 'Assured',
      DECLARED: 'Declared',
    },
  }

  // return a formatter function
  if (formatList[name]) {
    return (value) => {
      const format = formatList[name]
      const newValue = typeof format[value] !== 'undefined' ? format[value] : value

      return newValue
    }
  } else return undefined
}

export default filterFormatter
