import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clone } from 'ramda'

import {
  selectDiamondsToUnlockAction,
  setUnlockModalVisibleAction,
  unlockDiamondsAction,
  selectDiamondsToShareAction,
  setNewShipmentOutAction,
} from 'store/actions'
import {
  selectUnlockVisibleModalName,
  selectDiamondsToUnlock,
  selectAfterUnlockTarget,
  selectNewShipmentOut,
} from 'store/selectors'
import { Modal, Button, Table } from 'components/system'

import ParticipantIdCell from './ParticipantIdCell'
import classes from './styles.module.css'

const UnlockSingleModal = () => {
  const dispatch = useDispatch()
  const modalName = useSelector(selectUnlockVisibleModalName)
  const afterUnlock = useSelector(selectAfterUnlockTarget)
  const diamonds = useSelector(selectDiamondsToUnlock)
  const shipment = useSelector(selectNewShipmentOut)
  const [showConfirm, setShowConfirm] = useState(false)

  const columns = [
    {
      key: 'participant_id',
      title: 'Participant ID',
      sortable: false,
      cellComponent: ParticipantIdCell,
    },
    {
      sortable: false,
      key: 'diamond_id',
      title: 'Tracr ID',
    },
  ]

  const rows = diamonds.map((row, index) => {
    return {
      id: row.diamond_id,
      rowIndex: index,
      selected: false,
      data: {
        ...row,
      },
    }
  })

  const titles = {
    share: 'Unlock your diamond before sharing',
    shipmentOut: 'Confirm diamond for unlock',
  }

  const handleModalAction = (action) => {
    switch (action) {
      case 'unlock':
        setShowConfirm(true)
        break
      case 'back':
        setShowConfirm(false)
        break
      case 'cancel':
      case 'close':
        dispatch(selectDiamondsToUnlockAction([]))
        dispatch(setUnlockModalVisibleAction(null))

        break
      case 'close_secondary':
        dispatch(setUnlockModalVisibleAction(null))
        setShowConfirm(false)
        break
      case 'confirm_unlock':
        dispatch(unlockDiamondsAction(rows.map((row) => row.data.diamond_id))).then((result) => {
          dispatch(setUnlockModalVisibleAction(null))
          setShowConfirm(false)

          // TODO: replace both ifs with onSuccess or onComplete function and let the parent component handle the success
          if (afterUnlock === 'share') {
            const unlocked = result[0]

            if (unlocked.status === 'success') dispatch(selectDiamondsToShareAction([rows[0].data.diamond_id]))
          } else if (afterUnlock === 'shipmentOut') {
            const unlocked = result[0]

            if (unlocked.status !== 'success') return

            const diamonds = clone(shipment.diamonds)

            const diamond = diamonds.find((diamond) => diamond.diamond_id === rows[0].data.diamond_id)

            if (!diamond) return

            diamond.status = 'success'

            dispatch(
              setNewShipmentOutAction({
                ...shipment,
                diamonds,
              })
            )
          }
        })
        break
      default:
        break
    }
  }

  useEffect(() => {}, [JSON.stringify()]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <Modal
        isOpen={!showConfirm && modalName === 'singular'}
        width={560}
        title={titles[afterUnlock] || titles.share}
        onClose={() => handleModalAction('close')}
      >
        <div className={classes.unlockSingleModalContainer}>
          <div className={classes.description}>
            {afterUnlock === 'share'
              ? 'Diamonds can only be shared when unlocked. The diamond below is locked and will be unlocked on confirmation, after that you will be able to share or transfer it.'
              : 'The diamond listed below will be unlocked after your confirmation.'}
            &nbsp;Fees may apply with this action as mentioned in our
            <a href="/support#conditions" target="_blank" rel="noopener noreferrer">
              {' '}
              terms and conditions
            </a>
            .
          </div>
          <div className={classes.diamonds}>
            <Table columns={columns} rows={rows} height={109} />
          </div>
          <div className={classes.actions}>
            <div className={classes.buttons}>
              <Button variant="secondary" onClick={() => handleModalAction('cancel')}>
                Cancel
              </Button>
              <Button onClick={() => handleModalAction('unlock')}>Unlock diamond</Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showConfirm}
        title="Confirm diamond for unlock"
        width={560}
        confirmLabel="Confirm"
        cancelLabel="Go back"
        onConfirm={() => handleModalAction('confirm_unlock')}
        onCancel={() => handleModalAction('back')}
        onClose={() => handleModalAction('close_secondary')}
      >
        <div className={classes.smallModalText}>
          <span>Are you sure?</span> You are about to unlock 1 diamond.
          <br />
          <span>This action cannot be undone and fees may apply.</span>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default UnlockSingleModal
