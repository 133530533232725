import React, { useState, useEffect } from 'react'

import { Tooltip } from 'components/system'

import LabelAndValue from '../LabelAndValue'
import classes from './styles.module.css'
import { testIds } from 'config'

const extractCert = (diamond) => {
  if (!diamond.polished) return []

  const list = diamond.polished.grading_certificates || []
  const certificates = []
  list.forEach((cert) => {
    if (!cert.grader_inscription_no) return
    certificates.push({ lab: cert.grading_lab, number: cert.grader_inscription_no })
  })
  return certificates[certificates.length - 1]
}

export const InscriptionNumbers = ({ diamond = null }) => {
  const [isMobile, setIsMobile] = useState(false)
  const [cert, setCert] = useState(undefined)

  useEffect(() => {
    if (diamond) setCert(extractCert(diamond))
  }, [JSON.stringify(diamond)]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth < 768 && !isMobile) setIsMobile(true)
      else if (window.innerWidth >= 768 && isMobile) setIsMobile(false)
    }

    onResize()

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!diamond) return null
  return (
    <div className={classes.inscriptionNumbersContainer}>
      <div className={classes.wrapper}>
        {cert && (
          <div
            data-test-id={testIds.diamondExperience.inscriptionNumberSection}
            className={classes.inscriptionNumber}
            key={cert.lab}
          >
            <Tooltip
              title={'INSCRIPTION NUMBER'}
              description={
                'A laser inscription number is a combination of letters and numbers etched on a diamond, most often on the stone’s girdle, but can also be on the table. The inscription is a unique ID which often links to a diamonds grading certificate.'
              }
              variant={isMobile ? 'center' : 'top'}
            >
              <div className={classes.tooltipWrapper}>
                <LabelAndValue label={`${cert.lab} INSCRIPTION NO.`} value={cert.number?.split(',').join(', ')} />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  )
}
export default InscriptionNumbers
