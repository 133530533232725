import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { nanoid } from 'nanoid'

import { selectCSVFile } from 'store/selectors'
import { Table } from 'components/system'
import testids from 'config/testIds'

import DragAndDropWrapper from '../../../DragAndDropWrapper'
import classes from './styles.module.css'

export const ValidationErrorView = ({ errors = [], onDrop, onFileInput }) => {
  const csvFile = useSelector(selectCSVFile)
  const [columns, setColumns] = useState([{ title: 'Details', key: 'message', width: '100%' }])
  const [rows, setRows] = useState([])
  const testIds = testids.dit

  const getRows = (errors) => {
    return errors.map((error, index) => {
      const type = error.type

      let data = null

      if (type === 'expected_header' || type === 'missing_header') {
        data = { message: `Issue with column '${error.col}', ${error.description}`, index }
      } else if (type === 'assumed_header') {
        data = { message: `${error.description || 'unknown error'}`, index }
      } else if (type === 'row') {
        if (columns[0].key !== 'participantId') {
          setColumns([
            { title: 'Participant ID', key: 'participantId', width: '16.66%' },
            { title: 'Details', key: 'message', width: '83.33%' },
          ])
        }

        data = error
      } else if (type === 'invalid_date') {
        data = {
          message: `Invalid date format for '${error.col}' at row ${error.row} -  ${error.description.split('] ')[1]}`,
        }
      } else if (type === 'files_not_unique') {
        data = {
          message: `${error.description.split(': ')[1]} referenced multiple times in csv at row ${error.row}`,
        }
      } else if (type === 'required') {
        data = {
          message: `'${error.col}' should be provided at row ${error.row}`,
        }
      } else if (type === 'invalid_id') {
        data = {
          message: `${error.description}`,
        }
      } else if (
        type === 'invalid_number' ||
        type === 'format' ||
        type === 'min_inclusive' ||
        type === 'max_inclusive' ||
        type === 'invalid_integer'
      ) {
        data = {
          message: `Invalid value in the column '${error.col}' at row ${error.row} ${error.description.split('] ')[1]}`,
        }
      } else if (type === 'too_many_values') {
        data = {
          message: `Too many values at row ${error.row}`,
        }
      } else if (type === 'empty_file') {
        data = {
          message: `${error.description}`,
        }
      } else if (type === 'dit_exception') {
        data = {
          message: `${error.description}`,
        }
      } else if (type) {
        data = {
          message: `Row ${error.row} didn't pass validation - ${error.description}`,
        }
      } else {
        data = error
      }

      return {
        id: nanoid(),
        rowIndex: index,
        data,
      }
    })
  }

  useEffect(() => {
    setRows(getRows(errors))
  }, [JSON.stringify(errors)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.validationErrorViewContainer}>
      <DragAndDropWrapper withBottomBar semiTransparent onDrop={onDrop} onFileInput={onFileInput}>
        <div className={classes.errorsSection} data-test-id={testIds.errorsSection}>
          <div className={classes.meta}>
            <span className={classes.metaMessage}>
              Please fix
              <span>
                &nbsp;{rows.length} error{rows.length > 1 ? 's' : ''}&nbsp;
              </span>
              and upload again to progress with this session
            </span>
            <span className={classes.fileSize}>{csvFile.size}</span>
          </div>
          <div className={classes.errors}>
            <Table
              columns={columns}
              rows={rows}
              withTabs
              grow={false}
              multiline
              columnTestIdPrefix={testIds.errorColumn}
              rowTestId={testIds.errorRow}
            />
          </div>
        </div>
      </DragAndDropWrapper>
    </div>
  )
}

export default ValidationErrorView
