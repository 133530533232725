import createActionSet from 'utils/createActionSet'

const namespace = 'SHIPMENTS_IN'
const GET_SHIPMENT = createActionSet(namespace, 'GET_SHIPMENT')
const GET_SHIPMENTS = createActionSet(namespace, 'GET_SHIPMENTS')
const ACCEPT_DIAMONDS = createActionSet(namespace, 'ACCEPT_DIAMONDS')
const REJECT_DIAMONDS = createActionSet(namespace, 'REJECT_DIAMONDS')
const SET_STATIC_FILTERS = `${namespace}_SET_STATIC_FILTERS`

export const SHIPMENTS_IN_ACTION_TYPES = {
  GET_SHIPMENT,
  GET_SHIPMENTS,
  ACCEPT_DIAMONDS,
  REJECT_DIAMONDS,
  SET_STATIC_FILTERS,
}
