import React, { useState } from 'react'

import API from 'api'
import { PageTitle, HelpButton } from 'components/system'
import { helpLinks } from 'config'

import Filters from './Filters'
import ExportReport from './ExportReport'
import List from './List'

import classes from './styles.module.css'

const Monitoring = () => {
  const [sort, setSort] = useState({ upload_date: 'desc' })

  return (
    <div className={classes.monitoringContainer}>
      <PageTitle title="INTEGRATION MONITORING">
        <HelpButton
          link={helpLinks.integrationMonitoring}
          onClick={() => API.tracking.logHelp('monitoring-page', helpLinks.integrationMonitoring)}
        />
      </PageTitle>
      <Filters />
      <ExportReport sort={sort} />
      <List sort={sort} setSort={setSort} />
    </div>
  )
}

export default Monitoring
