import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import { Checkbox } from 'components/system'

import classes from './styles.module.css'

export const FilterOption = ({ option, selected = false, formater, onChange, Icon = null }) => {
  const [selectedState, setSelectedState] = useState(selected)

  const toggleSelected = () => {
    setSelectedState(!selectedState)

    onChange(option.value, !selectedState)
  }

  useEffect(() => {
    setSelectedState(selected)
  }, [selected])

  return (
    <li data-test-id="filteroption" className={classes.optionContainer} onClick={toggleSelected}>
      <div className={classes.checkbox}>
        <Checkbox value={selectedState ? 2 : 0} />
      </div>
      <div className={classes.label}>
        {Icon && (
          <div className={classes.icon}>
            <Icon size="s" />
          </div>
        )}
        <div className={classnames(classes.value, { [classes.selectedState]: selectedState })}>
          {formater ? formater(option.value) : option.title ? option.title : option.value}
        </div>
      </div>
    </li>
  )
}

export default FilterOption
