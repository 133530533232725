import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getShipmentOutAction } from 'store/actions'
import {
  selectShipmentOutById,
  selectShipmentsOutStaticFilters,
  selectShipmentOutDiamondStatuses,
} from 'store/selectors'
import showNotification from 'containers/Notifications/controller'
import { Counter, Table, SubNav, StatusLabel } from 'components/system'
import { DownloadIcon } from 'components/Svg'
import { formatNumber, getTotalCaratsWeight, getCaratsRange } from 'utils/diamond'
import { timestampFormatter } from 'utils/dates'
import { resolveOutboundDiamondStatusToState } from 'utils/shipments'
import { sorter, sorterFloat } from 'utils/sorting'
import { testIds } from 'config/testIds'

import Nav from '../../containers/Nav'
import Counters from '../../components/Counters'
import DiamondPropCell from '../../components/DiamondPropCell'
import Filters from './Filters'

import classes from './styles.module.css'

export const ArchiveShipment = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const appliedFilters = useSelector(selectShipmentsOutStaticFilters('archive'))
  const preloadedShipment = useSelector(selectShipmentOutById(id))
  const statuses = useSelector(selectShipmentOutDiamondStatuses)
  const [shipment, setShipment] = useState(preloadedShipment)
  const [points, setPoints] = useState([])
  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])
  const [filteredOutIds, setFilteredOutIds] = useState([])

  const columnsList = [
    {
      key: 'diamond_id',
      title: 'Tracr ID',
      sorter,
    },
    {
      key: 'participant_id',
      title: 'Participant ID',
      sorter,
    },
    {
      key: 'inscription_numbers',
      title: 'Inscription Number',
      cellComponent: DiamondPropCell,
      props: {
        customMapper: (value) => `${value.join(', ')}`,
      },
      sorter,
    },
    {
      key: 'carats',
      title: 'Carat',
      rightAlign: true,
      cellComponent: DiamondPropCell,
      props: {
        customMapper: (value) => `${formatNumber(value)}ct`,
      },
      sorter: sorterFloat,
    },
    {
      key: 'colour',
      title: 'Colour',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'model',
      title: 'Model',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'shape',
      title: 'Shape',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'clarity',
      title: 'Clarity',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'cut_grade',
      title: 'Cut',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'quality',
      title: 'Quality',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'sight_year',
      title: 'Sight year',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'sight_number',
      title: 'Sight number',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'state',
      title: 'Status',
      cellComponent: StatusLabel,
      sorter,
    },
  ]

  const filterOut = (rows, ids) => {
    return rows.filter((row) => !ids.includes(row.data.diamond_id))
  }

  const resolveKeysToObjectItems = (keys, list) => {
    return keys
      .map((key) => {
        return list.find((item) => item.key === key)
      })
      .filter((item) => item)
  }

  const getPoints = (shipment) => {
    if (!shipment) return []

    return [
      { key: 'To', value: shipment.receiver },
      { key: 'Shipment name', value: shipment.name },
      { key: 'Date sent', value: timestampFormatter(shipment.created_at) },
    ]
  }

  const getCounters = (shipment) => {
    if (!shipment || shipment.diamonds.length === 0) return []

    const range = getCaratsRange(shipment.diamonds)

    return [
      {
        label: 'Total diamonds',
        value: shipment.diamonds.length,
        testId: testIds.shipmentsOutbound.overview.header.counter,
      },
      {
        label: 'Total carat weight',
        value: getTotalCaratsWeight(shipment.diamonds),
        testId: testIds.shipmentsOutbound.overview.header.totalCaratWeight,
      },
      {
        label: 'Carat range',
        value: `${formatNumber(range[0])}ct - ${formatNumber(range[1])}ct`,
        testId: testIds.shipmentsOutbound.overview.header.caratRange,
      },
    ]
  }

  const getColumns = (shipment) => {
    if (!shipment) return []

    const list = {
      rough: ['diamond_id', 'participant_id', 'provenance', 'carats', 'colour', 'model', 'quality', 'sight_no'],
      polished: [
        'diamond_id',
        'participant_id',
        'inscription_number',
        'provenance',
        'shape',
        'carats',
        'clarity',
        'colour',
        'cut_grade',
        'state',
      ],
    }

    return list[shipment.shipment_type]
  }

  const getRows = (shipment) => {
    if (!shipment) return []

    return shipment.diamonds.map((diamond) => {
      return {
        id: diamond.diamond_id,
        data: {
          ...diamond,
          state: resolveOutboundDiamondStatusToState(statuses, diamond.transfer_state, shipment.shipment_type),
        },
      }
    })
  }

  const handleFiltersChange = (filteredOutIds) => {
    setFilteredOutIds(filteredOutIds)
  }

  const createFullExportLink = () => {
    if (!shipment) return null

    const columnsResolved = resolveKeysToObjectItems(columns, columnsList)

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      columnsResolved
        .map((column) => {
          return column.title
        })
        .filter((title) => title)
        .join(',') +
      '\n' +
      shipment.diamonds
        .map((diamond) => {
          return columnsResolved
            .map((column) => {
              if (column.key === 'state') {
                const status = resolveOutboundDiamondStatusToState(
                  statuses,
                  diamond.transfer_state,
                  shipment.shipment_type
                )

                return status ? status.text : ''
              } else return diamond[column.key] || diamond[shipment.shipment_type][column.key]
            })
            .filter((key) => key)
            .join(',')
        })
        .join('\n')

    const encodedUri = encodeURI(csvContent)

    return (
      <a
        className={classes.exportBtn}
        href={encodedUri}
        download={`transfer_out_session_${new Date().getDate()}_${new Date().getFullYear()}.csv`}
        target="_blank"
        rel="noopener noreferrer"
        data-test-id={testIds.shipmentsOutbound.overview.downloadButton}
      >
        <DownloadIcon /> <span>Export as CSV</span>
      </a>
    )
  }

  useEffect(() => {
    if (!shipment) return

    setPoints(getPoints(shipment))
    setColumns(getColumns(shipment))
    setRows(getRows(shipment))
  }, [JSON.stringify(shipment)]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shipment && shipment.id === id) return

    dispatch(getShipmentOutAction(id)).then((response) => {
      if (response && response.status === 200) {
        setShipment(response.data.content)
      } else {
        const error = response && response.data ? response.data.error || response.data.message : ''

        showNotification({
          title: 'Error getting transfer out session',
          bodyText: error ? error : 'Unknown error',
          variant: 'error',
        })
      }
    })
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.archiveShipmentContainer}>
      <div className={classes.wrapper}>
        <Nav title={'Outbound'} backTestId={testIds.shipmentsOutbound.overview.backButton} />
        <div className={classes.counters}>
          <Counters
            testIdPrefix={testIds.shipmentsOutbound.overview.prefix}
            counters={getCounters(shipment)} />
        </div>
        <div className={classes.filters}>
          <Filters rows={rows} shipmentType={shipment ? shipment.shipment_type : null} onChange={handleFiltersChange} />
        </div>
        <div className={classes.counter}>
          <Counter
            total={shipment ? shipment.diamonds.length : null}
            filtered={Object.keys(appliedFilters).length ? rows.length - filteredOutIds.length : null}
            testId={testIds.shipmentsOutbound.overview.diamondsCounter}
          />
        </div>

        <div className={classes.metaTab}>
          <SubNav points={points} testId={testIds.shipmentsOutbound.overview.shipmentDetails}>
            <div className={classes.action}>{createFullExportLink()}</div>
          </SubNav>
        </div>
        <div className={classes.list}>
          <div className={classes.border}></div>
          <Table
            rowTestId={testIds.shipmentsOutbound.overview.row.prefix}
            columns={resolveKeysToObjectItems(columns, columnsList)}
            rows={filterOut(rows, filteredOutIds)}
          />
        </div>
      </div>
    </div>
  )
}

export default ArchiveShipment
