import React, { useRef, useState, useEffect } from 'react'
import classnames from 'classnames'
import camelCase from 'camelcase'

import { Checkbox, Tooltip } from 'components/system'
import { testId } from 'utils/testHelper'

import classes from './styles.module.css'

export const OptionContainer = ({ label, value, checked, onChange, testIdPrefix }) => {
  const checkboxRef = useRef()
  const labelRef = useRef()
  const [overflow, setOverflow] = useState(false)

  const handleLabelClick = () => {
    if (!checkboxRef.current) return

    const checkbox = checkboxRef.current.querySelector('.checkbox')

    if (!checkbox) return

    checkbox.click()
  }

  useEffect(() => {
    if (!labelRef.current) return

    const width = labelRef.current.offsetWidth

    if (width > labelRef.current.parentNode.offsetWidth) {
      setOverflow(true)
    } else if (overflow) setOverflow(false)
  }, [label]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.optionContainer}>
      <div className={classes.wrapper} data-test-id={testId(testIdPrefix, camelCase((label ?? '').toString()))}>
        <div className={classes.checkbox} ref={checkboxRef}>
          <Checkbox value={checked ? 2 : 0} onChange={(newValue) => onChange(value, newValue)} />
        </div>
        <label className={classnames({ [classes.overflow]: overflow })} onClick={handleLabelClick} ref={labelRef}>
          {overflow ? <Tooltip title={label}>{<span>{label}</span>}</Tooltip> : label}
        </label>
      </div>
    </div>
  )
}

export default OptionContainer
