import React from 'react'
import { nanoid } from 'nanoid'

import testids from 'config/testIds'
import { TumbleweedIcon } from 'components/Svg'
import EmptyStateOverlay from 'pages/dit/EmptyStateOverlay'
import { Table } from 'components/system'

import classes from './styles.module.css'

export const MissingFilesTab = ({ files, isLoading }) => {
  const testIds = testids.dit
  const isEmpty = files.length === 0 && !isLoading

  const rows = files.map((file, index) => {
    return { id: nanoid(), rowIndex: index, data: { ...file } }
  })

  const uniqueParticipants = [...new Set(files.map((item) => item.participantId))]
  const isParticipantSortable = uniqueParticipants.length > 1 ? true : false

  const columns =
    files.length > 0
      ? [
          { title: 'Participant ID', key: 'participantId', width: '16.6%', sortable: isParticipantSortable },
          { title: 'File name', key: 'name', width: '16.6%' },
          { title: 'File type', key: 'type', width: '66.8%' },
        ]
      : []

  const emptyTable = () => {
    return (
      <EmptyStateOverlay
        icon={<TumbleweedIcon />}
        label={'No files to display'}
        subLabel={'Required files you need to provide will be displayed here'}
      />
    )
  }

  return (
    <div className={classes.missingFilesTabContainer}>
      <div className={classes.content}>
        <Table
          columns={columns}
          rows={rows}
          virtualized
          heightSub={64}
          groupBy={'participantId'}
          columnTestIdPrefix={testIds.sessionDetailsColumn}
          rowTestId={testIds.missingFilesRow}
          emptyState={emptyTable()}
          isEmpty={isEmpty}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default MissingFilesTab
