import API from 'api'
import { FILTER_ACTION_TYPES } from '../actionTypes'
import { sessionExpiredAction } from './user'

export function getFiltersAction({ appliedFilters, list }) {
  return (dispatch) => {
    dispatch({ type: FILTER_ACTION_TYPES.GET_FILTERS.REQUEST })

    const body = {
      list,
      filters: { ...appliedFilters },
    }

    return API.getFilters(body).then(
      (response) => {
        dispatch({
          type: FILTER_ACTION_TYPES.GET_FILTERS.COMPLETE,
          payload: response.data.content,
        })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: FILTER_ACTION_TYPES.GET_FILTERS.FAILURE, payload: error })
        }
      }
    )
  }
}

export function selectFiltersAction(payload) {
  return {
    type: FILTER_ACTION_TYPES.SELECT,
    payload,
  }
}

export function applyFiltersAction(payload = {}) {
  return {
    type: FILTER_ACTION_TYPES.APPLY,
    payload,
  }
}

export function clearFiltersAction(payload) {
  return {
    type: FILTER_ACTION_TYPES.CLEAR,
    payload,
  }
}

export function drillDownFiltersAction(payload) {
  return {
    type: FILTER_ACTION_TYPES.DRILL_DOWN,
    payload,
  }
}
