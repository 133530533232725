import React from 'react'
import classnames from 'classnames'

import { Tooltip } from 'components/system'
import {
  CancelIcon,
  WarningIcon,
  AcceptIcon,
  UndoIcon,
  DenyIcon,
  InfoIcon,
  TransferIcon,
  LockIcon,
  EyeIcon,
  ShareIcon,
  DestoyedIcon,
  RoughDiamondIcon,
  SplitDiamondIcon,
  PolishedDiamondIcon,
} from 'components/system/SVG'

import classes from './styles.module.css'

export const StatusLabel = ({
  status = 'default',
  text = 'Status',
  color,
  fill,
  stroke,
  withIcon = true,
  className,
  isCard,
  children,
}) => {
  const getIcon = () => {
    switch (status) {
      case 'success':
      case 'accept':
        return <AcceptIcon fill={fill || '#00B707'} />
      case 'warning':
      case 'exclamation':
        return <WarningIcon fill={fill || '#F99B0D'} />
      case 'error':
      case 'cancel':
        return <CancelIcon fill={fill || '#EC123B'} />
      case 'not-found':
      case 'disabled':
      case 'rejected':
      case 'not-inscribed':
      case 'deny':
        return <DenyIcon fill={fill || '#979797'} />
      case 'transfer':
      case 'in-transfer':
      case 'in-shipment':
        return <TransferIcon fill={fill || '#FFF'} />
      case 'rough':
        return <RoughDiamondIcon fill={fill || '#FFF'} />
      case 'split':
        return <SplitDiamondIcon fill={fill || '#FFF'} />
      case 'polished':
        return <PolishedDiamondIcon fill={fill || '#FFF'} />
      case 'destroyed':
        return <DestoyedIcon fill={fill || '#FFF'} />
      case 'locked':
      case 'unlock':
      case 'lock':
        return <LockIcon fill={fill || '#FFF'} />
      case 'undo':
        return <UndoIcon fill={fill || '#FFF'} />
      case 'info':
        return <InfoIcon stroke={stroke || '#FFF'} />
      case 'eye':
      case 'view':
        return <EyeIcon fill={fill || '#FFF'} />
      case 'share':
        return <ShareIcon fill={fill || '#FFF'} />
      default:
        return <WarningIcon stroke={stroke || '#FFF'} fill={fill || '#FFF'} />
    }
  }

  return (
    <div className={classnames(classes.statusLabelContainer, className)}>
      <Tooltip title={text}>
        <div
          className={classnames(classes.wrapper, {
            [classes[status]]: !color && !!status,
            [classes.card]: isCard,
          })}
        >
          {withIcon ? <div className={classes.icon}>{children ? children : getIcon()}</div> : null}
          <span className={classes.label} style={{ color: `${color}` }}>
            {text}
          </span>
        </div>
      </Tooltip>
    </div>
  )
}

export default StatusLabel
