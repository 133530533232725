import React from 'react'
import camelCase from 'camelcase'

import Option from './Option'
import classes from './styles.module.css'
import { testId } from 'utils/testHelper'

export const CheckboxFilter = ({ title, path, options = [], value, testIdPrefix = '', onChange }) => {
  const handleValueChange = (label, value) => {
    onChange(path, label, value)
  }

  if (options.length === 0) return null
  const groupTestIdPrefix = testId(testIdPrefix, camelCase(title ?? ''));
  return (
    <div className={classes.checkboxFilterContainer}>
      <div className={classes.wrapper} data-test-id={groupTestIdPrefix}>
        <div className={classes.title}>{title}</div>
        <ul className={classes.options}>
          {options.map((option) => (
            <li key={option.value} className={classes.option}>
              <Option
                key={title}
                path={path}
                label={option.label || option.value}
                value={option.value}
                checked={value.includes(option.value)}
                onChange={handleValueChange}
                testIdPrefix={groupTestIdPrefix}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default CheckboxFilter
