import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import camelCase from 'camelcase'

import { ClickOutsideListener, FilterOption } from 'components/system'
import {
  DownArrowSVG,
  DownArrowActiveSVG,
  AboveSVG,
  BelowSVG,
  PrincessSVG,
  RadiantSVG,
  RoundSVG,
  PearSVG,
  AsscherSVG,
  EmeraldSVG,
  OtherShapeSVG,
  UpArrowActiveSVG,
  UpArrowSVG,
} from 'components/Svg'
import {
  FlagDTCSVG,
  FlagBotswanaSVG,
  FlagSouthAfricaSVG,
  FlagCanadaSVG,
  FlagNamibiaSVG,
  FlagSierraLeoneSVG,
} from 'components/Svg/flags'
import { HeartShapeSVG, CushionShapeSVG, OvalShapeSVG, MarquiseShapeSVG } from 'components/Svg/shapes'
import translation from 'text/en'
import {testIds} from 'config'
import { testId } from 'utils/testHelper'

import classes from './styles.module.css'

export const Filter = ({
  name,
  title,
  options = [],
  selectedOptions = [],
  appliedOptions = [],
  testIdPrefix= '',
  className,
  formater,
  sorted = true,
  onChange,
  onFilterApply,
  onFilterClear,
}) => {
  const [selectedState, setSelectedState] = useState(selectedOptions)
  const [formChanged, setFormChanged] = useState(false)
  const [visible, setVisible] = useState(false)

  // update component on selected prop change
  useEffect(() => {
    setSelectedState(selectedOptions)
  }, [JSON.stringify(selectedOptions)]) // eslint-disable-line react-hooks/exhaustive-deps

  const toggleFilter = () => {
    setVisible(!visible)
  }

  const onFilterCleared = (name) => {
    setFormChanged(false)
    onFilterClear(name)
  }

  const onFiltersApply = () => {
    setFormChanged(false)
    onFilterApply(name, selectedState)
  }

  const onValueChange = (optionValue, checked) => {
    setFormChanged(true)
    onChange(name, optionValue, checked)
  }

  const onSubmit = () => {
    setFormChanged(false)
    setVisible(false)
    onFilterApply(name)
  }

  const appliedVisible = () => {
    if (appliedOptions.length) {
      return true
    } else if (selectedState && selectedState.length) {
      return true
    } else {
      return false
    }
  }

  const iconMap = {
    provenance: {
      DTC: FlagDTCSVG,
      Botswana: FlagBotswanaSVG,
      'South Africa': FlagSouthAfricaSVG,
      Canada: FlagCanadaSVG,
      Namibia: FlagNamibiaSVG,
      'Sierra Leone': FlagSierraLeoneSVG,
    },
    benchmark: {
      Above: AboveSVG,
      Below: BelowSVG,
    },
    'polished.shape': {
      default: OtherShapeSVG,
      Pear: PearSVG,
      Oval: OvalShapeSVG,
      Round: RoundSVG,
      Princess: PrincessSVG,
      Radiant: RadiantSVG,
      Asscher: AsscherSVG,
      Emerald: EmeraldSVG,
      Cushion: CushionShapeSVG,
      Heart: HeartShapeSVG,
      Marquise: MarquiseShapeSVG,
    },
  }

  const ArrowComponent = () => {
    if (visible) {
      if (selectedOptions.length) return <UpArrowActiveSVG />
      else return <UpArrowSVG />
    } else {
      if (selectedOptions.length) return <DownArrowActiveSVG />
      else return <DownArrowSVG />
    }
  }

  const notSortedOptions = [
    'polished.clarity',
    'polished.cut_grade',
    'polished.quality',
    'intermediate.quality',
    'rough.quality',
    'carat_range',
  ]
  const cleanName = name.replace(/(rough|intermediate|polished)./i, '');
  const filterName = title || translation['filters.' + cleanName] || name

  return (
    <div 
      className={`${classes.filterContainer} ${className}`}
      data-test-id={testId(testIdPrefix, testIds.filters.infix, camelCase(cleanName))}
    >
      <ClickOutsideListener
        onClickOutside={() => {
          if (visible) toggleFilter(visible)
          if (formChanged) onFiltersApply()
        }}
      >
        <div className={classes.wrapper}>
          <div
            className={classNames(classes.labelContainer, { [classes.selected]: selectedOptions.length })}
            onClick={toggleFilter}
          >
            <div className={visible ? classes.labelVisible : classes.label}>
              {filterName}
              {selectedState.length > 0 && <div className={classes.selectedCount}>- {selectedState.length}</div>}
            </div>
            <div className={classNames(classes.arrow, { [classes.extraArrowPadding]: options.length > 5 })}>
              <ArrowComponent />
            </div>
          </div>
          <div className={classNames(classes.optionsContainer, { [classes.visible]: visible })}>
            <div className={classes.scrollbar}>
              <div className={classes.scrollableContainer}>
                {options
                  .sort((a, b) => {
                    if (!sorted) return 0

                    if (!notSortedOptions.includes(name)) {
                      return a.value > b.value ? 1 : -1
                    } else {
                      return 1
                    }
                  })
                  .map((option, index) => {
                    return (
                      <FilterOption
                        Icon={
                          (iconMap[name] && iconMap[name][option.value]) || (iconMap[name] && iconMap[name].default)
                        }
                        key={`filter-option-${index}`}
                        option={option}
                        formater={formater}
                        selected={selectedState.includes(option.value)}
                        onChange={onValueChange}
                      />
                    )
                  })}
              </div>
            </div>
            <div className={classes.actionContainer}>
              <div
                className={selectedState.length ? classes.clearFilters : classes.clearFiltersDisabled}
                onClick={selectedState.length ? () => onFilterCleared(name) : null}
              >
                Clear
              </div>
              <div
                className={appliedVisible() ? classes.applyFilters : classes.applyFiltersDisabled}
                onClick={appliedVisible() ? onSubmit : null}
              >
                Apply
              </div>
            </div>
          </div>
        </div>
      </ClickOutsideListener>
    </div>
  )
}

export default Filter
