import React from 'react'

import { Modal } from 'components/system'

import classes from './styles.module.css'

const CancelShipmentModal = ({ visible, testId, onCancel, onConfirm, children }) => {
  return (
    <Modal
      isOpen={visible}
      title={'CANCEL SHIPMENT'}
      confirmLabel="Confirm"
      cancelTestId={testId + '.cancel'}
      confirmTestId={testId + '.confirm'}
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm()}
    >
      <div className={classes.cancelShipmentModalContainer}>{children}</div>
    </Modal>
  )
}

export default CancelShipmentModal
