import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { nanoid } from 'nanoid'
import { isNil } from 'ramda'
import { capitalize } from 'lodash'

import { getMonitoringDiamondsAction, applyMonitoringFiltersAction } from 'store/actions'
import { selectMonitoringDiamonds, selectMonitoringFilters } from 'store/selectors'
import EmptyStateOverlay from 'pages/monitoring/EmptyStateOverlay'
import showNotification from 'containers/Notifications/controller'
import { RoughPolishingRotatedIcon } from 'components/Svg'
import { TZAwareTimestampFormatter } from 'utils/dates'
import { Table } from 'components/system'
import testids from 'config/testIds'

import Status from './Status'
import classes from './styles.module.css'

const List = ({ sort, setSort }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const filters = useSelector(selectMonitoringFilters)
  const diamonds = useSelector(selectMonitoringDiamonds)
  const [isLoading, setIsLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const sessionsPerPage = 40
  const testIds = testids.monitoring

  const handleSort = (sort) => {
    setIsLoading(true)
    setHasMore(true)
    setPage(1)
    setSort(sort)

    dispatch(getMonitoringDiamondsAction({ page: 1, limit: sessionsPerPage, sort, filters })).then((response) => {
      if (response && response.data) {
        if (response.data.data.length < sessionsPerPage) setHasMore(false)
      }

      setIsLoading(false)
    })
  }

  // sorts '-' to alwasy be at the end of the list
  const sortDashes = (list, key, order) => {
    let sortedList = list.filter((item) => item[key] !== '-')

    sortedList = sortedList.sort((a, b) => {
      const valA = !isNil(a[key]) ? a[key].valueOf() : a
      const valB = !isNil(b[key]) ? b[key].valueOf() : b

      if (valA > valB) {
        return order[0]
      } else if (valA < valB) {
        return order[1]
      } else {
        return 0
      }
    })

    sortedList = sortedList.concat(list.filter((item) => item[key] === '-'))

    return sortedList
  }

  const columns = [
    { title: 'Integration type', key: 'integration_type' },
    { title: 'Event date', key: 'upload_date', mapper: TZAwareTimestampFormatter, sorter: sortDashes },
    { title: 'Participant ID', key: 'participant_id' },
    { title: 'Box ID', key: 'box_id' },
    { title: 'Session ID', key: 'dit_session_id', sortable: false },
    { title: 'Tracr ID', key: 'tracr_id', sortable: false },
    { title: 'Status', key: 'status', minWidth: '131px', sorter: sortDashes, cellComponent: Status },
    // { title: 'Status Date', key: 'status_date', mapper: TZAwareTimestampFormatter, sorter: sortDashes, minWidth: '131px' },
    {
      title: 'Details',
      sortable: false,
      key: 'details',
    },
  ]

  const rows = diamonds.map((row, index) => {
    return {
      id: nanoid(),
      rowIndex: index,
      data: {
        upload_date: row.upload_date !== null ? row.upload_date : '-',
        participant_id: row.participant_id !== null ? row.participant_id : '-',
        dit_session_id: row.dit_session_id !== null ? row.dit_session_id : '-',
        box_id: row.box_id !== null ? row.box_id : '-',
        tracr_id: row.tracr_id !== null ? row.tracr_id : '-',
        integration_type: capitalize(row.integration_type),
        details: row.details !== null ? row.details : '-',
        status: row.status !== null ? row.status : '-',
      },
    }
  })

  const handleLoadMore = () => {
    setIsLoading(true)

    const nextPage = page + 1
    setPage(nextPage)

    dispatch(getMonitoringDiamondsAction({ page: nextPage, limit: sessionsPerPage, sort: sort, filters })).then(
      (response) => {
        if (response && response.data) {
          if (response.data.data.length < sessionsPerPage) setHasMore(false)
        }
        setIsLoading(false)
      }
    )
  }

  const handleRedirect = () => {
    navigate('/integration/dit')
  }

  const handleClearFilters = () => {
    dispatch(applyMonitoringFiltersAction([]))
    navigate('/integration/monitoring')
  }

  useEffect(() => {
    // prevent loading when id is passed in url params but filters are not built in redux yet
    if (id && filters.length === 0) return

    if (page > 1) setPage(1)
    setIsLoading(true)
    setHasMore(true)
    dispatch(getMonitoringDiamondsAction({ page: 1, limit: sessionsPerPage, sort: sort, filters }))
      .then((response) => {
        if (response && response.data) {
          if (response.data.data.length < sessionsPerPage) setHasMore(false)
        }
        setIsLoading(false)
      })
      .catch((err) => {
        showNotification({
          title: err,
          variant: 'error',
        })
      })
  }, [JSON.stringify(filters)]) // eslint-disable-line react-hooks/exhaustive-deps

  const emptyTable = () => {
    return (
      <EmptyStateOverlay
        icon={<RoughPolishingRotatedIcon />}
        label={filters.length > 0 ? 'All events have been filtered out of view' : 'No events to display'}
        withTabs={false}
        subLabel={filters.length > 0 ? 'To view them, adjust or' : 'Integrate diamonds to monitor events'}
        buttonText={filters.length > 0 ? 'Clear all filters' : 'Integration tool'}
        onClick={filters.length > 0 ? handleClearFilters : handleRedirect}
      />
    )
  }

  const isMonitoringEmpty = !isLoading && (rows.length === 0 || diamonds.length === 0)

  return (
    <div className={classes.listContainer}>
      <div className={classes.wrapper}>
        <Table
          columns={columns}
          rows={rows}
          paginated
          virtualized
          hasMore={hasMore}
          isLoading={isLoading}
          onLoadMore={handleLoadMore}
          onSort={handleSort}
          emptyState={emptyTable()}
          isEmpty={isMonitoringEmpty}
          testIdPrefix={testIds.prefix}
        />
      </div>
    </div>
  )
}

export default List
