import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectLifecycle, selectView } from 'store/selectors'
import PageViewNav from 'containers/PageViewNav'
import UnlockSingleModal from 'containers/UnlockSingleModal'
import UnlockMultipleModal from 'containers/UnlockMultipleModal'
import { Scroller, Diamond } from 'components/system'

import DiamondNav from './DiamondNav/index'
import classes from './styles.module.css'

export const DiamondExperience = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const lifecycle = useSelector(selectLifecycle)
  const selectedView = useSelector(selectView)

  const onBack = () => navigate(`/inventory/${lifecycle.stage}/${selectedView}`)

  if (!id) return null

  return (
    <div className={classes.diamondExperienceContainer}>
      <PageViewNav onBack={onBack} shareType={true} />

      <div className={classes.wrapper}>
        <DiamondNav /> {/* DiamondNav is empty as it's still being used in old DE page and uses old selector */}
        <Scroller withPadding>
          <Diamond id={id} isFullScreen />
        </Scroller>
      </div>
      <UnlockSingleModal />
      <UnlockMultipleModal />
    </div>
  )
}

export default DiamondExperience
