import React, { useEffect, useState, useRef } from 'react'

import testids from 'config/testIds'

import CSVOverlay from './CSVOverlay'
import FilesOverlay from './FilesOverlay'
import classes from './styles.module.css'

export const DragAndDropWrapper = ({
  withBottomBar,
  persistent,
  semiTransparent,
  forFiles,
  children,
  onDrop,
  onFileInput,
}) => {
  const dropZone = useRef()
  const fileInput = useRef()
  const [isDraggedOver, setIsDraggedOver] = useState(false)
  const testIds = testids.dit

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDragEnter = () => {
    if (isDraggedOver) return

    setIsDraggedOver(true)
  }

  const handleDragLeave = (event) => {
    if (!isDraggedOver) return

    if (dropZone.current.contains(event.relatedTarget)) return

    setIsDraggedOver(false)
  }

  const handleDrop = (event) => {
    if (Array.from(event.dataTransfer.files).length === 0) return setIsDraggedOver(false)

    event.preventDefault()

    setIsDraggedOver(false)

    onDrop(event.dataTransfer.files)
  }

  const handleInputClick = () => {
    fileInput.current.click()
  }

  const handleInputChange = () => {
    const file = fileInput.current.files[0]

    if (!file) return

    if (forFiles) {
      onFileInput(fileInput.current.files)
    } else {
      onFileInput(file)
    }

    // unset input value in case same file re-drop
    fileInput.current.value = null
  }

  useEffect(() => {}, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classes.dragAndDropWrapperContainer}
      ref={dropZone}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {forFiles ? (
        <FilesOverlay
          persistent={persistent}
          semiTransparent={semiTransparent}
          isDraggedOver={isDraggedOver}
          onButtonClick={handleInputClick}
        />
      ) : (
        <CSVOverlay
          persistent={persistent}
          semiTransparent={semiTransparent}
          isDraggedOver={isDraggedOver}
          onButtonClick={handleInputClick}
        />
      )}
      {children}
      {withBottomBar && (
        <div
          className={classes.bottomBar}
          onClick={handleInputClick}
          data-test-id={forFiles ? testIds.sessionSelectFilesText : testIds.uploadCSVSelectFileText}
        >
          <span className={classes.bottomBarHint}>Drag and drop,</span>
          <span className={classes.bottomBarColored}>or select from device</span>
        </div>
      )}
      <input
        className={classes.dropFileInput}
        type="file"
        ref={fileInput}
        accept={forFiles ? '*' : '.csv'}
        multiple={!!forFiles}
        onChange={handleInputChange}
      />
    </div>
  )
}

export default DragAndDropWrapper
