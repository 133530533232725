import React, { useEffect, useState, createRef } from 'react'
import PropTypes from 'prop-types'
import { splitEvery } from 'ramda'

import Empty from 'containers/Empty'
import { useWindowResize } from 'components/utils/hooks'

import CardRow from '../CardRow'
import Ghost from '../Ghost'
import classes from './styles.module.css'

const CardTable = ({
  data,
  selectedItems,
  sortKey,
  onLoadMore,
  onCardClick,
  targetCardHeight,
  targetCardWidth,
  isLoading,
  onSelect,
}) => {
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const [perRow, setPerRow] = useState(1)
  const [rowHeight, setRowHeight] = useState(0)
  const [imageSize, setImageSize] = useState(null)
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const container = createRef()
  const card = createRef()
  const size = useWindowResize()

  const handleScroll = (evt) => {
    const scrolledPrc = evt.target.scrollTop / (evt.target.children[0].offsetHeight - evt.target.offsetHeight)

    if (scrolledPrc > 0.85 && !isLoading && !isLoadingLocal) {
      setIsLoadingLocal(true)
      onLoadMore()
    }
  }

  useEffect(() => {
    const node = container.current

    if (node) {
      const horizontalPadding = 24 * 2
      const availableWidth = (container.current && container.current.clientWidth) - horizontalPadding || 0

      const perRow = Math.ceil(availableWidth / (targetCardWidth + 24))
      const rowHeight = (card.current && card.current.clientHeight + 24) || targetCardHeight
      const cardNode = card.current && card.current.firstChild && card.current.firstChild
      const cardImageNodes = cardNode && cardNode.getElementsByTagName('img')

      if (cardImageNodes && cardImageNodes.length > 0) {
        const width = cardImageNodes[0].clientWidth
        const height = cardImageNodes[0].clientHeight

        if (imageSize === null) {
          setImageSize({ width, height })
        } else {
          if (imageSize.width !== width && imageSize.height !== height) {
            setImageSize({ width, height })
          }
        }
      }

      setPerRow(perRow)
      setRowHeight(rowHeight)
      setWidth(availableWidth)
    }
  }, [card, container, targetCardWidth, targetCardHeight, size.width, imageSize])

  useEffect(() => {
    if (size.height) {
      const filters = document.getElementById('filters')

      if (filters) {
        // 152 is page title bar and page nav bar + padding between cards and filters
        setHeight(size.height - filters.clientHeight - 152 - 24)
      }
    }
  }, [size.height, size.width])

  useEffect(() => {
    setIsLoadingLocal(isLoading)
  }, [isLoading])

  const onCardCheckboxChange = (itemId) => {
    const isCheckBoxCurrentlySelected = selectedItems.find((id) => id === itemId)

    // if already selected we deselect
    if (isCheckBoxCurrentlySelected) {
      const nextItems = selectedItems.filter((id) => id !== itemId)
      onSelect(nextItems)
    } else {
      const nextItems = selectedItems.concat([itemId])
      onSelect(nextItems)
    }
  }

  const rows = splitEvery(
    perRow,
    data.map((item) => ({ ...item, selected: !!selectedItems.find((id) => id === item.diamond_id) }))
  )

  const columns = new Array(perRow)
    .fill('')
    .map(() => '1fr')
    .join(' ')

  const rowsAvailable = rows.length > 0

  if (!rowsAvailable && !isLoading && height) {
    return (
      <div style={{ height: `${height}px` }}>
        <Empty />
      </div>
    )
  }

  return (
    <div className={classes.cardTableContainer} ref={container} key={sortKey}>
      {rowsAvailable && (
        <>
          <Ghost ref={card} data={rows[0][0]} columns={columns} />

          <div className={classes.infiniteLoader}>
            <div className={classes.wrapper} style={{ width, maxHeight: `${height}px` }} onScroll={handleScroll}>
              <div>
                {rows.map((_item, index) => {
                  const Row = CardRow(rows, columns, imageSize, onCardClick, onCardCheckboxChange)
                  const isLast = index === rows.length - 1
                  return (
                    <Row
                      key={index}
                      index={index}
                      style={{ width: '100%', height: `${isLast ? rowHeight - 24 : rowHeight}px` }}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

CardTable.propTypes = {
  data: PropTypes.array.isRequired,
  total: PropTypes.shape({
    count: PropTypes.number,
  }).isRequired,
  sortKey: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onCardClick: PropTypes.func.isRequired,
  targetCardHeight: PropTypes.number.isRequired,
  targetCardWidth: PropTypes.number.isRequired,
}

export default CardTable
