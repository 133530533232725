import React from 'react'

import classes from './styles.module.css'

export const PageHeader = ({ title, children }) => {
  return (
    <div className={classes.pageHeaderContainer}>
      <h3 className={classes.title} dangerouslySetInnerHTML={{ __html: title }} />
      {children}
    </div>
  )
}

export default PageHeader
