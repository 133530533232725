import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { selectNavIsCollapsed } from 'store/selectors'
import { LifecycleEpic, LocationObserver } from 'store/epics'
import { loadUserDataAction } from 'store/actions'
import NavigationContainer from 'containers/Navigation'

import classes from './styles.module.css'

/**
 * List page layout
 * @reactProps {number} id page identifier
 * @reactProps {number} parentId parent page identifier
 */

export const DefaultLayout = function () {
  const navIsCollapsed = useSelector(selectNavIsCollapsed)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadUserDataAction())
  })

  return (
    <div className={classes.defaultLayoutContainer}>
      <LocationObserver />
      <LifecycleEpic />
      <NavigationContainer />
      <div className={classnames(classes.contentContainer, { [classes.navCollapsed]: navIsCollapsed })}>
        <Outlet />
      </div>
    </div>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.object,
}

export default DefaultLayout
