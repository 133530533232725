import React from 'react'

import { Heading } from 'components/system'

import classes from './styles.module.css'

const Cookies = () => {
  return (
    <div className={classes.cookiesContainer}>
      <Heading text="Cookies" />
      <div className={classes.contentContainer}>
        <div className={classes.mainHeading}>Cookies</div>
        <div className={classes.body}>
          1. We use cookies to ensure that you get the most out of our Services. Cookies are small amounts of
          information in the form of text files which we store on the device you use to access our Services. Cookies
          allow us to monitor and simplify your use of our Services. There are various different types of cookies, but
          the main types used on our Services are:
          <br />
          1.1 <strong>Strictly necessary cookies:</strong> These are cookies that are required for the operation of our
          Services. They include, for example, cookies that enable you to log into secure areas of our Services.
          <br />
          1.2 <strong>Analytical/performance cookies:</strong> They allow us to recognise and count the number of
          visitors and to see how visitors move around our Services when they are using it. This helps us to improve the
          way our Services works, for example, by ensuring that users are finding what they are looking for easily.
          <br />
          1.3 <strong>Functionality cookies:</strong> These are used to recognise you when you return to our Services.
          This enables us to personalise our content for you, greet you by name and remember your preferences (for
          example, your choice of language or region).
          <br />
          <br />
          2. Our Services contains content and links to other sites that are operated by third parties that may also
          operate cookies. We don&rsquo;t control these third-party sites or cookies and this Privacy Notice does not
          apply to them. Please consult the terms and conditions and privacy notice of the relevant third-party site to
          find out how that site collects and uses your information and to establish whether and for what purpose they
          use cookies.
          <br />
          <br />
          3. If you do not wish for cookies to be installed on your device, you can change the settings on your browser
          or device to reject cookies. For more information about how to reject cookies using your internet browser
          settings please consult the &ldquo;Help&rdquo; section of your internet browser (or alternatively visit{' '}
          <a href="http://www.aboutcookies.org.uk/" target="_blank" className={classes.link} rel="noopener noreferrer">
            http://www.aboutcookies.org.uk
          </a>
          ). Please note that, if you do set your Internet browser to reject cookies, you may not be able to access all
          of the functions of the Services.
        </div>
      </div>
    </div>
  )
}

export default Cookies
