import React from 'react'

import { ButtonsCell } from 'components/system/TableElements'
import testIds from 'config/testIds'

export const Buttons = ({ row, ...props }) => {
  const getItems = () => {
    const items = []

    if (row.data.shipment_type === 'polished')
      items.push({ key: 'view', label: 'View', type: 'primary', testId: testIds.shipmentsInbound.archived.viewButton })

    return items
  }

  return <ButtonsCell row={row} buttons={getItems()} {...props} />
}

export default Buttons
