import http from '../utils/http'

const namespace = 'share'

const shareDiamonds = (body) => http.put(`${namespace}/`, body)

const getSharedDiamonds = (id) => {
  return http.get(`${namespace}/${id}`)
}

const endpoints = {
  shareDiamonds,
  getSharedDiamonds,
}

export default endpoints
