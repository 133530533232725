import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { save, load } from 'redux-localstorage-simple'

import rootReducer from './reducers'
import { localStorageVersion } from './reducers/version'
import { STORE_INIT } from './actionTypes'

const LOCALSTORAGE_NAMESPACE = 'tracr-portal-ui'

// clear localStorage if version changed
// TODO: move this to env var
if (+localStorage.getItem(`${LOCALSTORAGE_NAMESPACE}_version`) !== localStorageVersion) {
  localStorage.clear()
}

// thunk, location history and local storage middleware
const middleware = [thunk, save({ states: ['session', 'settings', 'version'], namespace: LOCALSTORAGE_NAMESPACE })]

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  preloadedState: load({ states: ['session', 'settings', 'version'], namespace: LOCALSTORAGE_NAMESPACE }),
})

// dispatch action to let all reducers know that store was initialized
// mostly needed for session reducer
store.dispatch({ type: STORE_INIT })

export default store
