import React from 'react'

export const RoughLifecycleIcon = () => {
  return (
    <svg width="88" height="60" viewBox="0 0 88 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2348 16.1456L4.56735 22.2532M14.2348 16.1456L4.56735 36.0585M14.2348 16.1456L36.5844 22.2532M14.2348 16.1456L32.1808 1.38477M54.2917 3.19751L68.2231 22.2532M54.2917 3.19751L86.4615 36.0585M54.2917 3.19751L32.1808 1.38477M86.4615 36.0585L66.0966 58.6155M86.4615 36.0585L54.2917 52.4679M86.4615 36.0585L68.2231 22.2532M66.0966 58.6155L23.2763 52.4679M66.0966 58.6155L54.2917 52.4679M1.23071 45.9264L4.56735 22.2532M1.23071 45.9264L4.56735 36.0585M1.23071 45.9264L8.35487 50.1992L23.2763 52.4679M4.56735 22.2532V36.0585M54.2917 52.4679L4.56735 36.0585M54.2917 52.4679H23.2763M54.2917 52.4679L36.5844 22.2532M54.2917 52.4679L68.2231 22.2532M4.56735 36.0585L23.2763 52.4679M4.56735 36.0585L36.5844 22.2532M36.5844 22.2532H68.2231M36.5844 22.2532L32.1808 1.38477M68.2231 22.2532L32.1808 1.38477"
        stroke="white"
      />
    </svg>
  )
}

export const SplitLifecycleIcon = () => {
  return (
    <svg width="87" height="69" viewBox="0 0 87 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.611354 22.8179L2.94375 27.2341M0.611354 22.8179L4.33126 34.4268M0.611354 22.8179L4.93279 17.8837L38.1784 2.82886L44.9345 1.25135L76.4276 12.1442L84.3044 16.3669M84.3044 16.3669L86.403 22.6273M84.3044 16.3669L84.3043 21.5441M41.9056 47.5787L48.0719 67.8822M41.9056 47.5787L30.725 60.6076M41.9056 47.5787L58.7966 49.6648M41.9056 47.5787L12.0893 34.9697M41.9056 47.5787L63.8845 38.0659M48.0719 67.8822L58.7966 49.6648M48.0719 67.8822L71.9218 42.7097M48.0719 67.8822L30.725 60.6076M30.725 60.6076L12.0893 34.9697M30.725 60.6076L4.33126 34.4268M58.7966 49.6648L71.9218 42.7097M58.7966 49.6648L63.8845 38.0659M12.0893 34.9697L2.94375 27.2341M71.9218 42.7097L76.4272 25.9987M71.9218 42.7097L84.304 29.0992L86.403 22.6273M63.8845 38.0659L76.4272 25.9987M76.4272 25.9987L84.3043 21.5441M86.403 22.6273L84.3043 21.5441M2.94375 27.2341L4.33126 34.4268"
        stroke="white"
      />
    </svg>
  )
}

export const PolishedLifecycleIcon = () => {
  return (
    <svg width="87" height="54" viewBox="0 0 87 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.6441 52.3847L86.4615 14.5709M43.6441 52.3847L1.23071 14.5709M43.6441 52.3847L60.6095 14.5709M43.6441 52.3847V39.5119M43.6441 52.3847L28.4965 14.5709M43.6441 52.3847L33.9496 39.5119M43.6441 52.3847L54.7524 39.5119M86.4615 14.5709V11.9561M86.4615 14.5709H83.23M86.4615 11.9561L80.8064 6.52537M86.4615 11.9561H83.23M80.8064 6.52537L68.2843 0.692383M80.8064 6.52537L83.23 11.9561M68.2843 0.692383H54.7524M68.2843 0.692383L69.9001 6.52537M19.4079 0.692383L6.88584 6.52537M19.4079 0.692383H33.9496M19.4079 0.692383L18.8885 6.52537M6.88584 6.52537L1.23071 11.9561M6.88584 6.52537L4.86615 11.9561M1.23071 11.9561V14.5709M1.23071 11.9561H4.86615M1.23071 14.5709H4.86615M43.6441 6.52537L54.7524 0.692383M43.6441 6.52537L60.6095 11.9561M43.6441 6.52537L33.9496 0.692383M43.6441 6.52537L28.4965 11.9561M43.6441 6.52537V11.9561M54.7524 0.692383L69.9001 6.52537M54.7524 0.692383H33.9496M69.9001 6.52537L83.23 11.9561M69.9001 6.52537L73.5355 11.9561M69.9001 6.52537L60.6095 11.9561M83.23 11.9561H73.5355M73.5355 11.9561H60.6095M60.6095 11.9561H43.6441M33.9496 0.692383L18.8885 6.52537M4.86615 11.9561L18.8885 6.52537M4.86615 11.9561H14.5606M18.8885 6.52537L14.5606 11.9561M18.8885 6.52537L28.4965 11.9561M14.5606 11.9561H28.4965M28.4965 11.9561H43.6441M4.86615 14.5709H14.5606M4.86615 14.5709L33.9496 39.5119M14.5606 14.5709H28.4965M14.5606 14.5709L33.9496 39.5119M28.4965 14.5709H43.6441M28.4965 14.5709L43.6441 39.5119M28.4965 14.5709L33.9496 39.5119M43.6441 14.5709H60.6095M43.6441 14.5709V11.9561M43.6441 14.5709V39.5119M60.6095 14.5709H73.5355M60.6095 14.5709L54.7524 39.5119M60.6095 14.5709L43.6441 39.5119M73.5355 14.5709H83.23M73.5355 14.5709L54.7524 39.5119M83.23 14.5709L54.7524 39.5119"
        stroke="white"
      />
    </svg>
  )
}

export const RoughLifecycleLargeIcon = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 400 316" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} />
      <path
        d="M102.805 112.588L70.9831 132.692M102.805 112.588L70.9831 178.134M102.805 112.588L176.373 132.692M102.805 112.588L161.877 64M234.659 69.9669L280.517 132.692M234.659 69.9669L340.551 178.134M234.659 69.9669L161.877 64M340.551 178.134L273.517 252.385M340.551 178.134L234.659 232.149M340.551 178.134L280.517 132.692M273.517 252.385L132.567 232.149M273.517 252.385L234.659 232.149M60 210.616L70.9831 132.692M60 210.616L70.9831 178.134M60 210.616L83.4503 224.681L132.567 232.149M70.9831 132.692V178.134M234.659 232.149L70.9831 178.134M234.659 232.149H132.567M234.659 232.149L176.373 132.692M234.659 232.149L280.517 132.692M70.9831 178.134L132.567 232.149M70.9831 178.134L176.373 132.692M176.373 132.692H280.517M176.373 132.692L161.877 64M280.517 132.692L161.877 64"
        stroke="white"
      />
    </svg>
  )
}

export const SplitLifecycleLargeIcon = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 400 316" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} />
      <path
        d="M64.3268 121.793L72.0043 136.33M64.3268 121.793L76.5715 160.005M64.3268 121.793L78.5516 105.551L187.985 55.9956L210.224 50.803L313.889 86.6587L339.817 100.558M339.817 100.558L346.724 121.165M339.817 100.558L339.816 117.6M200.254 203.297L220.551 270.129M200.254 203.297L163.451 246.184M200.254 203.297L255.853 210.164M200.254 203.297L102.109 161.792M200.254 203.297L272.601 171.984M220.551 270.129L255.853 210.164M220.551 270.129L299.057 187.27M220.551 270.129L163.451 246.184M163.451 246.184L102.109 161.792M163.451 246.184L76.5715 160.005M255.853 210.164L299.057 187.27M255.853 210.164L272.601 171.984M102.109 161.792L72.0043 136.33M299.057 187.27L313.887 132.263M299.057 187.27L339.815 142.469L346.724 121.165M272.601 171.984L313.887 132.263M313.887 132.263L339.816 117.6M346.724 121.165L339.816 117.6M72.0043 136.33L76.5715 160.005"
        stroke="white"
      />
    </svg>
  )
}

export const PolishedLifecycleLargeIcon = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 400 316" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} />
      <path
        d="M198.333 260.483L338.828 136.406M198.333 260.483L59.1641 136.406M198.333 260.483L254.001 136.406M198.333 260.483V218.244M198.333 260.483L148.63 136.406M198.333 260.483L166.523 218.244M198.333 260.483L234.782 218.244M338.828 136.406V127.826M338.828 136.406H328.224M338.828 127.826L320.272 110.007M338.828 127.826H328.224M320.272 110.007L279.184 90.8672M320.272 110.007L328.224 127.826M279.184 90.8672H234.782M279.184 90.8672L284.485 110.007M118.808 90.8672L77.7199 110.007M118.808 90.8672H166.523M118.808 90.8672L117.104 110.007M77.7199 110.007L59.1641 127.826M77.7199 110.007L71.0928 127.826M59.1641 127.826V136.406M59.1641 127.826H71.0928M59.1641 136.406H71.0928M198.333 110.007L234.782 90.8672M198.333 110.007L254.001 127.826M198.333 110.007L166.523 90.8672M198.333 110.007L148.63 127.826M198.333 110.007V127.826M234.782 90.8672L284.485 110.007M234.782 90.8672H166.523M284.485 110.007L328.224 127.826M284.485 110.007L296.414 127.826M284.485 110.007L254.001 127.826M328.224 127.826H296.414M296.414 127.826H254.001M254.001 127.826H198.333M166.523 90.8672L117.104 110.007M71.0928 127.826L117.104 110.007M71.0928 127.826H102.903M117.104 110.007L102.903 127.826M117.104 110.007L148.63 127.826M102.903 127.826H148.63M148.63 127.826H198.333M71.0928 136.406H102.903M71.0928 136.406L166.523 218.244M102.903 136.406H148.63M102.903 136.406L166.523 218.244M148.63 136.406H198.333M148.63 136.406L198.333 218.244M148.63 136.406L166.523 218.244M198.333 136.406H254.001M198.333 136.406V127.826M198.333 136.406V218.244M254.001 136.406H296.414M254.001 136.406L234.782 218.244M254.001 136.406L198.333 218.244M296.414 136.406H328.224M296.414 136.406L234.782 218.244M328.224 136.406L234.782 218.244"
        stroke="white"
      />
    </svg>
  )
}

export const cycles = {
  rough: RoughLifecycleLargeIcon,
  split: SplitLifecycleLargeIcon,
  polished: PolishedLifecycleLargeIcon,
}
