import { addParams } from 'utils'

import http from '../utils/http'

const getShipmentsIn = ({ page = 1, limit = 1000, sort }) => {
  const sortKey = sort ? Object.keys(sort)[0] : null
  const sortOrder = sort ? (sort[sortKey] === 'desc' ? '-' : '') : null

  return http.get(
    addParams(`shipments/inbound`, { page, size: limit, ...(sort ? { sort: `${sortOrder}${sortKey}` } : null) })
  )
}

const getShipmentIn = (id) => {
  return http.get(`shipments/inbound/${id}`)
}

const getShipmentsOut = ({ page = 1, limit = 1000, sort }) => {
  const sortKey = sort ? Object.keys(sort)[0] : null
  const sortOrder = sort ? (sort[sortKey] === 'desc' ? '-' : '') : null

  return http.get(
    addParams(`shipments/outbound`, { page, size: limit, ...(sort ? { sort: `${sortOrder}${sortKey}` } : null) })
  )
}

const getShipmentOut = (id) => {
  return http.get(`shipments/outbound/${id}`)
}

const validateShipmentOutCSV = (file, shipmentType) => {
  const formData = new FormData()

  formData.append('shipmentType', shipmentType)
  formData.append('diamonds', file)

  return http.post(`shipments/outbound/validate/file`, formData, 'file')
}

const sendNewShipmentOut = (shipment) => {
  return http.post(`shipments/outbound/start`, shipment)
}

const cancelShipmentOutDiamonds = (id, diamonds) => {
  return http.post(`shipments/outbound/${id}/cancel`, diamonds)
}

const acceptShipmentInDiamonds = (id, diamonds) => {
  return http.post(`shipments/inbound/${id}/accept`, diamonds)
}

const rejectShipmentInDiamonds = (id, diamonds) => {
  return http.post(`shipments/inbound/${id}/reject`, diamonds)
}

export const shipments = {
  getShipmentsIn,
  getShipmentIn,
  getShipmentsOut,
  getShipmentOut,
  validateShipmentOutCSV,
  sendNewShipmentOut,
  cancelShipmentOutDiamonds,
  acceptShipmentInDiamonds,
  rejectShipmentInDiamonds,
}

export default shipments
