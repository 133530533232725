export const selectLifecycle = state => {
  return state.lifecycle
}

export const selectLifecycleState = state => {
  return state.lifecycle.state
}

export const selectLifecycleStage = state => {
  return state.lifecycle.stage
}

export const selectAvailableLifecycles = state => {
  return state.lifecycle.available
}
