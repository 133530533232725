import createActionSet from 'utils/createActionSet'

const namespace = 'SHARING'
const GET_SHARES = createActionSet(namespace, 'GET_SHARES')
const GET_SHARES_COUNT = createActionSet(namespace, 'GET_SHARES_COUNT')
const REVOKE_SHARE = createActionSet(namespace, 'REVOKE_SHARE')

const CHANGE_VIEW = `${namespace}_CHANGE_OUTBOX_VIEW`
const SELECT_SHARE = `${namespace}_SELECT_OUTBOX_SHARE`

export const SHARING_ACTION_TYPES = {
  GET_SHARES,
  GET_SHARES_COUNT,
  REVOKE_SHARE,
  CHANGE_VIEW,
  SELECT_SHARE,
}
