import React, { forwardRef } from 'react'

import Card from '../Card'

const Ghost = forwardRef(({ columns, data, media }, ref) => (
  <div
    ref={ref}
    style={{
      position: 'fixed',
      visibility: 'hidden',
      display: 'grid',
      gridTemplateColumns: columns,
      gridGap: '24px',
    }}
  >
    <Card {...data} media={media} {...{ imageSize: { width: null, height: null } }} isGhost />
  </div>
))

Ghost.defaultProps = {
  media: {
    type: 'image',
    value: '/images/card_view_ghost.jpg',
  },
}

export default Ghost
