import React from 'react'
import classnames from 'classnames'

import { Tooltip } from 'components/system'
import { AcceptIcon} from 'components/system/SVG'

import translation from 'text/en'
import classes from './styles.module.css'

/**
 * 
 * @param {className: string} param0 
 * @returns 
 */
export function MatchedLabel({ className, slim }) {
  return (
    <div className={classnames(classes.matchedLabelContainer, className)}>
      <div
          className={classnames(classes.wrapper, {[classes.slim]: slim })}
        >
      <Tooltip
        title={translation.listview.table.tooltips.diamondMatch.cell.title}
        description={translation.listview.table.tooltips.diamondMatch.cell.description}
        inlineFixedWidth={254-32}
        inline
      >
          <div className={classes.icon}><AcceptIcon fill={'#00B707'} /></div>
      </Tooltip>
      </div>
    </div>
  )
}
