import React from 'react'
import { nanoid } from 'nanoid'

import { Table } from 'components/system'
import { TumbleweedIcon } from 'components/Svg'
import EmptyStateOverlay from 'pages/dit/EmptyStateOverlay'
import testids from 'config/testIds'

import classes from './styles.module.css'

export const UnexpectedFilesTab = ({ files, isLoading }) => {
  const testIds = testids.dit
  const columns =
    files.length > 0
      ? [
          { title: 'File name', key: 'name', width: '25%' },
          { title: 'File type', key: 'type', width: '75%' },
        ]
      : []

  const rows = files.map((file, index) => {
    return { id: nanoid(), rowIndex: index, data: { ...file } }
  })

  const isEmpty = files.length === 0 && !isLoading

  const emptyTable = () => {
    return (
      <EmptyStateOverlay
        icon={<TumbleweedIcon />}
        label={'No files to display'}
        subLabel={'Files not detailed in this sessions .CSV  will be displayed here'}
      />
    )
  }

  return (
    <div className={classes.unexpectedFilesTabContainer}>
      <div className={classes.content}>
        <Table
          columns={columns}
          rows={rows}
          grow={false}
          heightSub={64}
          columnTestIdPrefix={testIds.sessionDetailsColumn}
          rowTestId={testIds.unexpectedFilesRow}
          emptyState={emptyTable()}
          isEmpty={isEmpty}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default UnexpectedFilesTab
