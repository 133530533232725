export const selectDiamondIdsToUnlock = (state) => {
  return state.unlock.data.selected
}

export const selectDiamondsToUnlock = (state) => {
  const ids = state.unlock.data.selected
  let diamonds = state.diamonds.data.list.filter((d) => ids.includes(d.diamond_id))

  if (diamonds.length === 0)
    diamonds = state.shipmentsOut.data.newShipment
      ? state.shipmentsOut.data.newShipment.diamonds.filter((d) => ids.includes(d.diamond_id))
      : []

  return diamonds
}

export const selectUnlockVisibleModalName = (state) => {
  return state.unlock.data.visibleModalName
}

export const selectAfterUnlockTarget = (state) => {
  return state.unlock.data.target
}
