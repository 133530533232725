import * as ALL_ACTION_TYPES from '../actionTypes'

export const resolveActionType = (namespace, type) => {
  const ACTIONS = ALL_ACTION_TYPES[namespace] // eslint-disable-line

  if (!ACTIONS) return null

  const resolvedType = Object.keys(ACTIONS).find(key => {
    const setOrSingleAction = ACTIONS[key]

    // ignore sync actions
    if (typeof setOrSingleAction === 'string') return false

    // check if one of action set action types match the provided type
    const hasMatch = Object.keys(setOrSingleAction).find(requestKey => {
      const requestType = setOrSingleAction[requestKey]

      if (requestType === type) return requestKey

      return false
    })

    // return the key of the action set
    if (hasMatch) return key

    return false
  })

  return resolvedType
}
