import React from 'react'
import PropTypes from 'prop-types'

import classes from './styles.module.css'

export const Counter = ({ total, filtered, name = 'Diamond', testId }) => {
  const isFiltered = Number.isInteger(filtered)

  return (
    <div className={classes.counterContainer} data-test-id={testId}>
      {isFiltered ? <span className={classes.number}>{filtered}</span> : null}
      {isFiltered ? ' of ' : null}
      <span className={classes.number}>{total}</span>
      {Number.isInteger(total) ? ` ${name}` + (total !== 1 || isFiltered ? 's' : '') : null}
    </div>
  )
}

Counter.propTypes = {
  total: PropTypes.number,
  filtered: PropTypes.number,
  name: PropTypes.string,
}

export default Counter
