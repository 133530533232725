import React from 'react'

import classnames from 'classnames'

import classes from './styles.module.css'

export const EmptyStateOverlay = ({ icon, label, subLabel, withTabs = true, fullHeight }) => {
  return (
    <div
      className={classes.emptyStateOverlayContainer}
      style={{
        height: withTabs
          ? 'calc(100vh - 72px - 64px - 56px - 48px - 64px)'
          : 'calc(100vh - 72px - 64px - 64px - 59px - 48px)',
      }}
    >
      <div
        className={classnames(classes.elementsContainer, {
          [classes.persistent]: true,
          fullHeight,
        })}
      >
        <div className={classes.overlayInfo}>
          <div>{icon}</div>
          <div className={classes.label}>{label}</div>
          <div className={classes.subLabel}>{subLabel}</div>
        </div>
      </div>
    </div>
  )
}

export default EmptyStateOverlay
