import React from 'react'

import { getPriorityDataPoints } from 'utils/diamond'
import testIds from 'config/testIds'

import classes from './styles.module.css'
import { FlagIcon } from 'components/system'

const NavTitle = ({ diamond }) => {
  const titleParts = `${getPriorityDataPoints(diamond, diamond.lifecycle_state)
    .filter((x) => x !== '-')
    .join(', ')}`

  return (
    <div className={classes.navTitleContainer}>
      <div className={classes.content} data-test-id={testIds.diamondsNav.title}>
        <div className={classes.text}><FlagIcon style={{ height: '24px' }} country={diamond.provenance.provenance_name} />{titleParts}</div>
      </div>
    </div>
  )
}

export default NavTitle
