import { VIEW_ACTION_TYPES, STORE_INIT } from '../actionTypes'

const DEFAULT_VIEW = 'list'

const initialState = DEFAULT_VIEW

const viewReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case STORE_INIT:
      return initialState
    case VIEW_ACTION_TYPES.UPDATE:
      return payload
    default:
      return state
  }
}

export default viewReducer
