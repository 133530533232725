import React from 'react'
import classnames from 'classnames'
import camelCase from 'camelcase'

import { Tooltip } from 'components/system'

import classes from './styles.module.css'
import { testId } from 'utils/testHelper'

const ViewButton = ({ title, icon, feature, selected, disabled, testIdPrefix, onClick }) => {
  return (
    <div className={classes.viewButtonContainer}>
      <Tooltip title={disabled ? 'Feature is disabled' : title}>
        <div
          data-test-id={testId(testIdPrefix, "viewSelector", `${camelCase(feature)}Button`)}
          className={classnames(classes.button, {
            [classes.selected]: selected,
            [classes.disabled]: disabled,
          })}
          onClick={disabled ? null : () => onClick(feature)}
        >
          {icon()}
        </div>
      </Tooltip>
    </div>
  )
}

export default ViewButton
