import React from 'react'

import { ButtonsCell } from 'components/system/TableElements'

export const Buttons = ({ row, ...props }) => {
  const getItems = () => {
    const items = []

    items.push({ key: 'remove', label: 'Remove', type: 'secondary' })

    return items
  }

  return <ButtonsCell row={row} buttons={getItems()} {...props} />
}

export default Buttons
