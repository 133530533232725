import React from 'react'
import classnames from 'classnames'
import { pathOr } from 'ramda'

import classes from './styles.module.css'

export const DiamondPropCell = ({ row, path, mapper, rightAlign, ...props }) => {
  const defaultMapper = (val) => {
    if (val === undefined || val === null) return '-'
    return val
  }

  const lifecycle = row.data.lifecycle_state === 'polished' ? 'polished' : 'rough'
  const value = pathOr(null, [lifecycle, path], row.data)
  const mapperToUse = props.customMapper ? props.customMapper : defaultMapper

  return (
    <div className={classes.diamondPropCellContainer}>
      <div
        className={classnames(classes.content, {
          [classes.rightAlign]: rightAlign,
        })}
      >
        {mapperToUse(value)}
      </div>
    </div>
  )
}

export default DiamondPropCell
