import React from 'react'
import classnames from 'classnames'

import { Button } from 'components/system'

import classes from './styles.module.css'

const NavAction = ({ title, styled, testId, onClick }) => {
  return (
    <div className={classes.navActionContainer}>
      <Button
        className={classnames(classes.button, { [classes.buttonOdd]: styled === 'odd' })}
        onClick={onClick}
        data-test-id={testId}
      >
        {title}
      </Button>
    </div>
  )
}

export default NavAction
