import React from 'react'
import { Other } from 'components/Svg/shapes-new'

const ShapeSVGWrapper = ({ size = 'm', children }) => {
  const sizes = {
    s: { widthHeight: 24 },
    m: { widthHeight: 32 },
    l: { widthHeight: 48 },
  }

  const selected = sizes[size] ? sizes[size] : sizes.m

  return (
    <svg
      width={selected.widthHeight}
      height={selected.widthHeight}
      viewBox={`0 0 ${selected.widthHeight} ${selected.widthHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  )
}

export const PrincessShapeSVG = ({ size = 'm', fill = 'white' }) => {
  const svgs = {
    s: (
      <ShapeSVGWrapper size={size}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.41421 12L12 4.41421L19.5858 12L12 19.5858L4.41421 12ZM3 12L3.70711 11.2929L11.2929 3.70711L12 3L12.7071 3.70711L20.2929 11.2929L21 12L20.2929 12.7071L12.7071 20.2929L12 21L11.2929 20.2929L3.70711 12.7071L3 12ZM9.32339 12.0005L12.0001 9.32379L14.6768 12.0005L12.0001 14.6772L9.32339 12.0005ZM7.90918 12.0005L8.61629 11.2934L11.293 8.61669L12.0001 7.90958L12.7072 8.61669L15.3839 11.2934L16.091 12.0005L15.3839 12.7076L12.7072 15.3843L12.0001 16.0914L11.293 15.3843L8.61629 12.7076L7.90918 12.0005Z"
          fill={fill}
        />
      </ShapeSVGWrapper>
    ),
    m: (
      <ShapeSVGWrapper size={size}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.41421 16L16 5.41421L26.5858 16L16 26.5858L5.41421 16ZM4 16L4.70711 15.2929L15.2929 4.70711L16 4L16.7071 4.70711L27.2929 15.2929L28 16L27.2929 16.7071L16.7071 27.2929L16 28L15.2929 27.2929L4.70711 16.7071L4 16ZM11.9598 16.0007L16.0001 11.9603L20.0404 16.0007L16.0001 20.041L11.9598 16.0007ZM10.5456 16.0007L11.2527 15.2935L15.293 11.2532L16.0001 10.5461L16.7072 11.2532L20.7476 15.2935L21.4547 16.0007L20.7476 16.7078L16.7072 20.7481L16.0001 21.4552L15.293 20.7481L11.2527 16.7078L10.5456 16.0007Z"
          fill={fill}
        />
      </ShapeSVGWrapper>
    ),
    l: (
      <ShapeSVGWrapper size={size}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.41421 24L24 7.41421L40.5858 24L24 40.5858L7.41421 24ZM6 24L6.70711 23.2929L23.2929 6.70711L24 6L24.7071 6.70711L41.2929 23.2929L42 24L41.2929 24.7071L24.7071 41.2929L24 42L23.2929 41.2929L6.70711 24.7071L6 24ZM17.2326 24.001L24.0002 17.2334L30.7678 24.001L24.0002 30.7686L17.2326 24.001ZM15.8184 24.001L16.5255 23.2939L23.2931 16.5263L24.0002 15.8192L24.7073 16.5263L31.4749 23.2939L32.182 24.001L31.4749 24.7081L24.7073 31.4757L24.0002 32.1828L23.2931 31.4757L16.5255 24.7081L15.8184 24.001Z"
          fill={fill}
        />
      </ShapeSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const AsscherShapeSVG = ({ size = 'm', stroke = 'white' }) => {
  const svgs = {
    s: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M3.5 5.69148L6.17848 3.5H17.8215L20.5 5.69148V18.3085L17.8215 20.5H6.17848L3.5 18.3085V5.69148Z"
          stroke={stroke}
        />
        <rect x="9.22705" y="9.22705" width="5.54545" height="5.54545" stroke={stroke} />
      </ShapeSVGWrapper>
    ),
    m: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M4.5 7.50967L8.17848 4.5H23.8215L27.5 7.50967V24.4903L23.8215 27.5H8.17848L4.5 24.4903V7.50967Z"
          stroke={stroke}
        />
        <rect x="12.1361" y="12.1361" width="7.72727" height="7.72727" stroke={stroke} />
      </ShapeSVGWrapper>
    ),
    l: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M6.5 11.146L12.1785 6.5H35.8215L41.5 11.146V36.854L35.8215 41.5H12.1785L6.5 36.854V11.146Z"
          stroke={stroke}
        />
        <rect x="17.9541" y="17.9541" width="12.0909" height="12.0909" stroke={stroke} />
      </ShapeSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const CushionShapeSVG = ({ size = 'm', stroke = 'white' }) => {
  const svgs = {
    s: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M14.4545 3.5H16.0098L18.2754 4.2552L19.7434 5.72321L20.5 8.05219V16.0098L19.7448 18.2754L18.2754 19.7448L16.0098 20.5H7.99023L5.72464 19.7448L4.2552 18.2754L3.5 16.0098V7.99023L4.2552 5.72464L5.72464 4.2552L7.99023 3.5H14.4545Z"
          stroke={stroke}
        />
        <rect
          x="8.61629"
          y="12.186"
          width="5.04839"
          height="5.04839"
          transform="rotate(-45 8.61629 12.186)"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
    m: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M19.2727 4.5H21.3734L24.4572 5.52792L26.4706 7.54139L27.5 10.7099V21.3734L26.4721 24.4572L24.4572 26.4721L21.3734 27.5H10.6266L7.54282 26.4721L5.52792 24.4572L4.5 21.3734V10.6266L5.52793 7.54282L7.54282 5.52793L10.6266 4.5H19.2727Z"
          stroke={stroke}
        />
        <rect
          x="11.2527"
          y="16.248"
          width="7.06452"
          height="7.06452"
          transform="rotate(-45 11.2527 16.248)"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
    l: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M28.9091 6.5H32.1007L36.8208 8.07338L39.9252 11.1778L41.5 16.0252V32.1007L39.9266 36.8208L36.8208 39.9266L32.1007 41.5H15.8993L11.1792 39.9266L8.07338 36.8208L6.5 32.1007V15.8993L8.07338 11.1792L11.1792 8.07338L15.8993 6.5H28.9091Z"
          stroke={stroke}
        />
        <rect
          x="16.5255"
          y="24.3721"
          width="11.0968"
          height="11.0968"
          transform="rotate(-45 16.5255 24.3721)"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const EmeraldShapeSVG = ({ size = 'm', stroke = 'white' }) => {
  const svgs = {
    s: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M5 5.66165L7.16165 3.5H16.5656L18.7273 5.66165V18.3383L16.5656 20.5H7.16165L5 18.3383V5.66165Z"
          stroke={stroke}
        />
        <rect x="9" y="8.40918" width="5.54545" height="7.18182" stroke={stroke} />
      </ShapeSVGWrapper>
    ),
    m: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M6.5 7.47983L9.47983 4.5H22.1565L25.1364 7.47983V24.5202L22.1565 27.5H9.47983L6.5 24.5202V7.47983Z"
          stroke={stroke}
        />
        <rect x="11.8333" y="11.0455" width="7.72727" height="9.90909" stroke={stroke} />
      </ShapeSVGWrapper>
    ),
    l: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M9.5 11.1162L14.1162 6.5H33.3383L37.9545 11.1162V36.8838L33.3383 41.5H14.1162L9.5 36.8838V11.1162Z"
          stroke="white"
        />
        <rect x="17.5" y="16.3184" width="12.0909" height="15.3636" stroke={stroke} />
      </ShapeSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const HeartShapeSVG = ({ size = 'm', stroke = 'white' }) => {
  const svgs = {
    s: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M3.76596 10.7447L11.8085 19.9362L20.617 10.7447L21 7.68085L18.7021 5H14.8723L11.8085 7.68085L9.12766 5H5.29787L3 7.68085L3.76596 10.7447Z"
          stroke={stroke}
        />
        <path d="M11.8083 11.5104L6.82959 8.82959L11.8083 14.9572L16.787 8.82959L11.8083 11.5104Z" stroke="white" />
      </ShapeSVGWrapper>
    ),
    m: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M5.02128 14.3262L15.7447 26.5815L27.4894 14.3262L28 10.2411L24.9362 6.66663H19.8298L15.7447 10.2411L12.1702 6.66663H7.06383L4 10.2411L5.02128 14.3262Z"
          stroke={stroke}
        />
        <path d="M15.7444 15.3472L9.10608 11.7727L15.7444 19.9429L22.3827 11.7727L15.7444 15.3472Z" stroke="white" />
      </ShapeSVGWrapper>
    ),
    l: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M7.53191 21.4894L23.617 39.8723L41.234 21.4894L42 15.3617L37.4043 10H29.7447L23.617 15.3617L18.2553 10H10.5957L6 15.3617L7.53191 21.4894Z"
          stroke={stroke}
        />
        <path d="M23.6166 23.0209L13.6592 17.6592L23.6166 29.9145L33.5741 17.6592L23.6166 23.0209Z" stroke="white" />
      </ShapeSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const MarquiseShapeSVG = ({ size = 'm', stroke = 'white' }) => {
  const svgs = {
    s: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M19.1579 8.47354V15.5265L12.0789 20.3932L5 15.5265V8.47354L12.0789 3.60677L19.1579 8.47354Z"
          stroke={stroke}
        />
        <path
          d="M14.8454 10.9183V13.0821L12.0727 15.0671L9.29999 13.0821V10.9183L12.0727 8.93328L14.8454 10.9183Z"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
    m: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M25.7105 11.2104V20.7896L16.1053 27.3932L6.5 20.7896V11.2104L16.1053 4.60677L25.7105 11.2104Z"
          stroke={stroke}
        />
        <path
          d="M19.9606 14.4721V17.5284L16.097 20.2944L12.2333 17.5284V14.4721L16.097 11.7061L19.9606 14.4721Z"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
    l: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M38.8158 16.6841V31.3159L24.1579 41.3932L9.5 31.3159V16.6841L24.1579 6.60677L38.8158 16.6841Z"
          stroke={stroke}
        />
        <path
          d="M30.1909 21.5796V26.4211L24.1455 30.7491L18.1 26.4211V21.5796L24.1455 17.2516L30.1909 21.5796Z"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const OvalShapeSVG = ({ size = 'm', stroke = 'white' }) => {
  const svgs = {
    s: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M16.1876 5.33412L18.0217 9.36917V14.6308L16.1876 18.6659L12.2609 20.4508L8.33412 18.6659L6.5 14.6308V8.58437L7.94128 5.3415L12.2522 3.54528L16.1876 5.33412Z"
          stroke={stroke}
        />
        <path
          d="M13.4974 9.49166L14.1087 10.8365V12.6742L13.4974 14.0191L12.2609 14.5811L11.0244 14.0191L10.4131 12.6742V10.5408L10.8761 9.49904L12.2522 8.92565L13.4974 9.49166Z"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
    m: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M21.7094 6.98629L24.1957 12.4561V19.5439L21.7094 25.0137L16.3478 27.4508L10.9863 25.0137L8.5 19.5439V11.4105L10.463 6.99367L16.3391 4.54528L21.7094 6.98629Z"
          stroke={stroke}
        />
        <path
          d="M18.1225 12.5297L18.9783 14.4126V16.9351L18.1225 18.818L16.3479 19.6246L14.5733 18.818L13.7175 16.9351V14.0191L14.3761 12.5371L16.3392 11.7191L18.1225 12.5297Z"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
    l: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M32.7528 10.2906L36.5435 18.63V29.37L32.7528 37.7094L24.5217 41.4508L16.2906 37.7094L12.5 29.37V17.0626L15.5065 10.298L24.5131 6.54528L32.7528 10.2906Z"
          stroke={stroke}
        />
        <path
          d="M27.3725 18.6057L28.7175 21.5647V25.4568L27.3725 28.4158L24.5218 29.7115L21.6712 28.4158L20.3262 25.4568V20.9755L21.3761 18.6131L24.5131 17.306L27.3725 18.6057Z"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const PearShapeSVG = ({ size = 'm', stroke = 'white' }) => {
  const svgs = {
    s: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M5.53769 13.8237L12.3636 3.88328L19.1896 13.8237L18.4471 16.9514L16.5716 19.4096L12.3636 20.484L8.15572 19.4096L6.28013 16.9514L5.53769 13.8237Z"
          stroke={stroke}
        />
        <path
          d="M10.4502 14.2887L12.3636 11.6289L14.2771 14.2887L14.0866 15.0546L13.5795 15.689L12.3636 15.9853L11.1477 15.689L10.6406 15.0546L10.4502 14.2887Z"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
    m: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M7.20432 18.3981L16.4848 4.88328L25.7653 18.3981L24.7501 22.6748L22.1927 26.0266L16.4848 27.484L10.7769 26.0266L8.21948 22.6748L7.20432 18.3981Z"
          stroke={stroke}
        />
        <path
          d="M13.7531 19.017L16.4848 15.2198L19.2164 19.017L18.935 20.1485L18.2007 21.0672L16.4848 21.4854L14.7688 21.0672L14.0345 20.1485L13.7531 19.017Z"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
    l: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M10.5377 27.5471L24.7273 6.88328L38.9169 27.5471L37.3562 34.1216L33.4352 39.2607L24.7273 41.484L16.0194 39.2607L12.0983 34.1216L10.5377 27.5471Z"
          stroke={stroke}
        />
        <path
          d="M20.3592 28.4734L24.7272 22.4016L29.0952 28.4734L28.632 30.336L27.4431 31.8234L24.7272 32.4853L22.0113 31.8234L20.8224 30.336L20.3592 28.4734Z"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const RadiantShapeSVG = ({ size = 'm', stroke = 'white' }) => {
  const svgs = {
    s: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M3.5 5.69148L6.17848 3.5H17.8215L20.5 5.69148V18.3085L17.8215 20.5H6.17848L3.5 18.3085V5.69148Z"
          stroke={stroke}
        />
        <rect x="7.79793" y="12" width="5.9425" height="5.9425" transform="rotate(-45 7.79793 12)" stroke={stroke} />
      </ShapeSVGWrapper>
    ),
    m: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M4.5 7.50967L8.17848 4.5H23.8215L27.5 7.50967V24.4903L23.8215 27.5H8.17848L4.5 24.4903V7.50967Z"
          stroke={stroke}
        />
        <rect x="10.1615" y="16" width="8.25667" height="8.25667" transform="rotate(-45 10.1615 16)" stroke={stroke} />
      </ShapeSVGWrapper>
    ),
    l: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M6.5 11.146L12.1785 6.5H35.8215L41.5 11.146V36.854L35.8215 41.5H12.1785L6.5 36.854V11.146Z"
          stroke={stroke}
        />
        <rect x="14.8887" y="24" width="12.885" height="12.885" transform="rotate(-45 14.8887 24)" stroke={stroke} />
      </ShapeSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const RoundShapeSVG = ({ size = 'm', stroke = 'white' }) => {
  const svgs = {
    s: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M9.90453 9.1158L12.0001 8.43491L14.0956 9.1158L15.3908 10.8984V13.1018L14.0956 14.8844L12.0001 15.5653L9.90453 14.8844L8.6094 13.1018V10.8984L9.90453 9.1158Z"
          stroke={stroke}
        />
        <path
          d="M7.01895 5.14417L12 3.52573L16.9811 5.14417L20.0595 9.38131V14.6187L16.9811 18.8558L12 20.4743L7.01895 18.8558L3.94049 14.6187V9.38131L7.01895 5.14417Z"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
    m: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M13.1031 12.0126L16.0001 11.0713L18.8972 12.0126L20.6877 14.477V17.5232L18.8972 19.9876L16.0001 20.9289L13.1031 19.9876L11.3126 17.5232V14.477L13.1031 12.0126Z"
          stroke={stroke}
        />
        <path
          d="M9.25559 6.71712L16 4.52573L22.7444 6.71712L26.9127 12.4543V19.5457L22.7444 25.2829L16 27.4743L9.25559 25.2829L5.08732 19.5457V12.4543L9.25559 6.71712Z"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
    l: (
      <ShapeSVGWrapper size={size}>
        <path
          d="M19.5 17.8063L24.0002 16.3441L28.5003 17.8063L31.2815 21.6343V26.366L28.5003 30.1941L24.0002 31.6563L19.5 30.1941L16.7188 26.366V21.6343L19.5 17.8063Z"
          stroke={stroke}
        />
        <path
          d="M13.7289 9.86302L24 6.52573L34.2711 9.86302L40.619 18.6002V29.3998L34.2711 38.137L24 41.4743L13.7289 38.137L7.38098 29.3998V18.6002L13.7289 9.86302Z"
          stroke={stroke}
        />
      </ShapeSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const OtherShapeSVG = ({ size = 'm', stroke = 'white' }) => {
  const svgs = {
    s: <Other width={24} height={24} stroke={stroke} fill={stroke} />,
    m: <Other width={32} height={32} stroke={stroke} fill={stroke} />,
    l: <Other width={48} height={48} stroke={stroke} fill={stroke} />,
  }

  return svgs[size] ? svgs[size] : svgs.m
}
