import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import CardTop from '../CardTop'
import CardMiddle from '../CardMiddle'
import CardBottom from '../CardBottom'
import CardActions from '../CardActions'

import classes from './styles.module.css'

const Card = ({
  id,
  diamond_id,
  selected,
  unlock_status,
  media,
  imageSize,
  points,
  icon,
  isGhost,
  actions,
  onClick,
  onCheckboxChange,
}) => {
  const handleClick = () => (onClick ? onClick(diamond_id) : null)

  return (
    <div className={classes.cardContainer} onClick={handleClick}>
      <div className={classnames(classes.content, { [classes.selected]: selected })}>
        <CardTop {...{ id, diamond_id, unlock_status, selected, onCheckboxChange }} />
        <CardMiddle {...{ id, diamond_id, media, imageSize }} isGhost={isGhost} />
        <CardBottom {...{ points, icon }} />
      </div>
      <CardActions actions={actions} />
    </div>
  )
}

Card.propTypes = {
  id: PropTypes.string.isRequired,
  diamond_id: PropTypes.string.isRequired,
  isGhost: PropTypes.bool,
  imageSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  onCheckboxChange: PropTypes.func,
  onClick: PropTypes.func,
  selected: PropTypes.bool.isRequired,
  benchmark: PropTypes.shape({
    status: PropTypes.string.isRequired,
    time: PropTypes.number.isRequired,
  }).isRequired,
  status: PropTypes.shape({
    value: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    tooltip: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }),
  }).isRequired,
  media: PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  icon: PropTypes.string.isRequired,
  points: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      events: PropTypes.shape({
        onClick: PropTypes.func,
      }),
      text: PropTypes.string.isRequired,
      disabled: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
}

export default Card
