import { nanoid } from 'nanoid'

import config from 'config/notifications'

class NotificationsController {
  constructor() {
    this.listener = null
    this.list = []

    this.add = this.add.bind(this)
    this.registerListener = this.registerListener.bind(this)
  }

  /**
   *
   * @param {id, title, bodyText, variant = 'info|success|error'} params
   */
  add(params) {
    const id = nanoid()

    this.list.push({
      ...params,
      id,
      config: {
        ...config,
        ...params.config,
      },
      onClose: () => {
        this.remove(id)

        if (params.onClose) params.onClose()
      },
    })

    if (this.listener) this.listener(this.list)
  }

  remove(id) {
    const index = this.list.findIndex((item) => item.id === id)

    if (index < 0) return

    this.list.splice(index, 1)

    this.listener(this.list)
  }

  registerListener(callback) {
    this.listener = callback
  }
}

const notificationsController = new NotificationsController()

/**
 *
 * @param {id, title, bodyText, variant = 'info'} params
 */
const showNotification = notificationsController.add

export { notificationsController }

export default showNotification
