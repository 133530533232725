import React from 'react'
import PropTypes from 'prop-types'
import Card from '../Card'

const CardRow =
  (rows, columns, imageSize, onCardClick, onCardCheckboxChange) =>
  ({ key, index, style }) => {
    return (
      <div
        key={key}
        style={{
          ...style,
          display: 'grid',
          gridTemplateColumns: columns,
          gridGap: '24px',
        }}
      >
        {rows[index].map((item, index) => (
          <Card
            {...item}
            key={`card-${index}`}
            imageSize={imageSize}
            onClick={onCardClick}
            onCheckboxChange={onCardCheckboxChange}
          />
        ))}
      </div>
    )
  }

CardRow.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.string.isRequired,
  imageSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  onCardClick: PropTypes.func,
  onCardCheckboxChange: PropTypes.func,
}

export default CardRow
