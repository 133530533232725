import { clone } from 'ramda'
import messages from 'text/messages'
import { SHARING_ACTION_TYPES } from '../actionTypes'

const initialState = {
  isLoading: null,
  isTotalLoading: null,
  error: null,
  data: {
    totalCount: null,
    list: [],
    view: 'activeShares', // outbox state toggle
    selectedShare: null, // used in outbox share details popup
  },
}

const sharingReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case SHARING_ACTION_TYPES.GET_SHARES.REQUEST:
    case SHARING_ACTION_TYPES.REVOKE_SHARE.REQUEST: {
      const newState = clone(state)
      return { ...newState, isLoading: true, error: null }
    }
    case SHARING_ACTION_TYPES.GET_SHARES_COUNT.REQUEST: {
      const newState = clone(state)
      return { ...newState, isTotalLoading: true, error: null }
    }
    case SHARING_ACTION_TYPES.GET_SHARES.FAILURE: {
      const newState = clone(state)
      return {
        ...newState,
        isLoading: false,
        error: payload.error || messages.login.failure,
      }
    }
    case SHARING_ACTION_TYPES.GET_SHARES_COUNT.FAILURE: {
      const newState = clone(state)
      return {
        ...newState,
        isTotalLoading: false,
        error: payload.error || messages.login.failure,
      }
    }
    case SHARING_ACTION_TYPES.REVOKE_SHARE.FAILURE: {
      const newState = clone(state)
      // TODO -> show notification
      return { ...newState, isLoading: false, error: null }
    }
    case SHARING_ACTION_TYPES.GET_SHARES.COMPLETE: {
      const newState = clone(state)
      return {
        error: null,
        isTotalLoading: newState.isTotalLoading,
        isLoading: false,
        data: {
          ...newState.data,
          list: payload,
        },
      }
    }
    case SHARING_ACTION_TYPES.GET_SHARES_COUNT.COMPLETE: {
      const newState = clone(state)
      return {
        error: null,
        isLoading: newState.isLoading,
        isTotalLoading: false,
        data: {
          ...newState.data,
          totalCount: payload,
        },
      }
    }

    case SHARING_ACTION_TYPES.REVOKE_SHARE.COMPLETE: {
      const newState = clone(state)
      // filter out revoked share
      const list = newState.data.list.filter(share => share.id !== payload)

      return {
        error: null,
        isLoading: false,
        isTotalLoading: newState.isTotalLoading,
        data: {
          ...newState.data,
          totalCount: newState.data.totalCount - 1,
          list,
        },
      }
    }

    case SHARING_ACTION_TYPES.CHANGE_VIEW: {
      const newState = clone(state)

      return {
        error: null,
        isLoading: true,
        isTotalLoading: newState.isTotalLoading,
        data: {
          ...newState.data,
          list: [],
          view: payload,
        },
      }
    }

    case SHARING_ACTION_TYPES.SELECT_SHARE: {
      const newState = clone(state)

      return {
        error: null,
        isLoading: false,
        isTotalLoading: newState.isTotalLoading,
        data: {
          ...newState.data,
          selectedShare: state.data.list.find(share => share.id === payload),
        },
      }
    }
    default:
      return state
  }
}

export default sharingReducer
