import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import {
  selectDiamondsToShareAction,
  selectDiamondsToUnlockAction,
  setTargetAction,
  setUnlockModalVisibleAction,
} from 'store/actions'
import { selectDiamonds, selectSelectedDiamonds } from 'store/selectors'
import ShareModal from 'containers/ShareModal'
import { getLockedIds } from 'utils/diamond'
import featureToggles from 'config/featureToggles'
import testIds from 'config/testIds'

import NavWrapper from './Components'
import NavAction from './Components/NavAction'

const DiamondNav = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const diamonds = useSelector(selectDiamonds)
  const selectedDiamondIDs = useSelector(selectSelectedDiamonds)

  const getButtonText = () => {
    const count = selectedDiamondIDs.includes(id) ? selectedDiamondIDs.length || 1 : 1
    return `Share ${count} ${count > 1 ? 'Diamonds' : 'Diamond'}`
  }

  const handleShare = () => {
    const ids = selectedDiamondIDs.length ? selectedDiamondIDs : [id]
    const lockedIds = getLockedIds(diamonds, ids)

    if (lockedIds.length) {
      dispatch(selectDiamondsToUnlockAction(lockedIds))
      dispatch(setTargetAction('share'))
      dispatch(setUnlockModalVisibleAction(lockedIds.length > 1 ? 'multiple' : 'singular'))
    } else {
      dispatch(selectDiamondsToShareAction(ids))
    }
  }

  return (
    <>
      <NavWrapper testId={testIds.diamondExperience.title}>
        {featureToggles.share === 2 ? (
          <NavAction title={getButtonText()} onClick={handleShare} testId={testIds.diamondExperience.actions.share} />
        ) : null}
      </NavWrapper>

      {featureToggles.share === 2 ? <ShareModal /> : null}
    </>
  )
}

export default DiamondNav
