import React from 'react'
import classnames from 'classnames'

import { ItemArrowRightIcon } from 'components/Svg/nav'

import classes from './styles.module.css'

export const LinkWithChildren = ({ route, navIsCollapsed, isActive, isExpanded, handleToggle }) => {
  const isDisabled = route.disabled

  return (
    <div
      className={classnames(classes.linkWithChildrenContainer, {
        [classes.active]: isActive && !isDisabled,
        [classes.disabled]: isDisabled,
        [classes.closed]: navIsCollapsed,
        [classes.activeWithChildren]: isActive && !isDisabled,
      })}
      onClick={() => !isDisabled && handleToggle()}
      data-test-id={`navigationBar.${route.feature}Button`}
    >
      <div className={classes.icon}>{route.icon ? <route.icon /> : null}</div>

      {!navIsCollapsed ? <div className={classes.text}>{route.title}</div> : null}
      {!navIsCollapsed ? (
        <div className={classnames(classes.expandIcon, { [classes.isExpanded]: isExpanded })}>
          <ItemArrowRightIcon />
        </div>
      ) : null}
    </div>
  )
}

export default LinkWithChildren
