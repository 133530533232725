import env from '@beam-australia/react-env'

import { Navigate } from 'react-router-dom'

import {
  InventoryIcon,
  IntegrationIcon,
  LogoutIcon,
  DITIcon,
  MonitoringIcon,
  CollapseIcon,
  ExpandIcon,
  SupportIcon,
  TransferIcon,
  TransferInIcon,
  TransferOutIcon,
} from 'components/Svg/nav'
import { Rough, Split, Planning, Sawing, Bruting, Polishing, Polished, Graded } from 'components/Svg/lifecycle'

import DefaultLayout from 'containers/Layout/Default'
import { ProtectedRoute } from 'containers/Navigators'
import Login from 'pages/login'
import ListView from 'pages/listView'
import CardView from 'pages/cardView'
import DiamondExperience from 'pages/diamondExperience'
import DITPage from 'pages/dit'
import { SessionHistoryPage, ReuploadCSVPage, SessionPage, SessionCompletePage } from 'pages/dit/Pages'
import MonitoringPage from 'pages/monitoring'
import ShipmentsIn from 'pages/shipmentsIn'
import {
  Sessions as ShipmentsInSessions,
  ArchiveShipment as ShipmentsInArchiveShipment,
  ConfirmOwnership,
} from 'pages/shipmentsIn/Pages'
import ShipmentsOut from 'pages/shipmentsOut'
import {
  Sessions as ShipmentsOutSessions,
  ActiveShipment as ShipmentsOutActiveShipment,
  ArchiveShipment as ShipmentsOutArchiveShipment,
  NewShipment,
} from 'pages/shipmentsOut/Pages'
import Support from 'pages/support'
import Logout from 'containers/Logout'

const PARTICIPANT_TYPE = env('PLATFORM_TYPE') ? env('PLATFORM_TYPE').toLowerCase() : null

const getDefaultRoute = () => {
  let defaultRoute = env('DEFAULT_ROUTE')
  if (defaultRoute) {
    return defaultRoute
  } else if (PARTICIPANT_TYPE === 'retailer') {
    return '/inventory/polished/list'
  } else {
    return '/inventory/rough/list'
  }
}

// We have some logic that sets the default route based on participant type.
// This works the you first login, but if you hit the default route the page
// loaded comes from the order of the routes in the router.
// So we need some mechanism of prioritising the routes.
// For lack of a better solution, we can make it so that the polished list gets
// bubbled to the top of the list if the participant is a retailer.
// In the future we can extend this to set priorities against each route based
// on the participant type.
const routeComparator = (a, b) => {
  let prioritisedFeature = 'rough'
  if (PARTICIPANT_TYPE === 'retailer') {
    prioritisedFeature = 'polished'
  }

  if (a.feature === prioritisedFeature) {
    return -1
  } else if (b.feature === prioritisedFeature) {
    return 1
  }

  return 0
}

const commonRoutes = [
  {
    title: 'List',
    path: 'list',
    feature: 'list',
    hasView: true,
    element: <ListView />,
  },
  {
    title: 'Cards',
    path: 'cards',
    feature: 'cards',
    hasView: true,
    element: <CardView />,
  },
  {
    title: 'Diamond Experience',
    path: 'diamond/:id',
    hasView: true,
    element: <DiamondExperience />,
  },
]

const routes = [
  {
    path: '/login',
    element: <Login />,
    hasView: true,
  },
  {
    element: <ProtectedRoute withDefaultNavigation />,
    children: [
      {
        path: '/',
        element: <DefaultLayout />,
        menuRoot: true,
        children: [
          {
            title: 'Inventory',
            path: 'inventory',
            feature: 'inventory',
            icon: InventoryIcon,
            children: [
              {
                title: 'Rough',
                path: 'rough',
                feature: 'rough',
                icon: Rough,
                children: [...commonRoutes],
              },
              {
                title: 'Split',
                path: 'split',
                feature: 'split',
                icon: Split,
                children: [...commonRoutes],
              },
              {
                title: 'Planning',
                path: 'planning',
                feature: 'planning',
                icon: Planning,
                children: [...commonRoutes],
              },
              {
                title: 'Sawing',
                path: 'sawing',
                feature: 'sawing',
                icon: Sawing,
                children: [...commonRoutes],
              },
              {
                title: 'Bruting',
                path: 'bruting',
                feature: 'bruting',
                icon: Bruting,
                children: [...commonRoutes],
              },
              {
                title: 'Polishing',
                path: 'polishing',
                feature: 'polishing',
                icon: Polishing,
                children: [...commonRoutes],
              },
              {
                title: 'Polished',
                path: 'polished',
                feature: 'polished',
                icon: Polished,
                children: [...commonRoutes],
              },
              {
                title: 'Graded',
                path: 'graded',
                feature: 'graded',
                icon: Graded,
                children: [...commonRoutes],
              },
            ].sort(routeComparator),
          },
          {
            title: 'Integration',
            path: 'integration',
            feature: 'integration',
            icon: IntegrationIcon,
            children: [
              {
                title: 'Monitoring',
                path: 'monitoring',
                feature: 'monitoring',
                hasView: true,
                element: <MonitoringPage />,
                icon: MonitoringIcon,
                children: [
                  {
                    title: 'Monitoring',
                    path: ':id',
                    feature: 'monitoring',
                    hasView: true,
                    element: <MonitoringPage />,
                    icon: MonitoringIcon,
                  },
                ],
              },
              {
                title: 'Integration Tool',
                path: 'dit',
                feature: 'dit',
                hasView: true,
                element: <DITPage />,
                icon: DITIcon,
                children: [
                  { title: 'Sessions', path: '', hasView: true, element: <SessionHistoryPage /> },
                  { title: 'New session', path: 'new-session', hasView: true, element: <SessionHistoryPage /> },
                  {
                    title: 'New session - validation',
                    path: 'new-session/validation',
                    hasView: true,
                    element: <ReuploadCSVPage />,
                  },
                  { title: 'Session', path: ':id', hasView: true, element: <SessionPage /> },
                  { title: 'Session complete', path: ':id/complete', hasView: true, element: <SessionCompletePage /> },
                ],
              },
            ],
          },
          {
            title: 'Shipments',
            path: 'shipments',
            icon: TransferIcon,
            feature: 'shipments',
            children: [
              {
                title: 'Inbound',
                path: 'inbound',
                icon: TransferInIcon,
                feature: 'shipmentsIn',
                hasView: true,
                element: <ShipmentsIn />,
                children: [
                  {
                    path: '',
                    hasView: true,
                    element: <ShipmentsInSessions />,
                  },
                  {
                    path: 'pending/:id',
                    hasView: true,
                    element: <ConfirmOwnership />,
                  },
                  {
                    path: 'archive/:id',
                    hasView: true,
                    element: <ShipmentsInArchiveShipment />,
                  },
                ],
              },
              {
                title: 'Outbound',
                path: 'outbound',
                icon: TransferOutIcon,
                feature: 'shipmentsOut',
                hasView: true,
                element: <ShipmentsOut />,
                children: [
                  {
                    path: '',
                    hasView: true,
                    element: <ShipmentsOutSessions />,
                  },
                  {
                    title: 'New shipment',
                    path: 'preview',
                    hasView: true,
                    element: <NewShipment />,
                  },
                  {
                    title: 'Outbound',
                    path: 'pending/:id',
                    hasView: true,
                    element: <ShipmentsOutActiveShipment />,
                  },
                  {
                    title: 'Outbound',
                    path: 'archive/:id',
                    hasView: true,
                    element: <ShipmentsOutArchiveShipment />,
                  },
                ],
              },
            ],
          },
          {
            title: 'collapse', // keep as used for key properties
            feature: 'collapse',
            placeAtBottom: true,
            isStatic: true,
            collapseSettings: {
              open: {
                title: 'Collapse menu',
                icon: CollapseIcon,
              },
              closed: {
                title: 'Expand menu',
                icon: ExpandIcon,
              },
            },
          },
          {
            title: 'Support',
            path: 'support',
            feature: 'support',
            hasView: true,
            element: <Support />,
            icon: SupportIcon,
            placeAtBottom: true,
          },
          {
            title: 'Logout',
            path: 'logout',
            hasView: true,
            element: <Logout />,
            icon: LogoutIcon,
            placeAtBottom: true,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={'/'} />,
  },
]

export { getDefaultRoute, routeComparator }

export default routes
