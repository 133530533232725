import axios from 'axios'
import env from '@beam-australia/react-env'

class Http {
  constructor() {
    this.API_URL = env('API_URL') || 'http://localhost:4000/api/v1'
  }

  getBaseUrl() {
    return this.API_URL
  }

  setAuthorization(value, type = 'JWT') {
    if (!value) {
      delete axios.defaults.headers.common.AuthorizationType
      delete axios.defaults.headers.common.Authorization
      return
    }

    if (type === 'JWT') {
      axios.defaults.headers.common.AuthorizationType = 'JWT'
      axios.defaults.headers.common.Authorization = `Bearer ${value}`
    } else {
      // used when viewing diamond via shared link
      axios.defaults.headers.common.AuthorizationType = 'ShareId'
      axios.defaults.headers.common.Authorization = `Id ${value}`
    }
  }

  validateBody(body, format) {
    // let all formats other than json be handled by axios
    if ((format && format !== 'json') || !body) return body

    const parsed = JSON.stringify(body)

    // do not send empty data to BE
    if (parsed.length === 0 || parsed === '{}' || parsed === '[]') return undefined

    return body
  }

  get(url, query, isBinary) {
    return axios.get(`${this.API_URL}/${url}`, {
      params: query,
      ...(isBinary ? { responseType: 'arraybuffer' } : null),
    })
  }

  post(url, body, format) {
    const validatedBody = this.validateBody(body, format)

    return axios.post(`${this.API_URL}/${url}`, validatedBody)
  }

  put(url, body, format) {
    const validatedBody = this.validateBody(body, format)

    return axios.put(`${this.API_URL}/${url}`, validatedBody)
  }

  delete(url, body, format) {
    const validatedBody = this.validateBody(body, format)

    return axios.delete(`${this.API_URL}/${url}`, validatedBody)
  }

  downloadFile(url, filename, inBlankPage, method = 'get', body = {}) {
    return axios({
      method,
      url: `${this.API_URL}/${url}`,
      data: body,
      responseType: 'blob',
      timeout: 55000,
    }).then((blob) => {
      const url = window.URL.createObjectURL(blob.data)
      const a = document.createElement('a')
      document.body.appendChild(a)

      a.style.display = 'none'
      a.href = url

      if (inBlankPage) {
        a.target = '_blank'
      } else {
        a.download = filename
      }

      a.click()

      window.URL.revokeObjectURL(url)

      document.body.removeChild(a)
    })
  }

  /* USED ONLY BY DIT, DO NOT USE IT ANYWHERE */
  requestWithResponse(method, endpoint, config = {}) {
    return axios({
      method,
      url: config.url,
      data: config.data,
      headers: { Pragma: 'no-cache', ...config.headers },
    }).catch((error) => {
      if (error && error.response && error.response.status === 401) {
        localStorage.removeItem('tracr-portal-ui_session')
        window.location.pathname = '/'
      } else {
        throw error
      }
    })
  }

  requestWithResponseBlob(method, endpoint, config = {}) {
    return axios({
      method,
      url: config.url,
      data: config.data,
      headers: { Pragma: 'no-cache' },
      responseType: 'blob',
    })
  }
}

const http = new Http()

export default http
