import createActionSet from 'utils/createActionSet'

const namespace = 'SEARCH'
const DIAMOND_SEARCH = createActionSet(namespace, 'DIAMOND_SEARCH')
const CLEAR_SEARCH = `${namespace}_CLEAR_SEARCH`

export const SEARCH_ACTION_TYPES = {
  DIAMOND_SEARCH,
  CLEAR_SEARCH,
}
