import React from 'react'
import classnames from 'classnames'

import { RadioSVG, RadioCheckedSVG } from 'components/Svg'
import testids from 'config/testIds'

import classes from './styles.module.css'

export const Radio = ({ option, label, onSelect, checked }) => {
  const testIds = testids.dit.quitSessionModal

  return (
    <div className={classes.radioItemContainer} onClick={() => onSelect(option)} data-test-id={testIds.selection}>
      {checked && <RadioCheckedSVG colour="#18BC98" />}
      {!checked && <RadioSVG colour="#C0C0C0" />}
      <span className={classnames({ [classes.selected]: checked })}>{label}</span>
    </div>
  )
}

export default Radio
