import React, { useEffect, useState, memo } from 'react'
import PropTypes from 'prop-types'
import { uniqBy } from 'lodash'
import { nanoid } from 'nanoid'

import Tag from './Tag'
import classes from './styles.module.css'

const EMAIL_REGEXP =
  /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/ // eslint-disable-line no-control-regex

const validateEmail = (value) => !!value.match(EMAIL_REGEXP) && value.match(EMAIL_REGEXP)[0] === value

export const TagInput = ({
  placeholder,
  allowDuplicates,
  allowEditing,
  delimiters,
  validation,
  onUpdate,
  onFocus,
  onBlur,
  type = 'text',
}) => {
  const [tags, setTags] = useState([])

  if (type === 'email') {
    validation = validateEmail
  }

  const submitTag = (eventValue) => {
    const value = eventValue.trim()

    if (value !== '') {
      const allValues = [
        ...tags,
        {
          id: nanoid(),
          value,
          isValid: validation ? validation(value) : true,
          isEditable: allowEditing,
        },
      ]
      const valuesDeduplicated = allowDuplicates ? allValues : uniqBy(allValues, (x) => x.value)

      setTags(valuesDeduplicated)
    }
  }

  const handleKeyDown = (event) => {
    if (delimiters.map((d) => d.value).includes(event.key)) {
      if (event.target.value.trim() !== '') {
        event.preventDefault()

        submitTag(event.target.value)
        event.target.value = ''
      }
    }

    if (['Backspace'].includes(event.key)) {
      if (event.target.value.trim() === '' && tags.length > 0) {
        handleTagRemoved(tags[tags.length - 1].id)
      }
    }
  }

  const handleBlur = (event) => {
    submitTag(event.target.value)
    event.target.value = ''
    if (onBlur) {
      onBlur()
    }
  }

  const handleTagRemoved = (id) => {
    setTags(tags.filter((tag) => tag.id !== id))
  }

  const handleTagUpdated = (id, value) => {
    setTags(
      tags.map((tag) => {
        if (tag.id === id) {
          return {
            ...tag,
            value,
            isValid: validation ? validation(value) : true,
          }
        }

        return tag
      })
    )
  }

  useEffect(() => {
    if (onUpdate) {
      onUpdate(tags)
    }
    // Ideally we would include onUpdate in the dependencies so that if the handler changed we would refire the tags.
    // However it causes this hook to be called on every page re-render and is a niche use case so leave it out.
  }, [tags]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.tagInputContainer}>
      <div className={classes.wrapper}>
        {tags.map((tag) => (
          <Tag key={tag.id} {...tag} delimiters={delimiters} onDelete={handleTagRemoved} onChange={handleTagUpdated} />
        ))}
        <div className={classes.inputWrapper}>
          <input
            type={type}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onFocus={onFocus}
            placeholder={tags.length > 0 ? '' : placeholder}
            classes={{
              root: classes.root,
            }}
          />
        </div>
      </div>
    </div>
  )
}

TagInput.defaultProps = {
  placeholder: '',
  allowDuplicates: true,
  allowEditing: true,
  delimiters: [],
}

TagInput.propTypes = {
  placeholder: PropTypes.string,
  allowDuplicates: PropTypes.bool,
  allowEditing: PropTypes.bool,
  delimiters: PropTypes.array,
  validation: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default memo(TagInput)
