import { combineReducers } from 'redux'

import userReducer from './user'
import sessionReducer from './session'
import filtersReducer from './filters'
import uiStateReducer from './uiState'
import diamondsReducer from './diamonds'
import ditReducer from './dit'
import monitoringReducer from './monitoring'
import searchReducer from './search'
import settingsReducer from './settings'
import demoDatasetReducer from './demoDataset'
import sharingReducer from './sharing'
import lifecycleReducer from './lifecycle'
import transferReducer from './transfer'
import viewReducer from './view'
import versionReducer from './version'
import listReducer from './list'
import shareReducer from './share'
import shipmentsInReducer from './shipmentsIn'
import shipmentsOutReducer from './shipmentsOut'
import unlockReducer from './unlock'

const rootReducer = combineReducers({
  filters: filtersReducer,
  user: userReducer,
  session: sessionReducer,
  uiState: uiStateReducer,
  diamonds: diamondsReducer,
  dit: ditReducer,
  monitoring: monitoringReducer,
  search: searchReducer,
  settings: settingsReducer,
  demoDataset: demoDatasetReducer,
  sharing: sharingReducer,
  lifecycle: lifecycleReducer,
  transfer: transferReducer,
  view: viewReducer,
  version: versionReducer,
  list: listReducer,
  share: shareReducer,
  shipmentsIn: shipmentsInReducer,
  shipmentsOut: shipmentsOutReducer,
  unlock: unlockReducer,
})

export default rootReducer
