import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clone } from 'ramda'

import { setShipmentsInStaticFiltersAction } from 'store/actions'
import { selectShipmentsInStaticFilters } from 'store/selectors'
import { Search, FilterButtons } from 'components/system'

import FilterContainer from './Filter'
import classes from './styles.module.css'

const FiltersContainer = ({ rows, shipmentType, onChange, testIdPrefix }) => {
  const dispatch = useDispatch()
  const [filters, setFilters] = useState([])
  const [selectedFilters, setSelectedFilters] = useState({})
  const appliedFilters = useSelector(selectShipmentsInStaticFilters('confirm'))
  const [hasDiff, setHasDiff] = useState(false)

  const caratRanges = [
    { range: [0, 1], text: '0.000 - 0.999' },
    { range: [1, 2.5], text: '1.000 - 2.499' },
    { range: [2.5, 5], text: '2.500 - 4.999' },
    { range: [5, 10], text: '5.000 - 9.999' },
    { range: [10], text: '10.000 and over' },
  ]

  const prepareFilters = () => {
    if (!shipmentType) return []

    const list = [
      { key: 'state', title: 'Status' },
      { key: 'shape', title: 'Shape' },
      { key: 'carats', title: 'Carat' },
      { key: 'clarity', title: 'Clarity' },
      { key: 'colour', title: 'Colour' },
      { key: 'cut_grade', title: 'Cut' },
    ]

    const filters = list.map((filter) => {
      const key = filter.key
      const distinctValues = rows.reduce((prev, next) => {
        switch (key) {
          case 'carats': {
            const value = next.data[shipmentType][key]
            const range = caratRanges.find((r) => {
              const min = r.range[0]
              const max = r.range[1]

              if (value < min) return false

              // within range
              if (value >= min && max && value < max) return true

              // max range
              if (value >= min && !max) return true

              return false
            })

            const exists = prev.find((option) => option.value === range.text)

            if (!exists) prev.push({ value: range.text })

            break
          }
          case 'state': {
            const value = next.data[filter.key].text
            const exists = prev.find((option) => option.value === value)

            if (!exists) prev.push({ value })

            break
          }

          default: {
            const value = next.data[shipmentType][key]
            const exists = prev.find((option) => option.value === value)

            if (!exists) prev.push({ value })

            break
          }
        }

        return prev
      }, [])

      return { name: filter.key, title: filter.title, options: distinctValues }
    })

    return filters
  }

  const handleIdFilterChange = (value) => {
    const updatedApplied = clone(appliedFilters)

    if (!value) {
      delete updatedApplied.id
    } else {
      updatedApplied.id = value
    }

    dispatch(setShipmentsInStaticFiltersAction({ page: 'confirm', filters: updatedApplied }))
  }

  const handleFilterChange = (name, value) => {
    const updatedSelectedFilters = clone(selectedFilters)
    const selected = updatedSelectedFilters[name] ? updatedSelectedFilters[name] : []
    const valueIndex = selected.indexOf(value)

    if (valueIndex !== -1) selected.splice(valueIndex, 1)
    else selected.push(value)

    updatedSelectedFilters[name] = selected

    if (selected.length === 0) delete updatedSelectedFilters[name]

    setSelectedFilters(updatedSelectedFilters)
  }

  const handleFilterApply = (name) => {
    const updatedApplied = clone(appliedFilters)
    updatedApplied[name] = clone(selectedFilters[name])

    if (!updatedApplied[name]) delete updatedApplied[name]

    dispatch(setShipmentsInStaticFiltersAction({ page: 'confirm', filters: updatedApplied }))
  }

  const handleFilterClear = (name) => {
    const updatedApplied = clone(appliedFilters)
    const updatedSelected = clone(selectedFilters)

    delete updatedApplied[name]
    delete updatedSelected[name]

    setSelectedFilters(updatedSelected)
    dispatch(setShipmentsInStaticFiltersAction({ page: 'confirm', filters: updatedApplied }))
  }

  const handleClearAllFilters = () => {
    setSelectedFilters({})
    dispatch(setShipmentsInStaticFiltersAction({ page: 'confirm', filters: {} }))
  }

  const filterOutIds = () => {
    const filteredOutIds = []

    Object.keys(appliedFilters).forEach((name) => {
      switch (name) {
        case 'id': {
          const value = appliedFilters.id
          const RE = new RegExp(`^${value}`)

          rows.forEach((row) => {
            if (
              !RE.test(row.data.diamond_id) &&
              !RE.test(row.data.inscription_number) &&
              !filteredOutIds.includes(row.data.diamond_id) &&
              !filteredOutIds.includes(row.data.inscription_number)
            )
              filteredOutIds.push(row.data.diamond_id)
          })

          break
        }
        case 'carats': {
          const selected = appliedFilters[name]
          const ranges = selected.map((value) => caratRanges.find((r) => r.text === value))

          rows.forEach((row) => {
            const carats = row.data[shipmentType][name]

            const match = ranges.find((r) => {
              const min = r.range[0]
              const max = r.range[1]

              if (carats < min) return false

              // within range
              if (carats >= min && max && carats < max) return true

              // max range
              if (carats >= min && !max) return true

              return false
            })

            if (!match && !filteredOutIds.includes(row.data.diamond_id)) filteredOutIds.push(row.data.diamond_id)
          })

          break
        }
        case 'state': {
          const selected = appliedFilters[name]

          rows.forEach((row) => {
            const value = row.data[name].text

            if (!selected.includes(value) && !filteredOutIds.includes(row.data.diamond_id)) {
              filteredOutIds.push(row.data.diamond_id)
            }
          })

          break
        }
        default: {
          const selected = appliedFilters[name]

          rows.forEach((row) => {
            const value = row.data[shipmentType][name]

            if (!selected.includes(value) && !filteredOutIds.includes(row.data.diamond_id)) {
              filteredOutIds.push(row.data.diamond_id)
            }
          })
          break
        }
      }
    })

    if (onChange) onChange(filteredOutIds)
  }

  useEffect(() => {
    filterOutIds()

    if (Object.keys(appliedFilters).length) setHasDiff(true)
    else setHasDiff(false)

    // clear all button clicked outside of this component
    if (Object.keys(appliedFilters).length === 0) setSelectedFilters({})
  }, [JSON.stringify(appliedFilters)]) // eslint-disable-line react-hooks/exhaustive-deps

  // update clear & apply buttons visible state on filters applied change or filters selection
  useEffect(() => {
    setFilters(prepareFilters())
    //setSelectedFilters({})
    //setAppliedFilters({})
  }, [JSON.stringify(rows)]) // eslint-disable-line react-hooks/exhaustive-deps

  // do not render component if state is undefined

  if (!rows) return null

  return (
    <div className={classes.filtersContainer}>
      <div className={classes.filters}>
        <div className={classes.idFilter}>
          <Search
            placeholder="Search by ID..."
            value={appliedFilters ? appliedFilters.id : null}
            minWidth={267}
            onChange={handleIdFilterChange}
            onClear={() => handleIdFilterChange('')}
            testIdPrefix={testIdPrefix}
          />
        </div>

        {filters.map((filter) => {
          return (
            <FilterContainer
              key={filter.name}
              name={filter.name}
              title={filter.title}
              options={filter.options}
              selectedOptions={selectedFilters[filter.name] || []}
              appliedOptions={appliedFilters[filter.name] || []}
              onChange={handleFilterChange}
              onApply={handleFilterApply}
              onClear={handleFilterClear}
              testIdPrefix={testIdPrefix}
            />
          )
        })}

        <div className={classes.buttons}>
          <FilterButtons
            testIdPrefix={testIdPrefix}
            enableClear={hasDiff} onClear={handleClearAllFilters} />
        </div>
      </div>
    </div>
  )
}

export default FiltersContainer
