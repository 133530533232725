import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { clone } from 'ramda'
import { capitalize } from 'lodash'

import API from 'api'
import { applyMonitoringFiltersAction } from 'store/actions'
import { selectMonitoringFilters } from 'store/selectors'
import Filter from 'containers/Filters/Filter'
import { Search, DateRangePicker, FilterButtons } from 'components/system'
import testids from 'config/testIds'

import classes from './styles.module.css'

export const Filters = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const appliedFilters = useSelector(selectMonitoringFilters)
  const [filters, setFilters] = useState([])
  const [selectedFilters, setSelectedFilters] = useState(appliedFilters)
  const testIds = testids.monitoring

  const statusMapper = {
    invalid: 'Invalid',
    error: 'Error',
    pending: 'Pending',
    unverifiable: 'Not Registered',
    verified: 'Registered',
    duplicate: 'Duplicate',
  }

  const handleDateChange = (dateRange) => {
    const name = 'date'
    const hasValues = !!Object.keys(dateRange).length
    const filterIndex = selectedFilters.findIndex((filter) => filter.name === name)
    const selectedFilter = selectedFilters[filterIndex]
    const newSelectedFilter = { name, value: dateRange }
    const newSelectedFilters = clone(selectedFilters)

    if (selectedFilter) {
      if (hasValues) newSelectedFilters[filterIndex] = newSelectedFilter
      else newSelectedFilters.splice(filterIndex, 1)
    } else if (hasValues) {
      newSelectedFilters.push(newSelectedFilter)
    }

    setSelectedFilters(newSelectedFilters)
    dispatch(applyMonitoringFiltersAction(newSelectedFilters))
  }

  const handleFilterValueChange = (name, value) => {
    const newSelectedFilters = clone(selectedFilters)
    const filterIndex = newSelectedFilters.findIndex((filter) => filter.name === name)
    const selectedFilter = newSelectedFilters[filterIndex]

    if (!value) return

    if (selectedFilter) {
      if (!selectedFilter.values.includes(value)) {
        // add filter value to selected filters
        selectedFilter.values = selectedFilter.values.concat(value)
      } else {
        // remove filter value
        selectedFilter.values.splice(selectedFilter.values.indexOf(value), 1)
      }

      if (selectedFilter.values.length === 0) {
        newSelectedFilters.splice(filterIndex, 1)
      }
    } else {
      newSelectedFilters.push({ name, values: [value] })
    }

    setSelectedFilters(newSelectedFilters)
  }

  const handleSearchChange = (value) => {
    const newSelectedFilters = clone(selectedFilters)
    const filterIndex = newSelectedFilters.findIndex((filter) => filter.name === 'id')
    const selectedFilter = newSelectedFilters[filterIndex]

    if (selectedFilter) {
      if (value) {
        selectedFilter.value = value
      } else {
        newSelectedFilters.splice(filterIndex, 1)
      }
    } else if (value) {
      newSelectedFilters.push({ name: 'id', value })
    }

    setSelectedFilters(newSelectedFilters)
    dispatch(applyMonitoringFiltersAction(newSelectedFilters))
  }

  const handleClearFilter = (name) => {
    const filterIndex = selectedFilters.findIndex((filter) => filter.name === name)
    const newSelectedFilters = clone(selectedFilters)

    newSelectedFilters.splice(filterIndex, 1)

    setSelectedFilters(newSelectedFilters)
    dispatch(applyMonitoringFiltersAction(newSelectedFilters))

    if (name === 'id' && id) navigate('/integration/monitoring')
  }

  const handleFilterApply = () => {
    dispatch(applyMonitoringFiltersAction(selectedFilters))
  }

  const handleClearFilters = () => {
    dispatch(applyMonitoringFiltersAction([]))
    if (id) navigate('/integration/monitoring')
  }

  useEffect(() => {
    setSelectedFilters(appliedFilters)
  }, [JSON.stringify(appliedFilters)]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!id) return
    handleSearchChange(id)
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const mapper = {
      lifecycle_state: 'integration_type',
      verification_status: 'status',
    }
    API.getMonitoringFilters().then((res) => {
      const list = res.data

      setFilters(
        Object.keys(list).map((key) => {
          return {
            name: mapper[key],
            options: list[key].map((value) => {
              let title = value

              // map status filter displayed values
              if (key === 'verification_status') {
                title = capitalize(statusMapper[value.toLowerCase()])
              } else {
                title = capitalize(value)
              }

              return { title, value }
            }),
          }
        })
      )
    }).catch((e) => console.error('monitoring filter failed to be retreaved: ', e))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const date = appliedFilters.find((filter) => filter.name === 'date')
  const idValue = appliedFilters.find((filter) => filter.name === 'id')

  return (
    <div className={classes.filtersContainer}>
      <div className={classes.filters}>
        <div className={classes.search}>
          <Search
            value={idValue ? idValue.value : ''}
            testIdPrefix={testIds.prefix}
            onChange={handleSearchChange}
            onClear={() => handleClearFilter('id')}
          />
        </div>
        <div className={classes.datePicker} data-test-id={testIds.dateRangePicker}>
          <DateRangePicker
            onClear={() => handleClearFilter('date')}
            onChange={handleDateChange}
            selected={date ? date.value : {}}
            label="Event date"
            testIdPrefix={testIds.prefix}
            testIdLeftCalender={testIds.leftCalender}
            testIdRightCalender={testIds.rightCalender}
          />
        </div>
        {filters.map((filter) => {
          const selectedFilter = selectedFilters.find((selectedFilter) => selectedFilter.name === filter.name)
          const selectedValues = selectedFilter ? selectedFilter.values : []
          const appliedFilter = appliedFilters.find((appliedFilter) => appliedFilter.name === filter.name)
          const appliedValues = appliedFilter ? appliedFilter.values : []

          return (
            <Filter
              key={`filter-components-${filter.name}`}
              name={filter.name}
              options={filter.options}
              selectedOptions={selectedValues}
              appliedOptions={appliedValues}
              sorted={false}
              testId={testIds[filter.name]}
              onFilterApply={handleFilterApply}
              onFilterValueChange={handleFilterValueChange}
              onFilterClear={handleClearFilter}
              testIdPrefix={testIds.prefix}
            />
          )
        })}
        {filters.length ? (
          <div className={classes.clearBtn} data-test-id={testIds.clearFiltersBtn}>
            <FilterButtons enableClear={appliedFilters.length || selectedFilters.length} onClear={handleClearFilters}
              testIdPrefix={testIds.prefix}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Filters
