import React, { useState, useRef } from 'react'

import Item from './Item'
import classes from './styles.module.css'

export const ContextMenu = ({ title, items, isOpen, onItemClick }) => {
  const container = useRef()
  const [posY, setPosY] = useState()
  const [posX, setPosX] = useState()

  const handleRef = (node) => {
    if (!node || container.current) return

    container.current = node

    const parentPos = node.parentNode.getBoundingClientRect()
    const menuPos = node.getBoundingClientRect()
    const availableHeight = document.body.offsetHeight

    // not enough bottom space to render the menu going down
    // so render it going up instead
    // 25 is spacing between three dots and the menu
    // 35 is to align it with the dots horizontally
    if (parentPos.top + menuPos.height + 25 > availableHeight) {
      setPosY(parentPos.top - menuPos.height)
    } else {
      // enough space to render the menu going down
      setPosY(parentPos.top + 25)
    }

    setPosX(menuPos.width - 35)
  }

  if (!isOpen) return null

  return (
    <div
      className={classes.contextMenuContainer}
      style={{ top: posY ? `${posY}px` : null, right: posX ? `${posX}px` : null }}
      data-contextmenu={true}
      ref={handleRef}
    >
      <div className={classes.wrapper}>
        {title ? <div className={classes.title}>{title}</div> : null}
        <ul className={classes.list}>
          {items.map((item) => (
            <Item testId={item.testId} key={item.title} action={item.key} Icon={item.icon} {...item} onClick={onItemClick} />
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ContextMenu
