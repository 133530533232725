import React from 'react'
import { useLocation, matchPath, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import { selectView } from 'store/selectors'
import featureToggles from 'config/featureToggles'

import WithOrWithoutTooltip from '../WithOrWithoutTooltip'
import classes from './styles.module.css'

const ChildLevelLink = ({ parent, route, availableLifecycles = [], isDisabled, navIsCollapsed }) => {
  const { pathname } = useLocation()
  const selectedView = useSelector(selectView)
  const isActive =
    matchPath(`${parent.path}/${route.path}/*`, pathname) ||
    (route.basePath && matchPath(`${parent.path}/${route.basePath}`))
  const isChildDisabled = route.disabled || isDisabled

  let isUnavailable = false // when there is no data for this lifecycle
  if (parent.feature === 'inventory' && !isChildDisabled) {
    if (!availableLifecycles.includes(route.feature)) isUnavailable = true
  }

  let viewPath = ''
  // logic for persistent view selection
  if (parent.feature === 'inventory') {
    const views = ['charts', 'list', 'cards']

    // if view is available, use it
    // otherwise try to find first available view
    if (featureToggles[selectedView] === 2) {
      viewPath = `/${selectedView}`
    } else {
      const view = views.find((view) => featureToggles[view] === 2)

      viewPath = `/${view || ''}`
    }
  }

  return (
    <li className={classes.childLevelLinkContainer}>
      <WithOrWithoutTooltip isDisabled={isChildDisabled} isUnavailable={isUnavailable}>
        <Link
          className={classnames(classes.childLevelLink, {
            [classes.active]: isActive && !isChildDisabled,
            [classes.disabled]: isChildDisabled || isUnavailable,
          })}
          to={`${parent.path}/${route.basePath ? route.basePath : route.path}${viewPath}`}
          onClick={(evt) => {
            if (isChildDisabled || isUnavailable) evt.preventDefault()
          }}
        >
          <div
            className={classnames(classes.childLevelLabel, {
              [classes.active]: isActive && !isChildDisabled,
              [classes.childLevelLabelCollapsed]: navIsCollapsed,
            })}
          >
            <div className={classes.childLevelIcon}>
              <route.icon />
            </div>
            {!navIsCollapsed ? <div className={classes.childLevelText}>{route.title}</div> : null}
          </div>
        </Link>
      </WithOrWithoutTooltip>
    </li>
  )
}

export default ChildLevelLink
