import { UI_ACTION_TYPES, STORE_INIT, LIFECYCLE_ACTION_TYPES } from '../actionTypes'

const INITIAL_SORT = { participant_timestamp: 'desc' }

const initialState = {
  showSecondaryFilters: true,
  page: 1,
  limit: 20,
  sort: INITIAL_SORT,
}

const uiStateReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case STORE_INIT:
      return initialState
    case UI_ACTION_TYPES.UPDATE_UI_STATE: {
      return { ...state, ...payload }
    }
    case LIFECYCLE_ACTION_TYPES.STAGE_CHANGE: {
      return { ...state, sort: INITIAL_SORT }
    }
    default:
      return state
  }
}

export default uiStateReducer
