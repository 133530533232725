import React from 'react'

import { Tooltip } from 'components/system'
import { DITWarningCircleIcon, DITNACircleIcon, DITSuccessCircleIcon, DITErrorCircleIcon } from 'components/Svg'
import testids from 'config/testIds'

import classes from './styles.module.css'

export const UploadStatusCell = ({ value = {} }) => {
  const testIds = testids.dit

  const mapper = {
    missing: DITWarningCircleIcon,
    na: DITNACircleIcon,
    uploaded: DITSuccessCircleIcon,
    error: DITErrorCircleIcon,
  }

  const copy = {
    missing: 'Missing',
    uploaded: 'Accepted',
    error: 'Failed',
    na: 'Not required',
  }

  if (Object.keys(value).length === 0) {
    // TRAC-1987: an empty object {} means the user didn't declare any files
    // of this type for this diamond
    value = {na: 0}
  }

  return (
    <div className={classes.uploadStatusCellContainer}>
      {Object.keys(value).map((status, index) => {
        const Icon = mapper[status]
        const count = value[status]

        return (
          <Tooltip
            title={
              <div className={classes.tooltipCopy}>
                <Icon />
                <span>{copy[status]}</span>
              </div>
            }
            tooltipTestIds={testIds.tooltips}
            key={status + index}
          >
            <div className={classes.statusGroup}>
              <Icon /> {count > 0 ? <span>{count}</span> : null}
            </div>
          </Tooltip>
        )
      })}
    </div>
  )
}

export default UploadStatusCell
