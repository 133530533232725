import React from 'react'

import classes from './styles.module.css'

export const Counter = ({ count, text, testId }) => {
  return (
    <div className={classes.counterContainer} data-test-id={testId}>
      <span>{count}</span> {text}
    </div>
  )
}

export default Counter
