import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import Media from 'containers/Media'
import { Spinner } from 'components/system'
import { resolveIconToSVG } from 'utils/svgResolver'
import { getOrderedMedia } from 'utils/diamond'

import classes from './styles.module.css'

export const HeaderImage = ({ diamond, ...other }) => {
  const [assetLoaded, setAssetLoaded] = useState(false)
  const [media, setMedia] = useState(null)

  const Icon = ({ variant, value, ...other }) => {
    const I = resolveIconToSVG(variant, value)

    return <I {...other} width={'100%'} height={'100%'} stroke={'#FFF'} />
  }

  const handleAssetLoad = () => {
    setAssetLoaded(true)
  }

  useEffect(() => {
    const mediaList = getOrderedMedia(diamond, ['videos', 'images', 'lifecycle', 'shape'])
    const mediaFile = mediaList[0]

    setMedia(mediaFile)

    if (mediaFile.type === 'icon' && !assetLoaded) setAssetLoaded(true)
  }, [diamond && diamond.id]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!media) return null

  return (
    <div className={classes.headerImageContainer}>
      {!assetLoaded ? <Spinner /> : null}
      <div {...other} className={classnames(classes.media, { [classes.visible]: assetLoaded })}>
        {media.type !== 'icon' ? (
          <Media {...other} media={{ id: diamond.id, ...media, fov: 25 }} onLoad={handleAssetLoad} />
        ) : null}
        {media.type === 'icon' ? <Icon {...other} variant={media.variant} value={media.value} /> : null}
      </div>
    </div>
  )
}

export default HeaderImage
