import { clone } from 'ramda'

import { LIST_ACTION_TYPES } from '../actionTypes'

const initialState = {
  data: {
    contextMenuId: null,
  },
}

const listReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case LIST_ACTION_TYPES.SET_CONTEXT_MENU_ID:
      const newState = clone(state)

      return {
        ...initialState,
        data: {
          ...initialState.data,
          contextMenuId: payload,
        },
        requests: {
          ...newState.requests,
        },
      }
    default:
      return state
  }
}

export default listReducer
