import API from 'api'
import { SEARCH_ACTION_TYPES } from '../actionTypes'
import { sessionExpiredAction } from './user'

export function searchDiamondAction(tracrId) {
  return dispatch => {
    dispatch({ type: SEARCH_ACTION_TYPES.DIAMOND_SEARCH.REQUEST, payload: tracrId })

    return API.findDiamond(tracrId).then(
      response => {
        dispatch({ type: SEARCH_ACTION_TYPES.DIAMOND_SEARCH.COMPLETE, payload: response.data.content })
      },
      error => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: SEARCH_ACTION_TYPES.DIAMOND_SEARCH.FAILURE, payload: error })
        }
      }
    )
  }
}

export function clearSearchAction() {
  return {
    type: SEARCH_ACTION_TYPES.CLEAR_SEARCH,
  }
}
