// import createActionSet from 'utils/createActionSet'

const namespace = 'UNLOCK'

const SELECT_DIAMONDS_TO_UNLOCK = `${namespace}_SELECT_DIAMONDS_TO_UNLOCK`
const REMOVE_DIAMONDS_FROM_UNLOCK = `${namespace}_REMOVE_DIAMONDS_FROM_UNLOCK`
const SET_UNLOCK_MODAL_VISIBLE = `${namespace}_SET_UNLOCK_MODAL_VISIBLE`
const SET_TARGET = `${namespace}_SET_TARGET`

export const UNLOCK_ACTION_TYPES = {
  SELECT_DIAMONDS_TO_UNLOCK,
  REMOVE_DIAMONDS_FROM_UNLOCK,
  SET_UNLOCK_MODAL_VISIBLE,
  SET_TARGET,
}
