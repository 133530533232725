import React from 'react'
import classnames from 'classnames'

import { Checkbox as CheckboxComponent } from 'components/system'
import testids from 'config/testIds'

import classes from './styles.module.css'
import { testId } from 'utils/testHelper'

export const CheckboxCell = ({ rows, row, isHeader, onChange, onSelectionChange, testIdPrefix }) => {

  const handleOnChange = (value) => {
    if (onChange) onChange(row, value, isHeader)

    if (!onSelectionChange) return

    let newSelection = []

    if (isHeader) {
      if (value === 2) newSelection = rows.filter((row) => !row.actionDisabled).map((row) => row.id)
    } else {
      rows.forEach((rw) => {
        if (row.id === rw.id) {
          if (value === 2) newSelection.push(rw.id)
        } else {
          if (rw.selected) newSelection.push(rw.id)
        }
      })
    }

    onSelectionChange(newSelection, value, isHeader)
  }

  const getValue = () => {
    let value = 0

    if (isHeader) {
      const selectionLength = rows.filter((row) => !row.actionDisabled && row.selected).length

      // fully selected
      if (selectionLength === rows.length && selectionLength > 0) value = 2
      // partially selected
      else if (selectionLength > 0) value = 1
    } else {
      // fully checked
      if (row.selected) value = 2
    }

    return value
  }

  return (
    <div
      className={classnames(classes.checkboxCellContainer, { [classes.margin]: !!isHeader })}
      data-checkbox={true}
      data-test-id={testId(testIdPrefix, isHeader ? testids.table.selectAll : testids.table.checkbox)}
    >
      <CheckboxComponent value={getValue()} isDisabled={row ? row.actionDisabled : false} onChange={handleOnChange} />
    </div>
  )
}

export default CheckboxCell
