import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { reverse } from 'ramda'

import { getFullDiamondAction } from 'store/actions'
import { selectFullDiamondById, selectLifecycle, selectView } from 'store/selectors'
import { ArrowUpIcon, ArrowDownIcon } from 'components/Svg/icons'
import { FlagIcon } from 'components/system'
import { getPriorityDataPoints, isDeclaredDiamond, validateUUID } from 'utils/diamond'
import { parseCsvEnvVar } from 'utils/index'

import Header from './Header'
import Journey from './Journey'
import Section from './Section'
import { getSections } from './utils'
import classes from './styles.module.css'
import { testIds } from 'config/testIds'

export const Diamond = ({ id, isFullScreen, isShare }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const lifecycle = useSelector(selectLifecycle)
  const selectedView = useSelector(selectView)
  const containerRef = useRef()
  const diamond = useSelector(selectFullDiamondById(id))
  const [diamondContent, setDiamondContent] = useState(diamond)
  const [isExpanded, setIsExpanded] = useState(isFullScreen)
  const PROPS_ORDER = parseCsvEnvVar('DIAMOND_PROPERTIES_ORDER')

  const getHeading = () => {
    if (!diamondContent) return ''

    return `${getPriorityDataPoints(diamondContent.current, diamondContent.current.lifecycle_state)
      .filter((x) => x !== '-')
      .join(', ')}`
  }

  const handleViewDiamondClick = () => {
    setIsExpanded(!isExpanded)
  }

  const handleBackToTopClick = () => {
    if (!containerRef.current) return

    containerRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const filterOutProps = (props, filterOut = []) => {
    return props.filter((prop) => !filterOut.includes(prop.label))
  }

  const prepareDiamond = (props, isShare, isDeclared) => {
    let filterOut = []

    const shareRestricted = ['Participant ID', 'Box ID', 'Box Description']
    const declaredRestrictred = ['Provenance']

    if (isShare) filterOut = filterOut.concat(shareRestricted)
    if (isDeclared) filterOut = filterOut.concat(declaredRestrictred)

    return filterOutProps(props, filterOut)
  }

  const orderProps = (props, order = PROPS_ORDER) => {
    return props.sort((a, b) => {
      const propAIndex = order.indexOf(a.key)
      const propBIndex = order.indexOf(b.key)

      if (propAIndex > propBIndex) {
        // propB is not in the order list, move ordered propA to left
        if (propBIndex === -1) return -1
        return 1
      } else if (propAIndex < propBIndex) {
        // propA is not in the order list, move ordered propB to left
        if (propAIndex === -1) return 1
        return -1
      } else return 0
    })
  }

  const sections = diamondContent
    ? getSections(diamondContent).map((section) => {
        return {
          ...section,
          props: orderProps(prepareDiamond(section.props, isShare, isDeclaredDiamond(diamondContent.current))),
        }
      })
    : []

  useEffect(() => {
    if (!diamond || !id) return

    setDiamondContent(diamond)
  }, [JSON.stringify(diamond)]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const rootPath = `/inventory/${lifecycle.stage}`

    if (diamond || !id) return

    dispatch(getFullDiamondAction(id)).then((response) => {
      if (!response) navigate(`${rootPath}/${selectedView}`)
      if (validateUUID(id)) navigate(`${rootPath}/diamond/${response.data.content.current.diamond_id}`)
      else setDiamondContent(response.data.content)
    })
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!diamondContent) return null

  return (
    <div className={classes.diamondContainer} ref={containerRef}>
      <div className={classnames(classes.wrapper, { [classes.fullscreen]: isFullScreen })}>
        <div className={classnames(classes.positioner, { [classes.fullscreen]: isFullScreen })}>
          {isFullScreen ? null : (
            <div data-test-id={testIds.diamondExperience.headerTitle} className={classes.heading}>
              <FlagIcon style={{ height: '24px' }} country={diamond?.current?.provenance?.provenance_name ?? ''} />
              {getHeading()}
            </div>
          )}
          <Header diamond={diamondContent.current} />
          {isFullScreen ? null : (
            <div
              className={classnames(classes.viewDiamondDetails, { [classes.expanded]: isExpanded })}
              onClick={handleViewDiamondClick}
              data-test-id={testIds.diamondExperience.viewHideDiamondDetailsButton}
            >
              {isExpanded ? 'Hide' : 'View'} diamond details
              {isExpanded ? <ArrowUpIcon /> : null}
              {!isExpanded ? <ArrowDownIcon /> : null}
            </div>
          )}
          <div
            className={classnames(classes.diamondDetails, {
              [classes.fullscreen]: isFullScreen,
              [classes.expanded]: isExpanded,
            })}
          >
            <div className={classnames(classes.journey, { [classes.visible]: sections.length > 1 })}>
              <Journey sections={sections} />
            </div>
            {reverse(sections).map((section) => {
              return (
                <div id={`${section.lifecycle}Section`} className={classes.section} key={section.lifecycle}>
                  <Section
                    testIdPrefix={testIds.diamondExperience.details.prefix}
                    diamond={diamondContent}
                    {...section}
                  />
                </div>
              )
            })}
          </div>
        </div>
        <button
          className={classnames(classes.backToTop, {
            [classes.visible]: isExpanded,
            [classes.fullscreen]: isFullScreen,
          })}
          onClick={handleBackToTopClick}
          data-test-id={testIds.diamondExperience.backToTop}
        >
          BACK TO TOP <ArrowUpIcon />
        </button>
      </div>
    </div>
  )
}

export default Diamond
