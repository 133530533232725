// import createActionSet from 'utils/createActionSet'

const namespace = 'SHARE'
// const GET_SHARES = createActionSet(namespace, 'GET_SHARES')

const SELECT_DIAMONDS_TO_SHARE = `${namespace}_SELECT_DIAMONDS_TO_SHARE`
const REMOVE_DIAMOND_FROM_SHARE = `${namespace}_REMOVE_DIAMOND_FROM_SHARE`

export const SHARE_ACTION_TYPES = {
  SELECT_DIAMONDS_TO_SHARE,
  REMOVE_DIAMOND_FROM_SHARE,
}
