import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import { ModalWrapper, Button } from 'components/system'
import { CloseSVG } from 'components/Svg'

import FilterGroup from './FilterGroup'
import classes from './styles.module.css'
import { testIds } from 'config'

const MoreFiltersPopup = ({
  show,
  currentFilters,
  availableFilters,
  localFilters,
  closePopup,
  onChange,
  onSliderChange,
  clearAll,
  applyFilters,
  filteredCount,
  count,
  testIdPrefix = 'filters',
}) => {
  const [filters, setFilters] = useState([])

  const handleFilterValueChange = (key, option, value) => {
    if (option || option === false) { // explicit false check required for boolean values
      onChange(!!value, option, key)
    } else {
      onSliderChange(value, key)
    }
  }

  const handleApplyFilters = () => {
    applyFilters()
  }

  const getDiamondsCount = () => {
    if (Object.values(localFilters).flat().length > 0) {
      if (filteredCount !== undefined) return filteredCount
    }

    return count
  }

  const prepareFilter = (filter, allFilters, localFilters) => {
    const prepared = { ...filter }

    if (prepared.filters && prepared.filters.length) {
      prepared.filters = prepared.filters
        .map((filter) => prepareFilter(filter, allFilters, localFilters))
        .filter((filter) => filter)

      if (prepared.filters.length === 0) delete prepared.filters
    }

    if (prepared.key) {
      prepared.options = allFilters[prepared.key] || []
      prepared.value = localFilters[prepared.key] || []

      if (!Array.isArray(prepared.value)) {
        if (Object.keys(prepared.value).length > 0) prepared.value = [prepared.value.from, prepared.value.to]
        else prepared.value = []
      }

      if (prepared.options.length === 0) delete prepared.options
    }

    if (!prepared.options && !prepared.filters) return null
    else return prepared
  }

  const prepareFilters = (currentFilters, allFilters, localFilters) => {
    return currentFilters.map((filter) => prepareFilter(filter, allFilters, localFilters)).filter((filter) => filter)
  }

  useEffect(() => {
    setFilters(prepareFilters(currentFilters, availableFilters, localFilters))
  }, [JSON.stringify(currentFilters), JSON.stringify(availableFilters), JSON.stringify(localFilters)]) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <ModalWrapper isOpen={show}>
      <div className={classes.moreFiltersPopupContainer}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <h3>More filters</h3>
            <div data-test-id={testIds.inventory.moreFilters.closeButton} className={classes.closeBtn} onClick={closePopup}>
              <CloseSVG />
            </div>
          </div>
          <div className={classes.filters}>
            {filters.map((filter, index) => (
              <FilterGroup
                key={filter.title}
                path={filter.key}
                {...filter}
                isLast={index === filters.length - 1}
                testIdPrefix={testIdPrefix}
                onChange={handleFilterValueChange}
              />
            ))}
          </div>
          <div className={classes.actions}>
            <div className={classnames(classes.action, { [classes.disabled]: false })}>
              <div data-test-id={testIds.inventory.moreFilters.clearAllButton} className={classes.textBtn} onClick={() => clearAll()}>
                Clear all
              </div>
            </div>
            <div className={classes.action}>
              <Button data-test-id={testIds.inventory.moreFilters.viewDiamondsButton} onClick={handleApplyFilters}>
                View {getDiamondsCount()} Diamond{getDiamondsCount() > 1 ? 's' : ''}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default MoreFiltersPopup
