// import createActionSet from 'utils/createActionSet'

const namespace = 'SETTINGS'

const UPDATE_NAV_IS_COLLAPSED = `${namespace}_UPDATE_NAV_IS_COLLAPSED`
const UPDATE_VIEW_COLUMNS = `${namespace}_UPDATE_VIEW_COLUMNS`
const RESET_VIEW_COLUMNS = `${namespace}_RESET_VIEW_COLUMNS`
const NAV_TOGGLE_ITEM = `${namespace}_NAV_TOGGLE_ITEM`
const DIT_SELECT_TAB = `${namespace}_DIT_SELECT_TAB`
const DIT_HIDE_SUBMITTED_DIAMONDS = `${namespace}_DIT_HIDE_SUBMITTED_DIAMONDS`
const DIT_RESET_SETTINGS = `${namespace}_DIT_RESET_SETTINGS`
const UPDATE_SHIPMENTS_IN_TAB = `${namespace}_UPDATE_SHIPMENTS_IN_TAB`
const UPDATE_SHIPMENTS_OUT_TAB = `${namespace}_UPDATE_SHIPMENTS_OUT_TAB`

export const SETTINGS_ACTION_TYPES = {
  UPDATE_NAV_IS_COLLAPSED,
  UPDATE_VIEW_COLUMNS,
  RESET_VIEW_COLUMNS,
  NAV_TOGGLE_ITEM,
  DIT_SELECT_TAB,
  DIT_HIDE_SUBMITTED_DIAMONDS,
  DIT_RESET_SETTINGS,
  UPDATE_SHIPMENTS_IN_TAB,
  UPDATE_SHIPMENTS_OUT_TAB,
}
