import React from 'react'
import classnames from 'classnames'

import { Button } from 'components/system'
import { UploadPlansIcon, UploadScansIcon, UploadVideosIcon, UploadImagesIcon, UploadMiscIcon } from 'components/Svg'
import testids from 'config/testIds'

import classes from './styles.module.css'

export const FilesOverlay = ({ persistent, semiTransparent, isDraggedOver, onButtonClick }) => {
  const testIds = testids.dit

  return (
    <div
      className={classnames(classes.filesOverlayContainer, {
        [classes.persistent]: persistent,
        [classes.semiTransparent]: semiTransparent,
        [classes.draggedOver]: isDraggedOver,
      })}
    >
      <div className={classes.overlayInfo}>
        <span className={classes.overlayHint}>
          {isDraggedOver ? 'Drop required files' : 'Drag and drop required files'}
        </span>
        <div className={classes.overlayIcons}>
          <UploadPlansIcon />
          <UploadScansIcon />
          <UploadVideosIcon />
          <UploadImagesIcon />
          <UploadMiscIcon />
        </div>
        {!isDraggedOver && (
          <Button
            className={classes.overlayButton}
            onClick={onButtonClick}
            data-test-id={testIds.sessionSelectFilesButton}
          >
            Or, select from device
          </Button>
        )}
      </div>
    </div>
  )
}

export default FilesOverlay
