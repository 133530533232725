import createActionSet from 'utils/createActionSet'

// request get available filters
const namespace = 'FILTERS'
const GET_FILTERS = createActionSet(namespace, 'GET_FILTERS')

const SELECT = `${namespace}_FILTERS_SELECT`
const APPLY = `${namespace}_FILTERS_APPLY`
const CLEAR = `${namespace}_FILTERS_CLEAR`
const DRILL_DOWN = `${namespace}_FILTERS_DRILL_DOWN`

export const FILTER_ACTION_TYPES = {
  GET_FILTERS,
  SELECT,
  APPLY,
  CLEAR,
  DRILL_DOWN,
}
