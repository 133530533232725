import React from 'react'

import { StatusLabel } from 'components/system'

export const StatusCell = ({ value = '' }) => {
  if (!value) return null

  const resolveStatus = (status) => {
    const formattedValue = status ? status.toLowerCase().replace(' ', '') : null

    switch (formattedValue) {
      case 'abandoned':
        return 'error'
      case 'submitted':
        return 'success'
      case 'inprogress':
        return 'warning'
      default:
        return 'default'
    }
  }

  return <StatusLabel status={resolveStatus(value)} text={value} />
}

export default StatusCell
