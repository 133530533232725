import React from 'react'

import classes from './styles.module.css'

export const SubNav = ({ points = [], children, testId }) => {
  return (
    <div className={classes.subNavContainer}>
      <ul className={classes.points} data-test-id={testId}>
        {points.map((point) => {
          return (
            <li key={point.key} className={classes.point}>
              {point.key}: <span>{point.value}</span>
            </li>
          )
        })}
      </ul>
      <div className={classes.actions}>{children}</div>
    </div>
  )
}

export default SubNav
