/* eslint-disable */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { Button, Modal } from 'components/system'
import { uploadCSVAction, validateCSVAction, parseCSVAction } from 'store/actions'
import { selectIntegrationTypes, selectDITSessionId, selectCSVFile, selectDITCSVErrors } from 'store/selectors'
import testids from 'config/testIds'

import PageHeader from '../../PageHeader'
import IntegrationTypeModal from './IntegrationTypeModal'
import DropView from './DropView'
import ValidationErrorView from './ValidationErrorView'
import classes from './styles.module.css'

export const ReuploadCSVPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const integrationTypes = useSelector(selectIntegrationTypes)
  const sessionId = useSelector(selectDITSessionId)
  const csvFile = useSelector(selectCSVFile)
  const initialCSVErrors = useSelector(selectDITCSVErrors)
  const [showConfirmIntegration, setShowConfirmIntegration] = useState(false)
  const [CSVValidationErrors, setCSVValidationErrors] = useState(initialCSVErrors)
  const [isCSVUploading, setIsCSVUploading] = useState(false)
  const [CSVInputError, setCSVInputError] = useState({ title: '', text: '' })
  const testIds = testids.dit

  const readCSVFile = (file) => {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.onload = function () {
        // split csv string by line breaks and filter out empty rows
        const lines = reader.result.split(/\n|\r/).filter((row) => !!row)

        // split each line into values
        resolve(lines.map((line) => line.split(',')))
      }

      reader.onerror = function (error) {
        reject(error)
      }

      reader.readAsBinaryString(file)
    })
  }

  const triggerFileUpload = (file) => {
    setShowConfirmIntegration(true)
    setIsCSVUploading(true)

    readCSVFile(file).then((csvContent) => {
      const size = file.size / 1024 >= 1 ? `${Math.floor(file.size / 1024)}kb` : `${file.size}bytes`

      dispatch(uploadCSVAction({ file, fileParsed: { name: file.name, size, content: csvContent } })).then(
        (response) => {
          setIsCSVUploading(false)

          if (response && response.status !== 200) {
            let errors = [{ type: 'unknown', message: 'Unknown error' }]

            if (response.data) {
              if (response.data.detail) {
                errors = [{ type: 'header', message: response.data.detail }]
              }
            }

            setShowConfirmIntegration(false)
            setCSVValidationErrors(errors)
          }
        }
      )
    })
  }

  const handleDrop = (files) => {
    const nameParts = files[0].name.split('.')
    const extension = nameParts[nameParts.length - 1]

    if (files.length > 1) {
      // raise multiple file upload error
      return setCSVInputError({ title: 'Multiple files', text: 'Please upload one CSV file per session.' })
    } else if (extension !== 'csv' && files[0].type !== 'text/csv' && files[0].type !== 'application/vnd.ms-excel') {
      // raise invalid file type
      return setCSVInputError({
        title: 'Incorrect file type',
        text: 'Please upload again ensuring your CSV file has the correct .CSV extension.',
      })
    } else if (files[0].size === 0) {
      // raise empty file upload
      return setCSVInputError({
        title: 'Empty file',
        text: 'Please make sure your CSV file contains data. <br />A CSV template can be found in the integration toolkit.',
      })
    }

    triggerFileUpload(files[0])
  }

  const handleFileInput = (file) => {
    const nameParts = file.name.split('.')
    const extension = nameParts[nameParts.length - 1]

    if (!file || file.size === 0) {
      return setCSVInputError({
        title: 'Empty file',
        text: 'Please make sure your CSV file contains data. <br />A CSV template can be found in the integration toolkit.',
      })
    } else if (extension !== 'csv' && file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
      // raise invalid file type
      return setCSVInputError({
        title: 'Incorrect file type',
        text: 'Please upload again ensuring your CSV file has the correct .CSV extension.',
      })
    }

    triggerFileUpload(file)
  }

  const handleCancelClick = () => {
    setShowConfirmIntegration(false)
  }
  const handleContinueClick = (integrationType) => {
    setIsCSVUploading(true)

    dispatch(validateCSVAction({ sessionId, integrationType }))
      .then((response) => {
        return new Promise((resolve, reject) => {
          if (response.status !== 200 || response.data.state === 'invalid') {
            reject(response)
          } else {
            resolve()
          }
        })
      })
      .then(() => {
        return dispatch(parseCSVAction(sessionId)).then((response) => {
          if (response.status !== 200) {
            throw response
          }
        })
      })
      .then(() => {
        setIsCSVUploading(false)
        setShowConfirmIntegration(false)

        navigate(`/integration/dit/${sessionId}`)
      })
      .catch((errors) => {
        let errorsParsed = []

        if (!errors) {
          errorsParsed = [{ type: 'unknown', message: 'Unknown error' }]
        } else {
          // if errors has a status, then its coming from back-end
          if (errors.status) {
            // if errors.data.errors exists, it's a validation error
            // if not, it's an unhandled exception from dit
            if (errors.data.errors) {
              errorsParsed = errors.data.errors
            } else {
              errorsParsed = [
                {
                  category: 'custom',
                  col: null,
                  description: 'An unexpected error occurred, please contact support.',
                  row: null,
                  type: 'dit_exception',
                },
              ]
            }
          } else if (Array.isArray(errors)) {
            errorsParsed = errors
          }
        }

        setIsCSVUploading(false)
        setShowConfirmIntegration(false)
        setCSVValidationErrors(errorsParsed)
      })
  }

  const handleConfirmModalClick = () => {
    setCSVInputError({ title: '', text: '' })
  }

  const handleQuitSession = () => {
    navigate(`/integration/dit`)
  }

  return (
    <div className={classes.reuploadCSVPageContainer}>
      <PageHeader title={CSVValidationErrors ? `UPLOAD FAILED FOR <span>${csvFile.name}</span>` : 'UPLOAD CSV FILE'}>
        <div className={classes.buttons}>
          <Button variant="secondary" onClick={handleQuitSession} data-test-id={testIds.uploadCSVQuitSession}>
            Quit
          </Button>
        </div>
      </PageHeader>
      {!CSVValidationErrors ? (
        <DropView onDrop={handleDrop} onFileInput={handleFileInput} />
      ) : (
        <ValidationErrorView errors={CSVValidationErrors} onDrop={handleDrop} onFileInput={handleFileInput} />
      )}

      <IntegrationTypeModal
        integrationTypes={integrationTypes}
        isOpen={showConfirmIntegration}
        isLoading={isCSVUploading}
        onCancel={handleCancelClick}
        onContinue={handleContinueClick}
      />
      <Modal
        isOpen={CSVInputError.title}
        title={CSVInputError.title}
        onConfirm={handleConfirmModalClick}
        onClose={handleConfirmModalClick}
      >
        <div className={classes.errorText} dangerouslySetInnerHTML={{ __html: CSVInputError.text }} />
      </Modal>
    </div>
  )
}

export default ReuploadCSVPage
