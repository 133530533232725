import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import classes from './styles.module.css';

export const LinkWithoutChildren = ({ route, isActive, navIsCollapsed, propsNavIsCollapsed, onNavToggle }) => {
  const isDisabled = route.disabled;

  if (route.isStatic) {
    return (
      <div
        className={classnames(classes.linkWithoutChildrenContainer, {
          [classes.active]: isActive && !isDisabled,
          [classes.disabled]: isDisabled,
          [classes.closed]: navIsCollapsed,
        })}
        onClick={(evt) => {
          if (isDisabled) evt.preventDefault();
          onNavToggle();
        }}
        data-test-id={`navigationBar.${route.feature}Button`}
      >
        <div className={classes.icon}>
          {navIsCollapsed && <route.collapseSettings.closed.icon />}
          {propsNavIsCollapsed && !navIsCollapsed && <route.collapseSettings.closed.icon />}
          {!propsNavIsCollapsed && <route.collapseSettings.open.icon />}
        </div>
        {!navIsCollapsed && propsNavIsCollapsed && (
          <div className={classes.text}>{route.collapseSettings.closed.title}</div>
        )}
        {!navIsCollapsed && !propsNavIsCollapsed && (
          <div className={classes.text}>{route.collapseSettings.open.title}</div>
        )}
      </div>
    );
  }

  return (
    <Link
      className={classnames(classes.linkWithoutChildrenContainer, {
        [classes.active]: isActive && !isDisabled,
        [classes.disabled]: isDisabled,
        [classes.closed]: navIsCollapsed,
      })}
      to={`${route.path}`}
      onClick={(evt) => {
        if (isDisabled) evt.preventDefault();
      }}
    >
      <div className={classes.icon}>
        <route.icon />
      </div>
      {!navIsCollapsed ? <div className={classes.text}>{route.title}</div> : null}
    </Link>
  );
};

export default LinkWithoutChildren;
