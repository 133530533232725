class MimeResolver {
  constructor() {
    this.types = {
      json: 'application/json',
      mp4: 'application/mp4',
      pdf: 'application/pdf',
      xml: 'application/xml',
      csv: 'text/csv',
      'htm|html': 'text/html',
    }
  }

  lookup(name) {
    if (!name || typeof name !== 'string') return null
    const parts = name.split('.')
    const extension = parts[parts.length - 1].toLocaleLowerCase()
    const key = Object.keys(this.types).find((key) => {
      if (key.includes('|')) return !!key.split('|').find((subKey) => subKey === extension)
      else return key === extension
    })

    if (key) return this.types[key]
    else return null
  }

  resolve(name) {
    if (!name) return null

    let type = this.lookup(name)

    if (!type) {
      const parts = name.split('.')

      if (parts.length < 2) return null

      type = parts[parts.length - 1]
    }

    return type.toLowerCase()
  }
}

export const mimeResolver = new MimeResolver()

export default mimeResolver
