import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { setDITCSVErrorsAction, validateCSVAction, parseCSVAction } from 'store/actions'
import { selectCSVFile, selectDITSessionId, selectIntegrationTypes } from 'store/selectors'
import { Modal, RadioInput } from 'components/system'
import { testIds as testids } from 'config'

import FileUpload from './FileUpload'
import classes from './styles.module.css'

export const NewSessionModal = ({ visible = false, onClose }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const csvFile = useSelector(selectCSVFile)
  const sessionId = useSelector(selectDITSessionId)
  const integrationTypes = useSelector(selectIntegrationTypes)
  // const integrationTypes = ['Rough'] // local testing
  const defaultSelected = integrationTypes?.length === 1 ? 0 : -1
  const [integrationTypeIdx, setIntegrationTypeIdx] = useState(defaultSelected)
  const [canContinue, setCanContinue] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const testIds = testids.dit

  const handleCloseClick = () => {
    if (onClose) onClose()
  }

  const handleRadioChange = (index) => {
    setIntegrationTypeIdx(index)
  }

  const handleUploadClick = () => {
    setIsLoading(true)

    dispatch(validateCSVAction({ sessionId, integrationType: integrationTypes[integrationTypeIdx] }))
      .then((response) => {
        return new Promise((resolve, reject) => {
          if (response.status !== 200 || response.data.state === 'invalid') {
            reject(response)
          } else {
            resolve()
          }
        })
      })
      .then(() => {
        return dispatch(parseCSVAction(sessionId)).then((response) => {
          if (response.status !== 200) {
            throw response
          }
        })
      })
      .then(() => {
        navigate(`/integration/dit/${sessionId}`)
      })
      .catch((errors) => {
        let errorsParsed = []

        if (!errors) {
          errorsParsed = [{ type: 'unknown', message: 'Unknown error' }]
        } else {
          // if errors has a status, then its coming from back-end
          if (errors.status) {
            // if errors.data.errors exists, it's a validation error
            // if not, it's an unhandled exception from dit
            if (errors.data.errors) {
              errorsParsed = errors.data.errors
            } else {
              errorsParsed = [
                {
                  category: 'custom',
                  col: null,
                  description: 'An unexpected error occurred, please contact support.',
                  row: null,
                  type: 'dit_exception',
                },
              ]
            }
          } else if (Array.isArray(errors)) {
            errorsParsed = errors
          }
        }

        dispatch(setDITCSVErrorsAction(errorsParsed))

        navigate(`/integration/dit/new-session/validation`)
      })
  }

  const prepareTypes = (types) => {
    return types.map((type) => {
      return { value: type, label: type }
    })
  }

  const validate = () => {
    if (!csvFile.content.length || integrationTypeIdx < 0 || !sessionId || isLoading) return setCanContinue(false)

    setCanContinue(true)
  }

  useEffect(() => {
    // defaultSelected may not have been set correctly on mount so we'll setIntegrationTypeIdx here
    if (integrationTypes?.length === 1) {
      setIntegrationTypeIdx(defaultSelected)
    }
    validate()
  }, [integrationTypeIdx, sessionId, isLoading, csvFile?.content?.length, defaultSelected]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isOpen={visible}
      width={668}
      title="New session"
      confirmLabel="Upload"
      onConfirm={handleUploadClick}
      confirmDisabled={!canContinue}
      confirmTestId={testIds.newSessionModalUploadButton}
      closeTestId={testIds.newSessionModalCloseButton}
      onClose={handleCloseClick}
    >
      <div className={classes.newSessionModalContainer} data-test-id={testIds.newSessionModal}>
        <div className={classes.radioInput}>
          <RadioInput
            defaultSelected={defaultSelected}
            items={prepareTypes(integrationTypes)}
            label="Integration type"
            testId={testIds.newSessionModalRadio}
            onChange={handleRadioChange}
          />
        </div>
        <div className={classes.fileInput}>
          <FileUpload testId={testIds.newSessionModalFileUpload} />
        </div>
      </div>
    </Modal>
  )
}

export default NewSessionModal
