import { clone } from 'ramda'
import { SHARE_ACTION_TYPES } from '../actionTypes'

const initialState = {
  // isLoading: null,
  // error: null,
  data: {
    diamondsSelected: [], // ids
  },
}

const shareReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case SHARE_ACTION_TYPES.SELECT_DIAMONDS_TO_SHARE: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          diamondsSelected: payload,
        },
      }
    }
    case SHARE_ACTION_TYPES.REMOVE_DIAMOND_FROM_SHARE: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          diamondsSelected: newState.data.diamondsSelected.filter((id) => id !== payload),
        },
      }
    }

    default:
      return state
  }
}

export default shareReducer
