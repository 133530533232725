import API from 'api'
import showNotification from 'containers/Notifications/controller'
import messages from 'text/messages'
import { USER_ACTION_TYPES as ACTION_TYPES, SESSION_ACTION_TYPES, USER_ACTION_TYPES } from '../actionTypes'

export function loginAction({ username, password }) {
  return (dispatch) => {
    dispatch({ type: ACTION_TYPES.LOGIN.REQUEST })

    return API.login(username, password).then(
      (response) => {
        dispatch({ type: SESSION_ACTION_TYPES.START, payload: response.headers.authorization })
        dispatch({ type: ACTION_TYPES.LOGIN.COMPLETE, payload: response.data.content })

        showNotification({
          title: messages.login.success,
          variant: 'success',
        })
      },
      (error) => {
        dispatch({ type: ACTION_TYPES.LOGIN.FAILURE, payload: error })
      }
    )
  }
}

// because session expired is called from many places
// and can be called at the same time, a threshold is needed
export const sessionExpiredAction = (() => {
  const threshold = 300 // once every 300ms
  let lastExecutionTimestamp = 0

  return () => {
    // allow execution once every ${threshold} ms
    if (new Date().getTime() - lastExecutionTimestamp >= threshold) {
      lastExecutionTimestamp = new Date().getTime()
    } else return () => {}

    return (dispatch) => {
      dispatch({ type: USER_ACTION_TYPES.LOGOUT.COMPLETE })
      dispatch({ type: SESSION_ACTION_TYPES.END })

      showNotification({
        title: messages.session.expired,
        variant: 'error',
      })
    }
  }
})()

export function logoutAction() {
  return (dispatch) => {
    dispatch({ type: ACTION_TYPES.LOGOUT.REQUEST })

    return API.logout()
      .then(
        () => {
          dispatch({ type: SESSION_ACTION_TYPES.END })
          dispatch({ type: ACTION_TYPES.LOGOUT.COMPLETE })

          document.cookie = 'SID=;expires=Thu, 01 Jan 1970 00:00:01 GMT'
        },
        (error) => {
          dispatch({ type: ACTION_TYPES.LOGOUT.FAILURE, payload: error })
        }
      )
      .finally(() => {
        showNotification({
          title: messages.logout.success,
          variant: 'success',
        })
      })
  }
}

export function loadUserDataAction() {
  return (dispatch) => {
    dispatch({ type: ACTION_TYPES.LOAD_USER.REQUEST })

    return API.loadUserData().then(
      (response) => {
        dispatch({ type: ACTION_TYPES.LOAD_USER.COMPLETE, payload: response.data.content })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: ACTION_TYPES.LOAD_USER.FAILURE, payload: error })
        }
      }
    )
  }
}
