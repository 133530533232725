import React, { useState, useEffect } from 'react'

import { Modal, Input } from 'components/system'

import classes from './styles.module.css'
import { testIds } from 'config/testIds'

// example recipient URL
// https://api.96cda698-f0cd-422a-aa70-ca6b896d45d8.dev.tracr.com
const recipientRegex = /^https:\/\/api\..+\.tracr\.com$/
const baseValidation = { state: '', message: '' }

export const ShipmentNameModal = ({ visible = false, shipment, onConfirm, onCancel }) => {
  const [transfer, setTransfer] = useState({})
  const [isTransferValid, setIsTransferValid] = useState(false)
  const [validation, setValidation] = useState({ name: baseValidation, recipient: baseValidation })

  const handleInputChange = (value, name) => {
    if (name === 'shipment_name') {
      setTransfer({ ...transfer, shipment_name: value })
    } else if (name === 'receiver') {
      setTransfer({ ...transfer, receiver: value })
    }
  }

  const handleConfirm = () => {
    onConfirm(transfer)
  }

  const validate = () => {
    let isValid = true
    const newValidation = { name: { ...baseValidation }, recipient: { ...baseValidation } }

    if (!transfer.shipment_name) {
      isValid = false
    } else if (transfer.shipment_name.startsWith(' ') || transfer.shipment_name.endsWith(' ')) {
      newValidation.name = {
        state: 'error',
        message: 'Name cannot start or end with a space.',
      }
    } else if (transfer.shipment_name.length < 3) {
      newValidation.name = {
        state: 'error',
        message: 'Name must be at least 3 characters long.',
      }
    } else {
      newValidation.name = {
        state: 'success',
        message: 'Name is valid.',
      }
    }

    if (!transfer.receiver) {
      isValid = false
    } else if (transfer?.receiver && !recipientRegex.test(transfer?.receiver)) {
      newValidation.recipient = {
        state: 'error',
        message: 'Please enter a valid recipient URL.',
      }
      isValid = false
    } else {
      newValidation.recipient = {
        state: 'success',
        message: 'Recipient URL is accepted.',
      }
    }

    setValidation(newValidation)
    setIsTransferValid(isValid)
  }

  useEffect(() => {
    validate()
  }, [transfer.shipment_name, transfer.receiver])

  return (
    <Modal
      isOpen={visible}
      width={668}
      title="SEND SHIPMENT"
      confirmLabel="Send shipment"
      confirmDisabled={!isTransferValid}
      confirmTestId={testIds.newShipment.nameModal.confirmButton}
      cancelTestId={testIds.newShipment.nameModal.cancelButton}
      onConfirm={handleConfirm}
      onClose={onCancel}
    >
      <div className={classes.shipmentNameModalContainer}>
        <div className={classes.name}>
          <Input
            label="Name"
            placeholder="Enter a name that is unique and relevant for this shipment"
            value={transfer.shipment_name}
            onChange={(value) => handleInputChange(value, 'shipment_name')}
            testId={testIds.newShipment.nameModal.nameInput}
            message={validation.name.message}
            state={validation.name.state}
          />
        </div>
        <div className={classes.recipient}>
          <Input
            label="Recipient"
            placeholder="Enter the recipient URL for this transfer"
            value={transfer.receiver}
            onChange={(value) => handleInputChange(value, 'receiver')}
            testId={testIds.newShipment.nameModal.recipientInput}
            message={validation.recipient.message}
            state={validation.recipient.state}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ShipmentNameModal
