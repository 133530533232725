import { clone } from 'ramda'

// import urls from 'config/urls'
import { STORE_INIT } from '../actionTypes'
import { LOCATION_ACTION_TYPES, LIFECYCLE_ACTION_TYPES } from '../actionTypes'
// when page refreshes, make sure that initial stage
// has the right lifecycle set

const STAGES = ['rough', 'split', 'planning', 'sawing', 'bruting', 'polishing', 'polished', 'graded']

const getPaths = (pathname) => pathname.split('/')

const hasAppliedStates = (pathname) => {
  const paths = getPaths(pathname)

  if (STAGES.includes(paths[2])) return true
  else return false
}

const getAppliedStage = (pathname = window.location.pathname) => {
  const paths = getPaths(pathname)

  if (STAGES.includes(paths[2])) return paths[2]
  else return ''
}

const initialState = {
  state: '',
  stage: '',
  available: [],
}

const stageToState = (state) => {
  const stages = {
    rough: 'rough',
    split: 'rough',
    planning: 'intermediate',
    sawing: 'intermediate',
    bruting: 'intermediate',
    polishing: 'intermediate',
    polished: 'polished',
    graded: 'polished',
  }

  return stages[state] || ''
}

const lifecycleReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case STORE_INIT:
      const stage = getAppliedStage()

      return {
        ...state,
        state: stageToState(stage),
        stage,
      }

    case LOCATION_ACTION_TYPES.CHANGE: {
      const newState = clone(state)
      const stage = getAppliedStage(payload.pathname)
      const hasAppliedState = hasAppliedStates(payload.pathname)

      if (!hasAppliedState)
        return {
          ...newState,
          state: '',
          stage: '',
        }

      return {
        ...newState,
        state: stageToState(stage),
        stage,
      }
    }
    case LIFECYCLE_ACTION_TYPES.GET_AVAILABLE.REQUEST: {
      return state
    }
    case LIFECYCLE_ACTION_TYPES.GET_AVAILABLE.COMPLETE: {
      const newState = clone(state)

      return {
        ...newState,
        available: payload,
      }
    }
    case LIFECYCLE_ACTION_TYPES.GET_AVAILABLE.FAILURE: {
      return state
    }
    default:
      return state
  }
}

export default lifecycleReducer
