import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'

import { MoreIcon } from 'components/Svg'

import ContextMenu from '../ContextMenu'
import classes from './styles.module.css'

export const ActionsCell = ({ row, items = [], onClick }) => {
  const containerRef = useRef()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleClick = () => {
    if (row.rowDisabled || row.actionDisabled) return

    setIsMenuOpen(true)

    /*
    const isOpen = contextMenuId === id

    if (isOpen) {
      dispatch(setContextMenuIdAction(null))
    } else {
      dispatch(setContextMenuIdAction(id))
    }
    */
  }

  useEffect(() => {
    const onClick = () => {
      // const container = containerRef.current

      window.removeEventListener('click', onClick)

      setIsMenuOpen(false)

      /*
      if (!container.contains(evt.target) || !evt.target.closest('[data-contextmenu]')) {
        window.removeEventListener('click', onClick)

        setIsMenuOpen(false)
      }
      */
    }

    if (isMenuOpen) {
      setTimeout(() => {
        window.addEventListener('click', onClick)
      })
    }
  }, [isMenuOpen])

  useEffect(() => {
    /*
    if (row.rowDisabled) return

    const isAction = (node) => {
      if (node.dataset && node.dataset.actions) return true
      else if (node.parentNode) return isAction(node.parentNode)
      else return false
    }

    const onClick = (evt) => {
      if (isAction(evt.target)) return

      // dispatch(setContextMenuIdAction(null))
    }

    window.addEventListener('click', onClick)

    return () => window.removeEventListener('click', onClick)
    */
  })

  return (
    <div
      className={classnames(classes.actionsCellContainer, {
        [classes.disabled]: row.rowDisabled || row.actionDisabled,
      })}
      data-actions={true}
      onClick={handleClick}
      ref={containerRef}
    >
      <MoreIcon />
      {!row.rowDisabled && !row.actionDisabled ? (
        <ContextMenu
          items={items.filter((item) => (item.condition ? item.condition(row) : true))}
          isOpen={isMenuOpen}
          onItemClick={(key) => onClick(row, key)}
        />
      ) : null}
    </div>
  )
}

export default ActionsCell
