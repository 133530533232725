import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import { AcceptIcon, WarningIcon } from 'components/system/SVG'

import InputLoader from '../InputLoader'
import classes from './styles.module.css'

export const Input = ({
  type = 'text',
  value = '',
  label,
  placeholder,
  isLoading = false,
  state,
  message,
  testId,
  onChange,
}) => {
  const [realValue, setRealValue] = useState(value)

  const resolveIcon = () => {
    switch (state) {
      case 'error':
        return <WarningIcon fill="#EC123B" />
      case 'success':
        return <AcceptIcon fill="#00B707" />
    }
  }

  const handleOnChange = (evt) => {
    setRealValue(evt.target.value)

    if (onChange) onChange(evt.target.value)
  }

  return (
    <div className={classes.inputContainer} {...(testId ? { 'data-test-id': `${testId}.inputContainer` } : null)}>
      <div className={classes.wrapper}>
        {label ? <div className={classes.label}>{label}</div> : null}
        <div className={classes.inputWrapper}>
          <input
            className={classnames(classes.input, { [classes[state]]: state })}
            type={type}
            value={realValue}
            placeholder={placeholder}
            onChange={handleOnChange}
            {...(testId ? { 'data-test-id': `${testId}.input` } : null)}
          />
          <div className={classnames(classes.actions, { [classes[state]]: state })}>
            {isLoading ? (
              <div className={classes.loader}>
                <InputLoader />
              </div>
            ) : null}
          </div>
        </div>
        {message ? (
          <div className={classes.message}>
            <div className={classes.icon}>{resolveIcon()}</div>
            <div className={classnames(classes.text, { [classes[state]]: state })}>{message}</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Input
