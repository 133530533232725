import React, { useState } from 'react'

import { DragAndDrop, Filebox } from 'components/system'
import { WarningIcon } from 'components/system/SVG'

import classes from './styles.module.css'

export const FileUpload = ({ file, uploadState, inputError, onFilesChange, testIdPrefix }) => {
  const [fileMeta, setFileMeta] = useState(null) // not actual file but its meta data

  const handleFileInput = (files) => {
    const file = files.length ? files[0] : files
    // const nameParts = file.name.split('.')
    // const extension = nameParts[nameParts.length - 1]

    setFileMeta({ name: file.name, size: file.size })

    onFilesChange(files)
  }

  const handleFileRemove = () => {
    setFileMeta(null)

    onFilesChange(null)
  }

  return (
    <div
      className={classes.fileUploadContainer}
      {...(testIdPrefix ? { 'data-test-id': `${testIdPrefix}.fileUploadContainer` } : null)}
    >
      <div className={classes.wrapper}>
        <div className={classes.label}>Upload diamonds</div>
        <div className={classes.subLabel}>
          Provide a CSV file with the <span>Tracr IDs</span> of the diamonds to want to send.
        </div>

        <div className={classes.uploadZone}>
          {fileMeta ? (
            <Filebox file={fileMeta} status={uploadState} onClose={handleFileRemove} testId={testIdPrefix} />
          ) : (
            <DragAndDrop withIcon onFiles={handleFileInput} testId={testIdPrefix} />
          )}
        </div>
        {inputError ? (
          <div className={classes.messageWrapper}>
            <div className={classes.message}>
              <div className={classes.icon}>
                <WarningIcon fill="#EC123B" />
              </div>
              <div className={classes.title}>
                {inputError.type === 'validation' ? inputError.text : inputError.title}
              </div>
            </div>

            <div className={classes.hint}>
              Please see our&nbsp;
              <a href="https://docs.tracr.com/docs/user-documentation/k1n9j0lxofq9w-integration-tool" target="_blank">
                help docs
              </a>
              &nbsp;for guidance on file requirements.
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default FileUpload
