import http from '../utils/http'

const GET_WIZARD = 'wizard'
const GET_DEFAULT_WIZARD = 'wizard/default-job'

const getWizard = () => {
  return http.get(`${GET_WIZARD}`)
}

const getDefaultWizard = () => {
  return http.get(`${GET_DEFAULT_WIZARD}`)
}

const createWizard = (body) => {
  return http.post(`${GET_WIZARD}`, body)
}

const endpoints = {
  getWizard,
  getDefaultWizard,
  createWizard,
}

export default endpoints
