import React from 'react'
import { nanoid } from 'nanoid'

const FlagSVGWrapper = ({ size = 'm', children }) => {
  const sizes = {
    s: { widthHeight: 24, xy: 2, maskWidthHeight: 20, r: 10 },
    m: { widthHeight: 32, xy: 2, maskWidthHeight: 28, r: 13.3333 },
    l: { widthHeight: 48, xy: 4, maskWidthHeight: 40, r: 20 },
    xl: { widthHeight: 96, xy: 8, maskWidthHeight: 80, r: 40 },
  }

  const selected = sizes[size] ? sizes[size] : sizes.m
  const id = nanoid()

  return (
    <svg
      data-test-id="icon"
      width={selected.widthHeight}
      height={selected.widthHeight}
      viewBox={`0 0 ${selected.widthHeight} ${selected.widthHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id={id}
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x={selected.xy}
        y={selected.xy}
        width={selected.maskWidthHeight}
        height={selected.maskWidthHeight}
      >
        <circle cx={selected.widthHeight / 2} cy={selected.widthHeight / 2} r={selected.r} fill="#C4C4C4" />
      </mask>
      <g mask={`url(#${id})`}>{children}</g>
    </svg>
  )
}

export const FlagBotswanaSVG = ({ size = 'm' }) => {
  const svgs = {
    s: (
      <FlagSVGWrapper size={size}>
        <path fillRule="evenodd" clipRule="evenodd" d="M2 2H22V22H2V2Z" fill="#498BEC" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2 8.66675H22V15.3334H2V8.66675Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2 9.75H22V14.25H2V9.75Z" fill="black" />
      </FlagSVGWrapper>
    ),
    m: (
      <FlagSVGWrapper size={size}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66669 2.66663H29.3334V29.3333H2.66669V2.66663Z"
          fill="#498BEC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66669 11.5554H29.3334V20.4443H2.66669V11.5554Z"
          fill="white"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.66669 13H29.3334V19H2.66669V13Z" fill="black" />
      </FlagSVGWrapper>
    ),
    l: (
      <FlagSVGWrapper size={size}>
        <path fillRule="evenodd" clipRule="evenodd" d="M4 4H44V44H4V4Z" fill="#498BEC" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4 17.3333H44V30.6666H4V17.3333Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4 19.5H44V28.5H4V19.5Z" fill="black" />
      </FlagSVGWrapper>
    ),
    xl: (
      <FlagSVGWrapper size={size}>
        <path fillRule="evenodd" clipRule="evenodd" d="M8 8H88V88H8V8Z" fill="#498BEC" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8 34.6665H88V61.3332H8V34.6665Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8 39H88V57H8V39Z" fill="black" />
      </FlagSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const FlagCanadaSVG = ({ size = 'm' }) => {
  const svgs = {
    s: (
      <FlagSVGWrapper size={size}>
        <path d="M5.125 2H18.875V22H5.125V2Z" fill="white" />
        <path
          d="M0.75 3.875H6.37422V20.125H0.75V3.875ZM17.6258 3.875H23.25V20.125H17.6289L17.6258 3.875ZM8.06056 11.7271L7.61957 11.8794L9.65373 13.7043C9.80901 14.1709 9.60093 14.3074 9.46739 14.5549L11.6724 14.2693L11.6196 16.5386L12.0792 16.5259L11.9767 14.2725L14.1879 14.5422C14.0512 14.2471 13.9301 14.0916 14.0543 13.6218L16.0854 11.8921L15.7314 11.7588C15.4394 11.5303 15.8556 10.6575 15.9177 10.1052C15.9177 10.1052 14.7345 10.521 14.6568 10.302L14.3525 9.7085L13.2748 10.9209C13.1568 10.9495 13.1071 10.9019 13.0792 10.8003L13.5761 8.26758L12.7873 8.72144C12.722 8.75317 12.6568 8.72778 12.6134 8.64844L11.8525 7.09326L11.0699 8.70874C11.0109 8.76587 10.9519 8.77222 10.9022 8.73413L10.1506 8.30249L10.604 10.813C10.5668 10.9146 10.4798 10.9399 10.3804 10.886L9.34627 9.68945C9.21273 9.91162 9.12267 10.2703 8.94255 10.3528C8.76553 10.429 8.16615 10.2004 7.76553 10.1116C7.90217 10.6162 8.33075 11.4541 8.06056 11.7302V11.7271Z"
          fill="#C72A31"
        />
      </FlagSVGWrapper>
    ),
    m: (
      <FlagSVGWrapper size={size}>
        <path d="M6.83331 2.66663H25.1666V29.3333H6.83331V2.66663Z" fill="white" />
        <path
          d="M1 5.16663H8.49897V26.8333H1V5.16663ZM23.501 5.16663H31V26.8333H23.5052L23.501 5.16663ZM10.7474 15.636L10.1594 15.8392L12.8716 18.2724C13.0787 18.8945 12.8012 19.0765 12.6232 19.4065L15.5631 19.0257L15.4928 22.0514L16.1056 22.0345L15.9689 19.0299L18.9172 19.3896C18.735 18.9961 18.5735 18.7887 18.7391 18.1624L21.4472 15.8561L20.9752 15.6783C20.5859 15.3737 21.1408 14.2099 21.2236 13.4736C21.2236 13.4736 19.646 14.028 19.5424 13.736L19.1366 12.9446L17.6998 14.5612C17.5424 14.5992 17.4762 14.5358 17.4389 14.4004L18.1014 11.0234L17.0497 11.6285C16.9627 11.6709 16.8758 11.637 16.8178 11.5312L15.8033 9.45764L14.7598 11.6116C14.6812 11.6878 14.6025 11.6962 14.5362 11.6455L13.5342 11.0699L14.1387 14.4173C14.089 14.5527 13.9731 14.5865 13.8406 14.5146L12.4617 12.9192C12.2836 13.2155 12.1636 13.6936 11.9234 13.8037C11.6874 13.9052 10.8882 13.6005 10.354 13.4821C10.5362 14.1549 11.1077 15.2721 10.7474 15.6403V15.636Z"
          fill="#C72A31"
        />
      </FlagSVGWrapper>
    ),
    l: (
      <FlagSVGWrapper size={size}>
        <path d="M10.25 4H37.75V44H10.25V4Z" fill="white" />
        <path
          d="M1.5 7.75H12.7484V40.25H1.5V7.75ZM35.2516 7.75H46.5V40.25H35.2578L35.2516 7.75ZM16.1211 23.4541L15.2391 23.7588L19.3075 27.4087C19.618 28.3418 19.2019 28.6147 18.9348 29.1099L23.3447 28.5386L23.2391 33.0771L24.1584 33.0518L23.9534 28.5449L28.3758 29.0845C28.1025 28.4941 27.8602 28.1831 28.1087 27.2437L32.1708 23.7842L31.4627 23.5176C30.8789 23.0605 31.7112 21.3149 31.8354 20.2104C31.8354 20.2104 29.4689 21.042 29.3137 20.604L28.705 19.417L26.5497 21.8418C26.3137 21.8989 26.2143 21.8037 26.1584 21.6006L27.1522 16.5352L25.5745 17.4429C25.4441 17.5063 25.3137 17.4556 25.2267 17.2969L23.705 14.1865L22.1398 17.4175C22.0217 17.5317 21.9037 17.5444 21.8043 17.4683L20.3012 16.605L21.2081 21.626C21.1335 21.8291 20.9596 21.8799 20.7609 21.772L18.6925 19.3789C18.4255 19.8232 18.2453 20.5405 17.8851 20.7056C17.5311 20.8579 16.3323 20.4009 15.5311 20.2231C15.8043 21.2324 16.6615 22.9082 16.1211 23.4604V23.4541Z"
          fill="#C72A31"
        />
      </FlagSVGWrapper>
    ),
    xl: (
      <FlagSVGWrapper size={size}>
        <path d="M20.5 8H75.5V88H20.5V8Z" fill="white" />
        <path
          d="M3 15.5H25.4969V80.5H3V15.5ZM70.5031 15.5H93V80.5H70.5155L70.5031 15.5ZM32.2422 46.9082L30.4783 47.5176L38.6149 54.8174C39.236 56.6836 38.4037 57.2295 37.8696 58.2197L46.6894 57.0771L46.4783 66.1543L48.3168 66.1035L47.9068 57.0898L56.7516 58.1689C56.205 56.9883 55.7205 56.3662 56.2174 54.4873L64.3416 47.5684L62.9255 47.0352C61.7578 46.1211 63.4224 42.6299 63.6708 40.4209C63.6708 40.4209 58.9379 42.084 58.6273 41.208L57.4099 38.834L53.0994 43.6836C52.6273 43.7979 52.4286 43.6074 52.3168 43.2012L54.3043 33.0703L51.1491 34.8857C50.8882 35.0127 50.6273 34.9111 50.4534 34.5938L47.4099 28.373L44.2795 34.835C44.0435 35.0635 43.8075 35.0889 43.6087 34.9365L40.6025 33.21L42.4161 43.252C42.2671 43.6582 41.9193 43.7598 41.5217 43.5439L37.3851 38.7578C36.8509 39.6465 36.4907 41.0811 35.7702 41.4111C35.0621 41.7158 32.6646 40.8018 31.0621 40.4463C31.6087 42.4648 33.323 45.8164 32.2422 46.9209V46.9082Z"
          fill="#C72A31"
        />
      </FlagSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const FlagSierraLeoneSVG = ({ size = 'm' }) => {
  const svgs = {
    s: (
      <FlagSVGWrapper size={size}>
        <path fillRule="evenodd" clipRule="evenodd" d="M2 15.4375H22V22H2V15.4375Z" fill="#498BEC" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2 8.5625H22V15.4375H2V8.5625Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2 2H22V8.5625H2V2Z" fill="#7AA251" />
      </FlagSVGWrapper>
    ),
    m: (
      <FlagSVGWrapper size={size}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66666 20.5833H29.3333V29.3333H2.66666V20.5833Z"
          fill="#498BEC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66666 11.4165H29.3333V20.5832H2.66666V11.4165Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66666 2.66663H29.3333V11.4166H2.66666V2.66663Z"
          fill="#7AA251"
        />
      </FlagSVGWrapper>
    ),
    l: (
      <FlagSVGWrapper size={size}>
        <path fillRule="evenodd" clipRule="evenodd" d="M4 30.875H44V44H4V30.875Z" fill="#498BEC" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4 17.125H44V30.875H4V17.125Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4 4H44V17.125H4V4Z" fill="#7AA251" />
      </FlagSVGWrapper>
    ),
    xl: (
      <FlagSVGWrapper size={size}>
        <path fillRule="evenodd" clipRule="evenodd" d="M8 61.75H88V88H8V61.75Z" fill="#498BEC" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8 34.25H88V61.75H8V34.25Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8 8H88V34.25H8V8Z" fill="#7AA251" />
      </FlagSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const FlagSouthAfricaSVG = ({ size = 'm' }) => {
  const svgs = {
    s: (
      <FlagSVGWrapper size={size}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.00056 17.9279V6.07935L7.88148 11.9996L2.00056 17.9279Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.01366 22.003L12.615 15.3381H21.9967V22.003H6.01366Z"
          fill="#1C54AE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.58942 2.00045H21.9994V8.66928H12.6124C12.6124 8.66928 5.67747 1.93641 5.58942 2.00045Z"
          fill="#C72A31"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.00056 4.5022V6.07534L7.88148 11.9996L2.00056 17.9239V19.501L9.40774 11.9996L2.00056 4.5022Z"
          fill="#FADA61"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 4.50425V2.00244H4.47084L12.3156 9.96821H21.9962V14.0392H12.3156L4.47084 22.0009H2V19.5031L9.40718 12.0017L2 4.50425Z"
          fill="#7AA251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.47141 2.00049H6.01368L12.615 8.66932H21.9968V9.96625H12.3162L4.47141 2.00049ZM4.47141 22.003H6.01368L12.615 15.3381H21.9968V14.0412H12.3162L4.47141 21.999V22.003Z"
          fill="white"
        />
      </FlagSVGWrapper>
    ),
    m: (
      <FlagSVGWrapper size={size}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66742 23.9038V8.10571L10.5086 15.9994L2.66742 23.9038Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.01819 29.3371L16.82 20.4507H29.329V29.3371H8.01819Z"
          fill="#1C54AE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.45255 2.66723H29.3325V11.559H16.8165C16.8165 11.559 7.56995 2.58184 7.45255 2.66723Z"
          fill="#C72A31"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66742 6.00305V8.10057L10.5086 15.9996L2.66742 23.8987V26.0015L12.5437 15.9996L2.66742 6.00305Z"
          fill="#FADA61"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66666 6.00567V2.66992H5.96111L16.4208 13.2909H29.3283V18.7189H16.4208L5.96111 29.3346H2.66666V26.0041L12.5429 16.0022L2.66666 6.00567Z"
          fill="#7AA251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.96185 2.66711H8.01822L16.82 11.5589H29.329V13.2881H16.4216L5.96185 2.66711ZM5.96185 29.3371H8.01822L16.82 20.4507H29.329V18.7214H16.4216L5.96185 29.3317V29.3371Z"
          fill="white"
        />
      </FlagSVGWrapper>
    ),
    l: (
      <FlagSVGWrapper size={size}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.00113 35.8558V12.1587L15.763 23.9993L4.00113 35.8558Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0273 44.0059L25.23 30.6763H43.9935V44.0059H12.0273Z"
          fill="#1C54AE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1788 4.00091H43.9988V17.3386H25.2247C25.2247 17.3386 11.3549 3.87282 11.1788 4.00091Z"
          fill="#C72A31"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.00113 9.00464V12.1509L15.763 23.9995L4.00113 35.8481V39.0023L18.8155 23.9995L4.00113 9.00464Z"
          fill="#FADA61"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 9.0085V4.00488H8.94168L24.6313 19.9364H43.9924V28.0783H24.6313L8.94168 44.0018H4V39.0062L18.8144 24.0034L4 9.0085Z"
          fill="#7AA251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.94281 4.00073H12.0274L25.2301 17.3384H43.9935V19.9323H24.6324L8.94281 4.00073ZM8.94281 44.0057H12.0274L25.2301 30.676H43.9935V28.0822H24.6324L8.94281 43.9977V44.0057Z"
          fill="white"
        />
      </FlagSVGWrapper>
    ),
    xl: (
      <FlagSVGWrapper size={size}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00226 71.7117V24.3174L31.5259 47.9985L8.00226 71.7117Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.0546 88.0118L50.4601 61.3525H87.9869V88.0118H24.0546Z"
          fill="#1C54AE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.3577 8.00182H87.9977V34.6771H50.4495C50.4495 34.6771 22.7099 7.74563 22.3577 8.00182Z"
          fill="#C72A31"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00226 18.0093V24.3018L31.5259 47.999L8.00226 71.6961V78.0047L37.631 47.999L8.00226 18.0093Z"
          fill="#FADA61"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 18.017V8.00977H17.8834L49.2625 39.8728H87.9848V56.1566H49.2625L17.8834 88.0037H8V78.0124L37.6287 48.0067L8 18.017Z"
          fill="#7AA251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.8856 8.00146H24.0547L50.4601 34.6768H87.987V39.8645H49.2648L17.8856 8.00146ZM17.8856 88.0114H24.0547L50.4601 61.3521H87.987V56.1643H49.2648L17.8856 87.9954V88.0114Z"
          fill="white"
        />
      </FlagSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const FlagNamibiaSVG = ({ size = 'm' }) => {
  const svgs = {
    s: (
      <FlagSVGWrapper size={size}>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.22656 2H21.2266V22H1.22656V2Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3 2.00781L-2.96875 16.4062L19.4531 2L-3 2.00781Z"
          fill="#1C54AE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.8633 21.9805L25.8086 7.01172L3.19141 21.9922L25.8633 21.9805Z"
          fill="#516C35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-2.98828 17.4922L-2.96875 22L1.92578 21.9922L25.8203 5.97266L25.7969 2.05469L20.9609 2.00781L-2.98438 17.4922H-2.98828Z"
          fill="#951D24"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.98836 9.21253L6.2971 8.79329L5.89611 9.5L5.50709 8.7873L4.80385 9.20055L4.82181 8.38903L4.01384 8.39502L4.43279 7.70029L3.72656 7.29902L4.43877 6.90974L4.02581 6.20602L4.83378 6.22399L4.83079 5.41247L5.52504 5.83171L5.92603 5.125L6.31505 5.8347L7.02427 5.42745L7.00632 6.23597L7.81429 6.23297L7.39534 6.9277L8.10156 7.32598L7.38935 7.71826L7.79932 8.41898L6.99135 8.404L6.98836 9.21253Z"
          fill="#FFE700"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.85036 7.26504C6.85679 7.39199 6.83735 7.51892 6.7932 7.63812C6.74905 7.75731 6.68112 7.86629 6.59355 7.95842C6.50597 8.05054 6.40057 8.1239 6.28377 8.17403C6.16696 8.22415 6.04117 8.25 5.91406 8.25C5.78695 8.25 5.66117 8.22415 5.54436 8.17403C5.42755 8.1239 5.32215 8.05054 5.23458 7.95842C5.147 7.86629 5.07908 7.75731 5.03493 7.63812C4.99078 7.51892 4.97133 7.39199 4.97776 7.26504C4.98993 7.02493 5.09389 6.79866 5.26815 6.63302C5.4424 6.46737 5.67364 6.375 5.91406 6.375C6.15449 6.375 6.38572 6.46737 6.55998 6.63302C6.73423 6.79866 6.83819 7.02493 6.85036 7.26504Z"
          fill="#FFE700"
        />
      </FlagSVGWrapper>
    ),
    m: (
      <FlagSVGWrapper size={size}>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.63538 2.66663H28.302V29.3333H1.63538V2.66663Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4 2.67704L-3.95833 21.875L25.9375 2.66663L-4 2.67704Z"
          fill="#1C54AE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.4844 29.3072L34.4114 9.34888L4.25519 29.3228L34.4844 29.3072Z"
          fill="#516C35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.98438 23.323L-3.95833 29.3334L2.56771 29.323L34.4271 7.96358L34.3958 2.73962L27.9479 2.67712L-3.97917 23.323H-3.98438Z"
          fill="#951D24"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.31782 12.2833L8.39613 11.7243L7.86148 12.6666L7.34278 11.7163L6.40514 12.2673L6.42908 11.1853L5.35179 11.1933L5.91038 10.267L4.96875 9.73195L5.91836 9.2129L5.36775 8.27462L6.44504 8.29857L6.44105 7.21655L7.36672 7.77553L7.90138 6.83325L8.42007 7.77952L9.36569 7.23651L9.34176 8.31454L10.419 8.31055L9.86045 9.23686L10.8021 9.76789L9.85247 10.2909L10.3991 11.2252L9.32181 11.2053L9.31782 12.2833Z"
          fill="#FFE700"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.13377 9.68672C9.14235 9.85599 9.11642 10.0252 9.05756 10.1842C8.99869 10.3431 8.90812 10.4884 8.79136 10.6112C8.67459 10.7341 8.53406 10.8319 8.37831 10.8987C8.22257 10.9655 8.05486 11 7.88538 11C7.7159 11 7.54819 10.9655 7.39244 10.8987C7.23669 10.8319 7.09617 10.7341 6.9794 10.6112C6.86263 10.4884 6.77206 10.3431 6.7132 10.1842C6.65433 10.0252 6.6284 9.85599 6.63698 9.68672C6.65321 9.36657 6.79181 9.06488 7.02415 8.84402C7.2565 8.62316 7.56481 8.5 7.88538 8.5C8.20594 8.5 8.51426 8.62316 8.7466 8.84402C8.97894 9.06488 9.11755 9.36657 9.13377 9.68672Z"
          fill="#FFE700"
        />
      </FlagSVGWrapper>
    ),
    l: (
      <FlagSVGWrapper size={size}>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.45312 4H42.4531V44H2.45312V4Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6 4.01562L-5.9375 32.8125L38.9063 4L-6 4.01562Z"
          fill="#1C54AE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.7266 43.9609L51.6172 14.0234L6.38281 43.9844L51.7266 43.9609Z"
          fill="#516C35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.97656 34.9844L-5.9375 44L3.85156 43.9844L51.6406 11.9453L51.5938 4.10937L41.9219 4.01562L-5.96875 34.9844H-5.97656Z"
          fill="#951D24"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9767 18.4251L12.5942 17.5866L11.7922 19L11.0142 17.5746L9.60771 18.4011L9.64362 16.7781L8.02768 16.79L8.86557 15.4006L7.45312 14.598L8.87754 13.8195L8.05162 12.412L9.66756 12.448L9.66157 10.8249L11.0501 11.6634L11.8521 10.25L12.6301 11.6694L14.0485 10.8549L14.0126 12.4719L15.6286 12.4659L14.7907 13.8554L16.2031 14.652L14.7787 15.4365L15.5986 16.838L13.9827 16.808L13.9767 18.4251Z"
          fill="#FFE700"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7007 14.5301C13.7136 14.784 13.6747 15.0378 13.5864 15.2762C13.4981 15.5146 13.3622 15.7326 13.1871 15.9168C13.0119 16.1011 12.8011 16.2478 12.5675 16.3481C12.3339 16.4483 12.0823 16.5 11.8281 16.5C11.5739 16.5 11.3223 16.4483 11.0887 16.3481C10.8551 16.2478 10.6443 16.1011 10.4692 15.9168C10.294 15.7326 10.1582 15.5146 10.0699 15.2762C9.98156 15.0378 9.94266 14.784 9.95553 14.5301C9.97987 14.0499 10.1878 13.5973 10.5363 13.266C10.8848 12.9347 11.3473 12.75 11.8281 12.75C12.309 12.75 12.7714 12.9347 13.12 13.266C13.4685 13.5973 13.6764 14.0499 13.7007 14.5301Z"
          fill="#FFE700"
        />
      </FlagSVGWrapper>
    ),
    xl: (
      <FlagSVGWrapper size={size}>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.90625 8H84.9062V88H4.90625V8Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12 8.03125L-11.875 65.625L77.8125 8L-12 8.03125Z"
          fill="#1C54AE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.453 87.9219L103.234 28.0469L12.7656 87.9688L103.453 87.9219Z"
          fill="#516C35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.9531 69.9687L-11.875 88L7.70313 87.9687L103.281 23.8906L103.188 8.21875L83.8438 8.03125L-11.9375 69.9687H-11.9531Z"
          fill="#951D24"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.9534 36.8501L25.1884 35.1732L23.5844 38L22.0283 35.1492L19.2154 36.8022L19.2872 33.5561L16.0554 33.5801L17.7311 30.8012L14.9062 29.1961L17.7551 27.6389L16.1032 24.8241L19.3351 24.896L19.3231 21.6499L22.1002 23.3268L23.7041 20.5L25.2602 23.3388L28.0971 21.7098L28.0253 24.9439L31.2571 24.9319L29.5814 27.7108L32.4062 29.3039L29.5574 30.873L31.1973 33.6759L27.9654 33.616L27.9534 36.8501Z"
          fill="#FFE700"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.4014 29.0602C27.4272 29.568 27.3494 30.0757 27.1728 30.5525C26.9962 31.0293 26.7245 31.4652 26.3742 31.8337C26.0239 32.2022 25.6023 32.4956 25.1351 32.6961C24.6678 32.8966 24.1647 33 23.6563 33C23.1478 33 22.6447 32.8966 22.1774 32.6961C21.7102 32.4956 21.2886 32.2022 20.9383 31.8337C20.588 31.4652 20.3163 31.0293 20.1397 30.5525C19.9631 30.0757 19.8853 29.568 19.9111 29.0602C19.9597 28.0997 20.3756 27.1947 21.0726 26.5321C21.7696 25.8695 22.6946 25.5 23.6563 25.5C24.6179 25.5 25.5429 25.8695 26.2399 26.5321C26.9369 27.1947 27.3528 28.0997 27.4014 29.0602Z"
          fill="#FFE700"
        />
      </FlagSVGWrapper>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const FlagDTCSVG = ({ size = 'm' }) => {
  const svgs = {
    s: (
      <svg width="24" height="24" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="48"
          cy="48"
          r="40"
          fill="#0C2C45"
          stroke="white"
          strokeWidth="2"
          vectorEffect={'non-scaling-stroke'}
        />
        <path
          vectorEffect={'non-scaling-stroke'}
          d="M29.0741 41.7H35.1161C36.5601 41.7 37.8331 41.9787 38.9351 42.536C40.0497 43.0807 40.9111 43.8533 41.5191 44.854C42.1397 45.8547 42.4501 47.02 42.4501 48.35C42.4501 49.68 42.1397 50.8453 41.5191 51.846C40.9111 52.8467 40.0497 53.6257 38.9351 54.183C37.8331 54.7277 36.5601 55 35.1161 55H29.0741V41.7ZM34.9641 52.473C36.2941 52.473 37.3517 52.1057 38.1371 51.371C38.9351 50.6237 39.3341 49.6167 39.3341 48.35C39.3341 47.0833 38.9351 46.0827 38.1371 45.348C37.3517 44.6007 36.2941 44.227 34.9641 44.227H32.1521V52.473H34.9641ZM47.3408 44.208H43.0848V41.7H54.6748V44.208H50.4188V55H47.3408V44.208ZM62.5104 55.228C61.155 55.228 59.9264 54.9367 58.8244 54.354C57.735 53.7587 56.8737 52.9417 56.2404 51.903C55.6197 50.8517 55.3094 49.6673 55.3094 48.35C55.3094 47.0327 55.6197 45.8547 56.2404 44.816C56.8737 43.7647 57.735 42.9477 58.8244 42.365C59.9264 41.7697 61.1614 41.472 62.5294 41.472C63.682 41.472 64.7207 41.6747 65.6454 42.08C66.5827 42.4853 67.368 43.068 68.0014 43.828L66.0254 45.652C65.126 44.6133 64.0114 44.094 62.6814 44.094C61.858 44.094 61.1234 44.2777 60.4774 44.645C59.8314 44.9997 59.3247 45.5 58.9574 46.146C58.6027 46.792 58.4254 47.5267 58.4254 48.35C58.4254 49.1733 58.6027 49.908 58.9574 50.554C59.3247 51.2 59.8314 51.7067 60.4774 52.074C61.1234 52.4287 61.858 52.606 62.6814 52.606C64.0114 52.606 65.126 52.0803 66.0254 51.029L68.0014 52.853C67.368 53.6257 66.5827 54.2147 65.6454 54.62C64.708 55.0253 63.663 55.228 62.5104 55.228Z"
          fill="white"
        />
      </svg>
    ),
    m: (
      <svg width="32" height="32" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="48"
          cy="48"
          r="40"
          fill="#0C2C45"
          stroke="white"
          strokeWidth="2"
          vectorEffect={'non-scaling-stroke'}
        />
        <path
          vectorEffect={'non-scaling-stroke'}
          d="M29.0741 41.7H35.1161C36.5601 41.7 37.8331 41.9787 38.9351 42.536C40.0497 43.0807 40.9111 43.8533 41.5191 44.854C42.1397 45.8547 42.4501 47.02 42.4501 48.35C42.4501 49.68 42.1397 50.8453 41.5191 51.846C40.9111 52.8467 40.0497 53.6257 38.9351 54.183C37.8331 54.7277 36.5601 55 35.1161 55H29.0741V41.7ZM34.9641 52.473C36.2941 52.473 37.3517 52.1057 38.1371 51.371C38.9351 50.6237 39.3341 49.6167 39.3341 48.35C39.3341 47.0833 38.9351 46.0827 38.1371 45.348C37.3517 44.6007 36.2941 44.227 34.9641 44.227H32.1521V52.473H34.9641ZM47.3408 44.208H43.0848V41.7H54.6748V44.208H50.4188V55H47.3408V44.208ZM62.5104 55.228C61.155 55.228 59.9264 54.9367 58.8244 54.354C57.735 53.7587 56.8737 52.9417 56.2404 51.903C55.6197 50.8517 55.3094 49.6673 55.3094 48.35C55.3094 47.0327 55.6197 45.8547 56.2404 44.816C56.8737 43.7647 57.735 42.9477 58.8244 42.365C59.9264 41.7697 61.1614 41.472 62.5294 41.472C63.682 41.472 64.7207 41.6747 65.6454 42.08C66.5827 42.4853 67.368 43.068 68.0014 43.828L66.0254 45.652C65.126 44.6133 64.0114 44.094 62.6814 44.094C61.858 44.094 61.1234 44.2777 60.4774 44.645C59.8314 44.9997 59.3247 45.5 58.9574 46.146C58.6027 46.792 58.4254 47.5267 58.4254 48.35C58.4254 49.1733 58.6027 49.908 58.9574 50.554C59.3247 51.2 59.8314 51.7067 60.4774 52.074C61.1234 52.4287 61.858 52.606 62.6814 52.606C64.0114 52.606 65.126 52.0803 66.0254 51.029L68.0014 52.853C67.368 53.6257 66.5827 54.2147 65.6454 54.62C64.708 55.0253 63.663 55.228 62.5104 55.228Z"
          fill="white"
        />
      </svg>
    ),
    l: (
      <svg width="48" height="48" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="48"
          cy="48"
          r="40"
          fill="#0C2C45"
          stroke="white"
          strokeWidth="2"
          vectorEffect={'non-scaling-stroke'}
        />
        <path
          vectorEffect={'non-scaling-stroke'}
          d="M29.0741 41.7H35.1161C36.5601 41.7 37.8331 41.9787 38.9351 42.536C40.0497 43.0807 40.9111 43.8533 41.5191 44.854C42.1397 45.8547 42.4501 47.02 42.4501 48.35C42.4501 49.68 42.1397 50.8453 41.5191 51.846C40.9111 52.8467 40.0497 53.6257 38.9351 54.183C37.8331 54.7277 36.5601 55 35.1161 55H29.0741V41.7ZM34.9641 52.473C36.2941 52.473 37.3517 52.1057 38.1371 51.371C38.9351 50.6237 39.3341 49.6167 39.3341 48.35C39.3341 47.0833 38.9351 46.0827 38.1371 45.348C37.3517 44.6007 36.2941 44.227 34.9641 44.227H32.1521V52.473H34.9641ZM47.3408 44.208H43.0848V41.7H54.6748V44.208H50.4188V55H47.3408V44.208ZM62.5104 55.228C61.155 55.228 59.9264 54.9367 58.8244 54.354C57.735 53.7587 56.8737 52.9417 56.2404 51.903C55.6197 50.8517 55.3094 49.6673 55.3094 48.35C55.3094 47.0327 55.6197 45.8547 56.2404 44.816C56.8737 43.7647 57.735 42.9477 58.8244 42.365C59.9264 41.7697 61.1614 41.472 62.5294 41.472C63.682 41.472 64.7207 41.6747 65.6454 42.08C66.5827 42.4853 67.368 43.068 68.0014 43.828L66.0254 45.652C65.126 44.6133 64.0114 44.094 62.6814 44.094C61.858 44.094 61.1234 44.2777 60.4774 44.645C59.8314 44.9997 59.3247 45.5 58.9574 46.146C58.6027 46.792 58.4254 47.5267 58.4254 48.35C58.4254 49.1733 58.6027 49.908 58.9574 50.554C59.3247 51.2 59.8314 51.7067 60.4774 52.074C61.1234 52.4287 61.858 52.606 62.6814 52.606C64.0114 52.606 65.126 52.0803 66.0254 51.029L68.0014 52.853C67.368 53.6257 66.5827 54.2147 65.6454 54.62C64.708 55.0253 63.663 55.228 62.5104 55.228Z"
          fill="white"
        />
      </svg>
    ),
    xl: (
      <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="48"
          cy="48"
          r="40"
          fill="#0C2C45"
          stroke="white"
          strokeWidth="2"
          vectorEffect={'non-scaling-stroke'}
        />
        <path
          vectorEffect={'non-scaling-stroke'}
          d="M29.0741 41.7H35.1161C36.5601 41.7 37.8331 41.9787 38.9351 42.536C40.0497 43.0807 40.9111 43.8533 41.5191 44.854C42.1397 45.8547 42.4501 47.02 42.4501 48.35C42.4501 49.68 42.1397 50.8453 41.5191 51.846C40.9111 52.8467 40.0497 53.6257 38.9351 54.183C37.8331 54.7277 36.5601 55 35.1161 55H29.0741V41.7ZM34.9641 52.473C36.2941 52.473 37.3517 52.1057 38.1371 51.371C38.9351 50.6237 39.3341 49.6167 39.3341 48.35C39.3341 47.0833 38.9351 46.0827 38.1371 45.348C37.3517 44.6007 36.2941 44.227 34.9641 44.227H32.1521V52.473H34.9641ZM47.3408 44.208H43.0848V41.7H54.6748V44.208H50.4188V55H47.3408V44.208ZM62.5104 55.228C61.155 55.228 59.9264 54.9367 58.8244 54.354C57.735 53.7587 56.8737 52.9417 56.2404 51.903C55.6197 50.8517 55.3094 49.6673 55.3094 48.35C55.3094 47.0327 55.6197 45.8547 56.2404 44.816C56.8737 43.7647 57.735 42.9477 58.8244 42.365C59.9264 41.7697 61.1614 41.472 62.5294 41.472C63.682 41.472 64.7207 41.6747 65.6454 42.08C66.5827 42.4853 67.368 43.068 68.0014 43.828L66.0254 45.652C65.126 44.6133 64.0114 44.094 62.6814 44.094C61.858 44.094 61.1234 44.2777 60.4774 44.645C59.8314 44.9997 59.3247 45.5 58.9574 46.146C58.6027 46.792 58.4254 47.5267 58.4254 48.35C58.4254 49.1733 58.6027 49.908 58.9574 50.554C59.3247 51.2 59.8314 51.7067 60.4774 52.074C61.1234 52.4287 61.858 52.606 62.6814 52.606C64.0114 52.606 65.126 52.0803 66.0254 51.029L68.0014 52.853C67.368 53.6257 66.5827 54.2147 65.6454 54.62C64.708 55.0253 63.663 55.228 62.5104 55.228Z"
          fill="white"
        />
      </svg>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}

export const FlagNoProvenanceSVG = ({ size = 'm' }) => {
  const svgs = {
    s: (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="9.5" fill="#8B8B8B" fillOpacity="0.5" stroke="white" />
      </svg>
    ),
    m: (
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14.0001" cy="14" r="12.8333" fill="#8B8B8B" fillOpacity="0.5" stroke="white" />
      </svg>
    ),
    l: (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="19.5" fill="#8B8B8B" fillOpacity="0.5" stroke="white" />
      </svg>
    ),
    xl: (
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="39.5" fill="#8B8B8B" fillOpacity="0.5" stroke="white" />
      </svg>
    ),
  }

  return svgs[size] ? svgs[size] : svgs.m
}
