import React from 'react'

import testids from 'config/testIds'
import Switcher from 'components/system/Switcher'

import classes from './styles.module.css'

export const ToggleDiamondsButton = ({ label, isDisabled, filter, setFilter }) => {
  const testIds = testids.dit

  const handleChange = () => {
    setFilter(!filter)
  }

  return (
    <div className={classes.toggleDiamondsButtonContainer} data-test-id={testIds.toggleDiamondsButton}>
      <Switcher label={label} value={filter} isDisabled={isDisabled} onChange={handleChange} />
    </div>
  )
}

export default ToggleDiamondsButton
