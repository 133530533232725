import React from 'react'

export const Rough = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M145.652 37.5L62.4995 147.606M145.652 37.5L228.804 147.606M145.652 37.5L135.869 171.543M62.4995 147.606L135.869 171.543M62.4995 147.606L150.543 262.5M135.869 171.543L228.804 147.606M135.869 171.543L150.543 262.5M228.804 147.606L150.543 262.5"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
  </svg>
)

export const Split = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 6.42857L10.8235 9.46429M2 6.42857L12.5882 21M2 6.42857L12.5882 4L22 6.42857M10.8235 9.46429L22 6.42857M10.8235 9.46429L12.5882 21M22 6.42857L12.5882 21"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
  </svg>
)

export const Polished = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg width={width} height={height} viewBox={'0 0 24 24'} fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M4 10.7127L8.51282 12.796M4 10.7127L6.46154 7.796M4 10.7127L11.7949 21.1293M8.51282 12.796H15.4872M8.51282 12.796L11.7949 21.1293M8.51282 12.796L8.92308 9.046M15.4872 12.796L20 10.7127M15.4872 12.796L14.6667 9.046M15.4872 12.796L11.7949 21.1293M20 10.7127L17.1282 7.796M20 10.7127L11.7949 21.1293M17.1282 7.796L13.8462 6.12933H9.74359L6.46154 7.796M17.1282 7.796L14.6667 9.046M6.46154 7.796L8.92308 9.046M8.92308 9.046H14.6667"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
    <path d="M11.7 4.12933L11.7 1.12933" stroke={stroke} vectorEffect={'non-scaling-stroke'} />
    <path d="M19 6.12933L21 4.12933" stroke={stroke} vectorEffect={'non-scaling-stroke'} />
    <path d="M5 6.12933L3 4.12933" stroke={stroke} vectorEffect={'non-scaling-stroke'} />
    <path d="M22 11.1294L24 11.1294" stroke={stroke} vectorEffect={'non-scaling-stroke'} />
    <path d="M0 11.1294L2 11.1294" stroke={stroke} vectorEffect={'non-scaling-stroke'} />
  </svg>
)

export const Planning = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg width={width} height={height} viewBox={'0 0 24 24'} fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M12.0173 2.76831L4.82401 11.1605L11.171 12.9848M12.0173 2.76831L19.2106 11.1605L11.171 12.9848M12.0173 2.76831L11.171 12.9848M12.4404 22.1067L11.4383 16.6336M12.4404 22.1067L18.3643 14.8092L11.4383 16.6336M12.4404 22.1067L5.24714 14.8092L11.4383 16.6336"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
    <path
      d="M3.13138 12.6202L11.1709 14.8095L20.903 12.6202"
      stroke={stroke}
      strokeDasharray="2 2"
      vectorEffect={'non-scaling-stroke'}
    />
  </svg>
)

export const Sawing = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M62.5 168.75L139.706 189.583M62.5 168.75L155.147 268.75M62.5 168.75L155.147 152.083L237.5 168.75M139.706 189.583L237.5 168.75M139.706 189.583L155.147 268.75M237.5 168.75L155.147 268.75"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
    <path
      d="M144.853 31.2497L62.5 131.25L144.853 147.916M144.853 31.2497L237.5 131.25L144.853 147.916M144.853 31.2497V147.916"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
  </svg>
)

export const Bruting = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M59.7222 51.1168L103.641 77.3788M59.7222 51.1168L103.641 31.5473L146.434 25L189.227 31.5473L233.333 51.1168M59.7222 51.1168L25 96.8213M103.641 77.3788L146.434 83.9261M103.641 77.3788L73.6111 129.467M146.434 83.9261L196.359 70.8314M146.434 83.9261V149.055M196.359 70.8314L233.333 51.1168M196.359 70.8314L219.444 129.467M233.333 51.1168L275 96.8213M25 96.8213L59.7222 195.231L115.278 246.993M25 96.8213L73.6111 129.467M115.278 246.993L146.434 262.5M115.278 246.993L73.6111 129.467M146.434 262.5L182.095 247.61M146.434 262.5V227.968V149.055M182.095 247.61L233.333 195.231L275 96.8213M182.095 247.61L219.444 129.467M275 96.8213L219.444 129.467M219.444 129.467L146.434 149.055M146.434 149.055L73.6111 129.467"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
  </svg>
)

export const Polishing = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 300 300'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M25 95.1923L95.5128 127.244M25 95.1923L63.4615 50.3205M25 95.1923L146.795 255.449M95.5128 127.244H204.487M95.5128 127.244L146.795 255.449M95.5128 127.244L101.923 69.5513M204.487 127.244L275 95.1923M204.487 127.244L191.667 69.5513M204.487 127.244L146.795 255.449M275 95.1923L230.128 50.3205M275 95.1923L146.795 255.449M230.128 50.3205L178.846 37.5H114.744L63.4615 50.3205M230.128 50.3205L191.667 69.5513M63.4615 50.3205L101.923 69.5513M101.923 69.5513H191.667"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
  </svg>
)

export const Graded = ({ width = 24, height = 24, stroke = '#fff', style = {} }) => (
  <svg width={width} height={height} viewBox={'0 0 24 24'} fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M18.6188 2.5H21.2643V19.1107H18.6188M18.6188 2.5V19.1107M18.6188 2.5H4.86205V21.0187L3.96606 22.2854H17.8812L18.6188 21.0187V19.1107"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
    <path
      d="M7.50726 11.0711L8.91821 9.37793H14.562L15.9729 11.0711M7.50726 11.0711L11.7401 15.7272L15.9729 11.0711M7.50726 11.0711H11.7401H15.9729"
      stroke={stroke}
      vectorEffect={'non-scaling-stroke'}
    />
  </svg>
)

export const cycles = {
  rough: Rough,
  split: Split,
  planning: Planning,
  sawing: Sawing,
  bruting: Bruting,
  polishing: Polishing,
  polished: Polished,
  graded: Graded,
}
