import { toLower } from 'lodash'

import { resolveProp } from 'utils/diamond'

export const sorter = (rows, key, order) => {
    return rows.sort((a, b) => {
      const propA = toLower(a.data[key] || resolveProp(a.data, key) || '-')
      const propB = toLower(b.data[key] || resolveProp(b.data, key) || '-')

      if (propA === '-' && propB !== '-') return 1
      else if (propA !== '-' && propB === '-') return -1
      else if (propA && propB === '-') return 0

      if (propA > propB) return order[0]
      else if (propA < propB) return order[1]
      else return 0
    })
  }

export const sorterFloat = (rows, key, order) => {
  return rows.sort((a, b) => {
    const propA = parseFloat(a.data[key] || resolveProp(a.data, key)) || 0
    const propB = parseFloat(b.data[key] || resolveProp(b.data, key)) || 0

    if (propA > propB) return order[0]
    else if (propA < propB) return order[1]
    else return 0
  })
}
