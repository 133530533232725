import React, { useRef, useState } from 'react'

import HeaderCell from '../HeaderCell'
import classes from './styles.module.css'

export const Header = ({
  columns = [],
  rows = [],
  widths = [],
  grow = true,
  centered = false,
  sorted,
  showSort,
  onSort,
  onColumnResize,
  onRender,
  testIdPrefix = ''
}) => {
  const headerRef = useRef()
  const [isResizing, setIsResizing] = useState(false)

  const handleResizeStartEnd = (start) => {
    setIsResizing(start)
  }

  // Only needs this to be executed once, on first table render. Consecutive renders are not relevant
  // as we already know the header height.
  const onHeaderRef = (node) => {
    if (headerRef.current) return

    headerRef.current = node

    onRender()
  }

  if (columns.length === 0 || widths.length === 0) return null

  return (
    <div className={classes.headerContainer} ref={onHeaderRef}>
      {columns.map((column, index) => {
        return (
          <HeaderCell
            key={column.key || index}
            title={column.title}
            Component={column.headerComponent}
            rows={rows}
            columnKey={column.key}
            sortable={column.sortable}
            resizable={index !== columns.length - 1 && column.resizable !== false}
            tooltip={column.tooltip}
            minWidth={column.minWidth}
            width={widths[index]}
            grow={grow}
            maxScale={column.maxScale}
            centered={centered && index !== 0}
            rightAlign={column.rightAlign}
            sorted={showSort && sorted[column.key]}
            isResizing={isResizing}
            isComponent={column.headerComponent}
            testIdPrefix={testIdPrefix}
            onCellClick={onSort}
            onCellResize={onColumnResize}
            onResizeStartEnd={handleResizeStartEnd}
            {...column.props}
          />
        )
      })}
    </div>
  )
}

export default Header
