import React from 'react'

import { StatusLabel } from 'components/system'
import { AcceptIcon, TransferIcon, LockIcon } from 'components/system/SVG'

const Status = ({ value }) => {
  const resolveStatus = (status) => {
    const formattedValue = status ? status.toLowerCase().replace(' ', '') : null

    switch (formattedValue) {
      case 'available':
        return 'success'
      case 'locked':
        return 'warning'
      default:
        return 'default'
    }
  }

  const resolveIcon = (status) => {
    const value = status.toLowerCase()

    switch (value) {
      case 'available':
        return <AcceptIcon fill={'#00B707'} />
      case 'locked':
        return <LockIcon fill={'#F99B0D'} />
      default:
        return <TransferIcon />
    }
  }

  return (
    <StatusLabel status={resolveStatus(value)} text={value}>
      {resolveIcon(value)}
    </StatusLabel>
  )
}

export default Status
