import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import {
  BinIcon,
  BlockIcon,
  CancelIcon,
  AcceptIcon,
  LockIcon,
  ShareIcon,
  EyeIcon,
  TransferIcon,
} from 'components/system/SVG/icons'
import { CloseSVG } from 'components/Svg'

import classes from './styles.module.css'
import { testIds } from 'config/testIds'
import { testId } from 'utils/testHelper'

export const ActionsBar = ({ actions = [], label, pluralLabel, selectionLength = 0, testIdPrefix, onClear }) => {
  const [rendered, setRendered] = useState(false)
  const [animateState, setAnimateState] = useState('')

  const handleClearClick = () => {
    if (onClear) onClear()
  }

  const animateShow = () => {
    setRendered(true)

    setTimeout(() => {
      setAnimateState('in')
    }, 10)
  }

  const animateHide = () => {
    setAnimateState('')

    setTimeout(() => {
      setRendered(false)
    }, 300)
  }

  useEffect(() => {
    if (selectionLength) {
      animateShow()
    } else {
      animateHide()
    }
  }, [selectionLength]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!rendered) return null

  const isPlural = selectionLength > 1

  return (
    <div className={classes.actionsBarContainer} data-test-id={testId(testIdPrefix, 'container')}>
      <div className={classes.positioner}>
        <div className={classnames(classes.wrapper, { [classes.in]: animateState })}>
          <div className={classes.counter}>
            <div className={classes.count} data-test-id={testId(testIdPrefix, testIds.actionBar.selectionCounterPostfix)}>
              {selectionLength} {isPlural && pluralLabel ? pluralLabel : label}
            </div>
          </div>
          <div className={classes.separator}></div>
          <div
            className={classes.clearBtn}
            onClick={handleClearClick}
            data-test-id={testId(testIdPrefix, 'clearSelectionButton')}
          >
            <div className={classes.icon}>
              <CloseSVG />
            </div>
            <div className={classes.label}>Clear selection</div>
          </div>
          <div className={classes.actions}>
            {actions
              .map((action) => <CTA action={action} testIdPrefix={testIdPrefix} isPlural={isPlural} />)
              .filter((action) => action)}
          </div>
        </div>
      </div>
    </div>
  )
}

const resolveIcon = (type) => {
  switch (type) {
    case 'bin':
      return <BinIcon />
    case 'block':
      return <BlockIcon />
    case 'cancel':
      return <CancelIcon />
    case 'accept':
      return <AcceptIcon />
    case 'lock':
      return <LockIcon />
    case 'share':
      return <ShareIcon />
    case 'eye':
      return <EyeIcon />
    case 'transfer':
      return <TransferIcon />
    default:
      return null
  }
}

const CTA = ({ action, testIdPrefix, isPlural }) => {
  const Icon = action.icon
  return (
    <div
      key={action.key}
      className={classnames(classes.action, action.style === 'primary' ? classes.primary : classes.secondary)}
      onClick={action.onClick}
      data-test-id={testId(testIdPrefix, `${action.key}Button`)}
    >
      {Icon ? <div className={classes.actionIcon}>{resolveIcon(action.icon)}</div> : null}
      <div className={classes.actionLabel}>{isPlural && action.pluralLabel ? action.pluralLabel : action.label}</div>
    </div>
  )
}

export default ActionsBar
