import camelCase from 'camelcase'
import React from 'react'

import classes from './styles.module.css'

export function Prop({ title, value, renderer, testIdPrefix }) {
  if (renderer) {
    return (
      <div className={classes.propContainer}>
        <div data-test-id={`${testIdPrefix}.${camelCase(title.replace('%', 'percent'))}`} className={classes.wrapper}>
          <div className={classes.title}>{title}</div>
          <div className={classes.value}>{renderer(title, value)}</div>
        </div>
      </div>
    )
  }

  if (typeof value !== 'string') return null

  return (
    <div className={classes.propContainer}>
      <div data-test-id={`${testIdPrefix}.${camelCase(title.replace('%', 'percent'))}`} className={classes.wrapper}>
        <div className={classes.title}>{title}</div>
        <div className={classes.value}>{value}</div>
      </div>
    </div>
  )
}

export default Prop
