// import http from '../utils/http'

// const namespace = 'settings'

// TODO: save settings into the db
const updateListViewColumnSettings = (columns) => Promise.resolve()
const updateClassificationPopupSettings = (value) => Promise.resolve()

const endpoints = {
  updateListViewColumnSettings,
  updateClassificationPopupSettings,
}

export default endpoints
