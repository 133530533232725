import React from 'react'
import classNames from 'classnames'

import API from 'api'
import showNotification from 'containers/Notifications/controller'
import testids from 'config/testIds'

import classes from './styles.module.css'

const ExportFileButton = ({ activeTabId, sessionId, missingFiles, failedFiles, unexpectedFiles }) => {
  const testIds = testids.dit

  const title = () => {
    switch (activeTabId) {
      case 'missingFiles':
        return 'Missing Files '
      case 'failedFiles':
        return 'Failed Files '
      case 'unexpectedFiles':
      default:
        return 'Unexpected Files '
    }
  }

  const files = () => {
    switch (activeTabId) {
      case 'missingFiles':
        return missingFiles
      case 'failedFiles':
        return failedFiles
      case 'unexpectedFiles':
      default:
        return unexpectedFiles
    }
  }

  const handleSessionFilesDownload = () => {
    API.downloadSessionFilesReport(files(), sessionId, `${activeTabId}_${sessionId}.xlsx`)
      .then(() => {
        showNotification({
          title: 'File downloaded',
          variant: 'success',
        })
      })
      .catch(() => {
        showNotification({
          title: 'File download failed',
          variant: 'error',
        })
      })
  }

  const isDisabled = files().length === 0

  return (
    <div className={classes.exportButtonContainer} data-test-id={testIds.exportSessionFilesButton}>
      <span className={classes.text}>{title()}</span>
      <span
        onClick={!isDisabled ? handleSessionFilesDownload : null}
        className={classNames(classes.link, { [classes.disabled]: isDisabled })}
      >
        download as .xlsx
      </span>
    </div>
  )
}

export default ExportFileButton
