import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { clone } from 'ramda'

import { applyDITFiltersAction } from 'store/actions'
import { selectDITFilters } from 'store/selectors'
import Filter from 'containers/Filters/Filter'
import { DateRangePicker, FilterButtons } from 'components/system'
import testids from 'config/testIds'

import classes from './styles.module.css'

export const Filters = () => {
  const dispatch = useDispatch()
  const appliedFilters = useSelector(selectDITFilters)
  const [selectedFilters, setSelectedFilters] = useState([])
  const testIds = testids.dit

  const filters = [
    {
      name: 'dit.integration_type',
      options: [
        { title: 'Rough', value: 'rough' },
        { title: 'Split', value: 'split' },
        { title: 'Polished', value: 'polished' },
      ],
    },
    {
      name: 'dit.status',
      options: [
        { title: 'In progress', value: 'in_progress' },
        { title: 'Submitted', value: 'submitted' },
        { title: 'Abandoned', value: 'abandoned' },
      ],
    },
  ]

  const handleDateChange = (dateRange) => {
    const name = 'date'
    const hasValues = !!Object.keys(dateRange).length
    const filterIndex = selectedFilters.findIndex((filter) => filter.name === name)
    const selectedFilter = selectedFilters[filterIndex]
    const newSelectedFilter = { name, value: dateRange }
    const newSelectedFilters = clone(selectedFilters)

    if (selectedFilter) {
      if (hasValues) newSelectedFilters[filterIndex] = newSelectedFilter
      else newSelectedFilters.splice(filterIndex, 1)
    } else if (hasValues) {
      newSelectedFilters.push(newSelectedFilter)
    }

    setSelectedFilters(newSelectedFilters)
    dispatch(applyDITFiltersAction(newSelectedFilters))
  }

  const handleFilterValueChange = (name, value) => {
    const filterIndex = selectedFilters.findIndex((filter) => filter.name === name)
    const selectedFilter = selectedFilters[filterIndex]
    const newSelectedFilter = { name, values: selectedFilter ? clone(selectedFilter.values) : [] }
    const newSelectedFilters = clone(selectedFilters)

    if (selectedFilter) {
      const valueIndex = selectedFilter.values.indexOf(value)

      if (valueIndex !== -1) {
        newSelectedFilter.values.splice(valueIndex, 1)
      } else {
        newSelectedFilter.values.push(value)
      }
    } else {
      newSelectedFilter.values.push(value)
    }

    if (selectedFilter) {
      if (newSelectedFilter.values.length === 0) {
        newSelectedFilters.splice(filterIndex, 1)
      } else {
        newSelectedFilters[filterIndex] = newSelectedFilter
      }
    } else {
      newSelectedFilters.push(newSelectedFilter)
    }

    setSelectedFilters(newSelectedFilters)
  }

  const handleClearFilter = (name) => {
    const filterIndex = selectedFilters.findIndex((filter) => filter.name === name)
    const newSelectedFilters = clone(selectedFilters)

    newSelectedFilters.splice(filterIndex, 1)

    setSelectedFilters(newSelectedFilters)
    dispatch(applyDITFiltersAction(newSelectedFilters))
  }

  const handleFilterApply = (filter) => {
    dispatch(applyDITFiltersAction(selectedFilters))
  }

  const handleClearFilters = () => {
    dispatch(applyDITFiltersAction([]))
  }

  useEffect(() => {
    setSelectedFilters(appliedFilters)
  }, [JSON.stringify(appliedFilters)]) // eslint-disable-line react-hooks/exhaustive-deps

  const date = appliedFilters.find((filter) => filter.name === 'date')

  return (
    <div className={classes.filtersContainer}>
      <div className={classes.filters}>
        <div className={classes.datePicker} data-test-id={testIds.sessionHistoryFilters.date}>
          <DateRangePicker
            label="Start date"
            selected={date ? date.value : {}}
            onClear={() => handleClearFilter('date')}
            onChange={handleDateChange}
            testIdPrefix={testIds.sessionHistory.prefix}
          />
        </div>
        <div className={classes.otherFilters}>
          {filters.map((filter) => {
            const selectedFilter = selectedFilters.find((selectedFilter) => selectedFilter.name === filter.name)
            const selectedValues = selectedFilter ? selectedFilter.values : []
            const appliedFilter = appliedFilters.find((appliedFilter) => appliedFilter.name === filter.name)
            const appliedValues = appliedFilter ? appliedFilter.values : []

            return (
              <Filter
                key={`filter-components-${filter.name}`}
                name={filter.name}
                options={filter.options}
                selectedOptions={selectedValues}
                appliedOptions={appliedValues}
                sorted={false}
                testIdPrefix={testIds.sessionHistory.prefix}
                onFilterApply={handleFilterApply}
                onFilterValueChange={handleFilterValueChange}
                onFilterClear={handleClearFilter}
              />
            )
          })}
        </div>

        <div className={classes.clearBtn} data-test-id={testIds.sessionHistoryFilters.clear}>
          <FilterButtons enableClear={appliedFilters.length || selectedFilters.length} onClear={handleClearFilters}
            testIdPrefix={testIds.sessionHistory.prefix}
          />
        </div>
      </div>
    </div>
  )
}

export default Filters
