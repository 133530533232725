import createActionSet from 'utils/createActionSet'

const namespace = 'USER'
const LOGIN = createActionSet(namespace, 'LOGIN')
const LOGOUT = createActionSet(namespace, 'LOGOUT')
const LOAD_USER = createActionSet(namespace, 'LOAD_USER')

export const USER_ACTION_TYPES = {
  LOGIN,
  LOGOUT,
  LOAD_USER,
}
