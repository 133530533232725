import React, { useState } from 'react'
import classnames from 'classnames'

import { Tooltip } from 'components/system'

import classes from './styles.module.css'

// TODO: Allow complete replacement for BodyCell component by a custom Cell component
export const BodyCell = ({ value, row, path, mapper, width, maxScale, multiline, rightAlign, isComponent }) => {
  const [tooltipTitle, setTooltipTitle] = useState('')
  const parsedValue = mapper ? mapper(value, row, path) : value
  // only show tooltips for primitive values
  const isPrimitive = Object(parsedValue) !== parsedValue
  const tooltipValue = isPrimitive ? parsedValue : ''

  // only render tooltips when content doesn't fit into container
  const handleMouseEnter = (evt) => {
    const target = evt.target

    if (target.offsetWidth < target.scrollWidth) setTooltipTitle(tooltipValue)
    else if (tooltipTitle) setTooltipTitle('')
  }

  return (
    <div
      className={classnames(classes.bodyCellContainer, {
        [classes.customComponent]: isComponent,
        [classes.maxScale]: maxScale,
      })}
      style={{ width: `${width}px` }}
      onMouseEnter={tooltipValue ? handleMouseEnter : null}
    >
      {tooltipTitle ? (
        <Tooltip title={tooltipValue}>
          <div
            className={classnames(classes.content, {
              [classes.multiline]: multiline,
              [classes.rightAlign]: rightAlign,
              [classes.disabled]: !!row.rowDisabled,
            })}
          >
            {parsedValue}
          </div>
        </Tooltip>
      ) : (
        <div
          className={classnames(classes.content, {
            [classes.multiline]: multiline,
            [classes.rightAlign]: rightAlign,
            [classes.disabled]: !!row.rowDisabled && isPrimitive,
          })}
        >
          {parsedValue}
        </div>
      )}
    </div>
  )
}

export default BodyCell
