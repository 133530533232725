export const selectFilters = state => {
  const { available, selected, applied } = state.filters.data
  return { available, selected, applied }
}

export const selectAvailableFilters = state => {
  const { available } = state.filters.data
  return available
}

export const selectSelectedFilters = state => {
  const { selected } = state.filters.data
  return selected
}

export const selectAppliedFilters = state => {
  const { applied } = state.filters.data
  return applied
}

export const selectFiltersLoading = state => {
  return state.filters.isLoading
}
