import React from 'react'

import { Button, Heading } from 'components/system'
import { SupportPlatformIcon, ExternalLinkSVGSmall } from 'components/Svg'
import testIds from 'config/testIds'

import classes from './styles.module.css'

const PlatformSupport = () => {
  const testId = testIds.support

  const handleClick = (e) => {
    e.preventDefault()
    window.open('https://support.tracr.com/hc/en-gb', '_blank')
  }

  return (
    <div className={classes.platformSupportContainer}>
      <Heading text="Tracr Platform Support" />
      <div className={classes.contentContainer}>
        <SupportPlatformIcon />
        <div data-test-id={testId.platform.text}>
          <div className={classes.description}>
            View FAQ’s, integration templates, platform documentation and raise support tickets.
          </div>
          <div className={classes.loginText}>Login to the Tracr Support Portal with your Jumpcloud credentials</div>
        </div>

        <Button onClick={handleClick}>
          <div className={classes.button} data-test-id={testId.platform.button}>
            <span>Support portal</span>
            <ExternalLinkSVGSmall />
          </div>
        </Button>
      </div>
    </div>
  )
}

export default PlatformSupport
