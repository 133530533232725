import createActionSet from 'utils/createActionSet'

const namespace = 'DIAMOND'
const GET_FULL_DIAMOND = createActionSet(namespace, 'GET_FULL_DIAMOND')
const GET_DIAMOND_BLUENILE = createActionSet(namespace, 'GET_DIAMOND_BLUENILE')
const GET_DIAMONDS = createActionSet(namespace, 'GET_DIAMONDS')
const GET_FULL_DIAMONDS = createActionSet(namespace, 'GET_FULL_DIAMONDS')
const GET_DIAMOND_PARENT = createActionSet(namespace, 'GET_DIAMOND_PARENT')
const GET_DIAMONDS_COUNT = createActionSet(namespace, 'GET_DIAMONDS_COUNT')
const GET_TOTAL_DIAMONDS_COUNT = createActionSet(namespace, 'GET_TOTAL_DIAMONDS_COUNT')
const TRANSFER_DIAMOND = createActionSet(namespace, 'TRANSFER_DIAMOND')
const SHARE_DIAMOND = createActionSet(namespace, 'SHARE_DIAMOND')
const SHARE_DIAMOND_TO_EMAIL = createActionSet(namespace, 'SHARE_DIAMOND_TO_EMAIL')
const GET_SHARED_DIAMONDS = createActionSet(namespace, 'GET_SHARED_DIAMONDS')
const UPDATE_SELECTED_DIAMONDS = createActionSet(namespace, 'UPDATE_SELECTED_DIAMONDS')
const GET_SAMPLE_DIAMOND = createActionSet(namespace, 'GET_SAMPLE_DIAMOND')
const UNLOCK_DIAMONDS = createActionSet(namespace, 'UNLOCK_DIAMONDS')

export const DIAMONDS_ACTION_TYPES = {
  GET_FULL_DIAMOND,
  GET_DIAMOND_BLUENILE,
  GET_DIAMONDS,
  GET_FULL_DIAMONDS,
  GET_DIAMOND_PARENT,
  GET_DIAMONDS_COUNT,
  GET_TOTAL_DIAMONDS_COUNT,
  TRANSFER_DIAMOND,
  SHARE_DIAMOND,
  SHARE_DIAMOND_TO_EMAIL,
  UPDATE_SELECTED_DIAMONDS,
  GET_SHARED_DIAMONDS,
  GET_SAMPLE_DIAMOND,
  UNLOCK_DIAMONDS,
}
