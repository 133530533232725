import http from '../utils/http'

const namespace = 'share'

const getShares = (body) => {
  return http.post(`${namespace}`, body)
}

const getSharesCount = (body) => {
  return http.post(`${namespace}/count`, body)
}

const revokeShare = (id) => {
  return http.delete(`${namespace}/${id}`)
}
const endpoints = {
  getShares,
  getSharesCount,
  revokeShare,
}

export default endpoints
