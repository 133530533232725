// import { MONITORING_ACTION_TYPES } from 'store/actionTypes'
// import { resolveActionType } from '../utils'

export const selectMonitoringDiamonds = state => {
  return state.monitoring.data.diamonds
}

export const selectMonitoringDiamondsCount = state => {
  return {
    filtered: state.monitoring.data.count,
    total: state.monitoring.data.totalCount,
  }
}

export const selectMonitoringFilters = state => {
  return state.monitoring.data.filters
}
