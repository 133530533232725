import React from 'react'

import { InfoIcon } from 'components/Svg/icons'

import classes from './styles.module.css'

export const Bar = ({ id, onRemove, onCancel }) => {
  const handleRemoveClick = () => {
    if (onRemove) onRemove(id)
  }

  return (
    <div className={classes.barContainer}>
      <div className={classes.wrapper}>
        <div className={classes.disclaimer}>
          <InfoIcon />
          <span>Are you sure you wish to remove this Diamond from selection?</span>
        </div>
        <div className={classes.actions}>
          <div className={classes.action} onClick={handleRemoveClick}>
            Yes, remove
          </div>
          <div className={classes.spacer}></div>
          <div className={classes.action} onClick={onCancel}>
            No, cancel
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bar
