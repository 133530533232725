import React from 'react'

import { Modal } from 'components/system'
import testids from 'config/testIds'

import classes from './styles.module.css'

const SubmitSessionModal = ({ open, diamondsCount, skippedCount, onConfirm, onCancel }) => {
  const testIds = testids.dit.submitSessionModal

  const handleCancelClick = () => {
    onCancel()
  }

  const handleConfirmClick = () => {
    onConfirm()
  }

  return (
    <Modal
      isOpen={open}
      title={'SUBMIT SESSION'}
      cancelLabel={'Cancel'}
      confirmLabel={'Submit'}
      cancelTestId={testIds.cancel}
      confirmTestId={testIds.confirm}
      onCancel={handleCancelClick}
      onConfirm={handleConfirmClick}
    >
      <div className={classes.body}>
        <div className={classes.text}>
          <span>
            You are submitting{' '}
            <span className={classes.bold}>
              {diamondsCount - skippedCount} diamond{diamondsCount - skippedCount === 1 ? '' : 's'}
            </span>
          </span>
          <span>
            You have excluded{' '}
            <span className={classes.bold}>
              {skippedCount} diamond{skippedCount === 1 ? '' : 's'}
            </span>
          </span>
          <span>Please confirm you would like to submit this session for matching and verification?</span>
        </div>
      </div>
    </Modal>
  )
}

export default SubmitSessionModal
