import { clone } from 'ramda'
import { TRANSFER_ACTION_TYPES } from '../actionTypes'

const initialState = {
  selected: [],
}

const trasnferReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case TRANSFER_ACTION_TYPES.SET_FOR_TRANSFER:
      const newState = clone(state)

      return {
        ...newState,
        selected: payload,
      }
    default:
      return state
  }
}

export default trasnferReducer
