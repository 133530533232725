import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { nanoid } from 'nanoid'

import { hideUnhideDiamondAction } from 'store/actions'
import { selectDITSessionId } from 'store/selectors'
import { RoughPolishingRotatedIcon } from 'components/Svg'
import EmptyStateOverlay from 'pages/dit/EmptyStateOverlay'
import { Table } from 'components/system'
import testids from 'config/testIds'

import UploadStatusCell from './UploadStatusCell'
import SessionIdCell from './SessionIdCell'
import classes from './styles.module.css'

export const OverviewTab = ({ diamonds, isLoading, allDiamonds }) => {
  const dispatch = useDispatch()
  const sessionId = useSelector(selectDITSessionId)
  const [rows, setRows] = useState([])
  const [, setHoveredRowIndex] = useState() // eslint-disable-line
  const testIds = testids.dit

  const groups = [
    { key: '3D scans', types: ['scan'] },
    { key: '3D scan reports', types: ['scan_report'] },
    { key: 'Plans', types: ['plan'] },
    { key: 'Plan reports', types: ['plan_report'] },
    { key: 'Grading certificates', types: ['grading_certificate'] },
    {
      key: 'Images',
      types: [
        'image',
        'image_heart',
        'image_arrow',
        'image_inclusion_map',
        'image_fluorescence',
        'image_aset_scope',
        'image_plot',
        'image_face_up',
        'image_dark_field',
        'image_ideal_scope',
        'image_3d',
        'image_b2b',
        'image_diagram',
      ],
    },
    { key: 'Videos', types: ['video'] },
    { key: 'Supplementary', types: ['supplementary_files'] },
  ]

  const handleCellClick = (row) => {
    const action = row.skipped ? 'unhide_diamond' : 'hide_diamond'

    dispatch(hideUnhideDiamondAction(action, sessionId, row.data.participant_id))
  }

  const resolveFileTypeToColumn = (type) => {
    const group = groups.find((group) => {
      if (group.types.includes(type)) return true

      return false
    })

    return (group && group.key) || 'Unknown'
  }

  const sessionHasDiamonds = diamonds.length > 0 || allDiamonds.length > 0

  // condition for session columns to be kept when the completed are filtered
  var columnDiamonds = []

  if (allDiamonds.length > 0) {
    columnDiamonds = allDiamonds
  } else {
    columnDiamonds = diamonds
  }

  const uniqueColumns = []
  const columns = sessionHasDiamonds
    ? columnDiamonds
        .reduce(
          (prev, next) => {
            if (!next.has_files) return prev

            next.data.forEach((file) => {
              const columnName = resolveFileTypeToColumn(file.column)

              // if column is already present or file is not provided
              if (uniqueColumns.includes(columnName) || !file.content) return

              prev.push({
                title: columnName,
                key: columnName,
                sortable: false,
                cellComponent: UploadStatusCell,
                rightAlign: true,
              })

              uniqueColumns.push(columnName)
            })

            return prev
          },
          [
            {
              title: 'Participant ID',
              key: 'participant_id',
              cellComponent: SessionIdCell,
              onCellClick: handleCellClick,
            },
          ]
        )
        .sort((a, b) => {
          const indexA = groups.findIndex((group) => group.key === a.title)
          const indexB = groups.findIndex((group) => group.key === b.title)

          if (indexA < indexB) return -1
          else if (indexA > indexB) return 1
          else return 0
        })
    : groups.reduce(
        (prev, next) => {
          prev.push({
            title: next.key,
            key: next.key,
            sortable: false,
            cellComponent: UploadStatusCell,
            rightAlign: true,
          })
          return prev
        },
        [
          {
            title: 'Participant ID',
            key: 'participant_id',
            sortable: false,
            cellComponent: SessionIdCell,
            onCellClick: handleCellClick,
          },
        ]
      )

  const setTableRows = () => {
    const tableRows = diamonds.reduce((prev, next, index) => {
      if (!next.has_files) return prev

      const newRow = {
        id: nanoid(),
        data: {},
      }
      // push participant_id cell
      newRow.data.participant_id = next.internal_id
      // mandatory meta information
      newRow.rowIndex = index
      newRow.skipped = next.skipped

      /**
       * Uncomment for persistant icons upon hide/unhide action
       */
      /*
      if (isFinite(hoveredRowIndex) && index === hoveredRowIndex) {
        newRow.hovered = true
      }
      */

      if (next.skipped) {
        // if diamond is skipped, no need to accumulate its cells
        // start after participant id
        newRow.colspan = [
          {
            start: 1,
            copy: 'You have excluded this diamond, by doing so it will not be submitted with this session',
          },
        ]

        prev.push(newRow)

        return prev
      }

      // push other cells
      next.data.forEach((file) => {
        const columnName = resolveFileTypeToColumn(file.column)
        const columnPresent = !!columns.find((column) => column.title === columnName)
        let cell = newRow.data[columnName]

        if (!columnPresent) return

        if (!cell) cell = newRow.data[columnName] = {}

        if (file.upload_ended_ts) {
          cell.uploaded = cell.uploaded ? cell.uploaded + 1 : 1

          delete cell.na
        } else if (file.error) {
          cell.error = cell.error ? cell.error + 1 : 1

          delete cell.na
        } else if (file.content) {
          cell.missing = cell.missing ? cell.missing + 1 : 1

          delete cell.na
        } else if (Object.keys(cell).length === 0) {
          cell.na = 0
        }
      })

      // remove all NAs' where other files are still required
      Object.keys(newRow).forEach((key) => {
        const cell = newRow[key]

        // if there are defined files in this cell, but files
        // that were not provided, remove the NA status
        if (Object.keys(cell).length > 1 && isFinite(cell.na)) {
          delete cell.na
        }
      })

      prev.push(newRow)

      return prev
    }, [])

    setRows(tableRows)
  }

  const isSessionComplete = diamonds.length === 0 && !isLoading

  const emptyTable = () => {
    return (
      <EmptyStateOverlay
        icon={<RoughPolishingRotatedIcon />}
        label={'Complete diamonds are now hidden'}
        subLabel={'This session is ready to submit'}
      />
    )
  }

  const handleRowHover = (hoveredRow) => {
    rows.find((row, index) => {
      if (index === hoveredRow.rowIndex) row.hovered = true

      setRows(rows)

      return false
    })

    setHoveredRowIndex(hoveredRow.rowIndex)
  }

  const handleRowLeave = () => {
    rows.find((row) => {
      if (row.hovered) row.hovered = false

      setRows(rows)

      return false
    })

    setHoveredRowIndex()
  }

  useEffect(() => {
    setTableRows()
  }, [JSON.stringify(diamonds)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.overviewTabContainer}>
      <div className={classes.content}>
        <Table
          columns={sessionHasDiamonds || isLoading ? columns : []}
          rows={rows}
          virtualized
          heightSub={64}
          columnTestIdPrefix={testIds.sessionDetailsColumn}
          rowTestId={testIds.sessionDetailsRow}
          isEmpty={isSessionComplete}
          isLoading={isLoading}
          emptyState={emptyTable()}
          onRowHover={handleRowHover}
          onRowLeave={handleRowLeave}
        />
      </div>
    </div>
  )
}

export default OverviewTab
