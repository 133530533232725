export const helpLinks = {
  listView: 'https://docs.tracr.com/docs/user-documentation/7x790kk4qwntj-inventory',
  diamondExperience: 'https://docs.tracr.com/docs/user-documentation/649luylrdyocq-diamond-experience',
  share: 'https://docs.tracr.com/docs/user-documentation/1jg37a2vjdy8k-share',
  integrationToolSessions:
    'https://docs.tracr.com/docs/user-documentation/9wyowvsywk4iy-integration-tool-session-history',
  integrationToolNewSession:
    'https://docs.tracr.com/docs/user-documentation/8s6w7vs7bq2fu-integration-tool-new-session',
  integrationToolSession: 'https://docs.tracr.com/docs/user-documentation/5ybozz7x5sbjg-integration-tool-data-upload',
  integrationToolSessionComplete:
    'https://docs.tracr.com/docs/user-documentation/79f9caecc65b8-integration-tool-session-success',
  integrationMonitoring: 'https://docs.tracr.com/docs/user-documentation/tdvcm0o1ct5ux-integration-monitoring',
  transferInShipments: 'https://docs.tracr.com/docs/user-documentation/usnw1xgc950ar-shipments-inbound',
  transferInConfirmOwnership:
    'https://docs.tracr.com/docs/user-documentation/qyzhl3zha8ruu-shipments-inbound-accept-ownership-of-diamonds',
  transferInCompleteShipment:
    'https://docs.tracr.com/docs/user-documentation/2o9j4ip3jh6kk-shipments-inbound-archived-details',
  transferOutShipments: 'https://docs.tracr.com/docs/user-documentation/0wc8rj9bh2wa3-shipments-outbound',
  transferOutNewTransfer:
    'https://docs.tracr.com/docs/user-documentation/yvr7ga60hak82-shipments-outbound-new-shipment',
  transferOutPendingTransfer:
    'https://docs.tracr.com/docs/user-documentation/h7l0idgb8dbjp-shipments-outbound-pending-shipment',
  transferOutCompleteTransfer:
    'https://docs.tracr.com/docs/user-documentation/6795ea661572c-transfer-out-details-complete-transfer',
  transferOutArchiveStatus:
    'https://docs.tracr.com/docs/user-documentation/g0zqycfp5teo2-shipments-outbound-archived-details',
}

export default helpLinks
