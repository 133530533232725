import React from 'react'
import classnames from 'classnames'

// TODO: move svgs into system
import { HelpIcon } from 'components/Svg/icons'

import Tooltip from '../Tooltip'
import classes from './styles.module.css'

export const HelpButton = ({ link = '', tooltip = 'Open help for this page in a new tab', className, onClick }) => {
  return (
    <Tooltip title={tooltip} minWidth="120px" dynamicVariant={true}>
      <a
        className={classnames(classes.helpButtonContainer, className)}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
      >
        <HelpIcon />
      </a>
    </Tooltip>
  )
}

export default HelpButton
