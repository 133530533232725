import React from 'react'

import { StatusLabel } from 'components/system'

const Status = ({ value }) => {
  const resolveStatus = (status) => {
    const formattedValue = status ? status.toLowerCase().replace(' ', '') : null

    switch (formattedValue) {
      case 'error':
      case 'invalid':
      case 'duplicate':
      case 'notregistered':
        return 'error'
      case 'registered':
        return 'success'
      case 'pending':
        return 'warning'
      default:
        return 'default'
    }
  }

  return <StatusLabel status={resolveStatus(value)} text={value} />
}

export default Status
