import React from 'react'

import classes from './styles.module.css'

export const Item = ({ title, action, Icon, testId, onClick }) => {
  const handleClick = () => onClick(action)

  return (
    <li data-test-id={testId} className={classes.itemContainer} onClick={handleClick}>
      {Icon ? (
        <div className={classes.icon}>
          <Icon />
        </div>
      ) : null}
      <div className={classes.label}>{title}</div>
    </li>
  )
}

export default Item
