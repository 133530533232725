import React from 'react'

import { resolveShapeToSVG } from 'utils/svgResolver'

import Tooltip from '../../Tooltip'
import classes from './styles.module.css'

export const ShapeCell = ({ value }) => {
  const ShapeSVG = resolveShapeToSVG(value)

  return (
    <Tooltip title={value || ''}>
      <div className={classes.shapeCellContainer}>
        {value ? (
          <div className={classes.icon}>
            <ShapeSVG size="m" />
          </div>
        ) : null}
        <div className={classes.text}>{value || '-'}</div>
      </div>
    </Tooltip>
  )
}

export default ShapeCell
