import { useState, useEffect } from 'react'
import { debounce } from 'lodash'

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    let mounted = true

    const handleResize = debounce(() => {
      if (mounted) {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }
    }, 1000)

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      mounted = false
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize
}

export default useWindowSize
