// import API from 'api'
import { UNLOCK_ACTION_TYPES } from '../actionTypes'

export function selectDiamondsToUnlockAction(ids) {
  return {
    type: UNLOCK_ACTION_TYPES.SELECT_DIAMONDS_TO_UNLOCK,
    payload: ids,
  }
}

export function removeDiamondsFromUnlockAction(id) {
  return {
    type: UNLOCK_ACTION_TYPES.REMOVE_DIAMONDS_FROM_UNLOCK,
    payload: id,
  }
}

export function setUnlockModalVisibleAction(name) {
  return {
    type: UNLOCK_ACTION_TYPES.SET_UNLOCK_MODAL_VISIBLE,
    payload: name,
  }
}

export function setTargetAction(name) {
  return {
    type: UNLOCK_ACTION_TYPES.SET_TARGET,
    payload: name,
  }
}
