import React, { useRef, useState, useEffect } from 'react'
import classnames from 'classnames'

import { CloseSVG, SearchIcon } from 'components/Svg'
import ClickOutsideListener from 'utils/ClickOutsideListener'
import { testId } from 'utils/testHelper'

import classes from './styles.module.css'

export const Search = ({
  value: initValue = '',
  placeholder = 'Search by ID...',
  minWidth = 237,
  trimValue = true,
  testIdPrefix = 'search',
  onChange,
  onClear,
}) => {
  const [value, setValue] = useState(initValue)
  const [hovered, setHovered] = useState(false)
  const [focused, setFocused] = useState(false)
  const inputRef = useRef()

  const escapeValue = (val) => {
    return val ? val.replace(/^\s+|\s+$/g, '') : ''
  }

  const handleMouseOver = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }

  const handleFocus = () => {
    setFocused(true)
  }

  const handleClear = (refocus) => {
    inputRef.current.value = ''
    setValue('')

    if (onClear) onClear()

    if (refocus) inputRef.current.focus()
  }

  const handleClickOutside = () => {
    if (!focused) return

    setFocused(false)
  }

  const handleChange = () => {
    const val = inputRef.current.value
    const escapedValue = trimValue ? escapeValue(val) : val

    onChange(escapedValue)

    setValue(escapedValue)
  }

  useEffect(() => {
    if (initValue !== value) setValue(initValue)
  }, [initValue]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ClickOutsideListener onClickOutside={handleClickOutside}>
      <div
        className={classnames(classes.searchContainer, {
          [classes.hover]: hovered && !focused,
          [classes.focus]: focused,
        })}
        style={{ minWidth }}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onFocus={handleFocus}
        data-test-id={testId(testIdPrefix, 'search')}
      >
        <div className={classes.searchBtn}>
          <SearchIcon />
        </div>
        <div
          className={classnames(classes.input, {
            [classes.hover]: hovered && !focused,
            [classes.focus]: focused,
          })}
        >
          <input
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            ref={inputRef}
            data-test-id={testId(testIdPrefix, 'searchBox')}
          />
        </div>
        <div
          className={classnames(classes.clearBtn, {
            [classes.visible]: value ? value.length > 0 : false,
            [classes.hover]: hovered && !focused,
          })}
          onClick={() => handleClear(true)}
          data-test-id={testId(testIdPrefix, 'searchBox', 'clearButton')}
        >
          <CloseSVG stroke="#FFF" />
        </div>
      </div>
    </ClickOutsideListener>
  )
}

export default Search
