import { clone } from 'ramda'
import { UNLOCK_ACTION_TYPES } from '../actionTypes'

const initialState = {
  data: {
    selected: [], // ids
    visibleModalName: null,
  },
}

const unlockReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case UNLOCK_ACTION_TYPES.SELECT_DIAMONDS_TO_UNLOCK: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          selected: payload,
        },
      }
    }
    case UNLOCK_ACTION_TYPES.REMOVE_DIAMONDS_FROM_UNLOCK: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          selected: newState.data.selected.filter((id) => id !== payload),
        },
      }
    }
    case UNLOCK_ACTION_TYPES.SET_UNLOCK_MODAL_VISIBLE: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          visibleModalName: payload,
        },
      }
    }
    case UNLOCK_ACTION_TYPES.SET_TARGET: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          target: payload,
        },
      }
    }
    default:
      return state
  }
}

export default unlockReducer
