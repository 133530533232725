import React, { useState } from 'react'
import classnames from 'classnames'

import { AcceptIcon, WarningIcon } from 'components/system/SVG'

import RadioItem from './RadioItem'
import classes from './styles.module.css'

export const RadioInput = ({ label, items = [], state, message, onChange, defaultSelected = -1, testIdPrefix = '' }) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultSelected)

  const resolveIcon = () => {
    switch (state) {
      case 'error':
        return <WarningIcon fill="#EC123B" />
      case 'success':
        return <AcceptIcon fill="#00B707" />
      default:
        return null
    }
  }

  const handleRadioChange = (value, checked) => {
    const index = items.findIndex((item) => item.value === value)
    const newIndex = checked ? index : -1

    setSelectedIndex(newIndex)

    if (onChange) onChange(newIndex)
  }

  return (
    <div className={classes.radioInputContainer}>
      <div className={classes.wrapper}>
        {label ? <div className={classes.label}>{label}</div> : null}
        <div className={classes.inputWrapper}>
          {items.map((item, index) => {
            return (
              <RadioItem
                {...item}
                checked={index === selectedIndex}
                key={item.value}
                onChange={handleRadioChange}
                testIdPrefix={testIdPrefix}
              />
            )
          })}
        </div>
        {message ? (
          <div className={classes.message}>
            <div className={classes.icon}>{resolveIcon()}</div>
            <div className={classnames(classes.text, { [classes[state]]: state })}>{message}</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default RadioInput
