import React, { Fragment, useState } from 'react'

import { Modal } from 'components/system'
import { Radio } from 'components/system/Radio'
import testids from 'config/testIds'

import classes from './styles.module.css'

const saved = 'SAVED'

const QuitSessionModal = ({ open, setOpen, initialValue, onConfirm }) => {
  const testIds = testids.dit.quitSessionModal
  const [selectedSaveType, setSelectedSaveType] = useState(initialValue)

  const selectSaveType = (saveOption) => {
    setSelectedSaveType(saveOption)
  }

  const handleCancelClick = () => {
    setOpen(false)
  }

  const options = [
    { option: 'SAVED', label: 'Save and quit' },
    { option: 'ABANDONED', label: 'Abandon and quit (all progress will be lost)' },
  ]

  return (
    <Modal
      isOpen={open}
      title={'QUIT SESSION'}
      confirmLabel="Quit"
      cancelTestId={testIds.cancel}
      confirmTestId={testIds.quit}
      onCancel={handleCancelClick}
      onConfirm={() => onConfirm(selectedSaveType)}
    >
      <Fragment>
        <div className={classes.text}>
          <span>Are you sure you wish to quit this session?</span>
        </div>
        <div>
          {options.map((item) => {
            const checked = selectedSaveType === item.option
            return (
              <Radio
                option={item.option}
                label={item.label}
                key={item.label}
                onSelect={selectSaveType}
                checked={checked}
              />
            )
          })}
        </div>
      </Fragment>
    </Modal>
  )
}

QuitSessionModal.defaultProps = {
  initialValue: saved,
}

export default QuitSessionModal
