import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'

import { applyMonitoringFiltersAction } from 'store/actions'
import showNotification from 'containers/Notifications/controller'
import { ClickOutsideListener } from 'components/system'
import { MonitoringIcon } from 'components/Svg/nav'
import { DownloadMediumIcon } from 'components/Svg'
import { MoreIcon } from 'components/Svg'
import testids from 'config/testIds'
import API from 'api'

import classes from './styles.module.css'

export const ActionCell = ({ row }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const menuRef = useRef()
  const [posX, setPosX] = useState(null)
  const testIds = testids.dit

  const mapper = {
    abandoned: '-',
    submitted: <MoreIcon />,
    'in progress': 'Continue session',
  }

  const label = mapper[row.data.status.toLowerCase()]
  const sessionId = row.data.session_id

  const [isOpen, setIsOpen] = useState(false)

  const handleToggleClick = () => {
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  const handleActionCellClick = ({ row }) => {
    const sessionStatus = row.data.status.toLowerCase()

    switch (sessionStatus) {
      case 'in progress':
        navigate(`/integration/dit/${row.data.session_id}`)
        break
      case 'abandoned':
        break
      case 'submitted':
        handleToggleClick()
        break
      default:
        break
    }
  }

  const handleMonitoringRedirect = () => {
    dispatch(applyMonitoringFiltersAction([]))
    navigate(`/integration/monitoring/${sessionId}`)
  }

  const handleDownloadReport = () => {
    API.downloadSessionReport(row.data.session_id, 'report', `transaction-report-${row.data.session_id}.xlsx`)
      .then(() => {
        showNotification({
          title: 'File downloaded',
          variant: 'success',
        })
      })
      .catch(() => {
        showNotification({
          title: 'File download failed',
          variant: 'error',
        })
      })
  }

  const handleDownloadFullReport = () => {
    API.downloadFullSessionReport(row.data.session_id, `full-transaction-report-${row.data.session_id}.xlsx`)
      .then(() => {
        showNotification({
          title: 'File downloaded',
          variant: 'success',
        })
      })
      .catch(() => {
        showNotification({
          title: 'File download failed',
          variant: 'error',
        })
      })
  }

  const positionContextMenu = () => {
    if (!menuRef.current) return

    const node = menuRef.current

    const parentPos = node.parentNode.getBoundingClientRect()
    const menuPos = node.getBoundingClientRect()
    const availableHeight = document.body.offsetHeight

    // not enough bottom space to render the menu going down
    // so render it going up instead
    // 20 is a spacing between three dots and the menu
    if (parentPos.top + menuPos.height + 20 > availableHeight) {
      setPosX(parentPos.top - menuPos.height)
    } else {
      // enough space to render the menu going down

      setPosX(parentPos.top + 20)
    }
  }

  // TODO: Enable when the monitoring functionality and remove the false
  const isSessionSubmitted = row.data.status === 'Submitted'

  useEffect(() => {
    if (!isOpen) return
    positionContextMenu()
  }, [isOpen])

  useEffect(() => {
    setIsOpen(false) // eslint-disable-next-line
  }, [JSON.stringify(row)])

  if (!label) return null

  return (
    <div className={classes.actionCellContainer}>
      <ClickOutsideListener onClickOutside={() => setIsOpen(false)}>
        <div
          className={classnames(classes.label, { [classes.inactive]: label === '-' })}
          onClick={() => {
            handleActionCellClick({ row })
          }}
        >
          {label}
        </div>
        {isOpen && (
          <div className={classes.optionList} ref={menuRef} style={{ top: `${posX ? posX : null}px` }}>
            {isSessionSubmitted && (
              <div className={classes.options}>
                <div className={classes.optionHeader}>View processing status in</div>
                <div
                  className={classes.option}
                  onClick={handleMonitoringRedirect}
                  data-test-id={testIds.monitoringRedirect}
                >
                  <span className={classes.icon}>
                    <MonitoringIcon />
                  </span>
                  <span className={classes.optionText}>Monitoring</span>
                </div>
              </div>
            )}
            <div className={classes.separator}></div>
            <div className={classes.options}>
              <div className={classes.optionHeader}>Download session report as</div>
              <div
                className={classes.option}
                onClick={handleDownloadReport}
                data-test-id={testIds.downloadSessionReport}
              >
                <span className={classes.icon}>
                  <DownloadMediumIcon />
                </span>
                <span className={classes.optionText}>.xlsx file</span>
              </div>
            </div>

            <div className={classes.separator}></div>
            <div className={classes.options}>
              <div className={classes.optionHeader}>Download full session report</div>
              <div
                className={classes.option}
                onClick={handleDownloadFullReport}
                data-test-id={testIds.downloadFullReport}
              >
                <span className={classes.icon}>
                  <DownloadMediumIcon />
                </span>
                <span className={classes.optionText}>.xlsx file</span>
              </div>
            </div>
          </div>
        )}
      </ClickOutsideListener>
    </div>
  )
}

export default ActionCell
