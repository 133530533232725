import React from 'react'
import classnames from 'classnames'

import { Tooltip } from 'components/system'
import { InfoIcon } from 'components/system/SVG'

import ThumbnailImage from './ThumbnailImage'
import classes from './styles.module.css'

export const ParticiapntIdCell = ({ row }) => {
  return (
    <div className={classnames(classes.participantIdCellContainer)}>
      <div className={classes.image}>
        <ThumbnailImage diamond={row.data} />
      </div>
      <div className={classes.value}>{row.data.participant_id}</div>
      <Tooltip title={row.data.participant_id}>
        <div className={classes.tooltip}>
          <InfoIcon />
        </div>
      </Tooltip>
    </div>
  )
}

export default ParticiapntIdCell
