import createActionSet from 'utils/createActionSet'

const namespace = 'MONITORING'
const GET_DIAMONDS = createActionSet(namespace, 'GET_DIAMONDS')
const APPLY_FILTERS = `${namespace}_APPLY_FILTERS`

export const MONITORING_ACTION_TYPES = {
  GET_DIAMONDS,
  APPLY_FILTERS,
}
