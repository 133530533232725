import { clone } from 'ramda'
import { DEMODATASET_ACTION_TYPES } from '../actionTypes'

const initialState = {
  isLoading: false,
  error: null,
  data: {
    wizard: null,
    defaultWizard: null,
  },
}

const demoDatasetReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case DEMODATASET_ACTION_TYPES.GET_WIZARD.REQUEST: {
      return { ...state, isLoading: true }
    }
    case DEMODATASET_ACTION_TYPES.GET_WIZARD.FAILURE: {
      return { ...state, isLoading: false, error: payload }
    }
    case DEMODATASET_ACTION_TYPES.GET_WIZARD.COMPLETE: {
      const newState = clone(state)
      newState.data = payload
      return { ...newState, isLoading: false }
    }
    case DEMODATASET_ACTION_TYPES.CREATE_WIZARD.REQUEST: {
      return { ...state, isLoading: true }
    }
    case DEMODATASET_ACTION_TYPES.CREATE_WIZARD.FAILURE: {
      return { ...state, isLoading: false, error: payload }
    }
    case DEMODATASET_ACTION_TYPES.CREATE_WIZARD.COMPLETE: {
      const newState = clone(state)
      newState.data.wizard = payload
      return { ...newState, isLoading: false }
    }
    default:
      return state
  }
}

export default demoDatasetReducer
