import React, { useEffect, useState } from 'react'
import classnames from 'classnames'

import { ErrorCircleSVG, WarningCircleSVG, SuccessCircleSVG, InfoCircleSVG, CloseSVG } from 'components/Svg'

import classes from './styles.module.css'

const Notification = ({ id, title, bodyText, variant = 'info', config, onClose }) => {
  const [visible, setVisible] = useState(true)
  const [timeoutId, setTimeoutId] = useState()
  const [becameVisibleTimestamp, setBecameVisibleTimestamp] = useState()
  const [timeLeft, setTimeLeft] = useState()

  const handleClose = () => {
    clearTimeout(timeoutId)

    setVisible(false)

    setTimeout(() => {
      if (onClose) onClose(id)
    }, config.slideInOutAnimationTime)
  }

  const getSvg = () => {
    switch (variant) {
      case 'error':
        return <ErrorCircleSVG />
      case 'warning':
        return <WarningCircleSVG />
      case 'success':
        return <SuccessCircleSVG />
      default:
        return <InfoCircleSVG />
    }
  }

  const pauseTimer = () => {
    if (!visible) return

    clearTimeout(timeoutId)

    setTimeLeft(config.autoHideDuration + config.slideInOutAnimationTime - (Date.now() - becameVisibleTimestamp))
  }

  const resumeTimer = () => {
    // close action happened
    if (!visible) return

    setTimeoutId(
      setTimeout(() => {
        handleClose()
      }, timeLeft)
    )
  }

  useEffect(() => {
    setBecameVisibleTimestamp(Date.now())

    setTimeoutId(
      setTimeout(() => {
        handleClose()
      }, config.autoHideDuration + config.slideInOutAnimationTime)
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.notificationContainer} data-test-id="notification">
      <div
        className={classnames(classes.wrapper, { [classes.slideIn]: visible, [classes.slideOut]: !visible })}
        onMouseEnter={pauseTimer}
        onMouseLeave={resumeTimer}
      >
        <div className={classes.closeBtn} onClick={() => handleClose()}>
          <CloseSVG />
        </div>
        <div className={classes.header}>
          <div className={classes.icon}>{getSvg()}</div>
          <div className={classnames(classes.title, { [classes[variant]]: true })}>{title}</div>
        </div>
        <div
          className={classnames(classes.body, { [classes.visible]: !!bodyText })}
          dangerouslySetInnerHTML={{ __html: bodyText }}
        />
      </div>
    </div>
  )
}

export default Notification
