export const dateFormatter = (val, withHours = true, withMilliseconds = false) => {
  if (!val) return ''

  const date = new Date(val)
  const year = date.getFullYear()
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()

  if (withHours && withMilliseconds) {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${date.getMilliseconds()}`
  } else if (withHours) {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  } else {
    return `${year}-${month}-${day}`
  }
}

export const timeFormatter = (val) => {
  const HOUR = 1000 * 60 * 60

  let time = ''
  const days = Math.floor(val / (HOUR * 24))
  const hours = Math.floor((val - days * HOUR * 24) / HOUR)
  // const minutes = Math.floor((val - days * HOUR * 24 - hours * HOUR) / 1000 / 60)

  if (days) {
    time += days
  }

  if (hours || days) {
    const formattedH = hours < 10 ? '0' + hours : hours

    time += days ? 'd ' + formattedH : formattedH
    time += 'h'
  }

  /*
  if (minutes || hours || days) {
    const formattedH = hours < 10 ? '0' + hours : hours
    const formattedM = minutes < 10 ? '0' + minutes : minutes

    if (!hours) time += formattedH

    time += ' ' + formattedM + 'm'
  }
  */

  // if no days or hours
  if (!time) time = '1h'

  return time
}

export const calculateDays = (time) => {
  if (time >= 24) {
    return (time - (time % 24)) / 24
  } else {
    return 0
  }
}

export const calculateHours = (time) => time % 24

const shortMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const fullMonth = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const formatShortDate = (value) => {
  const date = new Date(value)

  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  return `${day} ${shortMonth[month]} ${year.toString()[2]}${year.toString()[3]}`
}

export const formatLongDate = (value) => {
  const date = new Date(value)

  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  return `${day} ${fullMonth[month]} ${year}`
}

export const toTime = (value) => {
  const date = new Date(value).toLocaleTimeString('en-GB', { hour12: false })
  const dateSplit = date.split(':')

  // In Indian hours can go above 24h
  // example: new Date('2023-02-01T03:00:33.449+0530').toLocaleTimeString(undefined, { hour12: false })
  const hours = dateSplit[0] === '24' ? '00' : dateSplit[0]
  const minutes = dateSplit[1]

  if (dateSplit[0] === '24') console.log('Browser info', navigator.language, navigator.userAgent, new Date())

  return `${hours}:${minutes}`
}

// used in tables
export const timestampFormatter = (timestamp) => {
  if (!timestamp || timestamp === '-') return '-'

  // conver timestamp to local timestamp
  const original = new Date(timestamp)
  // const offset = original.getTimezoneOffset() * 60 * 1000
  const local = original.setTime(original.getTime())

  return `${formatShortDate(local)} - ${toTime(local)}`
}

export const TZAwareTimestampFormatter = (timestamp) => {
  // Format a string representing a timestamp with a timezone
  
  // Render any missing data as hyphens "-" for styling consistency
  if (!timestamp || timestamp === '-') return '-'

  // Match any timestamp like YYYY-MM-DDTHH(:MM)(:SS)(.ffffff)(Z/+HH((:)MM)
  const isoRegex = /(\d{4}-\d{2}-\d{2})T(\d{2})(:\d{2})?(:\d{2})?(\.\d+)?(Z|(?:\+\d{2}(?::?\d{2})))?/
  if (!isoRegex.test(timestamp)) {
    // timestamp doesn't match, revert to timestampFormatter instead
    return timestampFormatter(timestamp);
  } else {

    // Determine whether the timezone offset is already implied by the
    // timestamp, or whether we need to manually correct
    const matches = timestamp.match(isoRegex);
    const lastGroup = matches[matches.length -1];
    if (lastGroup === "Z" || /\+\d{2}(?::?\d{2})/.test(lastGroup)) {
      // Timezone already implied in timestamp
      return timestampFormatter(timestamp);
    } else {
      // Get the timezone offset from the browser's time to UTC
      const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000 * -1
      const original = new Date(timestamp);
      const local = original.setTime(original.getTime() + timezoneOffset)
      return `${formatShortDate(local)} - ${toTime(local)}`
    }
  }
}

export const toISOString = (date) => {
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  return `${date.getFullYear()}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}T${
    hours < 10 ? '0' + hours : hours
  }:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}.000Z`
}
