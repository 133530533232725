import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { selectActiveShipmentsIn, selectShipmentInDiamondStatuses } from 'store/selectors'
import { getShipmentsInAction, acceptShipmentInDiamondsAction, rejectShipmentInDiamondsAction } from 'store/actions'
import { StatusLabel, Table } from 'components/system'
// import { GradientProgressCell } from 'components/system/TableElements'
import { timestampFormatter } from 'utils/dates'
import { getTotalCaratsWeight, getCaratsRange, formatNumericalValueDefault } from 'utils/diamond'
import { resolveInboundShipmentStatusToState } from 'utils/shipments'
import { isPlural } from 'utils'

import Counter from '../../../../components/Counter'
import ConfirmModal from '../../../../components/ConfirmModal'
import Buttons from './Buttons'
import classes from './styles.module.css'
import testIds from 'config/testIds'

const ActiveTab = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const DEFAULT_PAGINATION = { page: 1, limit: 1000 }
  const DEFAULT_SORTING = { created_at: 'desc' }
  const shipments = useSelector(selectActiveShipmentsIn)
  const statuses = useSelector(selectShipmentInDiamondStatuses)
  const [page, setPage] = useState(DEFAULT_PAGINATION.page)
  const [limit] = useState(DEFAULT_PAGINATION.limit)
  const [sort] = useState(DEFAULT_SORTING)
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [shipmentInAction, setShipmentInAction] = useState([])
  const [modalShown, setModalShown] = useState(null)
  const [modalType, setModalType] = useState(null)
  const columns = [
    {
      key: 'sender',
      title: 'Sender',
      sortable: false,
    },
    {
      key: 'id',
      title: 'Shipment ID',
    },
    {
      key: 'created_at',
      title: 'Date sent',
      mapper: timestampFormatter,
    },
    {
      key: 'name',
      title: 'Shipment name',
    },
    {
      key: 'diamonds_count',
      title: 'Total diamonds',
      rightAlign: true,
      sortable: false,
    },
    {
      key: 'carats_weight',
      title: 'Total carats',
      rightAlign: true,
      sortable: false,
    },
    {
      key: 'carats_range',
      title: 'Carat range',
      rightAlign: true,
      mapper: (val) => `${formatNumericalValueDefault(val[0])}ct - ${formatNumericalValueDefault(val[1])}ct`,
      sortable: false,
    },
    /*
    {
      key: 'progress',
      title: 'Diamonds uploaded',
      cellComponent: GradientProgressCell,
    },
    */
    {
      key: 'integration_type',
      title: 'Integration',
      sortable: false,
    },
    {
      key: 'state',
      title: 'Status',
      cellComponent: StatusLabel,
      sortable: false,
    },
    {
      key: 'actions',
      title: 'Actions',
      cellComponent: Buttons,
      minWidth: '200px',
      sortable: false,
      props: {
        testId: testIds.shipmentsOutbound.tableActionButtons,
        onClick: (row, key) => {
          if (key === 'view') {
            navigate(`/shipments/inbound/pending/${row.data.id}`)
          } else if (key === 'accept') {
            setShipmentInAction(row.id)

            setModalType('confirmRoughShipment')
            setModalShown(true)
          } else if (key === 'reject') {
            setShipmentInAction(row.id)

            setModalType('rejectRoughShipment')
            setModalShown(true)
          } else if (key === 'upload') {
            navigate(`/integration/dit/new-session`)
          }
        },
      },
    },
  ]

  const getProgress = (shipment) => {
    const completeStatuses = statuses[shipment.shipment_type]
      .filter((status) => !status.active)
      .map((status) => status.key)

    const completeDiamondsCount = shipment.diamonds.filter((diamond) =>
      completeStatuses.includes(diamond.transfer_state)
    ).length

    return {
      done: completeDiamondsCount,
      total: shipment.diamonds.length,
      progress: (completeDiamondsCount / shipment.diamonds.length) * 100,
    }
  }

  const getRows = (shipments) => {
    if (!shipments) return []

    return shipments.map((shipment) => {
      return {
        id: shipment.id,
        data: {
          ...shipment,
          carats_weight: getTotalCaratsWeight(shipment.diamonds),
          carats_range: getCaratsRange(shipment.diamonds),
          progress: getProgress(shipment),
          integration_type: shipment.shipment_type.slice(0, 1).toUpperCase() + shipment.shipment_type.slice(1),
          state: resolveInboundShipmentStatusToState(shipment.status),
        },
      }
    })
  }

  const handleModalCancel = () => {
    setModalShown(false)
  }

  const handleModalConfirm = () => {
    switch (modalType) {
      case 'confirmRoughShipment': {
        const row = rows.find((row) => row.id === shipmentInAction)
        const diamonds = row.data.diamonds.map((diamond) => diamond.diamond_id)

        dispatch(acceptShipmentInDiamondsAction(row.id, diamonds))

        setModalShown(false)
        break
      }
      case 'rejectRoughShipment': {
        const row = rows.find((row) => row.id === shipmentInAction)
        const diamonds = row.data.diamonds.map((diamond) => diamond.diamond_id)

        dispatch(rejectShipmentInDiamondsAction(row.id, diamonds))

        setModalShown(false)
        break
      }

      default:
        break
    }
  }

  const loadShipments = ({ page, limit, sort }) => {
    setIsLoading(true)

    dispatch(getShipmentsInAction({ page, limit, sort })).then((response) => {
      if (response.data.content.pages <= page) {
        setHasMore(false)
      }

      setIsLoading(false)
    })
  }

  const handleSort = (sort) => {
    if (isLoading) return

    setPage(DEFAULT_PAGINATION.page)

    loadShipments({ page, limit, sort })
  }

  const handleLoadMore = () => {
    if (isLoading || !hasMore) return

    setPage(page + 1)

    loadShipments({ page: page + 1, limit, sort })
  }

  useEffect(() => {
    setRows(getRows(shipments))
  }, [JSON.stringify(shipments)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.activeTabContainer}>
      <div className={classes.wrapper}>
        {/*
        <div className={classes.filters}>
          <Filters filters={filters} applied={[]} onApply={() => {}} />
        </div>
        */}
        <div className={classes.counter} data-test-id={testIds.shipmentsInbound.shipmentsCounter}>
          <Counter
            testId={testIds.shipmentsInbound.active.counter}
            count={shipments.length}
            text={`Shipment${isPlural(shipments.length) ? 's' : ''}`}
          />
        </div>
        <div className={classes.list}>
          <Table
            columns={columns}
            rows={rows}
            sorted={{ created_at: 'desc' }}
            paginated
            hasMore={hasMore}
            onSort={handleSort}
            onLoadMore={handleLoadMore}
            testIdPrefix={testIds.shipmentsInbound.active.prefix}
          />
        </div>
      </div>

      <ConfirmModal
        title={modalType === 'confirmRoughShipment' ? 'Accept shipment' : 'Reject shipment'}
        visible={modalShown}
        onCancel={handleModalCancel}
        onConfirm={handleModalConfirm}
      >
        Are you sure?
        <br />
        <br />
        {modalType === 'confirmRoughShipment'
          ? 'Accepting this shipment will start registration. Once processed by Tracr, you will then need to upload diamond data.'
          : 'Rejecting this shipment will end the registration process. The sender will be notified that you have rejected the shipment. This process is irreversible.'}
      </ConfirmModal>
    </div>
  )
}

export default ActiveTab
