import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Media from 'containers/Media'
import { resolveIconToSVG } from 'utils/svgResolver'

import classes from './styles.module.css'

const CardMiddle = ({ id, media, imageSize, isGhost }) => {
  const Icon = media.type === 'icon' ? resolveIconToSVG(media.variant, media.value) : null

  return (
    <div className={classes.cardMiddleContainer}>
      <div className={classes.wrapper}>
        {isGhost && media.type === 'image' && (
          <img alt={'Diamond'} src={media.value} width={'100%'} style={{ display: 'block' }} />
        )}
        {!isGhost && (
          <div
            className={classes.media}
            style={{ maxWidth: imageSize ? imageSize.width : null, height: imageSize ? imageSize.height : null }}
          >
            <div
              className={classNames({
                [classes.mediaInner]: true,
                [classes.shape]: media.type === 'shape',
              })}
            >
              {media.type === 'image' && <Media media={{ id, ...media, fov: 25 }} />}
              {media.type === 'icon' && media.variant === 'shape' && <Icon width={'100%'} height={'100%'} />}
              {media.type === 'icon' && media.variant === 'lifecycle' && <Icon width={'95%'} height={'95%'} />}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

CardMiddle.propTypes = {
  id: PropTypes.string.isRequired,
  isGhost: PropTypes.bool,
  imageSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  media: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
}

export default CardMiddle
