import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectLifecycle, selectSelectedDiamonds, selectFullDiamond } from 'store/selectors'

import Nav from './Nav'

const NavWrapper = ({ title, sticky, children, testId }) => {
  const navigate = useNavigate()
  const params = useParams()
  const lifecycle = useSelector(selectLifecycle)
  const diamondId = params.id
  const diamond = useSelector(selectFullDiamond(diamondId))
  const selectedDiamonds = useSelector(selectSelectedDiamonds)
  const selectedIndex = selectedDiamonds.findIndex((id) => id === diamondId)

  const goToDiamond = (pos) => {
    const optionalGuideParam = params[0] ? '/guide' : ''
    let nextDiamondId

    if (pos === -1) {
      if (selectedIndex === 0) {
        nextDiamondId = selectedDiamonds[selectedDiamonds.length - 1]
      } else {
        nextDiamondId = selectedDiamonds[selectedIndex - 1]
      }
    } else {
      if (selectedIndex === selectedDiamonds.length - 1) {
        nextDiamondId = selectedDiamonds[0]
      } else {
        nextDiamondId = selectedDiamonds[selectedIndex + 1]
      }
    }

    navigate(`/inventory/${lifecycle.stage}/diamond/${nextDiamondId}${optionalGuideParam}`)
  }

  return (
    <Nav
      title={title}
      diamond={diamond && diamond.current}
      selectedCount={!diamond || selectedDiamonds.includes(diamond.current.diamond_id) ? selectedDiamonds.length : 1}
      selectedIndex={selectedIndex}
      sticky={sticky}
      onNavigate={goToDiamond}
      testId={testId}
    >
      {children}
    </Nav>
  )
}

export default NavWrapper
