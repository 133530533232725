import { clone, pick, omit } from 'ramda'
import { isEmpty } from 'lodash'
import { LOCATION_ACTION_TYPES, FILTER_ACTION_TYPES, STORE_INIT } from '../actionTypes'
import { filtersMapper } from '../mappers'

/**
* Diamond lifecycle states. These do not align backend lifecycle states.
 * The UI differentiates between polished and graded diamonds when filtering.
 * And we do not display destroyed diamonds.
 */
const LIFECYCLE_STATES = ['rough', 'split', 'polished', 'graded']
const POSSESSION_STATES = ['held', 'in_transit']

// when page refreshes, make sure that initial state
// has the right lifecycle set
const getAppliedState = (pathname = window.location.pathname) => {
  const path = pathname.split('/')

  // if url does not contain a 3rd part, page is not part part of lifecycle_state filters
  if (!path[3]) return ''

  if (LIFECYCLE_STATES.includes(path[2])) return path[2]
}

const initialState = {
  data: {
    available: {
      possession_state: POSSESSION_STATES,
    },
    selected: {},
    applied: {
      lifecycle_state: getAppliedState(),
      possession_state: POSSESSION_STATES,
    },

    // drillDown: {}, optional parameter, only present during drill down,
    // contains selected object + applied object
  },
  isLoading: false,
  error: null,
}

const filtersReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case STORE_INIT:
      return initialState
    case LOCATION_ACTION_TYPES.CHANGE: {
      const lifecycle_state = getAppliedState(payload.pathname)

      if (state.data.applied.lifecycle_state !== lifecycle_state) {
        const newState = clone(state)

        return {
          ...newState,
          data: {
            available: newState.data.available,
            selected: {},
            applied: {
              possession_state: initialState.data.applied.possession_state,
              lifecycle_state,
            },
          },
        }
      }

      return state
    }
    case FILTER_ACTION_TYPES.GET_FILTERS.REQUEST: {
      const newState = clone(state)
      return {
        ...newState,
        isLoading: true,
      }
    }
    case FILTER_ACTION_TYPES.GET_FILTERS.COMPLETE: {
      const newState = clone(state)

      // only allow "Checked" (true) option in UI
      const filteredMatched = payload?.matched.filter(({ value }) => value);

      return {
        ...newState,
        isLoading: false,
        error: null,
        data: {
          ...newState.data,
          available: {
            ...newState.data.available,
            ...payload,
            ...(filteredMatched && {
              matched: filteredMatched.map(option => ({ ...option, title: option.value ? "Checked" : "Not Checked" }))
            })
          },
        },
      }
    }
    case FILTER_ACTION_TYPES.SELECT: {
      const newState = clone(state)
      const selected = {}

      Object.keys(payload).forEach((filterName) => {
        if (payload[filterName] && !isEmpty(payload[filterName])) {
          selected[filterName] = payload[filterName]
        }
      })

      return {
        ...newState,
        data: {
          ...newState.data,
          selected,
        },
      }
    }
    case FILTER_ACTION_TYPES.APPLY: {
      const newState = clone(state)
      const mappedPayload = filtersMapper(payload)
      // lifecycle_State and possession_state cannot be cleared from filters
      const specialFilters = ['lifecycle_state', 'possession_state']
      const nonRemovable = pick(specialFilters, newState.data.applied)
      const selected = { ...newState.data.selected }
      // non null filters
      const special = {}

      // remove filters with undefined values
      Object.keys(mappedPayload).forEach((filterName) => {
        if (mappedPayload[filterName] === undefined) {
          delete selected[filterName]
        } else if (!specialFilters.includes(selected[filterName])) {
          selected[filterName] = mappedPayload[filterName]
        } else {
          // special case, when possession_state is updated
          special[filterName] = mappedPayload[filterName]
        }
      })

      return {
        ...newState,
        data: {
          ...newState.data,
          selected: {
            ...selected,
          },
          applied: {
            ...nonRemovable,
            ...selected,
            ...special,
          },
        },
      }
    }
    case FILTER_ACTION_TYPES.DRILL_DOWN: {
      const newState = clone(state)
      const mappedPayload = filtersMapper(payload)
      // lifecycle_State and possession_state cannot be cleared from filters
      const specialFilters = ['lifecycle_state', 'possession_state']
      const nonRemovable = pick(specialFilters, newState.data.applied)
      const selected = { ...newState.data.selected }
      // non null filters
      const special = {}

      // remove filters with undefined values
      Object.keys(mappedPayload).forEach((filterName) => {
        if (mappedPayload[filterName] === undefined) {
          delete selected[filterName]
        } else if (!specialFilters.includes(selected[filterName])) {
          selected[filterName] = mappedPayload[filterName]
        } else {
          // special case, when possession_state is updated
          special[filterName] = mappedPayload[filterName]
        }
      })

      return {
        ...newState,
        data: {
          ...newState.data,
          selected: {
            ...selected,
          },
          applied: {
            ...nonRemovable,
            ...selected,
            ...special,
          },
          // this object will be used to restore filters when going
          // back from drill down in list view
          history: {
            ...newState.data,
          },
        },
      }
    }
    case FILTER_ACTION_TYPES.CLEAR: {
      const newState = clone(state)
      // lifecycle_State and possession_state cannot be cleared from filters
      const newFilters = payload
        ? omit([[payload]], newState.data.applied)
        : pick(['lifecycle_state', 'possession_state'], newState.data.applied)

      return {
        ...newState,
        data: {
          available: newState.data.available,
          selected: newFilters,
          applied: newFilters,
        },
      }
    }
    default:
      return state
  }
}

export default filtersReducer
