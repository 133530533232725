import API from 'api'
import showNotification from 'containers/Notifications/controller'
import { createErrorMessage, createSuccessMessage } from 'utils/notificationMessages'
import { DIAMONDS_ACTION_TYPES } from '../actionTypes'
import { sessionExpiredAction } from './'

export function getFullDiamondAction(id) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.GET_FULL_DIAMOND.REQUEST })

    return API.getFullDiamond(id).then(
      (response) => {
        dispatch({ type: DIAMONDS_ACTION_TYPES.GET_FULL_DIAMOND.COMPLETE, payload: response.data.content })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIAMONDS_ACTION_TYPES.GET_FULL_DIAMOND.FAILURE, payload: error })
        }
      }
    )
  }
}

export function getDiamondsAction({ filters, sort, page, limit }) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.GET_DIAMONDS.REQUEST })

    const possession_state =
      filters.possession_state === 'held' ? ['held', 'staged', 'in_transit'] : filters.possession_state

    const { date } = filters
    const from = date ? new Date(date.from).toDateString() : null
    const to = date && date.to ? new Date(date.to).toDateString() : null

    const body = {
      filters: {
        ...filters,
        ...(date ? { date: { from, to } } : null),
        possession_state,
      },
      sort,
      page,
      limit,
    }

    return API.getDiamonds(body).then(
      (response) => {
        // update dashboard content
        return dispatch({
          type: DIAMONDS_ACTION_TYPES.GET_DIAMONDS.COMPLETE,
          payload: { ...response.data.content, page },
        })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIAMONDS_ACTION_TYPES.GET_DIAMONDS.FAILURE, payload: error })
        }
      }
    )
  }
}

export function getFullDiamondsAction(ids) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.GET_FULL_DIAMONDS.REQUEST })

    const body = {
      ids,
    }

    return API.getFullDiamonds(body).then(
      (response) => {
        // update dashboard content
        return dispatch({
          type: DIAMONDS_ACTION_TYPES.GET_FULL_DIAMONDS.COMPLETE,
          payload: response.data.content,
        })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIAMONDS_ACTION_TYPES.GET_FULL_DIAMONDS.FAILURE, payload: error })
        }
      }
    )
  }
}

export function getDiamondBlueNileAction(id) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.GET_DIAMOND_BLUENILE.REQUEST })

    return API.getDiamondBlueNile(id).then(
      (response) => {
        dispatch({ type: DIAMONDS_ACTION_TYPES.GET_DIAMOND_BLUENILE.COMPLETE, payload: response.data.content })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIAMONDS_ACTION_TYPES.GET_DIAMOND_BLUENILE.FAILURE, payload: error })
        }
      }
    )
  }
}

export function getDiamondsCountAction(filters) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.GET_DIAMONDS_COUNT.REQUEST })

    const possession_state =
      filters.possession_state === 'held' ? ['held', 'staged', 'in_transit'] : filters.possession_state

    const { date } = filters
    const from = date ? new Date(date.from).toDateString() : null
    const to = date && date.to ? new Date(date.to).toDateString() : null

    const body = {
      filters: {
        ...filters,
        ...(date ? { date: { from, to } } : null),
        possession_state,
      },
    }

    return API.getDiamondsCount(body).then(
      (response) => {
        dispatch({ type: DIAMONDS_ACTION_TYPES.GET_DIAMONDS_COUNT.COMPLETE, payload: response.data.content })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIAMONDS_ACTION_TYPES.GET_DIAMONDS_COUNT.FAILURE, payload: error })
        }
      }
    )
  }
}

export function getTotalDiamondsCountAction() {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.GET_TOTAL_DIAMONDS_COUNT.REQUEST })

    return API.getTotalDiamondsCount().then(
      (response) => {
        dispatch({ type: DIAMONDS_ACTION_TYPES.GET_TOTAL_DIAMONDS_COUNT.COMPLETE, payload: response.data.content })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIAMONDS_ACTION_TYPES.GET_TOTAL_DIAMONDS_COUNT.FAILURE, payload: error })
        }
      }
    )
  }
}

export function transferDiamondsAction(ids, platformName) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.TRANSFER_DIAMOND.REQUEST })

    const body = {
      ids,
      destination: platformName,
      participant_timestamp: new Date(),
    }

    return API.transferDiamonds(body).then(
      () => {
        dispatch({ type: DIAMONDS_ACTION_TYPES.TRANSFER_DIAMOND.COMPLETE, payload: ids })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIAMONDS_ACTION_TYPES.TRANSFER_DIAMOND.FAILURE, payload: error })
        }
      }
    )
  }
}

export function shareDiamondsAction(ids, templateVersionId) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.SHARE_DIAMOND.REQUEST })

    const body = {
      ids,
      templateVersionId,
    }

    return API.shareDiamonds(body).then(
      (data) => {
        return dispatch({ type: DIAMONDS_ACTION_TYPES.SHARE_DIAMOND.COMPLETE, payload: data.data.content })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIAMONDS_ACTION_TYPES.SHARE_DIAMOND.FAILURE, payload: error })
        }
      }
    )
  }
}

export function updateShareDiamondsAction(id, ids, templateVersionId) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.SHARE_DIAMOND.REQUEST })

    const body = {
      ids,
      templateVersionId,
    }

    return API.updateShareDiamonds(id, body).then(
      (data) => {
        return dispatch({ type: DIAMONDS_ACTION_TYPES.SHARE_DIAMOND.COMPLETE, payload: data.data.content })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIAMONDS_ACTION_TYPES.SHARE_DIAMOND.FAILURE, payload: error })
        }
      }
    )
  }
}

export function getSharedDiamondsAction(id) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.GET_SHARED_DIAMONDS.REQUEST })

    return API.getSharedDiamonds(id)
      .then((diamonds) => {
        return dispatch({ type: DIAMONDS_ACTION_TYPES.GET_SHARED_DIAMONDS.COMPLETE, payload: diamonds.data.content })
      })
      .catch((response) => {
        const error = JSON.parse(JSON.stringify(response))

        if (error.message && error.message.includes('404')) {
          dispatch({ type: DIAMONDS_ACTION_TYPES.GET_SHARED_DIAMONDS.COMPLETE, payload: false })
        }
      })
  }
}

export function shareDiamondsToEmailAction(id, body) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.SHARE_DIAMOND_TO_EMAIL.REQUEST })

    return API.shareDiamondsToEmail(id, body).then(
      (data) => {
        showNotification({
          title: createSuccessMessage({ key: 'share', operation: 'CREATE' }),
          variant: 'success',
        })

        return dispatch({ type: DIAMONDS_ACTION_TYPES.SHARE_DIAMOND_TO_EMAIL.COMPLETE, payload: data.data.content })
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          showNotification({
            title: createErrorMessage({ error, key: 'share', operation: 'CREATE' }),
            variant: 'error',
          })

          dispatch({ type: DIAMONDS_ACTION_TYPES.SHARE_DIAMOND_TO_EMAIL.FAILURE, payload: error })
        }
      }
    )
  }
}

export function unlockDiamondsAction(diamondIds) {
  return (dispatch) => {
    dispatch({ type: DIAMONDS_ACTION_TYPES.UNLOCK_DIAMONDS.REQUEST })

    return API.unlockDiamonds(diamondIds).then(
      (response) => {
        showNotification({
          title: `${diamondIds.length} diamond${diamondIds.length > 1 ? 's' : ''} successfully unlocked`,
          variant: 'success',
        })

        dispatch({ type: DIAMONDS_ACTION_TYPES.UNLOCK_DIAMONDS.COMPLETE, payload: response.data.content })

        return response.data.content
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          showNotification({
            title: `Error while unlocking diamonds ${error}`,
            variant: 'error',
          })

          dispatch({ type: DIAMONDS_ACTION_TYPES.UNLOCK_DIAMONDS.FAILURE, payload: error })
        }
      }
    )
  }
}

export function updateSelectedDiamonds(payload) {
  return {
    type: DIAMONDS_ACTION_TYPES.UPDATE_SELECTED_DIAMONDS,
    payload,
  }
}
