import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import classes from './styles.module.css'

export const Heading = ({ text, variant = 'primary', closed, children }) => {
  return (
    <div className={classes.headingContainer}>
      <div
        className={classnames(classes.wrapper, {
          [classes.primary]: variant === 'primary',
          [classes.secondary]: variant === 'secondary',
          [classes.closed]: closed,
        })}
      >
        {text}
        {children}
      </div>
    </div>
  )
}

Heading.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
}

export default Heading
