import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import {
  getDiamondsAction,
  updateUIAction,
  selectDiamondsToShareAction,
  updateSelectedDiamonds,
  selectDiamondsToUnlockAction,
  setUnlockModalVisibleAction,
  setTargetAction,
} from 'store/actions'
import {
  selectLifecycle,
  selectDiamonds,
  selectDiamondsHasMore,
  selectAppliedFilters,
  selectViewColumns,
  selectSorting,
  selectSelectedDiamonds,
} from 'store/selectors'
import { Table } from 'components/system'
import { CheckboxCell, ActionsCell } from 'components/system/TableElements'
import { EyeIcon, ShareIcon, LockIcon } from 'components/system/SVG'
import { getLockedIds } from 'utils/diamond'
import testids from 'config/testIds'

import { columnsMapper } from './columns'
import { defaultMapper } from './mappers'
import classes from './styles.module.css'

const List = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const lifecycle = useSelector(selectLifecycle)
  const appliedFilters = useSelector(selectAppliedFilters)
  const diamonds = useSelector(selectDiamonds)
  const viewColumns = useSelector(selectViewColumns(lifecycle.stage))
  const hasMore = useSelector(selectDiamondsHasMore)
  const selection = useSelector(selectSelectedDiamonds)
  const DEFAULT_PAGINATION = { page: 1, limit: 40 }
  const [isLoading, setIsLoading] = useState(false)
  const sort = useSelector(selectSorting)
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const checkboxColumn = {
    key: 'checkbox',
    resizable: false,
    sortable: false,
    minWidth: '40px',
    width: '40px',
    headerComponent: CheckboxCell,
    cellComponent: CheckboxCell,
    props: {
      path: 'diamond_id',
      onChange: (row, val, isHeader) => {
        if (isHeader) {
          // select all diamonds
          if (val === 2) {
            dispatch(updateSelectedDiamonds(diamonds.map((d) => d.diamond_id)))
          }
          // deselect all diamonds
          else dispatch(updateSelectedDiamonds([]))
        } else {
          if (val === 2) {
            // add to selection
            dispatch(updateSelectedDiamonds(selection.concat(row.data.diamond_id)))
          } else {
            dispatch(updateSelectedDiamonds(selection.filter((diamond_id) => diamond_id !== row.data.diamond_id)))
          }
        }
      },
    },
  }
  const testIds = testids.listView

  const parseColumns = () => {
    return [checkboxColumn]
      .concat(
        viewColumns
          .map((column) => {
            const mapper = columnsMapper[column.path]

            if (!mapper) {
              console.log('Missing mapper for', column.path)
              return {}
            }

            if (!column.isDefault && !column.isEnabled) {
              return {
                ...mapper,
                excluded: true,
              }
            } else {
              return {
                tooltip: { title: mapper.title },
                mapper: defaultMapper,
                key: column.path,
                ...mapper,
              }
            }
          })
          .filter((column) => !column.excluded)
      )
      .concat({
        key: 'actions',
        title: 'Actions',
        cellComponent: ActionsCell,
        minWidth: '160px',
        sortable: false,
        props: {
          items: [
            { key: 'view', title: 'View', icon: EyeIcon, testId: testids.inventory.list.actionsColumn.viewButton },
            { key: 'share', title: 'Share', icon: ShareIcon, testId: testids.inventory.list.actionsColumn.shareButton },
            {
              key: 'unlock',
              title: 'Unlock',
              icon: LockIcon,
              testId: testids.inventory.list.actionsColumn.unlockButton,
              condition: (row) => row.data.unlock_status !== 'Available',
            },
          ],
          onClick: (row, key) => {
            switch (key) {
              case 'view':
                if (!selection.includes(row.data.id)) dispatch(updateSelectedDiamonds([]))

                navigate(`/inventory/${lifecycle.stage}/diamond/${row.data.diamond_id}`)
                break
              case 'share': {
                const inSelection = selection.includes(row.data.diamond_id)
                let lockedIds = []

                if (inSelection) {
                  lockedIds = getLockedIds(diamonds, selection)
                } else {
                  dispatch(updateSelectedDiamonds([row.data.diamond_id]))

                  lockedIds = getLockedIds([row.data], [row.data.diamond_id])
                }

                if (lockedIds.length) {
                  dispatch(setTargetAction('share'))
                  dispatch(selectDiamondsToUnlockAction(lockedIds))
                  dispatch(setUnlockModalVisibleAction('multiple'))
                } else dispatch(selectDiamondsToShareAction(inSelection ? selection : [row.data.diamond_id]))

                break
              }
              case 'unlock': {
                const inSelection = selection.includes(row.data.diamond_id)
                let lockedIds = []

                if (inSelection) {
                  lockedIds = getLockedIds(diamonds, selection)
                } else {
                  dispatch(updateSelectedDiamonds([row.data.diamond_id]))

                  lockedIds = getLockedIds([row.data], [row.data.diamond_id])
                }

                dispatch(selectDiamondsToUnlockAction(lockedIds))
                dispatch(setUnlockModalVisibleAction('multiple'))

                break
              }
              default:
                break
            }
          },
        },
      })
  }

  const parseRows = () => {
    return diamonds.map((diamond, index) => {
      const formattedRow = {
        id: diamond.diamond_id,
        rowIndex: index,
        selected: selection.includes(diamond.diamond_id),
        data: {
          ...diamond,
        },
      }

      return formattedRow
    })
  }

  const handleSort = (sort) => {
    if (isLoading) return

    setPagination(DEFAULT_PAGINATION)
    dispatch(updateUIAction({ sort: sort }))

    //It's better to use the global "sort" as controlled by the redux store.
    //However, I was having difficulty transforming the updateUIAction into a
    //promise, so we use the sort passed by the event listener. The effect is
    //identical, but it's a bit of an anti-pattern - AS.
    loadDiamonds({ sort: sort, pagination: DEFAULT_PAGINATION, filters: appliedFilters })
  }

  const handleLoadMore = () => {
    if (isLoading) return

    const newPagination = { ...DEFAULT_PAGINATION, page: pagination.page + 1 }
    setPagination({ ...DEFAULT_PAGINATION, page: pagination.page + 1 })
    loadDiamonds({ pagination: newPagination, sort: sort, filters: appliedFilters })
  }

  const handleRowClick = (row, evt) => {
    const target = evt.nativeEvent.target

    const isCheckbox = (parent) => {
      if (parent.children.length && parent.children[0].dataset && parent.children[0].dataset.checkbox) return true

      if (parent.dataset && parent.dataset.checkbox) return true

      if (parent.parentNode) return isCheckbox(parent.parentNode)
    }

    const isAction = (parent) => {
      if (parent.dataset && parent.dataset.actions) return true

      if (parent.parentNode) return isAction(parent.parentNode)
    }

    if (isCheckbox(target) || isAction(target)) return

    if (!selection.includes(row.data.diamond_id)) dispatch(updateSelectedDiamonds([]))

    navigate(`/inventory/${lifecycle.stage}/diamond/${row.data.diamond_id}`)
  }

  const loadDiamonds = ({ pagination, sort, filters }) => {
    setIsLoading(true)

    dispatch(
      getDiamondsAction({
        filters,
        sort,
        ...pagination,
      })
    ).then(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (!appliedFilters.lifecycle_state) return

    setPagination(DEFAULT_PAGINATION)
    loadDiamonds({ pagination: DEFAULT_PAGINATION, sort, filters: appliedFilters })
  }, [JSON.stringify(appliedFilters)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.listContainer}>
      <Table
        columns={parseColumns()}
        rows={parseRows()}
        sorted={sort}
        paginated
        virtualized
        hasMore={hasMore}
        isLoading={isLoading}
        isEmpty={isLoading === false && diamonds.length === 0}
        testIdPrefix={testIds.prefix}
        onRowClick={handleRowClick}
        onSort={handleSort}
        onLoadMore={handleLoadMore}
      />
    </div>
  )
}

export default List
