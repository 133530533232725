import React from 'react'

import { Modal } from 'components/system'
import testids from 'config/testIds'

import classes from './styles.module.css'

const ConfirmModal = ({ open, type, onCancel, onConfirm }) => {
  const testIds = testids.transferOut

  return (
    <Modal
      isOpen={open}
      title={type === 'accept' ? 'ACCEPT OWNERSHIP' : 'reject ownership'}
      confirmLabel="Confirm"
      cancelTestId={testIds.transferModalCancel}
      confirmTestId={testIds.transferModalConfirm}
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm()}
    >
      <div className={classes.text}>
        {type ? <div>Are you sure?</div> : null}
        <br />
        {type === 'accept' ? (
          <div>By accepting ownership of the diamond(s), the transfer of the digital record(s) will begin.</div>
        ) : null}
        {type === 'reject' ? (
          <div>By rejecting ownership of diamond(s), you will not own or have access to the digital record(s).</div>
        ) : null}
      </div>
    </Modal>
  )
}

export default ConfirmModal
