import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import ViewNavContainer from './ViewNav'
import PrivacyPolicy from './pages/PrivacyPolicy'
import PlatformSupport from './pages/PlatformSupport'
import Conditions from './pages/Conditions'
import Cookies from './pages/Cookies'
// import DemoWizardSettingsContainer from './pages/DemoWizardSettings'
import Nav from './Nav'
import classes from './styles.module.css'

export const Support = () => {
  const { hash } = useLocation()
  const [section, setSelectedSection] = useState(hash ? hash.replace('#', '') : 'support')

  const sections = [
    {
      header: [{ title: 'Platform Support', id: 'platform' }],
      types: [{ id: 'support', title: 'Tracr Support Portal' }],
    },
    {
      header: [{ title: 'Legal', id: 'legal' }],
      types: [
        { id: 'conditions', title: 'Terms and Conditions' },
        { id: 'privacy', title: 'Privacy Policy' },
        { id: 'cookies', title: 'Cookies' },
      ],
    },
    {
      header: [{ title: 'Data Population', id: 'wizard' }],
      types: [{ id: 'wizard', title: 'Demo Data Wizard' }],
    },
  ]

  const handleLinkClick = (id) => {
    setSelectedSection(id)
  }

  const renderActiveSection = () => {
    switch (section) {
      case 'privacy':
        return <PrivacyPolicy />
      case 'conditions':
        return <Conditions onLinkClick={handleLinkClick} />
      case 'cookies':
        return <Cookies />
      /*
      case 'wizard':
        return <DemoWizardSettingsContainer />
        */
      case 'support':
      default:
        return <PlatformSupport />
    }
  }

  return (
    <div className={classes.supportContainer}>
      <ViewNavContainer />
      <div className={classes.wrapper}>
        <Nav section={section} onClick={handleLinkClick} templates={sections} />
        {renderActiveSection()}
      </div>
    </div>
  )
}

export default Support
