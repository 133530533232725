import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clone } from 'ramda'

import { updateViewColumnsAction, resetViewColumnsAction } from 'store/actions'
import { selectViewColumns, selectLifecycleStage } from 'store/selectors'
import Columnpicker from 'components/system/Columnpicker'
// import { featureToggles } from 'config'
import testids from 'config/testIds'

import copy from './copy'

const ColumnpickerContainer = ({testIdPrefix}) => {
  const dispatch = useDispatch()
  const lifecycle = useSelector(selectLifecycleStage)
  const columns = useSelector(selectViewColumns(lifecycle))

  const withTitles = (columns) => {
    return clone(columns).map((column) => {
      column.title = copy[column.path]
      return column
    })
  }

  const handleReset = () => {
    dispatch(resetViewColumnsAction({ lifecycle }))
  }

  const handleChange = (newState) => {
    dispatch(updateViewColumnsAction({ lifecycle, columns: newState }))
  }

  return (
    <Columnpicker
      columns={withTitles(columns)}
      testIdPrefix={testIdPrefix}
      onReset={handleReset}
      onChange={handleChange}
    />
  )
}

export default ColumnpickerContainer
