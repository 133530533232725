import React from 'react'
import classnames from 'classnames'

import { UploadCSVIcon, CloseSVG, TickFilledIcon, DITErrorCircleIcon } from 'components/Svg'

import classes from './styles.module.css'

export const Filebox = ({ file, status, statusText, testId, onClose }) => {
  return (
    <div className={classes.fileboxContainer} {...(testId ? { 'data-test-id': `${testId}.fileBoxContainer` } : null)}>
      <div className={classes.wrapper}>
        <div className={classes.icon}>
          <UploadCSVIcon />
        </div>
        <div className={classes.meta}>
          <div className={classes.title}>{file.name}
            {status === 'success' && <TickFilledIcon />}
          </div>
          <div className={classes.size}>{Math.ceil(file.size / 1024)}KB</div>
          {status === 'error' && (
            <div className={classnames(classes.message, classes.error)}>
              <DITErrorCircleIcon /> <span>{statusText || 'Error uploading file'}</span>
            </div>
          )}
        </div>
        <div
          className={classes.closeBnt}
          onClick={onClose}
          {...(testId ? { 'data-test-id': `${testId}.fileBoxRemoveButton` } : null)}
        >
          <CloseSVG />
        </div>
      </div>
    </div>
  )
}

export default Filebox
