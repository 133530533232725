import React, { useRef, useState, useEffect } from 'react'
import classnames from 'classnames'
import { SHARE_EXPIRES_IN } from 'config/constants'

import { InfoIcon } from 'components/Svg/icons'
import { Button, Tooltip, TagInput } from 'components/system'
import { testIds } from 'config';

import classes from './styles.module.css'

export const Form = ({ isLoading = false, onSubmit }) => {
  const container = useRef()
  const [tags, setTags] = useState([])
  const [isSubmittable, setIsSubmittable] = useState(false)
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState([])

  const parseExpiry = (value) => {
    const number = parseInt(value.split(/\D/).join(''))
    const unit = value.split(/\d/).join('')

    let fullUnit = ''

    switch (unit) {
      case 'm':
        fullUnit = 'minute'
        break
      case 'h':
        fullUnit = 'hour'
        break
      case 'd':
        fullUnit = 'day'
        break
      case 'w':
        fullUnit = 'week'
        break
      default:
        fullUnit = 'day'
        break
    }

    return `${number} ${fullUnit}${number > 1 ? 's' : ''}`
  }

  const updateIsSubmittable = () => {
    const areValuesSet = values.email && values.subject && values.body
    const noErrors = errors.length === 0

    if (areValuesSet && noErrors) setIsSubmittable(true)
    else if (isSubmittable) setIsSubmittable(false)
  }

  // When using setErrors there is a small delay on the value of `errors` being updated.
  // As a result if we call `updateIsSubmittable()` inline there will be no errors when
  // there should be and we will get into a bad state.
  useEffect(() => {
    updateIsSubmittable()
  }, [values]) // eslint-disable-line react-hooks/exhaustive-deps

  const filterOutErrors = (name) => {
    return errors.filter((error) => error.belongsTo !== name)
  }

  const handleFocus = (name) => {
    setErrors(filterOutErrors(name))
  }

  const handleBlur = (name) => {
    const fieldErrors = validateField(name)
    const allErrors = filterOutErrors(name)

    if (fieldErrors.length) {
      setErrors(allErrors.concat(fieldErrors))
    } else {
      setErrors(allErrors)
    }
  }

  const validateForm = (showErrors) => {
    const fields = ['email', 'subject', 'body']

    const errors = fields.map((name) => validateField(name)).flat()

    if (showErrors) setErrors(errors)
  }

  const validateField = (name) => {
    const errors = []
    const input = container.current.querySelector(`[name=${name}]`)
    switch (name) {
      case 'email': {
        const isEmpty = tags.length === 0
        var isInvalidFormat = false
        tags.map((tag) => {
          if (!tag.isValid) {
            isInvalidFormat = true
          }
          return isInvalidFormat
        })
        if (isEmpty || isInvalidFormat) {
          errors.push({ belongsTo: name, text: 'Please enter a valid email address' })
        }

        return errors
      }
      case 'subject': {
        const value = input.value
        const isEmpty = value.length === 0

        if (isEmpty) {
          errors.push({ belongsTo: name, text: 'Please enter a subject' })
        }

        return errors
      }
      case 'body': {
        const value = input.value
        const isEmpty = value.length === 0

        if (isEmpty) {
          errors.push({ belongsTo: name, text: 'Please enter a message' })
        }

        return errors
      }
      default:
        break
    }

    return errors
  }

  const hasError = (name) => {
    return !!errors.find((error) => error.belongsTo === name)
  }

  const handleValueChange = (evt) => {
    setValues({ ...values, [evt.target.name]: evt.target.value })

    validateForm(false)
  }

  const handleTagsChange = (tags) => {
    setTags(tags)
  }

  const handleSubmit = () => {
    if (onSubmit) onSubmit(values)
  }

  useEffect(() => {
    const emailAddresses = tags.map(({ value }) => value)

    setValues({ ...values, email: emailAddresses.join(', ') })
    if (emailAddresses.length > 0) {
      handleBlur('email')
    } else {
      validateForm(false)
    } // eslint-disable-next-line
  }, [tags])

  return (
    <div className={classes.formContainer} ref={container}>
      <div className={classes.description}>
        Email <span>Diamond(s) will be sent to recipients individually</span>
      </div>
      <div className={classnames(classes.emailInput, { [classes.error]: hasError('email') || hasError('subject') })}>
        <div className={classes.field}>
          <div className={classnames(classes.label, classes.email)}>To</div>
          <TagInput
            type="email"
            name="email"
            onFocus={() => handleFocus('email')}
            onBlur={() => handleBlur('email')}
            placeholder={'Add email address(es)'}
            delimiters={[
              { value: ';', replace: true },
              { value: ',', replace: true },
              { value: ' ', replace: true },
              { value: 'Tab', replace: true },
              { value: 'Enter', replace: true },
            ]}
            allowDuplicates={false}
            allowEditing={false}
            onUpdate={handleTagsChange}
          />
        </div>
        <div className={classnames(classes.field, classes.subject)}>
          <div className={classes.label}>Subject</div>
          <div className={classes.inputWrapper}>
            <input
              type="text"
              name="subject"
              placeholder="Enter subject"
              onChange={handleValueChange}
              onFocus={() => handleFocus('subject')}
              onBlur={() => handleBlur('subject')}
              autoComplete="off"
            />
          </div>
        </div>
      </div>
      <div className={classes.errorMessages}>
        {errors.map((error) => {
          if (error.belongsTo === 'email' || error.belongsTo === 'subject') {
            return (
              <div key={error.text} className={classes.message}>
                {error.text}
              </div>
            )
          } else return null
        })}
      </div>
      <div className={classes.description}>Include a message to recipient(s)</div>
      <div className={classnames(classes.messageInput, { [classes.error]: hasError('body') })}>
        <textarea
          name="body"
          placeholder="Add your message here"
          onChange={handleValueChange}
          onFocus={() => handleFocus('body')}
          onBlur={() => handleBlur('body')}
          rows="5"
        ></textarea>
      </div>
      <div className={classes.errorMessages}>
        {errors.map((error) => {
          if (error.belongsTo === 'body') {
            return (
              <div key={error.text} className={classes.message}>
                {error.text}
              </div>
            )
          } else return null
        })}
      </div>
      <div className={classes.submitSection}>
        {SHARE_EXPIRES_IN ? (
          <div className={classes.info}>
            <InfoIcon /> <span>This share will be active for {parseExpiry(SHARE_EXPIRES_IN)}</span>
          </div>
        ) : null}
        <div className={classes.submit}>
          {isLoading ? <button data-test-id={testIds.share.sendButton} className={classes.loadingBtn}>... Sending</button> : null}
          {!isLoading && !isSubmittable ? (
            <Tooltip variant="left" title="Mandatory fields must be entered">
              <Button data-test-id={testIds.share.sendButton} disabled={!isSubmittable} onClick={handleSubmit}>
                Send
              </Button>
            </Tooltip>
          ) : null}
          {!isLoading && isSubmittable ? (
            <Button data-test-id={testIds.share.sendButton} disabled={!isSubmittable} onClick={handleSubmit}>
              Send
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Form
