// The concept of epics is to pipe one action type into another
// https://redux-observable.js.org/ Can be used in the future
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { lifecycleStageChangeAction } from 'store/actions'
import { selectLifecycle } from 'store/selectors'

export const LifecycleEpic = () => {
  const dispatch = useDispatch()
  const lifecycle = useSelector(selectLifecycle)

  useEffect(() => {
    dispatch(lifecycleStageChangeAction(lifecycle.stage))
  }, [dispatch, lifecycle.stage])

  return null
}

export default LifecycleEpic
