import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import classes from './styles.module.css'

export const Switcher = ({ label, value, isDisabled, onChange, ...rest }) => {
  const [state, setState] = useState()

  const toggleState = () => {
    if (isDisabled) return

    const newState = typeof state !== 'undefined' ? !state : !value

    setState(newState)

    if (onChange) onChange(newState)
  }

  useEffect(() => {
    if (value !== state) setState(value)
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.switcherContainer} {...rest}>
      <div className={classnames(classes.wrapper, { [classes.disabled]: isDisabled })}>
        <div className={classnames(classes.label, { [classes.visible]: !!label })} onClick={toggleState}>
          {label}
        </div>
        <div
          className={classnames(classes.switcher, {
            [classes.on]: state,
            /**
             * TODO: find a fix for animation not to happen on initial load
             */
            // [classes.animateOn]: state === true,
            // [classes.animateOff]: state === false,
            [classes.disabled]: isDisabled,
          })}
          onClick={toggleState}
        >
          <div className={classes.thumb}></div>
        </div>
      </div>
    </div>
  )
}

Switcher.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Switcher
