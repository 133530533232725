import React from 'react'
import classnames from 'classnames'

import { Heading } from 'components/system'
import { featureToggles } from 'config'
import testIds from 'config/testIds'

import NavLink from '../NavLink'
import classes from './styles.module.css'

const Nav = ({ templates, section, onClick }) => {
  const testId = testIds.support

  return (
    <div className={classes.navContainer}>
      <div className={classes.wrapper}>
        {templates.map((item) => {
          return (
            <div key={item.header[0].id} className={classes.section}>
              {item.header
                .filter((section) => featureToggles[section.id] !== 0)
                .map((header) => {
                  return (
                    <div key={header.title} data-test-id={testId.header}>
                      <Heading text={header.title} />
                    </div>
                  )
                })}
              <div className={classes.section}>
                {item.types
                  .filter((template) => featureToggles[template.id] !== 0)
                  .map((template, i) => {
                    return (
                      <div
                        key={template.id}
                        className={classnames(classes.body, {
                          [classes.scrollable]: true,
                          [classes.disabled]: featureToggles[template.id] === 1,
                        })}
                      >
                        <NavLink
                          id={template.id}
                          label={template.title}
                          isActive={template.id === section}
                          onClick={() => onClick(template.id)}
                          disabled={featureToggles[template.id] === 1}
                          data-test-id={testId.navLink}
                        />
                      </div>
                    )
                  })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Nav
