import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import API from 'api'
import { uploadCSVAction, setDITCSVFileAction, setDITCSVErrorsAction } from 'store/actions'
import { selectIntegrationTypes, selectDITSessionId, selectCSVFile } from 'store/selectors'
import showNotification from 'containers/Notifications/controller'
import { DragAndDrop, Filebox } from 'components/system'
import { WarningIcon } from 'components/system/SVG'

import classes from './styles.module.css'
import { DIT_ACTION_TYPES } from 'store/actionTypes'

export const FileUpload = ({ testId }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [file, setFile] = useState(null)
  const [CSVUploadState, setCSVUploadState] = useState(null)
  const [CSVInputError, setCSVInputError] = useState(null)

  const readCSVFile = (file) => {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.onload = function () {
        // split csv string by line breaks and filter out empty rows
        const lines = reader.result.split(/\n|\r/).filter((row) => !!row)

        // split each line into values
        resolve(lines.map((line) => line.split(',')))
      }

      reader.onerror = function (error) {
        reject(error)
      }

      reader.readAsBinaryString(file)
    })
  }

  const triggerFileUpload = (file) => {
    setFile({ name: file.name, size: file.size })

    readCSVFile(file).then((csvContent) => {
      const size = file.size / 1024 >= 1 ? `${Math.floor(file.size / 1024)}kb` : `${file.size}bytes`

      dispatch(uploadCSVAction({ file, fileParsed: { name: file.name, size, content: csvContent } })).then(
        (response) => {
          if (response && response.status !== 200) {
            let errors = [{ type: 'unknown', message: 'Unknown error' }]

            if (response.data) {
              if (response.data.detail) {
                errors = [{ type: 'header', message: response.data.detail }]
              }
            }

            dispatch(setDITCSVErrorsAction(errors))

            navigate(`/integration/dit/new-session/validation`)
          } else {
            setCSVUploadState('success')
          }
        }
      )
    })
  }

  const handleFileInput = (files) => {
    const file = files.length ? files[0] : files
    const nameParts = file.name.split('.')
    const extension = nameParts[nameParts.length - 1]

    if (files.length > 1) {
      // raise multiple file upload error
      return setCSVInputError({ title: 'Multiple files', text: 'Please upload one CSV file per session.' })
    } else if (extension !== 'csv' && file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
      // raise invalid file type
      return setCSVInputError({
        title: 'File format is not accepted.',
        text: 'Please upload again ensuring your CSV file has the correct .CSV extension.',
      })
    } else if (file.size === 0) {
      // raise empty file upload
      return setCSVInputError({
        title: 'Empty file',
        text: 'Please make sure your CSV file contains data. <br />A CSV template can be found in the integration toolkit.',
      })
    }

    triggerFileUpload(file)
  }

  const handleFileRemove = () => {
    setFile(null)

    dispatch(setDITCSVFileAction(null))
  }

  return (
    <div
      className={classes.fileUploadContainer}
      {...(testId ? { 'data-test-id': `${testId}.fileUploadContainer` } : null)}
    >
      <div className={classes.wrapper}>
        <div className={classes.label}>Diamond data</div>
        <div className={classes.subLabel}>
          Drop your csv file here to begin integrating your diamond data. Please check the{' '}
          <Link className={classes.link} target="_blank" to="https://docs.tracr.com/docs/user-documentation/hm71l585o5ed3-integration">
            help
          </Link>
          {' '}for more information on integration, file requirements, and templates.
        </div>

        <div className={classes.uploadZone}>
          {file ? (
            <Filebox
              file={file}
              status={CSVUploadState ? CSVUploadState : null}
              onClose={handleFileRemove}
              testId={testId}
            />
          ) : (
            <DragAndDrop withIcon onFiles={handleFileInput} testId={testId} />
          )}
        </div>
        {CSVInputError ? (
          <div className={classes.messageWrapper}>
            <div className={classes.message}>
              <div className={classes.icon}>
                <WarningIcon fill="#EC123B" />
              </div>
              <div className={classes.text}>{CSVInputError.title}</div>
            </div>

            <div className={classes.hint}>
              Please see the{' '}
              <Link className={classes.link} target="_blank" to="https://docs.tracr.com/docs/user-documentation/k1n9j0lxofq9w-integration-tool">
                help
              </Link>
              {' '}for guidance on using the Integration Tool.
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default FileUpload
