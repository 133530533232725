import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Tooltip } from 'components/system'
import classes from './styles.module.css'

const ActionTooltip = ({ title, children }) => (
  <Tooltip title={title} placement="top">
    {children}
  </Tooltip>
)

const CardActions = ({ actions }) => {
  const actionsLength = actions.filter((action) => !action.excluded).length

  return (
    <div className={classes.cardActionsContainer}>
      <div className={classes.wrapper}>
        {actions
          .filter((action) => !action.excluded)
          .map((action, index) => {
            return (
              <React.Fragment key={`action-${index}`}>
                {action.disabled && action.tooltip && (
                  <ActionTooltip title={action.tooltip}>
                    <div
                      key={index}
                      className={classNames({
                        [classes.action]: true,
                        [classes.disabled]: true,
                        [classes.notLastAction]: index !== actionsLength - 1,
                        [classes.singleAction]: actionsLength === 1,
                      })}
                      style={{ flexBasis: `${100 / actionsLength}%` }}
                      {...action.events}
                      data-test-id={action.testId}
                    >
                      {action.text}
                    </div>
                  </ActionTooltip>
                )}
                {!action.disabled && (
                  <div
                    key={index}
                    className={classNames({
                      [classes.action]: true,
                      [classes.disabled]: false,
                      [classes.notLastAction]: index !== actionsLength - 1,
                      [classes.singleAction]: actionsLength === 0,
                    })}
                    {...action.events}
                    style={{ flexBasis: `${100 / actionsLength}%` }}
                    data-test-id={action.testId}
                  >
                    {action.text}
                  </div>
                )}
              </React.Fragment>
            )
          })}
      </div>
    </div>
  )
}

CardActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      events: PropTypes.shape({
        onClick: PropTypes.func,
      }),
      text: PropTypes.string.isRequired,
      disabled: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
}

export default CardActions
