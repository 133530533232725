import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import API from 'api'
import {
  selectAppliedFilters,
  selectDiamondsCount,
  selectLifecycle,
  selectSorting,
  selectViewColumns
} from 'store/selectors'
import showNotification from 'containers/Notifications/controller'
import { ClickOutsideListener, Tooltip } from 'components/system'
import { UpArrowSVG, DownArrowSVG, DownloadIcon } from 'components/Svg'
import testids from 'config/testIds'
import { testId } from 'utils/testHelper'

import classes from './styles.module.css'

const ExportInventory = ({testIdPrefix}) => {

  const { count, filteredCount } = useSelector(selectDiamondsCount)
  const lifecycle = useSelector(selectLifecycle)
  const viewColumns = useSelector(selectViewColumns(lifecycle.stage))
  const appliedFilters = useSelector(selectAppliedFilters)
  const sort = useSelector(selectSorting)
  const [isOpen, setIsOpen] = useState(false)
  const testIds = testids.export

  const isFilterEmpty = filteredCount === 0
  const isInventoryEmpty = count === 0

  const tooltipTitle = isInventoryEmpty ? 'Export is enabled when inventory is available' : ''

  const handleToggleClick = () => {
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  const exportAllLifecycle = () => {
    // Export all inventory in this lifecycle state. The endpoint still
    // requires us to pass in some basic default filters
    const body = {
      columns: viewColumns,
      filters: {
        lifecycle_state: lifecycle.stage,
        possession_state: ['held', 'in_transit']
      },
      sort: sort,
      timezone: Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : null
    }
    API.downloadDiamonds(body, `${lifecycle.stage}.xlsx`)
      .then(() => {
        showNotification({
          bodyText: 'Your data has been successfully exported as an Excel (.xlsx) file.',
          title: 'Export Complete',
          variant: 'success',
        })
      })
      .catch(() => {
        showNotification({
          bodyText: 'An error occurred while exporting your data. Please check your connection and try again.',
          title: 'Export Failed',
          variant: 'error',
        })
      })
    setIsOpen(false)
  }

  const exportFilteredLifecycle = () => {
    // Export the currently filtered diamonds in this lifecycle
    if (!isFilterEmpty) {
      // There's nothing preventing a user from clicking the button when there
      // are no diamonds in the filters, so we only do something if the filter
      // is non-empty
      const { date } = appliedFilters
      const from = date ? new Date(date.from).toDateString() : null
      const to = date && date.to ? new Date(date.to).toDateString() : null
      const body = {
        columns: viewColumns,
        filters: {
          ...appliedFilters,
          ...(date ? { date: { from, to } } : null),
        },
        sort: sort,
        timezone: Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : null
      }
      API.downloadDiamonds(body, `${lifecycle.stage}.xlsx`)
        .then(() => {
          showNotification({
            bodyText: 'Your data has been successfully exported as an Excel (.xlsx) file.',
            title: 'Export Complete',
            variant: 'success',
          })
        })
        .catch(() => {
          showNotification({
            bodyText: 'An error occurred while exporting your data. Please check your connection and try again.',
            title: 'Export Failed',
            variant: 'error',
          })
        })
      setIsOpen(false)
    }
  }

  return (
    <div className={classes.exportInventoryContainer}>
      <ClickOutsideListener onClickOutside={() => setIsOpen(false)}>
        <Tooltip title={tooltipTitle} minWidth={130}>
          <div
            className={classNames(classes.button, classes.hasMargin, { [classes.disabled]: isInventoryEmpty })}
            onClick={() => handleToggleClick(isOpen)}
            disabled={isInventoryEmpty}
            data-test-id={testId(testIdPrefix, testIds.dropdownButton)}
          >
            <span>Export inventory</span>
            <span className={classes.dropDownIcon}>
              {!isOpen && <DownArrowSVG />}
              {isOpen && <UpArrowSVG />}
            </span>
          </div>
        </Tooltip>
        {isOpen && (
          <div className={classes.listContainer}>
            <div className={classes.listHeader}>File format is Microsoft Excel (.xlsx)</div>
            <div className={classes.list}>
              <div 
                className={classes.listItem}
                onClick={exportAllLifecycle}
                data-test-id={testId(testIdPrefix, testIds.exportAllButton)}>
                <DownloadIcon /> Export all
              </div>
              <div
                className={classNames(classes.listItem, { [classes.disabled]: isFilterEmpty })}
                onClick={exportFilteredLifecycle}
                data-test-id={testId(testIdPrefix, testIds.exportFilteredButton)}
              >
                <DownloadIcon /> Export filtered
              </div>
            </div>
          </div>
        )}
      </ClickOutsideListener>
    </div>
  )
}

export default ExportInventory
