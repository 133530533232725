import React from 'react'
import PropTypes from 'prop-types'
import camelCase from 'camelcase'

import classes from './styles.module.css'
import { FlagIcon } from 'components/system'
import { testIds } from 'config'
import { testId } from 'utils/testHelper'

const CardBottom = ({ points }) => {
  return (
    <div className={classes.cardBottomContainer}>
      <div className={classes.list}>
        {points.map((point) => {
          switch (point.label) {
            case 'Provenance':
              return (
                <>
                  <span className={classes.label}>{point.label}:</span>
                  <span data-test-id={testId(testIds.inventory.card.prefix, camelCase(point.label))} className={classes.value}>
                    {point.value ? (
                      <FlagIcon style={{ height: '24px' }} country={point.value} />
                    ) : null}
                    {point.value || '-'}
                  </span>
                </>
              );
            default:
              return (<>
                <span className={classes.label}>{point.label}:</span>
                <span data-test-id={testId(testIds.inventory.card.prefix, camelCase(point.label))} className={classes.value}>{point.value}</span>
              </>);
          }
        })}
      </div>
    </div>
  )
}

CardBottom.propTypes = {
  icon: PropTypes.string.isRequired,
  points: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
}

export default CardBottom
