export const selectSearchError = state => {
  return state.search.error
}

export const selectSearchLoading = state => {
  return state.search.loading
}

export const selectSearchResult = state => {
  return state.search.data
}

export const selectSearchedValue = state => {
  return state.search.searchedFor
}
