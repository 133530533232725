import { clone } from 'ramda'
import { SHIPMENTS_OUT_ACTION_TYPES } from '../actionTypes'
import { resolveActionType } from '../utils'

const diamondStatuses = [
  {
    key: 'transfer_out_pending',
    label: 'Sent',
    icon: 'default',
    active: true,
    actionable: true,
  },
  {
    key: 'transfer_out_confirmed',
    label: 'Sent',
    icon: 'default',
    active: true,
    actionable: true,
  },
  {
    key: 'accepted',
    label: 'Processing',
    icon: 'success',
    active: true,
  },
  {
    key: 'transferred',
    label: 'Transferred',
    icon: 'success',
  },
  {
    key: 'pending_cancel',
    label: 'Processing',
    icon: 'warning',
    active: true,
  },
  {
    key: 'receiver_informed_of_cancel',
    label: 'Processing',
    icon: 'error',
  },
  {
    key: 'cancelled',
    label: 'Cancelled',
    icon: 'error',
  },
  {
    key: 'pending_reject',
    label: 'Processing',
    icon: 'warning',
    active: true,
  },
  {
    key: 'rejected',
    label: 'Rejected',
    icon: 'error',
  },
]

const initialState = {
  data: {
    page: 1,
    shipments: [],
    newShipment: null,
    statuses: {
      diamond: {
        rough: diamondStatuses,
        polished: diamondStatuses,
      },
      validation: {
        rough: [
          {
            key: 'diamond_already_transferred',
            label: 'Transferred',
            icon: 'success',
          },
          {
            key: 'diamond_already_pending_transfer',
            label: 'In Shipment',
            icon: 'transfer',
          },
          {
            key: 'success',
            label: 'Available',
            icon: 'success',
            available: true,
          },
          {
            key: 'diamond_in_terminal_state',
            label: 'Destroyed',
            icon: 'destroyed',
          },
          {
            key: 'diamond_does_not_exist',
            label: 'Not Found',
            icon: 'cancel',
          },
          {
            key: 'diamond_lifecycle_state_not_transferrable',
            label: 'Polished',
            icon: 'polished',
          },
          {
            key: 'diamond_cannot_have_parent',
            label: 'Split',
            icon: 'split',
          },
          {
            key: 'diamond_is_locked',
            label: 'Locked',
            icon: 'lock',
          },
        ],
        polished: [
          {
            key: 'diamond_already_transferred',
            label: 'Transferred',
            icon: 'success',
          },
          {
            key: 'diamond_already_pending_transfer',
            label: 'In Shipment',
            icon: 'transfer',
          },
          {
            key: 'success',
            label: 'Available',
            icon: 'success',
            available: true,
            actionable: true,
          },
          {
            key: 'diamond_in_terminal_state',
            label: 'Destroyed',
            icon: 'destroyed',
          },
          {
            key: 'diamond_does_not_exist',
            label: 'Not Found',
            icon: 'cancel',
          },
          {
            key: 'diamond_lifecycle_state_not_transferrable',
            label: 'Rough',
            icon: 'rough',
          },
          {
            key: 'diamond_cannot_have_parent',
            label: 'Split',
            icon: 'split',
          },
          {
            key: 'diamond_has_no_inscription_numbers',
            label: 'Not Inscribed',
            icon: 'not-inscribed',
          },
          {
            key: 'diamond_is_locked',
            label: 'Locked',
            icon: 'lock',
          },
        ],
      },
    },
    filters: {
      static: {
        preview: {},
        active: {},
        archive: {},
      },
      dynamic: {},
    },
  },
  requests: {
    // example request record
    // GET_TRANSFERS: {loading: true, error: null}
    GET_TRANSFERS: {},
  },
}

const shipmentsOutReducer = (state = initialState, { payload, type }) => {
  const requestType = resolveActionType('SHIPMENTS_OUT_ACTION_TYPES', type)

  switch (type) {
    case SHIPMENTS_OUT_ACTION_TYPES.GET_SHIPMENTS.REQUEST:
    case SHIPMENTS_OUT_ACTION_TYPES.VALIDATE_CSV.REQUEST:
    case SHIPMENTS_OUT_ACTION_TYPES.SEND_NEW_SHIPMENT.REQUEST:
    case SHIPMENTS_OUT_ACTION_TYPES.CANCEL_DIAMONDS.REQUEST: {
      const newState = clone(state)

      return {
        ...newState,
        requests: {
          ...newState.requests,
          [requestType]: { loading: true, error: null },
        },
      }
    }
    case SHIPMENTS_OUT_ACTION_TYPES.GET_SHIPMENTS.FAILURE:
    case SHIPMENTS_OUT_ACTION_TYPES.VALIDATE_CSV.FAILURE:
    case SHIPMENTS_OUT_ACTION_TYPES.SEND_NEW_SHIPMENT.FAILURE:
    case SHIPMENTS_OUT_ACTION_TYPES.CANCEL_DIAMONDS.FAILURE: {
      const newState = clone(state)

      return {
        ...newState,
        requests: {
          ...newState.requests,
          [requestType]: { loading: false, error: payload },
        },
      }
    }

    case SHIPMENTS_OUT_ACTION_TYPES.GET_SHIPMENTS.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      const shipments = clone(payload.results).map((shipment) => {
        let lastUpdated = ''

        shipment.diamonds.forEach((diamond) => {
          const updatedAt = new Date(diamond.updated_at).getTime()

          if (lastUpdated < updatedAt) lastUpdated = updatedAt
        })

        return {
          ...shipment,
          diamonds_count: shipment.diamonds.length,
          updated_at: lastUpdated,
        }
      })

      return {
        ...newState,
        data: {
          ...newState.data,
          page: payload.page,
          shipments: payload.page !== 1 ? newState.data.shipments.concat(shipments) : shipments,
        },
      }
    }

    case SHIPMENTS_OUT_ACTION_TYPES.GET_SHIPMENT.COMPLETE: {
      const newState = clone(state)
      delete newState.requests[requestType]

      // First attempt to update the shipment in the store, maintaining its
      // position in the shipments array. If it doesn't exist we'll add it
      // to the end of the array instead.
      let shipments = newState.data.shipments.map((shipment) => shipment.id === payload.id ? payload : shipment)
      if (!shipments.find((shipment) => shipment.id !== payload.id)) {
        shipments = shipments.concat([payload])
      }

      return {
        ...newState,
        data: {
          ...newState.data,
          shipments: shipments,
        },
      }
    }

    case SHIPMENTS_OUT_ACTION_TYPES.VALIDATE_CSV.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      return {
        ...newState,
        data: {
          ...newState.data,
        },
      }
    }

    case SHIPMENTS_OUT_ACTION_TYPES.SET_NEW_SHIPMENT: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          newShipment: payload,
        },
      }
    }

    case SHIPMENTS_OUT_ACTION_TYPES.SEND_NEW_SHIPMENT.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      return {
        ...newState,
        data: {
          ...newState.data,
          newShipment: null,
        },
      }
    }

    case SHIPMENTS_OUT_ACTION_TYPES.SET_CANCEL_DIAMOND_ID: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          cancelDiamondId: payload,
        },
      }
    }

    case SHIPMENTS_OUT_ACTION_TYPES.CANCEL_DIAMONDS.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      const shipment = newState.data.shipments.find((shipment) => shipment.id === payload.id)
      const statuses = initialState.data.statuses.diamond[shipment.shipment_type]
      const activeStatuses = statuses.filter((status) => status.active).map((status) => status.key)

      payload.diamonds.forEach((diamond) => {
        if (diamond.status !== 'success') return

        const stateDiamond = shipment.diamonds.find((d) => d.diamond_id === diamond.diamond_id)

        if (!stateDiamond) return

        stateDiamond.transfer_state = 'pending_cancel'
      })

      // We determine if the shipment is being cancelled based on whether any of
      // the diamonds in the shipment have the transfer_state `pending_cancel`
      const isCancelling = shipment.diamonds.find((diamond) => {
        return diamond.transfer_state === 'pending_cancel'
      })

      if (isCancelling) {
        // While the diamonds in the shipment are being cancelled the shipment status
        // will depend on the shipment_type. Rough shipments will have the status
        // `cancelling` whereas Polished shipments will have the status `in_progress`.
        shipment.status = shipment.shipment_type === 'rough' ? 'cancelling' : 'in_progress'
      }

      return {
        ...newState,
        data: {
          ...newState.data,
          shipments: newState.data.shipments,
        },
      }
    }

    case SHIPMENTS_OUT_ACTION_TYPES.SET_STATIC_FILTERS: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          filters: {
            ...newState.data.filters,
            static: {
              ...newState.data.filters.static,
              [payload.page]: payload.filters,
            },
          },
        },
      }
    }

    default:
      return state
  }
}

export default shipmentsOutReducer
