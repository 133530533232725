// import API from 'api'
import { SHARE_ACTION_TYPES } from '../actionTypes'

export function selectDiamondsToShareAction(ids) {
  return {
    type: SHARE_ACTION_TYPES.SELECT_DIAMONDS_TO_SHARE,
    payload: ids,
  }
}

export function removeDiamondFromShareAction(id) {
  return {
    type: SHARE_ACTION_TYPES.REMOVE_DIAMOND_FROM_SHARE,
    payload: id,
  }
}
