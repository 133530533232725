import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import classnames from 'classnames'

import classes from './styles.module.css'

// does the animation and the back screen overlay
export const ModalWrapper = ({ isOpen, className = '', children, onClose }) => {
  const [rendered, setRendered] = useState(false)
  const [animateState, setAnimateState] = useState('')

  const animateShow = () => {
    setAnimateState('in')

    setRendered(true)
  }

  const animateHide = () => {
    setAnimateState('out')

    setTimeout(() => {
      setRendered(false)

      if (onClose) onClose()
    }, 300)
  }

  useEffect(() => {
    if (isOpen) {
      animateShow()
    } else {
      if (rendered) animateHide()
    }
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!rendered) return null

  return createPortal(
    <div className={classnames(classes.modalContainer, className)}>
      <div className={classnames(classes.overlay, { [classes.in]: animateState === 'in' })}></div>
      <div
        className={classnames(classes.content, {
          [classes.bounceIn]: animateState === 'in',
          [classes.bounceOut]: animateState === 'out',
        })}
      >
        {children}
      </div>
    </div>,
    document.getElementsByTagName('body')[0]
  )
}

export default ModalWrapper
