import React from 'react'

import classes from './styles.module.css'

export const Counters = ({ counters = [] }) => {
  return (
    <ul className={classes.countersContainer}>
      {counters.map((counter) => {
        return (
          <li key={counter.label}>
            <div className={classes.border}></div>
            <div className={classes.content} data-test-id={counter.testId}>
              <div className={classes.value}>{counter.value}</div>
              <div className={classes.label}>{counter.label}</div>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default Counters
