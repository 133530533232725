import React from 'react'

import { Filter } from 'components/system'
import filterFormatter from 'utils/filterFormatter'
import testIds from 'config/testIds'

const FilterContainer = ({
  name,
  title,
  options,
  className = '',
  sorted,
  selectedOptions,
  appliedOptions,
  onChange,
  onApply,
  onClear,
  testIdPrefix
}) => {
  const formater = filterFormatter(name)

  return (
    <Filter
      testIdPrefix={testIdPrefix}
      name={name}
      title={title}
      options={options}
      selectedOptions={selectedOptions}
      appliedOptions={appliedOptions}
      className={className}
      formater={formater}
      sorted={sorted}
      onChange={onChange}
      onFilterApply={onApply}
      onFilterClear={onClear}
    />
  )
}

export default FilterContainer
