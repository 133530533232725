import React from 'react';
import { useNavigate } from 'react-router-dom';

import { NavArrow } from 'components/Svg';

import classes from './styles.module.css';

export const Nav = ({ title = '', children, buttonTestId }) => {
  const navigate = useNavigate();

  return (
    <div className={classes.navContainer}>
      <div className={classes.backBtn} onClick={() => navigate(-1)} data-test-id={buttonTestId}>
        <NavArrow />
        <span>Back</span>
      </div>
      <div className={classes.title}>{title}</div>
      <div className={classes.children}>{children}</div>
    </div>
  );
};

export default Nav;
