import { testIds } from 'config'
import { pathOr } from 'ramda'

import { getOrderedMedia, getPriorityDataPointsWithLabels } from 'utils/diamond'

export const mapDataToTable = (data = [], lifecycle, _onTransfer, onView) => {
  return data.map((diamond) => ({
    id: diamond.id,
    diamond_id: diamond.diamond_id,
    selected: true,
    icon: diamond.assurance_level === 'declared' ? null : pathOr('-', ['provenance', 'provenance_name'], diamond),
    media: getOrderedMedia(diamond, ['images', 'lifecycle', 'shape'])[0],
    unlock_status: diamond.unlock_status,
    points: getPriorityDataPointsWithLabels(diamond, lifecycle.state, diamond.assurance_level !== 'declared'),
    actions: [
      /*
      {
        events: {
          onClick: (e) => {
            e.stopPropagation()
            onTransfer([row.id])
          },
        },
        text: 'Transfer',
        disabled: featureToggles.transfer === 1 || row.possession_state !== 'held',
        excluded: featureToggles.transfer === 0,
        tooltip: 'Feature is disabled',
      },
      */
      {
        events: {
          onClick: (e) => {
            e.stopPropagation()
            onView(diamond.diamond_id)
          },
        },
        text: 'View',
        disabled: false,
        testId: testIds.inventory.card.viewButton,
      },
    ],
  }))
}
