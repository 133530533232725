import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import API from 'api'
import { PageTitle, HelpButton } from 'components/system'
import { helpLinks } from 'config'

import classes from './styles.module.css'

const ShipmentsIn = () => {
  const location = useLocation()

  const getHelpLink = () => {
    const { pathname } = location
    switch (true) {
      case /\/inbound$/.test(pathname):
        return helpLinks.transferInShipments
      case /\/pending/.test(pathname):
        return helpLinks.transferInConfirmOwnership
      case /\/archive/.test(pathname):
        return helpLinks.transferInCompleteShipment
      default:
        return null
    }
  }

  return (
    <div className={classes.shipmentsInContainer}>
      <PageTitle title={`Shipments`}>
        <HelpButton link={getHelpLink()} onClick={() => API.tracking.logHelp('transfer-in', getHelpLink())} />
      </PageTitle>
      <Outlet />
    </div>
  )
}

export default ShipmentsIn
