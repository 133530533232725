import React from 'react'

import { PageTitle } from 'components/system'

import classes from './styles.module.css'

const ViewNav = () => {
  return (
    <div className={classes.viewNavContainer}>
      <PageTitle title={`Info & Support`} withSearch></PageTitle>
    </div>
  )
}

export default ViewNav
