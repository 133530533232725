import React from 'react'

import { Heading } from 'components/system'

import classes from './styles.module.css'

// target="_blank" rel="noopener noreferrer"

const PrivacyPolicy = () => {
  return (
    <div className={classes.privacyPolicyContainer}>
      <Heading text="Privacy Policy" />
      <div className={classes.contentContainer}>
        <div className={classes.mainHeading}>
          Please read this Privacy Notice carefully before using our website and services.
        </div>
        <div className={classes.secondaryHeading}>1. Introduction</div>
        <div className={classes.body}>
          This privacy notice (Privacy Notice) sets out the ways in which we, TRACR LIMITED (a company incorporated
          under the laws of England and Wales under company number 07816513 with principal place of business at 1
          Giltspur Street London EC1A 9DD (Tracr, we, us, our), collect and use your personal information in connection
          with the Tracr website at www.tracr.com (the Website), including any/all other services, products and tools
          made available by us either through the Website or through a link or portal provided to you by or on behalf of
          us (such services, products and tools, together with the Website referred to as the Services). This Privacy
          Notice also explains what rights you have in relation to your personal data.
        </div>
        <div className={classes.secondaryHeading}>2. Who is responsible party of my data?</div>
        <div className={classes.body}>
          We are company established in the United Kingdom, with our registered address as set out above. You can
          contact us as follows: <br />
          Address: 17 Charterhouse Street, London, United Kingdom, EC1N 6RA <br />
          Email: client@tracr.com
        </div>
        <div className={classes.secondaryHeading}>3. Information we collect about you</div>
        <div className={classes.body}>
          3.1 We may collect, use, store and transfer different kinds of personal data about you (depending on the
          circumstances). We have grouped together these types of personal information as follows: <br />
          <br />
          3.1.1 identity and contact data: including name, address, email address, organisation, title and phone number
          (if applicable);
          <br />
          <br />
          3.1.2 survey data: from time to time we might ask if you would be willing to participate in our surveys in
          connection with the Services offered to you; if you agree, we will collect any information that you provide as
          part of that survey; <br />
          <br />
          3.1.3 website analytics data: we will also collect certain information about how you use our Services and the
          device that you use to access our Services. This might include your geographical location, device information
          (such as your hardware model, mobile network information, unique device identifiers), the data transmitted by
          your browser (such as your IP address, time zone settings, access status/HTTP status code, volume of data
          transmitted, browser type and version, language settings, length of visit to the Services, date and time of
          the request, operating system and interface), the number of page views and the search queries you make on the
          Services and similar information. This information may be collected by a third-party website analytics service
          provider on our behalf and/or may be collected using cookies or similar technologies. For more information on
          cookies please read the ‘COOKIES’ section below; <br />
          <br />
          3.1.4 account data: we will collect information when you make changes to your profile on your Services account
          (if applicable); <br />
          <br />
          <br />
          3.1.5 newsletter and marketing preferences: we will receive details of your newsletter and marketing
          preferences where we allow you to sign up to receive our newsletter or marketing via the Website or social
          media;
          <br />
          <br />
          3.1.6 social media profile information: if you interact with any of our social media profiles, you may provide
          us with personal information. For example, if you ‘like’ or ‘share’ posts that we have made on a social media
          platform, if you comment on our profile pages, or if you contact us directly via social media, you may reveal
          personal information such as details of your social media account name, and other identity and contact data
          described above. You will also share with us details of the information contained in any comments or
          correspondence with us; and <br />
          <br />
          3.1.7 any other personal information that you provide to us: for example, any other information that you
          choose to provide to us in any correspondence.
        </div>
        <div className={classes.secondaryHeading}>4. How we collect your personal data</div>
        <div className={classes.body}>
          4.1 We collect this personal information from you either directly, for example, when you contact us, interact
          with us on social media, or indirectly, such as your browsing activity while on our Services (please see the
          ‘COOKIES’ section below). <br />
          <br />
          4.2 In certain circumstances, we will receive information about you from third party sources. For example:
          <br />
          <br />
          4.2.1 website and IT support service providers: we may collect personal information about you from our website
          developers and IT support providers; <br />
          <br />
          4.2.2 website security: we will collect information from our website security service partners about any
          misuse to the Services, for instance, the introduction of viruses, Trojans, worms, logic bombs, website
          attacks or any other material or action that is malicious or harmful; <br />
          <br />
          4.2.3 website and social media analytics providers: we will collect personal information from our website
          analytics providers, such as how you use the Services and statistical or demographic aggregated data in order
          to assess the effectiveness of our Services. Aggregated data could be derived from your personal data but will
          not directly or indirectly reveal your identity; <br />
          <br />
          4.2.4 social media plugins: we may use social media plugins from service providers such as Facebook, Twitter
          and LinkedIn; and <br />
          <br />
          4.2.5 other third parties: we might also receive information about you from third parties if you have
          indicated to such third party that you would like to hear from us.
        </div>
        <div className={classes.secondaryHeading}>5. How we use your personal data</div>
        <div className={classes.body}>
          5.1 We use your information for the following purposes: <br />
          <br />
          5.1.1 to provide access to our Services: to provide users with access to our Services in a manner convenient
          and optimal, which may involve sharing information with our website hosts and developers, and IT support
          providers; <br />
          <br />
          5.1.2 to register your account: where you have an account, we will use the details provided on your account
          registration form to create and maintain an account; <br />
          <br />
          5.1.3 relationship management: to manage our relationship with you, which will include notifying you about
          changes to our Participant Terms and Conditions and/or Website Terms and Conditions (as applicable) or this
          Privacy Notice; <br />
          <br />
          5.1.4 user support: to deal with enquiries or complaints about the Services, which may including information
          with our website developer, security provider and IT service providers as necessary to provide user support;{' '}
          <br />
          <br />
          5.1.5 surveys: to enable you to take part in surveys that we may issue from time to time; <br />
          <br />
          5.1.6 news and marketing updates: to keep in contact with you about our news, events and developments that we
          believe may interest you, provided that we have you permission to do so, if necessary. We may share your
          information with our marketing services provider to enable us to carry out these functions; <br />
          <br />
          5.1.7 social media interactions: to interact with users on social media platforms including Facebook, LinkedIn
          and Twitter, for example, responding to comments and messages, posting, ‘retweeting’ and ‘liking’ posts;{' '}
          <br />
          <br />
          5.1.8 research and analytics: to use data analytics to carry out aggregated and anonymised research about
          general engagement with our Services in order to improve our Services user relationships and experiences{' '}
          <br />
          <br />
          5.1.9 suggestions and recommendations: to share your information with selected third parties such as our
          partners, to enable them to contact you with information about things that may interest you, with your
          permission where required; <br />
          <br />
          5.1.10 fraud and unlawful activity detection: to protect, investigate, and deter against fraudulent,
          unauthorised, harmful or illegal activity on our Services or social media accounts; <br />
          <br />
          5.1.11 compliance with policies, procedures and laws: to enable us to comply with our policies and procedures
          and enforce our legal rights, or to protect the rights, property or safety of others, and share your
          information with our technical and legal advisors
        </div>
        <div className={classes.secondaryHeading}>6. Legal basis for processing your personal information</div>
        <div className={classes.body}>
          6.1 We will only process your personal information where we have a legal basis to do so. The legal basis will
          depend on the purposes for which we have collected and use your personal information. In almost every case the
          legal basis will be one of the following: <br />
          <br />
          6.1.1 performance of your contract with us: for example, where you have purchased or signed up to our Services
          and entered into the relevant terms and conditions, we need to use your personal information to provide the
          Services to you; <br />
          <br />
          6.1.2 your consent (where we request it): for example, where you have provided your consent to receive certain
          marketing from us. You can withdraw your consent at any time, including by clicking on the “unsubscribe” link
          at the bottom of any marketing email we send you; <br />
          <br />
          6.1.3 where we need to comply with a legal or regulatory obligation: for example, where we are subject to a
          legal obligation and need to use your personal information in order to comply with that obligation; <br />
          <br />
          6.1.4 our legitimate interests: where it is necessary for us to understand our customers, promote our services
          and operate effectively, provided in each case that this is done in a legitimate way which does not unduly
          affect your privacy and other rights. Our legitimate interests don’t automatically override yours and we won’t
          use your information if we believe your interests should override ours unless we have other grounds to do so
          (such as your consent or a legal obligation). If you have any concerns about our processing please refer to
          the section titled ‘YOUR RIGHTS’ below.
        </div>
        <div className={classes.secondaryHeading}>7. Cookies</div>
        <div className={classes.body}>
          7.1 The cookies we use fall into the following categories: <br />
          <br />
          7.1.1 Session cookies: These allow our Website to link your actions during a particular browser session. These
          expire each time you close your browser and do not remain on your device afterwards. <br />
          <br />
          7.1.2 Persistent cookies: These are stored on your device in between browser sessions. They allow your
          preferences or actions across the Website to be remembered. These will remain on your device until they
          expire, or you delete them from your cache. <br />
          <br />
          7.1.3 Strictly necessary cookies: These cookies are essential for you to be able to navigate the Website and
          use its features. Without these cookies, the services you have asked for could not be provided. <br />
          <br />
          7.1.4 Performance cookies: These cookies collect information about how you use our Website, e.g. which pages
          you go to most often. <br />
          <br />
          7.1.5 Functionality cookies: These cookies allow the Website to remember the choices you make (such as your
          user name, language, last action and search preferences) and provide enhanced, more personal features. <br />
          <br />
          7.1.6 Marketing cookies: These cookies store user behaviour information and identify users across multiple
          devices. They are used to deliver more relevant and personalised advertising. <br />
          <br />
          7.1.7 Social media cookies: These cookies are set by a range of social media services that we have added to
          the Website to enable you to share our content with your friends and networks. They are capable of tracking
          your browser across other sites and building up a profile of your interests. This may impact the content and
          messages you see on other websites you visit. If you do not allow these cookies you may not be able to use or
          see these sharing tools.
        </div>
        <div className={classes.secondaryHeading}>8. Who we share your personal information with</div>
        <div className={classes.body}>
          8.1 In connection with the purposes and on the lawful grounds described above, and in addition to the
          recipients of your information as described above, we may share your personal information when relevant with
          third parties such as: <br />
          <br />
          8.1.1 partners and collaborators: programme partners with whom we collaborate in order to further the aims and
          activities of Tracr Limited. Our programme partners may be based in various territories across the globe;
          <br />
          <br />
          8.1.2 marketing parties: any named or defined third party that you consent to our sharing your information
          with for marketing purposes (where applicable); <br />
          <br />
          8.1.3 our service providers: service providers we work with to manage our Services and social media accounts;
          <br />
          <br />
          8.1.4 regulators and governmental bodies: tax authorities, regulators, governmental bodies and other
          authorities who require reporting of processing activities in certain circumstances; <br />
          <br />
          8.1.5 other third parties (including professional advisers): any other third parties (including legal or other
          advisors, regulatory authorities, courts, law enforcement agencies and government agencies) where necessary to
          enable us to enforce our legal rights, or to protect the rights, property or safety of individuals, or where
          such disclosure may be permitted or required by law. <br />
          <br />
          8.2 We require third parties to maintain appropriate security to protect your personal information from
          unauthorised access or processing.
        </div>
        <div className={classes.secondaryHeading}>9. How long we keep your information for</div>
        <div className={classes.body}>
          We will retain your personal information in line with our Data Retention Policy and for as long as we
          reasonably require for the lawful purposes of Tracr Limited, such as for the purposes of exercising our legal
          rights or where we are permitted or required to do so by law.
        </div>
        <div className={classes.secondaryHeading}>10. Help keep your information safe</div>
        <div className={classes.body}>
          10.1 We take the safety and security of your personal data very seriously and are committed to protecting your
          personal data. We have implemented appropriate organisational and technical measures to protect the security,
          integrity and availability of personal data on our website and through business activities and processes. Due
          to the inherent nature of the internet as an open global communication vehicle, we cannot guarantee that
          information, during transmission through the internet, will be absolutely safe from intrusion by others, such
          as hackers. We do maintain electronic and procedural safeguards to protect your personal data. <br />
          <br />
          10.2 You can also play a part in keeping your information safe by: <br />
          <br />
          10.2.1 keeping your devices protected by using the latest version of your operating system and maintaining any
          necessary anti-virus software; and <br />
          <br />
          10.2.2 being vigilant to any fraudulent emails that may appear to be from us.
        </div>
        <div className={classes.secondaryHeading}>11. International transfers of your personal information</div>
        <div className={classes.body}>
          11.1 Tracr Limited is based in the United Kingdom. <br />
          <br />
          11.2 For individuals based in the UK and/or European Economic Area (EEA), whenever we transfer your personal
          data out of the UK and/or EEA, we ensure that appropriate levels of protection are afforded to it by ensuring
          at least one of the following data transfer solutions are implemented: <br />
          <br />
          11.2.1 we will transfer your personal data to countries that have been deemed to provide an adequate level of
          protection for personal data by the Information Commissioner (ICO) and/or the European Commission; or <br />
          <br />
          11.2.2 where we use certain service providers, we may use specific contracts approved by the UK
          Government/European Commission which give personal data the same protection it has in the UK and Europe.{' '}
          <br />
          <br />
          11.3 Please contact us using the contact details at the top of this Privacy Notice if you want further
          information on the specific mechanism used by us when transferring your personal data out of the UK and/or EEA
          (as applicable).
        </div>
        <div className={classes.secondaryHeading}>12. Your rights</div>
        <div className={classes.body}>
          12.1 You have certain rights in respect of the information that we hold about you, including: <br />
          <br />
          12.1.1 the right to be informed of the ways in which we use your information, as we seek to do in this Privacy
          Notice; <br />
          <br />
          12.1.2 the right to ask us not to process your personal data for marketing purposes; <br />
          <br />
          12.1.3 the right to request access to the information that we hold about you; <br />
          <br />
          12.1.4 the right to request that we correct or rectify any information that we hold about you which is out of
          date or incorrect; <br />
          <br />
          12.1.5 the right to withdraw your consent for our use of your information, where we have relied on your
          consent to use your information, which you can do by contacting us using any of the details at the top of this
          Privacy Notice; <br />
          <br />
          12.1.6 in certain circumstances, the right to object to our using your information on the basis of our
          legitimate interests (or the legitimate interests of a third party) and there is something about your
          particular situation which makes you want to object to processing on this ground; <br />
          <br />
          12.1.7 in certain circumstances, the right to request that we transfer your personal information to another
          service provider in a structured, commonly-used, machine readable format; <br />
          <br />
          12.1.8 in certain circumstances, the right to ask us to limit or cease processing or erase information we hold
          about you; and <br />
          <br />
          12.1.9 the right to lodge a complaint about us to the privacy or data protection authority in your place of
          work or residence.
          <br />
          <br />
          How to exercise you rights: You may exercise your rights above by contacting us using the details above, or in
          the case of preventing processing for marketing activities also by checking certain boxes on forms that we use
          to collect your data to tell us that you don’t want to be involved in marketing. We will handle any request to
          exercise your rights in accordance with applicable law and any relevant legal exemptions. As mentioned above,
          you also have the right to complain to a data protection authority if you think we have processed your
          personal information in a manner which is unlawful or breaches your rights – if you have such concerns we
          request that you initially contact us (using the contact details above) so that we can investigate, and
          hopefully resolve, your concerns.
          <br />
          <br />
          12.2 What we need from you to process rights requests: We may need to request specific information from you to
          help us confirm your identity and to enable you to access your personal data (or to exercise any of your other
          rights). This is a security measure to ensure that personal data is not disclosed to any person who has no
          right to receive it. We may also contact you to ask you for further information in relation to your request to
          speed up our response.
          <br />
          <br />
          12.3 Is a fee payable?: You will not generally have to pay a fee to access your personal data (or to exercise
          any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded,
          repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances. We
          will try to respond to all legitimate requests within one month. Occasionally it may take us longer than a
          month if your request is particularly complex or you have made a number of requests. In this case, we will
          notify you and keep you updated, in accordance with the data protection laws.
        </div>
        <div className={classes.secondaryHeading}>13. Sharing data directly with third parties</div>
        <div className={classes.body}>
          You might end up providing personal information directly to third parties as a consequence of your
          interactions with our Services and social media profiles. For example, you may choose to correspond directly
          with other social media users when visiting our social media pages. We are not responsible for how such third
          parties use personal data provided by you. Please be responsible with personal information of others when
          using our Services. We are not responsible for your misuse of personal information, or for the direct
          relationship between you and others when takes place outside of the Services.
        </div>
        <div className={classes.secondaryHeading}>14. Third party links</div>
        <div className={classes.body}>
          The Services includes links to third-party websites, platforms, plug-ins and applications. Clicking on those
          links or enabling those connections may allow third parties to collect or share data about you. We do not
          control these third-party websites and are not responsible for their privacy statements. When you leave our
          Services, we encourage you to read the privacy notice of every website or platform you visit.
        </div>
        <div className={classes.secondaryHeading}>15. Changes to this privacy notice</div>
        <div className={classes.body}>
          15.1 We may make changes to this Privacy Notice from time to time. We will post any changes to our Website, or
          notify you of any material changes by e-mail if we have been provided with your email address. It is important
          that the personal data we hold about you is accurate and current. Please keep us informed if your personal
          data changes during your relationship with us by contacting us via the contact details stated at the top of
          this Privacy Notice.
        </div>
        <br />
        Terms last updated on 26 January 2023
      </div>
    </div>
  )
}

export default PrivacyPolicy
