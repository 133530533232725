import React, { useEffect, useState } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import { navToggleItemAction } from 'store/actions'
import { selectNavExpandedItems } from 'store/selectors'
import { featureToggles } from 'config'

import WithOrWithoutTooltip from '../WithOrWithoutTooltip'
import LinkWithChildren from './LinkWithChildren'
import LinkWithoutChildren from './LinkWithoutChildren'
import ChildLevelLink from '../ChildLevelLink'
import classes from './styles.module.css'

const TopLevelLink = ({ route, availableLifecycles, propsNavIsCollapsed, navIsCollapsed, toggleNav, index }) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const propsExpandedItems = useSelector(selectNavExpandedItems)
  const isActive = !!matchPath(`${route.path}/*`, pathname)
  const [isExpanded, setIsExpanded] = useState(propsExpandedItems.includes(route.title))
  const [childRoutes, setChildRoutes] = useState([])
  const childHeight = 48
  const isDisabled = route.disabled
  const hasVisibleChildren = route.children && !!route.children.find((child) => child.isMenuItem !== false)

  const handleToggle = () => {
    // internal state is needed to halve redux update lag
    setIsExpanded(!isExpanded)

    dispatch(navToggleItemAction(route.title))
  }

  useEffect(() => {
    const newState = propsExpandedItems.includes(route.title)

    if (isExpanded !== newState) setIsExpanded(newState)
  }, [propsExpandedItems]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const children = route.children || []
    // filter out child routes that are not part of the nav or are excluded in the inventory
    setChildRoutes(
      children
        .filter((childRoute) => childRoute.isMenuItem !== false)
        .filter((childRoute) => route.feature !== 'inventory' || availableLifecycles.includes(childRoute.feature))
        .filter((childRoute) => childRoute.feature && featureToggles[childRoute.feature] !== 0)
    )
  }, [JSON.stringify(availableLifecycles)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <li
      className={classnames(classes.topLevelLinkContainer, {
        [classes.firstLink]: index === 0,
      })}
      key={route.title}
    >
      <WithOrWithoutTooltip isDisabled={isDisabled}>
        {hasVisibleChildren ? (
          <LinkWithChildren
            route={route}
            isActive={isActive}
            isExpanded={isExpanded}
            navIsCollapsed={navIsCollapsed}
            propsNavIsCollapsed={propsNavIsCollapsed}
            handleToggle={handleToggle}
          />
        ) : (
          <LinkWithoutChildren
            route={route}
            isActive={isActive}
            isExpanded={isExpanded}
            navIsCollapsed={navIsCollapsed}
            propsNavIsCollapsed={propsNavIsCollapsed}
            onNavToggle={toggleNav}
          />
        )}
      </WithOrWithoutTooltip>
      <ul
        className={classnames(classes.childLevelList, {
          [classes.childLevelExpanded]: isExpanded,
        })}
        style={{
          height: isExpanded ? `${childRoutes.length * childHeight}px` : '0px',
        }}
      >
        {childRoutes.map((childRoute, index) => (
          <ChildLevelLink
            key={childRoute.title}
            parent={route}
            route={childRoute}
            availableLifecycles={availableLifecycles}
            isDisabled={isDisabled}
            navIsCollapsed={navIsCollapsed}
            index={index}
          />
        ))}
      </ul>
      {isExpanded ? (
        <div
          className={classnames(classes.borderLine, {
            [classes.borderLineCollapsed]: navIsCollapsed,
          })}
        >
          <div className={classes.line}></div>
        </div>
      ) : null}
    </li>
  )
}

export default TopLevelLink
