import React from 'react'
import ReactSlider from 'react-slider'

import classes from './styles.module.css'

export const Slider = ({ min, max, step = 0.01, value = [], onChange }) => {
  return (
    <div className={classes.sliderContainer}>
      <div className={classes.wrapper}>
        <ReactSlider
          min={min}
          max={max}
          step={step}
          minDistance={step}
          value={value.length ? value : [min, max]}
          pearling
          className={classes.slider}
          thumbClassName={classes.thumb}
          trackClassName={classes.track}
          defaultValue={[min, max]}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default Slider
