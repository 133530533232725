import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectShipmentsOutTab } from 'store/selectors'
import { getShipmentsOutAction, updateShipmentsOutTabAction } from 'store/actions'
import { SectionTitle, Button, Tabs } from 'components/system'

import NewShipmentModal from '../../containers/NewShipmentModal'
import ActiveTab from './Tabs/ActiveTab'
import ArchiveTab from './Tabs/ArchiveTab'
import classes from './styles.module.css'
import { testIds } from 'config/testIds'

export const Sessions = () => {
  const dispatch = useDispatch()
  const selectedTab = useSelector(selectShipmentsOutTab)
  const [newShipmentModal, setNewShipmentModal] = useState(false)

  const renderActiveTab = () => {
    switch (selectedTab) {
      case 'archive':
        return <ArchiveTab />
      case 'active':
      default:
        return <ActiveTab />
    }
  }

  const tabsWithCounters = () => {
    const selectedTabId = selectedTab ? selectedTab : 'active'

    const tabs = [
      {
        id: 'active',
        label: 'Active',
        active: selectedTabId === 'active',
        testId: testIds.shipmentsOutbound.activeButton,
      },
      {
        id: 'archive',
        label: 'Archived',
        active: selectedTabId === 'archive',
        testId: testIds.shipmentsOutbound.archivedButton,
      },
    ]

    return tabs
  }

  const handleTabChange = (tab) => {
    dispatch(updateShipmentsOutTabAction(tab.id))
  }

  useEffect(() => {
    dispatch(getShipmentsOutAction({ page: 1, limit: 1000, sort: { created_at: 'desc' } }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.sessionsContainer}>
      <div className={classes.wrapper}>
        <SectionTitle title={'Outbound'}>
          <Button
            onClick={() => {
              setNewShipmentModal(true)
            }}
            data-test-id={testIds.shipmentsOutbound.newShipmentButton}
          >
            New shipment
          </Button>
        </SectionTitle>
        <div className={classes.tabs}>
          <Tabs tabItems={tabsWithCounters()} onActiveChange={handleTabChange} data-test-id={'placeholder'} />
        </div>
        <div className={classes.tabContent}>{renderActiveTab()}</div>
      </div>
      <NewShipmentModal visible={newShipmentModal} onClose={() => setNewShipmentModal(false)} />
    </div>
  )
}

export default Sessions
