export const columnPickerCopy = {
  participant_id: 'Participant ID',
  participant_parent_id: 'Participant parent ID',
  diamond_id: 'Tracr ID',
  box_id: 'Box ID',
  participant_timestamp: 'Last updated',
  sight_year: 'Sight year',
  sight_no: 'Sight number',
  box_description: 'Box description',
  matched: 'Rough Check',
  unlock_status: 'Status',
  'provenance': 'Provenance',
  'provenance.provenance_name': 'Provenance',
  'supplementary_tags.sight_holder': 'Sight holder',
  'supplementary_tags.batch_id': 'Batch ID',
  'rough.carats': 'Carat',
  'rough.colour': 'Colour',
  'rough.model': 'Model',
  'rough.quality': 'Quality',
  'rough.fluorescence_intensity': 'Fluorescence intensity',
  'rough.fluorescence_colour': 'Fluorescence colour',
  'rough.stress': 'Stress',
  'rough.fancy_colour': 'Fancy colour',
  'rough.fancy_colour_intensity': 'Fancy colour intensity',
  'rough.fancy_colour_overtone': 'Fancy colour overtone',
  'rough.images': 'Images',
  'rough.videos': 'Videos',
  'rough.scan': '3D Scans',
  'rough.scan_reports': '3D Scan reports',
  'rough.plan': 'Plans',
  'rough.plan_reports': 'Plan reports',
  'rough.supplementary_files': 'Supplementary files',
  assurance_level: 'Assurance level',
  'polished.carats': 'Carat',
  'polished.model': 'Model',
  'polished.cut_grade': 'Cut',
  'polished.shape': 'Shape',
  'polished.colour': 'Colour',
  'polished.polish_quality': 'Polish',
  'polished.symmetry': 'Symmetry',
  'polished.length': 'Length',
  'polished.width': 'Width',
  'polished.depth': 'Depth',
  'polished.clarity': 'Clarity',
  'polished.depth_percent': 'Depth %',
  'polished.table_percent': 'Table %',
  'polished.girdle_thickness_from': 'Girdle thickness from',
  'polished.girdle_thickness_to': 'Girdle thickness to',
  'polished.girdle_condition': 'Girdle condition',
  'polished.pavillion_angle': 'Pavilion angle',
  'polished.pavillion_percent': 'Pavilion %',
  'polished.culet_size': 'Culet size',
  'polished.culet_condition': 'Culet condition',
  'polished.crown_height': 'Crown height',
  'polished.crown_angle': 'Crown angle',
  'polished.diameter_minimum': 'Diameter minimum',
  'polished.diameter_maximum': 'Diameter maximum',
  'polished.grading_lab': 'Grading lab',
  'polished.graded_date': 'Grading date',
  'polished.grader_report_no': 'Grading report number',
  'polished.grader_certificate_no': 'Grading certificate number',
  'polished.grader_inscription_no': 'Grading inscription number',
  'polished.grading_certificates': 'Grading certificates',
  'polished.fluorescence_intensity': 'Fluorescence intensity',
  'polished.fluorescence_colour': 'Fluorescence colour',
  'polished.fancy_colour': 'Fancy colour',
  'polished.fancy_colour_intensity': 'Fancy colour intensity',
  'polished.fancy_colour_overtone': 'Fancy colour overtone',
  'polished.images': 'Images',
  'polished.videos': 'Videos',
  'polished.scan': '3D Scans',
  'polished.scan_reports': '3D Scan reports',
  'polished.supplementary_files': 'Supplementary files',
}

export default columnPickerCopy
