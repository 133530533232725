import { pathOr } from 'ramda'

import { getCarat } from 'utils/diamond'

export const defaultMapper = (val) => {
  if (val === undefined || val === null) return '-'
  return val
}

export const carats = (val, row) => {
  const { data } = row

  return getCarat(data, data.lifecycle_state)
}

export const lengthWidthRatio = (val, row) => {
  const { data } = row

  const lengthToWidth = pathOr(false, ['polished', 'length_to_width'], data)
  const value = '-'

  if (lengthToWidth) {
    lengthToWidth.toString().slice(0, 6)
  }

  return value
}

export const gradingProp = (val, row, path) => {
  const { data } = row
  const lab = pathOr(false, ['polished', 'grading_certificates'], data)
  const prop = path.replace('polished.', '')

  if (!lab) return '-'

  return lab
    .map((cert) => {
      if (!cert[prop]) return '-'

      if (prop === 'graded_date') {
        const date = cert[prop].replace(/^\s+|\s+$/g, '').split(' ')[0]

        if (!date) return '-'

        return date
      } else {
        return cert[prop]
      }
    })
    .join(', ')
}

export const media = (val, _row, path) => {
  const type = path.replace(/^(\w|_)+\./, '')

  switch (type) {
    case 'scan':
    case 'plan':
      return val ? 1 : 0
    case 'videos':
    case 'images':
    case 'scan_reports':
    case 'plan_reports':
    case 'grading_certificates':
    case 'supplementary_files':
      return val ? val.length : 0
    default:
      return ''
  }
}
