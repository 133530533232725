import env from '@beam-australia/react-env'
export * from './mimeResolver'

// ************************************
// *** GENERAL UTIL METHODS GO HERE ***
// ************************************

/**
 * Executes a function after a certain delay. Provides an interface to cancel the execution
 * @param {*} func
 * @param {*} time
 */
export const delay = {
  start: (func, time) => {
    return setTimeout(func, time)
  },
  cancel: (id) => {
    clearTimeout(id)
  },
}

/**
 * Parses comma separated string into an array
 * @param {String} Comma separated values
 * @returns
 */
export const parseCsvEnvVar = (variable) => {
  const value = env(variable) || ''

  if (!value) return []

  return value.replace(/\s/g, '').split(',')
}

/**
 * Checks if the number ends with 1
 * Used to tell if a label should end with an 's'
 * @param {String|Number}
 * @returns {Boolean}
 */
export const isPlural = (value) => {
  const val = value.toString()
  const lastIndex = val.lastIndexOf('1')

  if (lastIndex !== -1 && lastIndex + 1 === val.length) return false

  return true
}

/**
 * Cappitalizes the argument
 * @param {String} word
 * @returns {String} same word cappitalized
 */
export const makeFirstCap = (word) => {
  if (!word) return null

  return word.slice(0, 1).toUpperCase() + word.slice(1)
}

/**
 * Gets actual type of the value
 * @param {Any} value
 * @returns actual value type
 */
export const getActualType = (value) => {
  return Object.prototype.toString.call(value).slice(8, -1).toLocaleLowerCase()
}

/**
 * Adds params to GET request url
 * @param {String} path
 * @param {Object} params
 * @returns {String} GET url with params
 */
export const addParams = (path, params) => {
  const parts = [path, '?']

  Object.keys(params).forEach((key, index) => {
    let value = params[key]

    if (typeof value === 'undefined') return null

    if (getActualType(value) === 'object') {
      const parts2 = []

      Object.keys(value).forEach((k2) => {
        parts2.push(`${k2}:${value[k2]}`)
      })

      value = parts2.join(',')
    }

    parts.push(`${index !== 0 ? '&' : ''}${key}=${value}`)
  })

  return parts.join('')
}
