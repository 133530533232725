import API from 'api'
// import showNotification from 'containers/Notifications/controller'
/// import { createErrorMessage, createSuccessMessage } from 'utils/notificationMessages'
import { SHIPMENTS_IN_ACTION_TYPES } from '../actionTypes'
import { sessionExpiredAction } from '.'

export function getShipmentsInAction({ page, limit, sort } = {}) {
  return (dispatch) => {
    dispatch({ type: SHIPMENTS_IN_ACTION_TYPES.GET_SHIPMENTS.REQUEST })

    return API.shipments.getShipmentsIn({ page, limit, sort }).then(
      (response) => {
        dispatch({ type: SHIPMENTS_IN_ACTION_TYPES.GET_SHIPMENTS.COMPLETE, payload: response.data.content })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: SHIPMENTS_IN_ACTION_TYPES.GET_SHIPMENTS.FAILURE, payload: error })
        }
      }
    )
  }
}

export function getShipmentInAction(id) {
  return (dispatch) => {
    dispatch({ type: SHIPMENTS_IN_ACTION_TYPES.GET_SHIPMENT.REQUEST })

    return API.shipments.getShipmentIn(id).then(
      (response) => {
        dispatch({ type: SHIPMENTS_IN_ACTION_TYPES.GET_SHIPMENT.COMPLETE, payload: response.data.content })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: SHIPMENTS_IN_ACTION_TYPES.GET_SHIPMENT.FAILURE, payload: error })
        }
      }
    )
  }
}

export function acceptShipmentInDiamondsAction(id, diamonds) {
  return (dispatch) => {
    dispatch({ type: SHIPMENTS_IN_ACTION_TYPES.ACCEPT_DIAMONDS.REQUEST })

    return API.shipments.acceptShipmentInDiamonds(id, diamonds).then(
      (response) => {
        const diamonds = Array.isArray(response.data.content) ? response.data.content : response.data.content.results

        dispatch({
          type: SHIPMENTS_IN_ACTION_TYPES.ACCEPT_DIAMONDS.COMPLETE,
          payload: { id, diamonds },
        })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: SHIPMENTS_IN_ACTION_TYPES.ACCEPT_DIAMONDS.FAILURE, payload: error })
        }
      }
    )
  }
}

export function rejectShipmentInDiamondsAction(id, diamonds) {
  return (dispatch) => {
    dispatch({ type: SHIPMENTS_IN_ACTION_TYPES.REJECT_DIAMONDS.REQUEST })

    return API.shipments.rejectShipmentInDiamonds(id, diamonds).then(
      (response) => {
        const diamonds = Array.isArray(response.data.content) ? response.data.content : response.data.content.results

        dispatch({
          type: SHIPMENTS_IN_ACTION_TYPES.REJECT_DIAMONDS.COMPLETE,
          payload: { id, diamonds },
        })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: SHIPMENTS_IN_ACTION_TYPES.REJECT_DIAMONDS.FAILURE, payload: error })
        }
      }
    )
  }
}

export function setShipmentsInStaticFiltersAction(payload) {
  return {
    type: SHIPMENTS_IN_ACTION_TYPES.SET_STATIC_FILTERS,
    payload: payload,
  }
}
