import React from 'react'
import { SVG } from './index'

export const Checkbox = (opacity = 0.1) => {
  return (
    <SVG width="16" height="17">
      <rect
        width="16"
        height="16"
        x="1"
        y="162"
        fill="#FFF"
        fillOpacity={opacity}
        fillRule="evenodd"
        rx="2"
        transform="translate(-1 -161.5)"
      />
    </SVG>
  )
}

export const CheckboxChecked = () => {
  return (
    <SVG width="16" height="17">
      <g fill="none" fillRule="evenodd">
        <rect width="16" height="16" fill="#18BC98" rx="2" />
        <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" d="M4.492 8.44l2.462 2.815 5.19-6.252" />
      </g>
    </SVG>
  )
}

export const SearchIcon = () => {
  return (
    <SVG width="16" height="17">
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        // eslint-disable-next-line
        d="M17 17l-6.28-6.406a5.568 5.568 0 0 0 0-7.948C8.496.451 4.89.451 2.668 2.646a5.568 5.568 0 0 0 0 7.948c1.674 1.653 4.133 2.06 6.194 1.224 0 0 .982-.36 1.858-1.224"
      />
    </SVG>
  )
}

export const RadioSVG = ({ colour }) => {
  return (
    <SVG width="18" height="18">
      <circle cx="582" cy="790" r="8" fill="none" fillRule="evenodd" stroke={colour} transform="translate(-573 -781)" />
    </SVG>
  )
}

export const RadioCheckedSVG = ({ colour }) => {
  return (
    <SVG width="18" height="18">
      <g fill="none" fillRule="evenodd" stroke={colour} transform="translate(1 1)">
        <circle cx="8" cy="8" r="8" />
        <circle cx="8" cy="8" r="5" fill={colour} />
      </g>
    </SVG>
  )
}

export const CopyToClipboardSVG = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.09143 9.90832L3.67172 11.4402C3.08314 12.0752 
        2.12997 12.0752 1.54189 11.4403C0.95368 10.8056 0.95368 
        9.77708 1.54177 9.14254L4.38168 6.0783C4.96977 5.44374 
        5.92303 5.44374 6.51111 6.0783C6.70716 6.28983 7.02503 
        6.28983 7.22108 6.0783C7.41712 5.86677 7.41712 5.52379 
        7.22108 5.31226C6.24087 4.25464 4.65192 4.25464 3.67172 
        5.31226L0.831831 8.37647C-0.148371 9.4341 -0.148371 11.1486 
        0.831831 12.2062C1.81192 13.2644 3.40098 13.2644 4.3817 
        12.2062L5.80141 10.6743C5.99746 10.4628 5.99746 10.1198 
        5.80141 9.9083C5.60537 9.69677 5.28747 9.69679 5.09143 
        9.90832Z"
        fill="#18BC98"
      />
      <path
        d="M11.4098 0.79322C10.4296 -0.264407 8.84011 -0.264407 
        7.8599 0.79322L6.15656 2.63111C5.96052 2.84264 5.96052 
        3.18562 6.15656 3.39715C6.35261 3.60868 6.67048 3.60868 
        6.86652 3.39715L8.56986 1.55926C9.15795 0.924694 
        10.1117 0.924694 10.6998 1.55926C11.2879 2.1938 11.2879 
        3.22236 10.6998 3.8569L7.57627 7.22719C6.98816 7.86176 
        6.03493 7.86176 5.44684 7.22719C5.25079 7.01566 4.93292 
        7.01566 4.73688 7.22719C4.54083 7.43872 4.54083 7.7817 
        4.73688 7.99323C5.71708 9.05086 7.30603 9.05086 8.28623 
        7.99323L11.4098 4.62296C12.39 3.56533 12.39 1.85085 
        11.4098 0.79322Z"
        fill="#18BC98"
      />
    </svg>
  )
}

export const GoBackSVG = () => {
  return (
    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.3335 9.13992L22.6668 9.13992M1.3335 9.13992L8.80016 16.9996M1.3335 9.13992L8.80016 1.28027"
        stroke="white"
      />
    </svg>
  )
}
