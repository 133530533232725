import { clone } from 'ramda'

import { SETTINGS_ACTION_TYPES } from '../actionTypes'

const columns = {
  rough: [
    {
      path: 'rough.scan',
      isEnabled: false,
    },
    {
      path: 'rough.scan_reports',
      isEnabled: false,
    },
    {
      path: 'box_description',
      isEnabled: false,
    },
    {
      path: 'box_id',
      isEnabled: false,
    },
    {
      path: 'rough.carats',
      isDefault: true,
    },
    {
      path: 'rough.colour',
      isDefault: true,
    },
    {
      path: 'rough.fancy_colour',
      isEnabled: false,
    },
    {
      path: 'rough.fancy_colour_intensity',
      isEnabled: false,
    },
    {
      path: 'rough.fancy_colour_overtone',
      isEnabled: false,
    },
    {
      path: 'rough.fluorescence_intensity',
      isEnabled: false,
    },
    {
      path: 'rough.fluorescence_colour',
      isEnabled: false,
    },
    {
      path: 'rough.images',
      isEnabled: false,
    },
    {
      path: 'participant_timestamp',
      isDefault: true,
    },
    {
      path: 'rough.model',
      isDefault: true,
    },
    {
      path: 'participant_id',
      isEnabled: false,
    },
    {
      path: 'rough.plan',
      isEnabled: false,
    },
    {
      path: 'rough.plan_reports',
      isEnabled: false,
    },
    {
      path: 'provenance',
      isDefault: true,
    },
    {
      path: 'rough.quality',
      isDefault: true,
    },
    {
      path: 'matched',
      isDefault: true,
    },
    {
      path: 'sight_no',
      isEnabled: false,
    },
    {
      path: 'sight_year',
      isEnabled: false,
    },
    {
      path: 'unlock_status', // titled as Status
      isDefault: true,
    },
    {
      path: 'rough.stress',
      isDefault: false,
    },
    {
      path: 'rough.supplementary_files',
      isEnabled: false,
    },
    {
      path: 'diamond_id', // titled as Tracr id
      isEnabled: false,
    },
    {
      path: 'rough.videos',
      isEnabled: false,
    },
  ],
  split: [
    {
      path: 'rough.scan',
      isEnabled: false,
    },
    {
      path: 'rough.scan_reports',
      isEnabled: false,
    },
    {
      path: 'supplementary_tags.batch_id',
      isEnabled: false,
    },
    {
      path: 'box_id',
      isEnabled: false,
    },
    {
      path: 'rough.carats',
      isDefault: true,
    },
    {
      path: 'rough.colour',
      isDefault: true,
    },
    {
      path: 'rough.fancy_colour',
      isEnabled: false,
    },
    {
      path: 'rough.fancy_colour_intensity',
      isEnabled: false,
    },
    {
      path: 'rough.fancy_colour_overtone',
      isEnabled: false,
    },
    {
      path: 'rough.fluorescence_intensity',
      isEnabled: false,
    },
    {
      path: 'rough.fluorescence_colour',
      isEnabled: false,
    },
    {
      path: 'rough.images',
      isEnabled: false,
    },
    {
      path: 'participant_timestamp',
      isDefault: true,
    },
    {
      path: 'rough.model',
      isDefault: true,
    },
    {
      path: 'participant_id',
      isEnabled: false,
    },
    {
      path: 'participant_parent_id',
      isEnabled: false,
    },
    {
      path: 'rough.plan',
      isEnabled: false,
    },
    {
      path: 'rough.plan_reports',
      isEnabled: false,
    },
    {
      path: 'provenance',
      isDefault: true,
    },
    {
      path: 'rough.quality',
      isDefault: true,
    },
    {
      path: 'matched',
      isDefault: true,
    },
    {
      path: 'sight_no',
      isEnabled: false,
    },
    {
      path: 'sight_year',
      isEnabled: false,
    },
    {
      path: 'unlock_status', // titled as Status
      isDefault: true,
    },
    {
      path: 'rough.stress',
      isDefault: false,
    },
    {
      path: 'rough.supplementary_files',
      isEnabled: false,
    },
    {
      path: 'diamond_id', // titled as Tracr id
      isEnabled: false,
    },
    {
      path: 'rough.videos',
      isEnabled: false,
    },
  ],
  'polished|graded': [
    {
      path: 'assurance_level',
      isEnabled: false,
    },
    {
      path: 'polished.scan',
      isEnabled: false,
    },
    {
      path: 'polished.scan_reports',
      isEnabled: false,
    },
    {
      path: 'box_description',
      isEnabled: false,
    },
    {
      path: 'box_id',
      isEnabled: false,
    },
    {
      path: 'polished.carats',
      isDefault: true,
    },
    {
      path: 'polished.clarity',
      isDefault: true,
    },
    {
      path: 'polished.colour',
      isDefault: true,
    },
    {
      path: 'polished.crown_angle',
      isEnabled: false,
    },
    {
      path: 'polished.crown_height',
      isEnabled: false,
    },
    {
      path: 'polished.culet_condition',
      isEnabled: false,
    },
    {
      path: 'polished.culet_size',
      isEnabled: false,
    },
    {
      path: 'polished.cut_grade',
      isEnabled: false,
    },
    {
      path: 'polished.depth',
      isEnabled: false,
    },
    {
      path: 'polished.depth_percent',
      isEnabled: false,
    },
    {
      path: 'polished.diameter_maximum',
      isEnabled: false,
    },
    {
      path: 'polished.diameter_minimum',
      isEnabled: false,
    },
    {
      path: 'polished.fancy_colour',
      isEnabled: false,
    },
    {
      path: 'polished.fancy_colour_intensity',
      isEnabled: false,
    },
    {
      path: 'polished.fancy_colour_overtone',
      isEnabled: false,
    },
    {
      path: 'polished.fluorescence_colour',
      isEnabled: false,
    },
    {
      path: 'polished.fluorescence_intensity',
      isEnabled: false,
    },
    {
      path: 'polished.girdle_condition',
      isEnabled: false,
    },
    {
      path: 'polished.girdle_thickness_from',
      isEnabled: false,
    },
    {
      path: 'polished.girdle_thickness_to',
      isEnabled: false,
    },
    {
      path: 'polished.grader_certificate_no', // grader and graded shows as Grading in the ui
      isEnabled: false,
    },
    {
      path: 'polished.grading_certificates',
      isEnabled: false,
    },
    {
      path: 'polished.graded_date',
      isEnabled: false,
    },
    {
      path: 'polished.grader_inscription_no',
      isEnabled: false,
    },
    {
      path: 'polished.grading_lab',
      isEnabled: false,
    },
    {
      path: 'polished.grader_report_no',
      isEnabled: false,
    },
    {
      path: 'polished.images',
      isEnabled: false,
    },
    {
      path: 'participant_timestamp', // Titled as last updated
      isDefault: true,
    },
    {
      path: 'polished.length',
      isEnabled: false,
    },
    {
      path: 'participant_id',
      isEnabled: false,
    },
    {
      path: 'participant_parent_id',
      isEnabled: false,
    },
    {
      path: 'polished.pavillion_angle',
      isEnabled: false,
    },
    {
      path: 'polished.pavillion_percent',
      isEnabled: false,
    },
    {
      path: 'polished.polish_quality',
      isEnabled: false,
    },
    {
      path: 'provenance',
      isDefault: true,
    },
    {
      path: 'matched',
      isDefault: true,
    },
    {
      path: 'polished.shape',
      isDefault: true,
    },
    {
      path: 'sight_no',
      isEnabled: false,
    },
    {
      path: 'sight_year',
      isEnabled: false,
    },
    {
      path: 'unlock_status',
      isDefault: true,
    },
    {
      path: 'polished.supplementary_files',
      isEnabled: false,
    },
    {
      path: 'polished.symmetry',
      isEnabled: false,
    },
    {
      path: 'polished.table_percent',
      isEnabled: false,
    },
    {
      path: 'diamond_id',
      isEnabled: false,
    },
    {
      path: 'polished.videos',
      isEnabled: false,
    },
    {
      path: 'polished.width',
      isEnabled: false,
    },
  ],
}

const initialState = {
  isLoading: false,
  error: null,
  data: {
    viewColumns: {
      ...clone(columns),
    },
    nav: {
      isCollapsed: false,
      expandedItems: [],
    },
    dit: {
      tab: 'sessionOverview',
      hideSubmittedDiamonds: false, // hide diamonds toggle in session view
    },
    shipmentsIn: {
      tab: null,
    },
    shipmentsOut: {
      tab: null,
    },
  },
}

const settingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SETTINGS_ACTION_TYPES.UPDATE_NAV_IS_COLLAPSED: {
      const newState = clone(state)

      return {
        error: null,
        isLoading: false,
        data: {
          ...newState.data,
          nav: {
            ...newState.data.nav,
            isCollapsed: payload,
          },
        },
      }
    }
    case SETTINGS_ACTION_TYPES.UPDATE_VIEW_COLUMNS: {
      const newState = clone(state)

      // resolve lifecycle stage to column key
      const key = Object.keys(newState.data.viewColumns).find((key) => key.includes(payload.lifecycle))

      newState.data.viewColumns = {
        ...newState.data.viewColumns,
        [key]: payload.columns,
      }

      return newState
    }
    case SETTINGS_ACTION_TYPES.RESET_VIEW_COLUMNS: {
      const newState = clone(state)

      // resolve lifecycle stage to column key
      const key = Object.keys(newState.data.viewColumns).find((key) => key.includes(payload.lifecycle))

      newState.data.viewColumns = {
        ...newState.data.viewColumns,
        [key]: columns[key],
      }

      return newState
    }
    case SETTINGS_ACTION_TYPES.NAV_TOGGLE_ITEM: {
      const newState = clone(state)

      const expandedItems = newState.data.nav.expandedItems
      const itemIndex = expandedItems.indexOf(payload)

      if (itemIndex !== -1) expandedItems.splice(itemIndex, 1)
      else expandedItems.push(payload)

      return {
        error: null,
        isLoading: false,
        data: {
          ...newState.data,
          nav: {
            ...newState.data.nav,
            expandedItems,
          },
        },
      }
    }

    case SETTINGS_ACTION_TYPES.DIT_SELECT_TAB: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          dit: {
            ...newState.data.dit,
            tab: payload,
          },
        },
      }
    }

    case SETTINGS_ACTION_TYPES.DIT_HIDE_SUBMITTED_DIAMONDS: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          dit: {
            ...newState.data.dit,
            hideSubmittedDiamonds: payload,
          },
        },
      }
    }

    case SETTINGS_ACTION_TYPES.DIT_RESET_SETTINGS: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          dit: {
            ...initialState.data.dit,
          },
        },
      }
    }

    case SETTINGS_ACTION_TYPES.UPDATE_SHIPMENTS_IN_TAB: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          shipmentsIn: {
            ...newState.data.shipmentsIn,
            tab: payload,
          },
        },
      }
    }

    case SETTINGS_ACTION_TYPES.UPDATE_SHIPMENTS_OUT_TAB: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          shipmentsOut: {
            ...newState.data.shipmentsOut,
            tab: payload,
          },
        },
      }
    }

    default:
      return state
  }
}

export default settingsReducer
