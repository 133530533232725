/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { Button, Tabs } from 'components/system'
import showNotification from 'containers/Notifications/controller'
import {
  getDITSessionAction,
  matchCompleteAction,
  uploadCompleteAction,
  quitSessionAction,
  abandonSessionAction,
  submitSessionAction,
  DITSelectTabAction,
  DITHideSubmittedDiamondsAction,
} from 'store/actions'
import {
  selectDITTab,
  selectDITHideSubmittedDiamonds,
  selectCSVFile,
  selectDITDiamonds,
  selectDITSession,
  selectMissingFiles,
  selectFailedFiles,
  selectUnexpectedFiles,
} from 'store/selectors'
import testids from 'config/testIds'

import PageHeader from '../../PageHeader'
import ProgressButton from './ProgressButton'
import DragAndDropWrapper from '../../DragAndDropWrapper'
import OverviewTab from './OverviewTab'
import MissingFilesTab from './MissingFilesTab'
import FailedFilesTab from './FailedFilesTab'
import UnexpectedFilesTab from './UnexpectedFilesTab'
import ExportFileButton from '../../ExportFileButton'
import ToggleDiamondsButton from '../../ToggleDiamondsButton'
import QuitSessionModal from './QuitSessionModal'
import SubmitSessionModal from './SubmitSessionModal'
import uploader from './uploader'
import classes from './styles.module.css'

export const SessionPage = () => {
  const navigate = useNavigate()
  const { id: sessionId } = useParams()
  const dispatch = useDispatch()
  const session = useSelector(selectDITSession)
  const csvFile = useSelector(selectCSVFile)
  const activeTabId = useSelector(selectDITTab)
  const diamonds = useSelector(selectDITDiamonds)
  const missingFiles = useSelector(selectMissingFiles)
  const failedFiles = useSelector(selectFailedFiles)
  const unexpectedFiles = useSelector(selectUnexpectedFiles)
  const filterCompleted = useSelector(selectDITHideSubmittedDiamonds)
  const [uploadProgress, setUploadProgress] = useState({ uploaded: 0, total: 0 })
  const [currentUploadProgress, setCurrentUploadProgress] = useState({ uploaded: 0, total: 0 })
  const [isUploading, setIsUploading] = useState(false)
  const [isQuitOpen, setIsQuitOpen] = useState(false)
  const [isSessionSubmitOpen, setIsSessionSubmitOpen] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const isExportButtonAvailable = activeTabId && activeTabId != 'sessionOverview'
  const testIds = testids.dit
  const tabs = [
    {
      id: 'sessionOverview',
      label: 'Session overview',
      active: true,
      testId: testIds.sessionOverviewTab,
    },
    {
      id: 'missingFiles',
      label: 'Missing files',
      testId: testIds.missingFilesTab,
      color: 'yellow',
    },
    {
      id: 'failedFiles',
      label: 'Failed files',
      testId: testIds.failedFilesTab,
      color: 'red',
    },
    {
      id: 'unexpectedFiles',
      label: 'Unexpected files',
      testId: testIds.unexpectedFilesTab,
      color: 'grey',
    },
  ]

  const resolveFileToParticipantId = (name) => {
    const diamond = diamonds.find((diamond) => {
      if (!diamond.has_files) return false

      return diamond.data.find((file) => file.content.toLowerCase() === name.toLowerCase())
    })

    if (diamond) return diamond.internal_id

    return ''
  }

  const fileMatchAndUploadListener = (type, data) => {
    switch (type) {
      case 'matchComplete':
        dispatch(matchCompleteAction(data))
        break
      case 'uploadComplete':
        if (data.success) {
          setCurrentUploadProgress((state) => {
            return { ...state, uploaded: state.uploaded + 1 }
          })
        }

        dispatch(uploadCompleteAction(data))

        break
      case 'complete':
        setIsUploading(false)

        break
    }
  }

  const updateUploadProgress = () => {
    if (diamonds.length === 0) return

    const updatedUploadProgress = diamonds.reduce(
      (prev, next) => {
        if (!next.has_files || next.skipped) return prev

        next.data.forEach((file) => {
          if (!file.content) return

          // add file to total files count
          prev.total++

          // add file to total uploaded files count
          if (file.matched && file.upload_ended_ts) prev.uploaded++
        })

        return prev
      },
      { uploaded: 0, total: 0 }
    )

    setUploadProgress(updatedUploadProgress)

    if (updatedUploadProgress.total === updatedUploadProgress.uploaded) {
      if (!isComplete) {
        setIsComplete(true)

        showNotification({
          title: 'Ready to submit',
          bodyText: 'All required files have been accepted and uploaded for this session, submit to continue.',
          variant: 'success',
        })
      }
    } else setIsComplete(false)
  }

  const unCompletedDiamonds = diamonds.filter(
    (diamondData) =>
      !diamondData.data.every((file) => file.content === '' || (file.matched && file.upload_ended_ts)) ||
      diamondData.skipped
  )

  const skippedDiamonds = diamonds.filter((diamondData) => diamondData.skipped)

  const tabsWithCounters = () => {
    return tabs.map((tab) => {
      switch (tab.id) {
        case 'missingFiles':
          if (missingFiles.length) tab.counter = missingFiles.length
          break
        case 'failedFiles':
          if (failedFiles.length) tab.counter = failedFiles.length
          break
        case 'unexpectedFiles':
          if (unexpectedFiles.length) tab.counter = unexpectedFiles.length
          break
      }

      if (tab.id === activeTabId) tab.active = true
      else tab.active = false

      return tab
    })
  }

  const renderActiveTab = () => {
    switch (activeTabId) {
      case 'missingFiles':
        return <MissingFilesTab files={missingFiles} isLoading={isLoading} />
      case 'failedFiles':
        return <FailedFilesTab files={failedFiles} isLoading={isLoading} />
      case 'unexpectedFiles':
        return <UnexpectedFilesTab files={unexpectedFiles} isLoading={isLoading} />
      case 'sessionOverview':
      default:
        const diamondsToDisplay = filterCompleted ? unCompletedDiamonds : diamonds
        return <OverviewTab diamonds={diamondsToDisplay} isLoading={isLoading} allDiamonds={diamonds} />
    }
  }

  const handleDrop = (files) => {
    if (isUploading) return

    triggerFileUpload(files)
  }

  const handleFileInput = (files) => {
    if (isUploading) return

    triggerFileUpload(files)
  }

  const triggerFileUpload = (files) => {
    setIsUploading(true)
    setCurrentUploadProgress({ total: files.length, uploaded: 0 })

    uploader.upload(
      [...files].map((file) => {
        const participantId = resolveFileToParticipantId(file.name)
        return { file, participantId }
      })
    )
  }

  const handleQuitSession = (option) => {
    if (option === 'SAVED') {
      dispatch(quitSessionAction(sessionId))

      showNotification({
        title: 'Session saved',
        variant: 'success',
      })

      navigate('/integration/dit')
    } else {
      dispatch(abandonSessionAction(sessionId)).then(() => {
        showNotification({
          title: 'Session abandoned',
          bodyText: 'This session is no longer accessible, all progress has been deleted.',
          variant: 'success',
        })

        navigate('/integration/dit')
      })
    }
  }

  const handleSessionSubmit = () => {
    dispatch(submitSessionAction(sessionId))
      .then(() => {
        showNotification({
          title: 'Submitted successfully',
          bodyText:
            'Your session has been submitted successfully and will now go through the next phase of matching and verification.',
          variant: 'success',
        })

        navigate(`/integration/dit/${sessionId}/complete`)
      })
      .catch(() => {
        showNotification({
          title: 'Failed to submit',
          bodyText: 'Please try to re-submit this session.',
          variant: 'error',
        })
      })
  }

  const handleTabChange = (tab) => {
    dispatch(DITSelectTabAction(tab.id))
  }

  const handleHideSubmittedDiamonds = (value) => {
    dispatch(DITHideSubmittedDiamondsAction(value))
  }

  useEffect(() => {
    uploader.setSessionId(sessionId)
    uploader.setSubscriber(fileMatchAndUploadListener)

    return () => {
      uploader.setSessionId(null)
      uploader.setSubscriber(null)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const allSkipped = !diamonds.find((diamond) => !diamond.skipped)

  const dragOverlayVisible =
    activeTabId === 'sessionOverview' &&
    failedFiles.length === 0 &&
    unexpectedFiles.length === 0 &&
    session &&
    session.altered_since_creation === false

  const isToggleButtonDisabled =
    allSkipped || isUploading || (unCompletedDiamonds.length === diamonds.length && !isComplete) ? true : false

  useEffect(() => {
    dispatch(getDITSessionAction(sessionId))
      .then((response) => {
        setIsLoading(false)
        if (!response.data || response.data.meta.status === 'COMPLETE') navigate('/integration/dit')
      })
      .catch((err) => {
        navigate('/integration/dit')
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateUploadProgress()
  }, [JSON.stringify(diamonds)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.sessionPageContainer}>
      <PageHeader title={'REQUIRED FILES FOR'}>
        <div className={classes.subTitle} data-test-id={testIds.uploadingFilesHeader}>
          <span>{csvFile.name}</span>
          <span className={classes.uploaded}>{uploadProgress.uploaded}</span>
          <span className={classes.spacer}>/</span>
          <span className={classes.total}>{uploadProgress.total}</span>
        </div>
        <div className={classes.buttons}>
          <Button variant="secondary" onClick={() => setIsQuitOpen(true)} data-test-id={testIds.quitSessionButton}>
            Quit
          </Button>
          <QuitSessionModal open={isQuitOpen} setOpen={setIsQuitOpen} onConfirm={handleQuitSession} />
          <SubmitSessionModal
            open={isSessionSubmitOpen}
            diamondsCount={diamonds.length}
            skippedCount={skippedDiamonds.length}
            onConfirm={handleSessionSubmit}
            onCancel={() => setIsSessionSubmitOpen(false)}
          />
          {!isComplete ? (
            <ProgressButton inProgress={isUploading}>
              {isUploading
                ? `${currentUploadProgress.uploaded}/${currentUploadProgress.total} files uploading`
                : `${uploadProgress.uploaded}/${uploadProgress.total} Uploaded`}
            </ProgressButton>
          ) : (
            <Button onClick={() => setIsSessionSubmitOpen(true)} data-test-id={testIds.uploadCSVSubmitSession}>
              Submit session
            </Button>
          )}
        </div>
      </PageHeader>
      <div className={classes.content}>
        <div className={classes.tabs}>
          <Tabs tabItems={tabsWithCounters()} onActiveChange={handleTabChange} data-test-id={testIds.tabsCounterText} />
          {isExportButtonAvailable && (
            <ExportFileButton
              activeTabId={activeTabId}
              sessionId={sessionId}
              missingFiles={missingFiles}
              failedFiles={failedFiles}
              unexpectedFiles={unexpectedFiles}
            />
          )}
          {!isExportButtonAvailable && (
            <ToggleDiamondsButton
              label={'Hide complete diamonds'}
              isDisabled={isToggleButtonDisabled}
              filter={filterCompleted}
              setFilter={handleHideSubmittedDiamonds}
            />
          )}
        </div>
        <div className={classes.tabContent}>
          <DragAndDropWrapper
            fileName={csvFile.name}
            withBottomBar={unCompletedDiamonds.length === 0 && filterCompleted ? false : true}
            semiTransparent
            forFiles
            persistent={dragOverlayVisible}
            onDrop={handleDrop}
            onFileInput={handleFileInput}
          >
            {renderActiveTab()}
          </DragAndDropWrapper>
        </div>
      </div>
    </div>
  )
}

export default SessionPage
