import createActionSet from 'utils/createActionSet'

const namespace = 'DEMO_DATASET'
const GET_WIZARD = createActionSet(namespace, 'GET_WIZARD')
const CREATE_WIZARD = createActionSet(namespace, 'CREATE_WIZARD')

export const DEMODATASET_ACTION_TYPES = {
  GET_WIZARD,
  CREATE_WIZARD,
}
