import React from 'react'
import { useDispatch } from 'react-redux'

import { selectDiamondsToUnlockAction, setTargetAction, setUnlockModalVisibleAction } from 'store/actions'
import { Button } from 'components/system'
import { LockIcon } from 'components/system/SVG'
import { TracrLogoIcon } from 'components/Svg/logos'
import { isDeclaredDiamond, getCertificateHash } from 'utils/diamond'
import http from 'utils/http'

import HeaderImage from './HeaderImage'
import InscriptionNumbers from './InscriptionNumbers'
import classes from './styles.module.css'
import { testIds } from 'config'

function getProvenanceCopy(diamond) {
  const provenance = diamond.provenance
  if (isDeclaredDiamond(diamond) || !provenance.provenance_name) {
    return null
  } else if (provenance.provenance_type === 'Entity' && provenance.provenance_name === 'DTC') {
    return (
      <div data-test-id={testIds.diamondExperience.provenanceCopy} className={classes.provenance}>
        Discovered by <span>DTC</span>, which sources <span className={classes.unbreakable}>diamonds in</span>
        <br />
        <span>Botswana, Canada, Namibia</span> and <span>South Africa</span>
      </div>
    )
  } else {
    return (
      <div data-test-id={testIds.diamondExperience.provenanceCopy} className={classes.provenance}>
        Discovered in <span>{provenance.provenance_name}</span>
      </div>
    )
  }
};

export const Header = ({ diamond }) => {
  const dispatch = useDispatch()

  const handleUnlockClick = () => {
    dispatch(selectDiamondsToUnlockAction(diamond.diamond_id))
    dispatch(setTargetAction('unlock'))
    dispatch(setUnlockModalVisibleAction('singular'))
  }

  return (
    <div className={classes.headerContainer}>
      <div className={classes.wrapper}>
        <div className={classes.logo}>
          <TracrLogoIcon />
        </div>
        <div data-test-id={testIds.diamondExperience.tracrIdSection} className={classes.tracrId}>
          <div className={classes.heading}>Tracr ID</div>
          <div data-test-id={testIds.diamondExperience.tracrId} className={classes.id}>{diamond.diamond_id}</div>
        </div>
        {diamond.unlock_status !== 'Available' ? (
          <div className={classes.unlockBtnWrapper} onClick={handleUnlockClick}>
            <Button data-test-id={testIds.diamondExperience.unlockButton} variant="secondary" className={classes.unlockBtn}>
              <LockIcon /> Unlock this diamond
            </Button>
          </div>
        ) : null}
        <div className={classes.imageAndCopy}>
          <div className={classes.headerImage}>
            <HeaderImage data-test-id={testIds.diamondExperience.heroImage} diamond={diamond} />
          </div>
          <div className={classes.copy}>
            {getProvenanceCopy(diamond)}
            <div className={classes.blockchainLine}>
              This diamond is immutably registered on the Tracr blockchain platform
            </div>
            {getCertificateHash(diamond) ? (
              <a
                href={`${http.getBaseUrl()}/diamond/asset/${diamond.id}/${getCertificateHash(diamond)}/pdf`}
                target="_blank"
                rel="noreferrer"
                className={classes.viewCertBtn}
                data-test-id={testIds.diamondExperience.viewGradingReportButton}
              >
                View grading report
              </a>
            ) : null}

            <div className={classes.inscriptionNumbers}>
              <InscriptionNumbers diamond={diamond} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
