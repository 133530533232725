import React from 'react'

import Search from 'containers/Search'

import DevBanner from '../DevBanner'
import classes from './styles.module.css'

export const PageTitle = ({ title, count, withSearch, children, ...rest }) => {
  const localed = Number.isInteger(count) ? count.toLocaleString() : null

  return (
    <div className={classes.pageTitleContainer} {...rest}>
      <div className={classes.pageTitle}>{title}</div>
      <DevBanner />
      {Number.isInteger(count) ? (
        <div className={classes.pageTitleCounter}>
          <div className={classes.counter}>
            <div className={classes.count}>{localed}</div>
          </div>
        </div>
      ) : null}
      {withSearch ? (
        <div className={classes.pageTitleSearch}>
          <Search />
        </div>
      ) : null}
      {children}
    </div>
  )
}

export default PageTitle
