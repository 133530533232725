import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectShipmentsInTab } from 'store/selectors'
import { getShipmentsInAction, updateShipmentsInTabAction } from 'store/actions'
import { SectionTitle, Tabs } from 'components/system'
// import { featureToggles } from 'config'

import ActiveTab from './Tabs/ActiveTab'
import ArchiveTab from './Tabs/ArchiveTab'
import classes from './styles.module.css'
import testIds from 'config/testIds'

export const Sessions = () => {
  const dispatch = useDispatch()
  const selectedTab = useSelector(selectShipmentsInTab)

  const renderActiveTab = () => {
    switch (selectedTab) {
      case 'active':
        return <ActiveTab />
      case 'archive':
        return <ArchiveTab />

      default:
        return <ActiveTab />
    }
  }

  const tabsWithCounters = () => {
    const selectedTabId = selectedTab ? selectedTab : 'active'

    const tabs = [
      {
        id: 'active',
        label: 'Active',
        active: selectedTabId === 'active',
        testId: testIds.shipmentsInbound.activeButton,
      },
      {
        id: 'archive',
        label: 'Archived',
        active: selectedTabId === 'archive',
        testId: testIds.shipmentsInbound.archivedButton,
      },
    ]

    return tabs
  }

  const handleTabChange = (tab) => {
    dispatch(updateShipmentsInTabAction(tab.id))
  }

  useEffect(() => {
    dispatch(getShipmentsInAction({ page: 1, limit: 1000, sort: { created_at: 'desc' } }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.sessionsContainer}>
      <div className={classes.wrapper}>
        <SectionTitle title={'Inbound'} />

        <div className={classes.tabs}>
          <Tabs tabItems={tabsWithCounters()} onActiveChange={handleTabChange} />
        </div>
        <div className={classes.tabContent}>{renderActiveTab()}</div>
      </div>
    </div>
  )
}

export default Sessions
