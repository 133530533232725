import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectSession } from 'store/selectors'

import DefaultNavigator from '../DefaultNavigator'

export const ProtectedRoute = ({ withDefaultNavigation }) => {
  // TODO: Replace with custom hook that checks both, local storage and
  // cookie, and sets the session reducer value if localstorage is empty
  const session = useSelector(selectSession)

  // TODO: continue with routes

  if (!session) return <Navigate to="/login" />

  if (withDefaultNavigation)
    return (
      <DefaultNavigator>
        <Outlet />
      </DefaultNavigator>
    )
  else return <Outlet />
}

export default ProtectedRoute
