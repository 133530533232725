import React from 'react'

import { ButtonsCell } from 'components/system/TableElements'
import { testIds } from 'config/testIds'

export const Buttons = ({ row, ...props }) => {
  const getItems = () => {
    const items = []

    if (['transfer_out_pending', 'transfer_out_confirmed'].includes(row.data.transfer_state)) {
      items.push({
        key: 'cancel',
        label: 'Cancel',
        type: 'secondary',
        testId: testIds.shipmentsOutbound.overview.row.cancelButton,
      })
    }

    return items
  }

  return <ButtonsCell row={row} buttons={getItems()} {...props} />
}

export default Buttons
