import React, { useState } from 'react'
import classnames from 'classnames'

import { Modal, Spinner } from 'components/system'
import { TransferIcon } from 'components/Svg'
import { Rough, Split, Polished } from 'components/Svg/lifecycle'
import testids from 'config/testIds'

import classes from './styles.module.css'

const IntegrationTypeModal = ({ integrationTypes, isOpen, isLoading, onCancel, onContinue }) => {
  const testIds = testids.dit
  const [selectedIntegrationType, setSelectedIntegrationType] = useState('')

  const RenderShemaIcon = ({ type }) => {
    const icons = {
      Rough,
      Split,
      Polished,
      Transfer: TransferIcon,
    }

    const Icon = icons[type]

    if (!Icon) return null

    return <Icon />
  }

  const selectIntegrationType = (type) => {
    setSelectedIntegrationType(type)
  }

  const onContinueClick = () => {
    onContinue(selectedIntegrationType)
  }

  return (
    <Modal
      isOpen={isOpen}
      title={isLoading ? 'Validating CSV file' : 'Confirm integration type'}
      cancelTestId={testIds.uploadCSVIntegrationCancel}
      confirmTestId={testIds.uploadCSVIntegrationContinue}
      confirmLabel={'Continue'}
      confirmDisabled={!selectedIntegrationType}
      onCancel={onCancel}
      onConfirm={onContinueClick}
    >
      <div className={classes.body}>
        {isLoading ? (
          <div className={classes.loader} style={{ height: 48 * integrationTypes.length }}>
            <Spinner />
          </div>
        ) : (
          <div className={classes.content}>
            {integrationTypes.map((type) => {
              return (
                <div
                  className={classnames(classes.integrationType, {
                    [classes.selected]: selectedIntegrationType === type,
                  })}
                  key={type}
                  onClick={() => selectIntegrationType(type)}
                  data-test-id={`${testIds.uploadCSVIntegration}${type}`}
                >
                  <RenderShemaIcon type={type} />
                  <span>{type}</span>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default IntegrationTypeModal
