import { clone } from 'ramda'

import { MONITORING_ACTION_TYPES } from '../actionTypes'
import { resolveActionType } from '../utils'

const initialState = {
  data: {
    diamonds: [],
    filters: [],
  },
  requests: {
    // example request record
    // GET_DIAMONDS: {loading: true, error: null}
  },
}

const monitoringReducer = (state = initialState, { payload, type, page }) => {
  const requestType = resolveActionType('MONITORING_ACTION_TYPES', type)

  switch (type) {
    case MONITORING_ACTION_TYPES.GET_DIAMONDS.REQUEST: {
      const newState = clone(state)

      return {
        ...newState,
        requests: {
          ...newState.requests,
          [requestType]: { loading: true, error: null },
        },
      }
    }
    case MONITORING_ACTION_TYPES.GET_DIAMONDS.FAILURE: {
      const newState = clone(state)

      return {
        ...newState,
        requests: {
          ...newState.requests,
          [requestType]: { loading: false, error: payload.error },
        },
      }
    }
    case MONITORING_ACTION_TYPES.GET_DIAMONDS.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      let diamonds = []

      if (page > 1) {
        diamonds = diamonds.concat(newState.data.diamonds).concat(payload.data)
      } else {
        diamonds = payload.data
      }

      return {
        ...newState,
        data: {
          ...newState.data,
          count: payload.count,
          totalCount: payload.total_count,
          diamonds: diamonds,
        },
      }
    }
    case MONITORING_ACTION_TYPES.APPLY_FILTERS: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          filters: payload,
        },
      }
    }

    default:
      return state
  }
}

export default monitoringReducer
