const urls = {
  root: '/',
  insights: {
    root: '/insights',
    benchmarking: {
      root: '/benchmarking',
      settings: {
        root: '/settings',
      },
    },
  },
  inventory: {
    root: '/inventory',
    benchmark: '/benchmark',
    overview: '/overview',
    rough: '/rough',
    polished: '/polished',
    planning: '/planning',
    sawing: '/sawing',
    bruting: '/bruting',
    polishing: '/polishing',
    graded: '/graded',
    pageId: '/:page/:id/',
    list: {
      root: '/list',
      dynamic: (start, end) => `${start}/list${end || ''}`,
      diamond: url => (url ? `/list/diamond/${url}` : '/list/diamond/:id'),
    },
    cards: {
      root: '/cards',
      dynamic: (start, end) => `${start}/cards${end || ''}`,
      diamond: url => (url ? `/cards/diamond/${url}` : '/cards/diamond/:id'),
    },
    chart: {
      root: '/charts',
      dynamic: start => `${start}/charts`,
      diamond: url => (url ? `/charts/diamond/${url}` : '/charts/diamond/:id'),
    },
    sharing: {
      root: '/sharing',
      diamond: id => (id ? `/sharing/diamond/${id}` : '/sharing/diamond/:id'),
    },
    settings: '/benchmarking/settings',
    demo: '/benchmarking/demo',
  },
  integration: {
    root: '/integration',
    tool: '/tool',
    manager: {
      root: '/manager',
      redirect: '/integration/manager/details/',
      details: url => (url ? `/manager/details/${url}` : '/manager/details/:id'),
    },
  },
  tracrJson: '/animations/Tracr_loader.json',
}

export default urls
