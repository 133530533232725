import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import { testId } from 'utils/testHelper'

import classes from './styles.module.css'

export const RadioItem = ({ value, label, checked, onChange, testIdPrefix = '' }) => {
  const [iChecked, setIChecked] = useState(checked)

  const handleClick = () => {
    const newIChecked = !iChecked;
    setIChecked(newIChecked)

    if (onChange) onChange(value, newIChecked)
  }

  useEffect(() => {
    setIChecked(checked)
  }, [checked]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.radioItemContainer}>
      <div
        className={classes.wrapper}
        onClick={handleClick}
        data-test-id={testId(testIdPrefix, `${value}Radio`)}
      >
        <div className={classnames(classes.input, { [classes.checked]: iChecked })}>
          {iChecked ? <div className={classes.circle}></div> : null}
        </div>
        <div className={classnames(classes.label, iChecked && classes.checked)}>{label}</div>
      </div>
    </div>
  )
}

export default RadioItem
