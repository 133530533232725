import React, { useEffect, useState, useCallback } from 'react'
import classnames from 'classnames'
import { isNil, pathOr, type } from 'ramda'

import BodyCell from '../BodyCell'
import classes from './styles.module.css'
import { testId } from 'utils/testHelper'

export const Body = ({
  columns = [],
  rows = [],
  widths,
  height,
  cellHeight,
  groupBy,
  virtualized,
  paginated,
  multiline,
  sortedBy,
  onScroll,
  onRowClick,
  onRowHover,
  onRowLeave,
  testIdPrefix,
}) => {
  const [contentRef, setContentRef] = useState(null)
  const [visibleIndexes, setVisibleIndexes] = useState([0, 0])

  // groups rows, returns only visible rows if virtualized is true
  const prepare = () => {
    if (!height) return []

    let rowsToShow = rows

    const sortKey = Object.keys(sortedBy)[0]

    const createDefaultSorter = (order) => (a, b) => {
      const valA = !isNil(a.data[sortKey]) ? a.data[sortKey].valueOf() : a.data
      const valB = !isNil(b.data[sortKey]) ? b.data[sortKey].valueOf() : b.data

      if (valA > valB) {
        return order[0]
      } else if (valA < valB) {
        return order[1]
      } else {
        return 0
      }
    }

    const sortUser = (sortKey, sortedBy, columns, rows) => {
      const order = sortedBy[sortKey] === 'asc' ? [1, -1] : [-1, 1]
      const sortColumn = columns.find((column) => column.key === sortKey)
      const customSort = sortColumn && sortColumn.sorter ? sortColumn.sorter : null

      if (!customSort) return rows.sort(createDefaultSorter(order))
      return customSort(rows, sortKey, order)
    }

    // add row index to each row, needed for odd/even backgrounds
    rows.forEach((row, index) => (row.rowIndex = index))

    // if paginated, then sorting happens in back-end
    // if rows are grouped, sorting will be done later on
    if (sortKey && !paginated && !groupBy) {
      rowsToShow = sortUser(sortKey, sortedBy, columns, rows)

      // set right index after sorting
      rowsToShow.forEach((row, index) => {
        row.rowIndex = index
      })
    }

    if (virtualized) {
      rowsToShow = rowsToShow.slice(visibleIndexes[0], visibleIndexes[1])
    }

    if (!groupBy) return rowsToShow

    // since groups are in an object, sorting by groupBy
    // needs to be done prior to splitting by groups
    // as object does not gurantee correct order

    if (!paginated && sortKey === groupBy) {
      rowsToShow = sortUser(sortKey, sortedBy, columns, rows)
    }

    const groupped = rowsToShow.reduce((prev, next) => {
      const rowClone = { ...next, data: { ...next.data } }
      const value = rowClone.data[groupBy]

      if (prev[value]) {
        rowClone.data[groupBy] = ''
        prev[value].push(rowClone)
      } else {
        prev[value] = [rowClone]
      }

      return prev
    }, {})

    // if sortKey is any other than groupBy field
    // then sorting needs to happend within each group individually
    // and is handled on front-end even if pagination is applied
    if (sortKey !== groupBy) {
      Object.keys(groupped).forEach((groupName) => {
        groupped[groupName] = sortUser(sortKey, sortedBy, columns, groupped[groupName])

        // re-assign the groupBy value to the first in list as it gets shuffled
        // and can occure in the middle or other indexes
        const rowWithGroupValue = groupped[groupName].find((row) => row.data[groupBy])
        const groupValue = rowWithGroupValue.data[groupBy]

        // empty groupBy value for current row and set it for the first in the list
        rowWithGroupValue.data[groupBy] = ''
        groupped[groupName][0].data[groupBy] = groupValue
      })
    }

    // convert hash into array
    const result = Object.keys(groupped).reduce((prev, next) => {
      return prev.concat(groupped[next])
    }, [])

    // indexes need to be re-set when using groupBy due to the fact
    // that sort can result in an even or odd rows being one after another
    result.forEach((row, index) => {
      row.rowIndex = index
    })

    return result
  }

  const calcVisibleIndexes = () => {
    if (!virtualized) return setVisibleIndexes([0, rows.length - 1])

    if (!contentRef) return

    const parentHeight = contentRef.parentNode.offsetHeight
    const parentScrollTop = contentRef.parentNode.scrollTop

    const rowsToSkip = Math.floor(parentScrollTop / cellHeight)
    const rowsToRender = Math.ceil(parentHeight / cellHeight)

    // render rowsToRender count + 8 rows before and 8 after
    setVisibleIndexes([Math.max(0, rowsToSkip - 8), rowsToSkip + rowsToRender + 8])
  }

  const handleSroll = (evt) => {
    onScroll(evt)

    if (!virtualized) return
    calcVisibleIndexes()
  }

  const onWindowResize = () => {
    calcVisibleIndexes()
  }

  const handleHover = (evt, row) => {
    onRowHover(row)
  }

  const handleLeave = (evt, row) => {
    const target = evt.nativeEvent.relatedTarget

    // when leaving to a tooltip within that row, ignore the leave event
    if (target && target.classList && target.classList.contains('tooltip')) {
      evt.preventDefault()
      return
    }

    onRowLeave(row)
  }

  const onRefChange = useCallback((node) => {
    setContentRef(node)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // attach on resize listener
  useEffect(() => {
    if (!virtualized) return

    window.addEventListener('resize', onWindowResize)

    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [contentRef]) // eslint-disable-line react-hooks/exhaustive-deps

  // calculcate initial height and rows visible
  useEffect(() => {
    if (!contentRef || !virtualized || !height) return

    contentRef.style.height = `${cellHeight * rows.length}px`
    calcVisibleIndexes()
  }, [rows.length, contentRef, height]) // eslint-disable-line react-hooks/exhaustive-deps

  if (rows.length === 0 || widths.length === 0) return null

  return (
    <div className={classnames(classes.bodyContainer, { tableBody: true })} onScroll={handleSroll}>
      <div
        className={classes.content}
        ref={onRefChange}
        style={{ paddingTop: virtualized ? visibleIndexes[0] * 48 : 0 }}
      >
        {prepare(rows).map((row) => {
          const isEven = row.rowIndex % 2 === 0

          return (
            <div
              // TODO: replace with internally generated unique id. nanoid() ?
              key={row.id + row.rowIndex}
              className={classnames(classes.row, {
                [classes.odd]: !isEven,
                [classes.even]: isEven,
                [classes.selected]: row.selected,
                [classes.multiline]: multiline,
                [classes.clickable]: !!onRowClick,
              })}
              data-test-id={testId(testIdPrefix, "row")}
              {...(onRowClick ? { onClick: (evt) => onRowClick(row, evt) } : null)}
              {...(onRowHover ? { onMouseEnter: (evt) => handleHover(evt, row) } : null)}
              {...(onRowLeave ? { onMouseLeave: (evt) => handleLeave(evt, row) } : null)}
            >
              {columns.map((column, columnIndex) => {
                let value

                // try to use pathOr on keys with dots inside
                if (column.key && column.key.indexOf('.') !== -1) {
                  value = pathOr(null, column.key.split('.'), row.data)
                } else {
                  value = row.data[column.key]
                }

                const colspan =
                  row.colspan &&
                  row.colspan.find(
                    (colspan) => columnIndex >= colspan.start && (columnIndex <= colspan.end || !colspan.end)
                  )

                /**
                 * If cells are to be united, check if current column is where the union starts.
                 * If so, add a standard body cell whos width is a sum of all cells that were united.
                 * If current column is part of the union but no the start return null
                 * Note: There can be multiple unions per row
                 */
                if (colspan) {
                  // if colspan starts from this column, append bodyCell
                  if (colspan.start === columnIndex) {
                    const totalWidth = widths.reduce((prev, next, index) => {
                      if (index >= columnIndex && (index <= colspan.end || !colspan.end)) {
                        return prev + next
                      } else return prev
                    }, 0)

                    return (
                      <BodyCell
                        key={column.key}
                        value={colspan.copy}
                        rows={rows}
                        row={row}
                        path={column.key}
                        width={totalWidth}
                        maxScale={column.maxScale}
                        multiline={multiline}
                        rightAlign={column.rightAlign}
                        onClick={column.onCellClick}
                        testIdPrefix={testIdPrefix}
                        {...column.props}
                      />
                    )
                  } else return null
                }

                // TODO: If custom component is passed, return it without BodyCell wrapper
                if (column.cellComponent) {
                  value = (
                    <column.cellComponent
                      {...(type(value) === 'Object' ? value : null)}
                      key={column.key}
                      value={value}
                      rows={rows}
                      row={row}
                      path={column.key}
                      mapper={column.mapper}
                      rightAlign={column.rightAlign}
                      onClick={column.onCellClick}
                      testIdPrefix={testIdPrefix}
                      {...column.props}
                    />
                  )
                }

                // add type switch here
                return (
                  <BodyCell
                    key={column.key}
                    value={value}
                    rows={rows}
                    row={row}
                    path={column.key}
                    mapper={column.mapper}
                    width={widths[columnIndex]}
                    maxScale={column.maxScale}
                    multiline={multiline}
                    isComponent={!!column.cellComponent}
                    rightAlign={column.rightAlign}
                    onClick={column.onCellClick}
                    testIdPrefix={testIdPrefix}
                    {...column.props}
                  />
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Body
