import React from 'react'

import classes from './styles.module.css'

export const Counter = ({ count, text, testId }) => {
  return (
    <div data-test-id={testId} className={classes.counterContainer}>
      <span>{count}</span> {text}
    </div>
  )
}

export default Counter
