import env from '@beam-australia/react-env'
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import API from 'api'
import { setNewShipmentOutAction } from 'store/actions'
import showNotification from 'containers/Notifications/controller'
import { Modal, RadioInput } from 'components/system'
import { testIds } from 'config/testIds'

import FileUpload from '../FileUpload'
import classes from './styles.module.css'

const PARTICIPANT_TYPE = env('PLATFORM_TYPE') ? env('PLATFORM_TYPE').toLowerCase() : 'manufacturer'

const SHIPMENT_TYPE_POLISHED = { value: 'polished', label: 'Polished' }
const SHIPMENT_TYPE_ROUGH = { value: 'rough', label: 'Rough' }
const SHIPMENT_TYPES = {
  producer: [SHIPMENT_TYPE_ROUGH],
  manufacturer: [SHIPMENT_TYPE_ROUGH, SHIPMENT_TYPE_POLISHED],
  retailer: [SHIPMENT_TYPE_POLISHED],
}

export const NewShipmentModal = ({ visible = false, onClose }) => {
  const inputRef = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [shipment, setShipment] = useState(null)
  const [shipmentTypes] = useState(SHIPMENT_TYPES[PARTICIPANT_TYPE])
  const [CSVUploadState, setCSVUploadState] = useState(null)
  const [CSVInputError, setCSVInputError] = useState(null)
  const [isShipmentValid, setIsShipmentValid] = useState(false)

  const triggerFileUpload = (file) => {
    API.shipments
      .validateShipmentOutCSV(file, shipment.shipment_type)
      .then((response) => {
        if (response.status !== 200) {
          const error =
            response && response.data ? response.data.error || response.data.message || response.message : ''
          const message = Object.prototype.toString.call(error).slice(8, -1) === 'Array' ? error[0].msg : error

          setCSVUploadState('error')
          setCSVInputError({ title: 'Request error', text: message, type: 'validation' })

          /*
          showNotification({
            title: 'CSV upload error',
            bodyText: message ? message : 'Unknown error',
            variant: 'error',
          })
          */
        } else {
          setCSVUploadState('success')

          showNotification({
            title: 'CSV uploaded successfully',
            variant: 'success',
          })

          dispatch(
            setNewShipmentOutAction({
              ...shipment,
              diamonds: response.data.content.diamonds,
              created_at: new Date().getTime(),
            })
          )
          navigate('/shipments/outbound/preview')
        }
      })
      .catch((err) => {
        let error = ''

        if (err && err.response) {
          if (err.response.data && err.response.data.message) error = err.response.data.message
          else error = err.message
        } else if (err) {
          if (err.message) error = err.message
        }

        const message = Object.prototype.toString.call(error).slice(8, -1) === 'Array' ? error[0].msg : error

        setCSVUploadState('error')
        setCSVInputError({ title: 'Request error', text: message, type: 'validation' })
      })
  }

  const handleRadioChange = (index) => {
    const type = shipmentTypes[index]

    setShipment({ ...shipment, shipment_type: type ? type.value : null })
  }

  const handleFilesChange = (files) => {
    setCSVInputError(null)
    setCSVUploadState(null)

    if (!files) {
      inputRef.current.file = null

      return validate()
    }

    const file = files.length ? files[0] : files
    const nameParts = file.name.split('.')
    const extension = nameParts[nameParts.length - 1]

    // TODO: rework FileUpload and DragAndDrop components to get rid of this hack
    inputRef.current.file = file

    if (files.length > 1) {
      // raise multiple file upload error
      return setCSVInputError({ title: 'Multiple files', text: 'Please upload one CSV file per session.' })
    } else if (extension !== 'csv' && file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
      // raise invalid file type
      return setCSVInputError({
        title: 'File format is not accepted.',
        text: 'Please upload again ensuring your CSV file has the correct .CSV extension.',
      })
    } else if (file.size === 0) {
      // raise empty file upload
      return setCSVInputError({
        title: 'Empty file',
        text: 'Please make sure your CSV file contains data. <br />A CSV template can be found in the integration toolkit.',
      })
    }

    validate()
  }

  const handleConfirm = () => {
    triggerFileUpload(inputRef.current.file)
  }

  const validate = () => {
    if (!shipment) return setIsShipmentValid(false)

    if (!shipment.shipment_type) return setIsShipmentValid(false)

    if (!inputRef.current?.file) return setIsShipmentValid(false)

    // add more checks
    if (CSVInputError) return setIsShipmentValid(false)

    setCSVUploadState('success')
    setIsShipmentValid(true)
  }

  useEffect(() => {
    validate()
  }, [JSON.stringify(shipment)]) // eslint-disable-line react-hooks/exhaustive-deps

  // when only one shipment type is available, set it as the default
  useEffect(() => {
    if (shipmentTypes.length === 1) {
      setShipment({ ...shipment, shipment_type: shipmentTypes[0].value })
    }
  }, [])

  return (
    <Modal
      closeTestId={testIds.shipmentsOutbound.newShipmentModal.closeButton}
      isOpen={visible}
      width={668}
      title="NEW SHIPMENT"
      confirmLabel="Upload"
      onConfirm={handleConfirm}
      confirmDisabled={!isShipmentValid}
      onClose={onClose}
      confirmTestId={testIds.shipmentsOutbound.newShipmentModal.uploadButton}
    >
      <div className={classes.newShipmentModalContainer}>
        <div className={classes.radioInput}>
          <RadioInput
            items={shipmentTypes}
            label="Integration type"
            onChange={handleRadioChange}
            defaultSelected={shipmentTypes.length === 1 ? 0 : -1}
            testIdPrefix={testIds.shipmentsOutbound.newShipmentModal.prefix}
          />
        </div>
        <div className={classes.fileInput}>
          <FileUpload
            uploadState={CSVUploadState}
            inputError={CSVInputError}
            onFilesChange={handleFilesChange}
            testIdPrefix={testIds.shipmentsOutbound.newShipmentModal.prefix}
          />
          <input className={classes.file} type="file" ref={inputRef} />
        </div>
      </div>
    </Modal>
  )
}

export default NewShipmentModal
