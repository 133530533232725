export const selectActiveShipmentsIn = (state) => {
  const activeStatuses = ['processing', 'action_required', 'incomplete', 'rejecting', 'accepting']

  return state.shipmentsIn.data.shipments.filter((shipment) => activeStatuses.includes(shipment.status))
}

export const selectInactiveShipmentsIn = (state) => {
  const inactiveStatuses = ['cancelling', 'cancelled', 'rejected', 'complete']

  return state.shipmentsIn.data.shipments.filter((shipment) => inactiveStatuses.includes(shipment.status))
}

export const selectShipmentInById = (id) => {
  return (state) => {
    return state.shipmentsIn.data.shipments.find((shipment) => shipment.id === id)
  }
}

export const selectShipmentInDiamondStatuses = (state) => {
  return state.shipmentsIn.data.statuses.diamond
}

export const selectShipmentsInStaticFilters = (page) => {
  return (state) => {
    return state.shipmentsIn.data.filters.static[page]
  }
}
