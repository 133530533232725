import React from 'react'
import classnames from 'classnames'

import classes from './styles.module.css'

export const ActionsCell = ({ row, onClick }) => {
  return (
    <div className={classnames(classes.actionsCellContainer)} onClick={() => onClick(row)}>
      <div className={classes.dash}>—</div>
      <div className={classes.label}>Remove from list</div>
    </div>
  )
}

export default ActionsCell
