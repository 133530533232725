import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Tab from './Tab'
import classes from './styles.module.css'

export const Tabs = ({ tabItems, onActiveChange }) => {
  const [tabs, setTabs] = useState([])

  const handleTabClick = (id) => {
    const selected = tabs.find((tab) => tab.active)
    const next = tabs.find((tab) => tab.id === id)

    selected.active = false
    next.active = true
    setTabs([...tabs])

    if (onActiveChange) onActiveChange(next)
  }

  useEffect(() => {
    setTabs(tabItems)
  }, [JSON.stringify(tabItems)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.tabsContainer}>
      {tabs.map((tab) => {
        return <Tab key={tab.id} {...tab} onClick={handleTabClick} />
      })}
    </div>
  )
}

Tabs.propTypes = {
  tabItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      icon: PropTypes.func,
      counter: PropTypes.number,
      tooltip: PropTypes.string,
      testId: PropTypes.string,
    })
  ),
  draggable: PropTypes.bool,
  onActiveChange: PropTypes.func,
  onOrderChange: PropTypes.func,
}

export default Tabs
