import API from 'api'
// import showNotification from 'containers/Notifications/controller'
// import { createErrorMessage, createSuccessMessage } from 'utils/notificationMessages'
import { DIT_ACTION_TYPES } from '../actionTypes'
import { sessionExpiredAction } from '.'

export function getIntegrationTypesAction() {
  return (dispatch) => {
    dispatch({ type: DIT_ACTION_TYPES.GET_INTEGRATION_TYPES.REQUEST })

    return API.getIntegrationTypes().then(
      (response) => {
        dispatch({ type: DIT_ACTION_TYPES.GET_INTEGRATION_TYPES.COMPLETE, payload: response.data })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIT_ACTION_TYPES.GET_INTEGRATION_TYPES.FAILURE, payload: error })
        }
      }
    )
  }
}

export function getSessionHistoryAction({ page, limit, sort, filters }) {
  return (dispatch) => {
    dispatch({ type: DIT_ACTION_TYPES.GET_SESSION_HISTORY.REQUEST })

    return API.getSessionHistory({ page, limit, sort, filters }).then(
      (response) => {
        dispatch({ type: DIT_ACTION_TYPES.GET_SESSION_HISTORY.COMPLETE, payload: { sessions: response.data, page } })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIT_ACTION_TYPES.GET_SESSION_HISTORY.FAILURE, payload: error })
        }

        return error.response
      }
    )
  }
}

export function getDITSessionAction(sessionId) {
  return (dispatch) => {
    dispatch({ type: DIT_ACTION_TYPES.GET_SESSION.REQUEST, payload: sessionId })

    return API.getDITSession(sessionId).then(
      (response) => {
        dispatch({ type: DIT_ACTION_TYPES.GET_SESSION.COMPLETE, payload: response.data })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIT_ACTION_TYPES.GET_SESSION.FAILURE, payload: error })

          throw error.response
        }
      }
    )
  }
}

export function applyDITFiltersAction(payload) {
  return {
    type: DIT_ACTION_TYPES.APPLY_FILTERS,
    payload,
  }
}

export function uploadCSVAction({ file, fileParsed }) {
  return (dispatch) => {
    dispatch({ type: DIT_ACTION_TYPES.UPLOAD_CSV_FILE.REQUEST, payload: fileParsed })

    return API.uploadCSV(file).then(
      (response) => {
        dispatch({ type: DIT_ACTION_TYPES.UPLOAD_CSV_FILE.COMPLETE, payload: response.data.session_id })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIT_ACTION_TYPES.UPLOAD_CSV_FILE.FAILURE, payload: error.response })

          return error.response
        }
      }
    )
  }
}

export function setDITCSVFileAction(payload) {
  return {
    type: DIT_ACTION_TYPES.SET_CSV_FILE,
    payload,
  }
}

export function setDITCSVErrorsAction(payload) {
  return {
    type: DIT_ACTION_TYPES.SET_CSV_ERRORS,
    payload,
  }
}

export function validateCSVAction({ sessionId, integrationType }) {
  return (dispatch) => {
    dispatch({ type: DIT_ACTION_TYPES.VALIDATE_CSV_FILE.REQUEST, payload: integrationType })

    return API.validateCSV(sessionId, integrationType).then(
      (response) => {
        dispatch({ type: DIT_ACTION_TYPES.VALIDATE_CSV_FILE.COMPLETE, payload: response.data })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIT_ACTION_TYPES.VALIDATE_CSV_FILE.FAILURE, payload: error.response })

          return error.response
        }
      }
    )
  }
}

export function parseCSVAction(sessionId) {
  return (dispatch) => {
    dispatch({ type: DIT_ACTION_TYPES.PARSE_CSV_FILE.REQUEST })

    return API.parseCSV(sessionId).then(
      (response) => {
        dispatch({ type: DIT_ACTION_TYPES.PARSE_CSV_FILE.COMPLETE, payload: response.data })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIT_ACTION_TYPES.PARSE_CSV_FILE.FAILURE, payload: error })

          return error.response
        }
      }
    )
  }
}

export function hideUnhideDiamondAction(action, sessionId, diamondId) {
  return (dispatch) => {
    dispatch({ type: DIT_ACTION_TYPES.HIDE_UNHIDE_DIAMOND.REQUEST })

    return API.hideUnhideDiamond(action, sessionId, diamondId).then(
      (response) => {
        dispatch({ type: DIT_ACTION_TYPES.HIDE_UNHIDE_DIAMOND.COMPLETE, payload: { action, diamondId } })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIT_ACTION_TYPES.HIDE_UNHIDE_DIAMOND.FAILURE, payload: error })

          return error.response
        }
      }
    )
  }
}

export function abandonSessionAction(sessionId) {
  return (dispatch) => {
    dispatch({ type: DIT_ACTION_TYPES.ABANDON_SESSION.REQUEST, payload: sessionId })

    return API.updateSession(sessionId, 'abandon').then(
      (response) => {
        dispatch({ type: DIT_ACTION_TYPES.ABANDON_SESSION.COMPLETE, payload: response.data })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIT_ACTION_TYPES.ABANDON_SESSION.FAILURE, payload: error })

          return error.response
        }
      }
    )
  }
}

export function submitSessionAction(sessionId) {
  return (dispatch) => {
    dispatch({ type: DIT_ACTION_TYPES.SUBMIT_SESSION.REQUEST })

    return API.updateSession(sessionId, 'submit').then(
      (response) => {
        dispatch({ type: DIT_ACTION_TYPES.SUBMIT_SESSION.COMPLETE, payload: response.data })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: DIT_ACTION_TYPES.SUBMIT_SESSION.FAILURE, payload: error })

          throw error.response
        }
      }
    )
  }
}

export function newSessionAction() {
  return {
    type: DIT_ACTION_TYPES.NEW_SESSION,
  }
}

export function matchCompleteAction(payload) {
  return {
    type: DIT_ACTION_TYPES.MATCH_COMPLETE,
    payload,
  }
}

export function uploadCompleteAction(payload) {
  return {
    type: DIT_ACTION_TYPES.UPLOAD_COMPLETE,
    payload,
  }
}

export function completeSessionAction(payload) {
  return {
    type: DIT_ACTION_TYPES.COMPLETE_SESSION,
    payload,
  }
}

export function quitSessionAction(payload) {
  return {
    type: DIT_ACTION_TYPES.QUIT_SESSION,
    payload,
  }
}
