import { clone } from 'ramda'

import { mimeResolver } from 'utils'

import { DIT_ACTION_TYPES } from '../actionTypes'
import { resolveActionType } from '../utils'

const initialState = {
  data: {
    sessions: [],
    filters: [],
    integrationTypes: [], // rough, split, polished in most cases
    session: null,
    csvFile: { name: '', content: [] },
    csvErrors: [],
    diamonds: [], // contains rows of diamonds parsed from csv file
    missingFiles: [],
    failedFiles: [],
    unexpectedFiles: [],
    updatedAt: 0,
  },
  requests: {
    // example request record
    // GET_SESSION_HISTORY: {loading: true, error: null}
  },
}

const ditReducer = (state = initialState, { payload, type }) => {
  const requestType = resolveActionType('DIT_ACTION_TYPES', type)

  const getMissingAndFailedFiles = (list) => {
    // return {missingFiles: [...], failedFiles: [...]}

    return list.reduce(
      (prev, next) => {
        if (!next.has_files || next.skipped) return prev

        next.data.forEach((file) => {
          const name = file.content
          const type = mimeResolver.resolve(file.content)

          if (!name) return

          if (file.error) {
            prev.failedFiles.push({
              participantId: next.internal_id,
              name,
              type,
              error: file.error,
            })
          } else if (!file.upload_ended_ts) {
            prev.missingFiles.push({
              participantId: next.internal_id,
              name,
              type,
            })
          }
        })

        return prev
      },
      { missingFiles: [], failedFiles: [] }
    )
  }

  switch (type) {
    case DIT_ACTION_TYPES.GET_INTEGRATION_TYPES.REQUEST:
    case DIT_ACTION_TYPES.GET_SESSION_HISTORY.REQUEST:
    case DIT_ACTION_TYPES.PARSE_CSV_FILE.REQUEST:
    case DIT_ACTION_TYPES.HIDE_UNHIDE_DIAMOND.REQUEST:
    case DIT_ACTION_TYPES.ABANDON_SESSION.REQUEST:
    case DIT_ACTION_TYPES.SUBMIT_SESSION.REQUEST: {
      const newState = clone(state)

      return {
        ...newState,
        requests: {
          ...newState.requests,
          [requestType]: { loading: true, error: null },
        },
      }
    }
    case DIT_ACTION_TYPES.UPLOAD_CSV_FILE.REQUEST: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          csvFile: payload,
        },
        requests: {
          ...newState.requests,
          [requestType]: { loading: true, error: null },
        },
      }
    }
    case DIT_ACTION_TYPES.VALIDATE_CSV_FILE.REQUEST: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          integrationType: payload,
        },
        requests: {
          ...newState.requests,
          [requestType]: { loading: true, error: null },
        },
      }
    }
    case DIT_ACTION_TYPES.GET_SESSION.REQUEST: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          session: { session_id: payload },
        },
        requests: {
          ...newState.requests,
          [requestType]: { loading: true, error: null },
        },
      }
    }
    case DIT_ACTION_TYPES.GET_INTEGRATION_TYPES.FAILURE:
    case DIT_ACTION_TYPES.GET_SESSION_HISTORY.FAILURE:
    case DIT_ACTION_TYPES.UPLOAD_CSV_FILE.FAILURE:
    case DIT_ACTION_TYPES.VALIDATE_CSV_FILE.FAILURE:
    case DIT_ACTION_TYPES.PARSE_CSV_FILE.FAILURE:
    case DIT_ACTION_TYPES.HIDE_UNHIDE_DIAMOND.FAILURE:
    case DIT_ACTION_TYPES.GET_SESSION.FAILURE:
    case DIT_ACTION_TYPES.ABANDON_SESSION.FAILURE:
    case DIT_ACTION_TYPES.SUBMIT_SESSION.FAILURE: {
      const newState = clone(state)

      return {
        ...newState,
        requests: {
          ...newState.requests,
          [requestType]: { loading: false, error: payload.error },
        },
      }
    }

    case DIT_ACTION_TYPES.GET_SESSION_HISTORY.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      let sessions = []

      if (payload.page > 1) {
        sessions = sessions.concat(newState.data.sessions).concat(payload.sessions)
      } else {
        sessions = payload.sessions
      }

      return {
        ...newState,
        data: {
          ...newState.data,
          sessions,
        },
      }
    }

    case DIT_ACTION_TYPES.GET_INTEGRATION_TYPES.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      // we only need stone_state values
      const integrationTypes = payload
        .map((type) => {
          return type.stone_state
        })
        .filter((type) => type !== 'Transfer')

      return {
        ...newState,
        data: {
          ...newState.data,
          integrationTypes,
        },
      }
    }

    case DIT_ACTION_TYPES.UPLOAD_CSV_FILE.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      return {
        ...newState,
        data: {
          ...newState.data,
          session: { session_id: payload },
        },
      }
    }

    case DIT_ACTION_TYPES.VALIDATE_CSV_FILE.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      return state
    }

    case DIT_ACTION_TYPES.PARSE_CSV_FILE.COMPLETE: {
      const newState = clone(state)

      delete newState.requests[requestType]

      return state
    }

    case DIT_ACTION_TYPES.MATCH_COMPLETE: {
      const newState = clone(state)
      const updatedDiamonds = newState.data.diamonds
      const updatedUnexpected = newState.data.unexpectedFiles

      payload.forEach((fileMatch) => {
        const diamond = updatedDiamonds.find((d) => d.internal_id === fileMatch.participantId)
        const file = diamond && diamond.data.find((f) => f.content.toLowerCase() === fileMatch.file.name.toLowerCase())
        const type = mimeResolver.resolve(fileMatch.file.name)

        if (file && fileMatch.matchResult) file.matched = true
        else updatedUnexpected.push({ name: fileMatch.file.name, type })
      })

      return {
        ...newState,
        data: {
          ...newState.data,
          session: {
            ...newState.data.session,
            altered_since_creation: true,
          },
          diamonds: updatedDiamonds,
          unexpectedFiles: updatedUnexpected,
        },
      }
    }

    case DIT_ACTION_TYPES.HIDE_UNHIDE_DIAMOND.COMPLETE: {
      const newState = clone(state)
      const updatedDiamonds = newState.data.diamonds
      const modifiedDiamond = updatedDiamonds.find((diamond) => diamond.internal_id === payload.diamondId)

      modifiedDiamond.skipped = payload.action === 'hide_diamond'

      // reset all file errors if there were any
      modifiedDiamond.data.forEach((file) => (file.error ? delete file.error : null))

      const missingAndFailedFiles = getMissingAndFailedFiles(updatedDiamonds)

      return {
        ...newState,
        data: {
          ...newState.data,
          session: {
            ...newState.data.session,
            altered_since_creation: true,
          },
          diamonds: updatedDiamonds,
          ...missingAndFailedFiles,
          updatedAt: Date.now(),
        },
      }
    }

    case DIT_ACTION_TYPES.UPLOAD_COMPLETE: {
      const newState = clone(state)
      const updatedDiamonds = newState.data.diamonds
      const diamond = updatedDiamonds.find((d) => d.internal_id === payload.file.participantId)
      const file = diamond && diamond.data.find((f) => f.content.toLowerCase() === payload.file.file.name.toLowerCase())

      // in case participant id was not resolved correctly
      if (!file) return state

      if (payload.success) {
        // if file was a failed file before
        if (file.error) delete file.error

        file.upload_ended_ts = Date.now()
      } else {
        if (payload.data && payload.data.detail) {
          file.error = payload.data.detail
        } else {
          file.error = 'Unknown error'
        }
      }

      const missingAndFailedFiles = getMissingAndFailedFiles(updatedDiamonds)

      return {
        ...newState,
        data: {
          ...newState.data,
          session: {
            ...newState.data.session,
            altered_since_creation: true,
          },
          diamonds: updatedDiamonds,
          ...missingAndFailedFiles,
          updatedAt: payload.success ? Date.now() : newState.data.updatedAt,
        },
      }
    }

    case DIT_ACTION_TYPES.GET_SESSION.COMPLETE: {
      const newState = clone(state)
      const diamonds = payload.diamonds
      const missingAndFailedFiles = getMissingAndFailedFiles(diamonds)

      const session = payload.meta

      return {
        ...newState,
        data: {
          ...newState.data,
          session,
          csvFile: {
            name: payload.meta.csv_filename,
            size: 'unknown',
            content: [],
          },
          diamonds,
          ...missingAndFailedFiles,
          updatedAt: session.updated_at,
        },
      }
    }

    case DIT_ACTION_TYPES.SUBMIT_SESSION.COMPLETE: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          updatedAt: Date.now(),
        },
      }
    }

    case DIT_ACTION_TYPES.NEW_SESSION: {
      const newState = clone(state)

      return {
        ...initialState,
        data: {
          ...initialState.data,
          sessions: newState.data.sessions,
          integrationTypes: newState.data.integrationTypes,
          filters: newState.data.filters,
        },
      }
    }

    case DIT_ACTION_TYPES.COMPLETE_SESSION:
    case DIT_ACTION_TYPES.QUIT_SESSION:
    case DIT_ACTION_TYPES.ABANDON_SESSION.COMPLETE: {
      const newState = clone(state)

      return {
        ...initialState,
        data: {
          ...initialState.data,
          integrationTypes: newState.data.integrationTypes,
          filters: newState.data.filters,
        },
      }
    }

    case DIT_ACTION_TYPES.APPLY_FILTERS: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          filters: payload,
        },
      }
    }

    case DIT_ACTION_TYPES.SET_CSV_FILE: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          csvFile: payload ? payload : initialState.data.csvFile,
        },
      }
    }

    case DIT_ACTION_TYPES.SET_CSV_ERRORS: {
      const newState = clone(state)

      return {
        ...newState,
        data: {
          ...newState.data,
          csvErrors: payload,
        },
      }
    }

    default:
      return state
  }
}

export default ditReducer
