import React from 'react'

import { Modal } from 'components/system'
import testids from 'config/testIds'

import classes from './styles.module.css'

const ConfirmModal = ({ title = '', visible, onCancel, onConfirm, children }) => {
  const testIds = testids.transferOut

  return (
    <Modal
      isOpen={visible}
      title={title}
      confirmLabel="Confirm"
      cancelTestId={testIds.transferModalCancel}
      confirmTestId={testIds.transferModalConfirm}
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm()}
    >
      <div className={classes.confirmModalContainer}>{children}</div>
    </Modal>
  )
}

export default ConfirmModal
