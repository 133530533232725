import React from 'react'
import classnames from 'classnames'

import Tooltip from 'components/system/Tooltip'

import classes from './styles.module.css'
import { FlagIcon } from 'components/system'

const Provenance = ({ value }) => {
  // XXX (AMS): for historic reasons the provenance value was always referred
  // to as a country, although it can be an entity in the case of DTC
  let country;

  if (value.provenance_type === "Country") {
    // Always display the provenance for countries
    country = value.provenance_name || "-";
  } else if (value.provenance_type === "Entity") {
    // Assume it's a DTC diamond otherwise (we only set "Entity" as the
    // provenance type if the name is DTC)
    country = "DTC";
  }

  return (
    <Tooltip title={country}>
      <div className={classnames(classes.provenanceContainer, { [classes.centered]: false })}>
        <div className={classes.text}><FlagIcon style={{ height: '24px' }} country={country} />{country}</div>
      </div>
    </Tooltip>
  )
}

export default Provenance
