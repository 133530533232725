import { getDiamondDetails, getDiamondMeasurements } from 'utils/diamond'

export const getLatestSplit = (diamonds) => {
  const diamond = diamonds.current

  if (!diamond.diamond_parent_id) return null

  return diamond
}

export const getRootDiamond = (diamonds, id) => {
  const diamond = id ? diamonds[id] : diamonds.current

  if (!diamond) return null

  if (diamond.diamond_parent_id) return getRootDiamond(diamonds, diamond.diamond_parent_id)

  if (diamond.lifecycle_state === 'rough' || diamond.lifecycle_state === 'split') return diamond

  return diamond
}

export const getSections = (diamond) => {
  // first get the root section
  const sections = []
  const root = getRootDiamond(diamond)
  const latestSplit = getLatestSplit(diamond)
  const latest = diamond.current

  // rough section
  if (root.rough) {
    sections.push({
      lifecycle: 'rough',
      props: getDiamondDetails(root, 'rough'),
    })
  }

  // split
  if (latestSplit) {
    if (latestSplit !== root) {
      sections.push({
        lifecycle: 'split',
        props: getDiamondDetails(latestSplit, 'rough'),
      })
    }
  }

  // polished
  if (latest.lifecycle_state === 'polished') {
    sections.push({
      lifecycle: 'polished',
      props: getDiamondDetails(latest, 'polished').concat(getDiamondMeasurements(latest)),
    })
  }

  return sections
}
