import http from '../utils/http'

const namespace = 'filters'

const getFilters = (body) => {
  return http.post(`${namespace}`, body)
}

const endpoints = {
  getFilters,
}

export default endpoints
