import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { debounce } from 'lodash'
import { clone } from 'ramda'

import {
  selectDiamondsToUnlockAction,
  setUnlockModalVisibleAction,
  selectDiamondsToShareAction,
  updateSelectedDiamonds,
  unlockDiamondsAction,
  setTargetAction,
  setNewShipmentOutAction,
} from 'store/actions'
import {
  selectUnlockVisibleModalName,
  selectDiamondIdsToUnlock,
  selectDiamondsToUnlock,
  selectSelectedDiamonds,
  selectDiamonds,
  selectAfterUnlockTarget,
  selectNewShipmentOut,
} from 'store/selectors'
import { Modal, Button, Table, Search } from 'components/system'
import { CheckboxCell, EmptyState } from 'components/system/TableElements'
import { RoughPolishingRotatedIcon } from 'components/Svg'

import ParticipantIdCell from './ParticipantIdCell'
import ActionsCell from './ActionsCell'
import classes from './styles.module.css'

const UnlockMultipleModal = () => {
  const dispatch = useDispatch()
  const modalName = useSelector(selectUnlockVisibleModalName)
  const afterUnlock = useSelector(selectAfterUnlockTarget)
  const entireSelection = useSelector(selectSelectedDiamonds)
  const lockedIds = useSelector(selectDiamondIdsToUnlock)
  const diamonds = useSelector(selectDiamonds)
  const lockedDiamonds = useSelector(selectDiamondsToUnlock)
  const shipment = useSelector(selectNewShipmentOut)
  const minTableHeight = 108 // 60 for table header and 48 for 1 row
  const modalHeight = useRef(0)
  const [tableHeight, setTableHeight] = useState(minTableHeight)
  const [rows, setRows] = useState([])
  const [visibleRows, setVisibleRows] = useState([])
  const [selection, setSelection] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)

  const columns = [
    {
      key: 'checkbox',
      resizable: false,
      sortable: false,
      minWidth: '40px',
      width: '40px',
      headerComponent: CheckboxCell,
      cellComponent: CheckboxCell,
      props: {
        onSelectionChange: (newSelection) => {
          const newRows = clone(rows)
          let selectedIds = []

          if (rows.length !== visibleRows.length) {
            const visibleIds = visibleRows.map((row) => row.data.diamond_id)

            selectedIds = rows
              .filter((row) => {
                const diamondId = row.data.diamond_id
                const isVisible = visibleIds.includes(diamondId)

                if (!isVisible) return row.selected ? diamondId : null

                return newSelection.includes(diamondId)
              })
              .map((r) => r.data.diamond_id)
          } else {
            selectedIds = rows
              .filter((row) => {
                return newSelection.includes(row.data.diamond_id)
              })
              .map((r) => r.data.diamond_id)
          }

          setRows(
            newRows.map((row) => {
              if (selectedIds.includes(row.data.diamond_id)) row.selected = true
              else row.selected = false

              return row
            })
          )

          setSelection(selectedIds)
        },
      },
    },
    {
      key: 'participant_id',
      title: 'Participant ID',
      cellComponent: ParticipantIdCell,
    },
    {
      key: 'diamond_id',
      title: 'Tracr ID',
    },

    {
      key: 'actions',
      title: 'Actions',
      resizable: false,
      sortable: false,
      cellComponent: ActionsCell,
      props: {
        onClick: (row) => handleRemoveClick([row.data.diamond_id]),
      },
    },
  ]

  const getRows = (diamonds) => {
    if (!diamonds) return []

    return diamonds.map((row, index) => {
      // const oldRow = rows.find((r) => r.data.diamond_id === row.diamond_id)

      return {
        id: row.diamond_id,
        rowIndex: index,
        selected: true,
        // oldRow ? oldRow.selected : true,
        data: {
          ...row,
        },
      }
    })
  }

  const handleSearchChange = (val) => {
    if (!val) setVisibleRows(rows)

    const RE = new RegExp(`^${val}`, 'i')

    setVisibleRows(rows.filter((row) => RE.test(row.data.diamond_id) || RE.test(row.data.participant_id)))
  }

  const handleRemoveClick = (ids = []) => {
    let newIds = []

    if (ids.length) newIds = lockedIds.filter((id) => !ids.includes(id))
    else newIds = rows.filter((row) => row.selected).map((row) => row.data.diamond_id)

    const removedIds = lockedIds.filter((id) => !newIds.includes(id))

    dispatch(selectDiamondsToUnlockAction(newIds))
    dispatch(
      updateSelectedDiamonds(
        diamonds
          .filter((d) => entireSelection.includes(d.diamond_id) && !removedIds.includes(d.diamond_id))
          .map((d) => d.diamond_id)
      )
    )
  }

  const handleModalAction = (action) => {
    switch (action) {
      case 'close':
      case 'cancel':
        dispatch(setUnlockModalVisibleAction(null))
        dispatch(setTargetAction(null))
        // dispatch(selectDiamondsToUnlockAction([]))
        break
      case 'close_secondary':
        dispatch(setUnlockModalVisibleAction(null))
        dispatch(setTargetAction(null))
        setShowConfirm(false)

        break
      case 'continue': {
        const unlockedIds = diamonds
          .filter((d) => entireSelection.includes(d.diamond_id) && !lockedIds.includes(d.diamond_id))
          .map((d) => d.diamond_id)

        dispatch(setUnlockModalVisibleAction(null))
        dispatch(selectDiamondsToShareAction(unlockedIds))
        break
      }
      case 'unlock':
        setShowConfirm(true)
        break
      case 'back':
        setShowConfirm(false)
        break
      case 'confirm_unlock':
        dispatch(
          unlockDiamondsAction(rows.map((row) => (row.selected ? row.data.diamond_id : null)).filter((row) => row))
        ).then((result) => {
          dispatch(setUnlockModalVisibleAction(null))
          dispatch(setTargetAction(null))
          setShowConfirm(false)

          const unlocked = result.map((d) => (d.status === 'success' ? d.diamond_id : null))

          if (afterUnlock === 'share') {
            const unlockedIds = diamonds
              .filter((d) => entireSelection.includes(d.diamond_id) && !lockedIds.includes(d.diamond_id))
              .concat(diamonds.filter((d) => unlocked.includes(d.diamond_id)))
              .map((d) => d.diamond_id)

            dispatch(selectDiamondsToShareAction(unlockedIds))
          } else if (afterUnlock === 'shipmentOut') {
            const diamonds = clone(shipment.diamonds)

            diamonds.forEach((diamond) => {
              if (unlocked.includes(diamond.diamond_id)) diamond.status = 'success'
            })

            dispatch(
              setNewShipmentOutAction({
                ...shipment,
                diamonds,
              })
            )
          }
        })
        break
      default:
        break
    }
  }

  const updateTableHeight = () => {
    if (modalHeight.current === 0) return null

    const availableHeight = document.body.offsetHeight
    const minSpacing = 96 // 48 top 48 bottom

    const potentialTableHeight = availableHeight - modalHeight.current - minSpacing

    if (potentialTableHeight < minTableHeight) setTableHeight(minTableHeight)
    else setTableHeight(potentialTableHeight)
  }

  const setModalRef = (node) => {
    if (!node) return null

    modalHeight.current = node.offsetHeight

    updateTableHeight()
  }

  useEffect(() => {
    const onResize = debounce(() => {
      updateTableHeight()
    }, 50)

    window.addEventListener('resize', onResize)

    onResize()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  useEffect(() => {
    const rows = getRows(lockedDiamonds)

    setRows(rows)
    setVisibleRows(rows)
    setSelection(rows.map((row) => row.data.diamond_id))
  }, [modalName, JSON.stringify(lockedDiamonds)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <Modal
        isOpen={!showConfirm && modalName === 'multiple'}
        width={1024}
        title={
          afterUnlock
            ? afterUnlock === 'shipmentOut'
              ? 'Confirm diamonds for unlock'
              : 'UNLOCK your diamonds before sharing'
            : 'Confirm diamonds for unlock'
        }
        onClose={() => handleModalAction('close')}
        onRef={setModalRef}
      >
        <div className={classes.unlockMultipleModalContainer}>
          {entireSelection.length !== lockedIds.length ? (
            <div className={classes.counter}>
              <div className={classes.count}>
                <div className={classes.number}>{entireSelection.length}</div>
                <div className={classes.label}>Selected</div>
              </div>
              <div className={classes.count}>
                <div className={classnames(classes.number, classes.locked)}>{lockedIds.length}</div>
                <div className={classes.label}>Locked</div>
              </div>
            </div>
          ) : null}
          <div
            className={classnames(classes.description, {
              [classes.extraPadding]: entireSelection.length !== lockedIds.length,
            })}
          >
            {afterUnlock === 'shipmentOut' ? (
              <span>
                {selection.length} diamonds listed below will be unlocked on confirmation. Fees may apply with this
                action as mentioned in our&nbsp;
                <a href="/support#conditions" target="_blank" rel="noopener noreferrer">
                  terms and conditions
                </a>
              </span>
            ) : entireSelection.length !== lockedIds.length ? (
              afterUnlock ? (
                <span>
                  Diamonds can only be shared when unlocked. Out of your selection,{' '}
                  {entireSelection.length - lockedIds.length} diamond
                  {entireSelection.length - lockedIds.length > 1 ? 's are' : ' is'} already unlocked. {lockedIds.length}{' '}
                  diamond
                  {lockedIds.length > 1 ? 's' : ''} listed below {lockedIds.length > 1 ? 'are' : 'is'} locked and will
                  be unlocked on confirmation, after that you will be able to share them.
                  <br />
                  Fees may apply with this action as mentioned in our{' '}
                  <a href="/support#conditions" target="_blank" rel="noopener noreferrer">
                    terms and conditions
                  </a>
                  .
                </span>
              ) : (
                <span>
                  Out of your selection, {entireSelection.length - lockedIds.length} diamond
                  {entireSelection.length - lockedIds.length > 1 ? 's are' : ' is'} already unlocked. {lockedIds.length}{' '}
                  diamond{lockedIds.length > 1 ? 's' : ''} listed below will be unlocked on confirmation.
                  <br /> Fees may apply with this action as mentioned in our{' '}
                  <a href="/support#conditions" target="_blank" rel="noopener noreferrer">
                    terms and conditions
                  </a>
                  .
                </span>
              )
            ) : afterUnlock ? (
              <span>
                Diamonds can only shared when unlocked. Out of your selection, the diamonds listed below are locked and
                will be unlocked on confirmation.
                <br />
                Fees may apply with this action as mentioned in our{' '}
                <a href="/support#conditions" target="_blank" rel="noopener noreferrer">
                  terms and conditions
                </a>
                .
              </span>
            ) : (
              <span>
                {lockedIds.length} diamond{lockedIds.length > 1 ? 's' : ''} listed below will be unlocked on
                confirmation.
                <br />
                Fees may apply with this action as mentioned in our{' '}
                <a href="/support#conditions" target="_blank" rel="noopener noreferrer">
                  terms and conditions
                </a>
                .
              </span>
            )}
          </div>
          <div className={classes.filters}>
            <div className={classes.search}>
              <Search placeholder="Search by ID" onChange={handleSearchChange} onClear={() => handleSearchChange('')} />
            </div>
            <div className={classes.removeBtn}>
              <Button
                variant="secondary"
                disabled={selection.length === lockedIds.length}
                onClick={() => handleRemoveClick()}
              >
                Remove unselected diamonds from list
              </Button>
            </div>
            {/*
            <div className={classes.reportBtn}>
              <ExportButton>Download Report</ExportButton>
            </div>
            */}
          </div>
          <div className={classes.diamonds}>
            <Table columns={columns} rows={visibleRows} height={tableHeight} isEmpty={rows.length === 0}>
              <EmptyState icon={<RoughPolishingRotatedIcon />} label={'You have no diamonds to unlock'} />
            </Table>
          </div>
          <div className={classes.actions}>
            <div className={classes.buttons}>
              <Button variant="secondary" onClick={() => handleModalAction('cancel')}>
                {lockedIds.length ? 'Cancel' : 'Close'}
              </Button>
              {afterUnlock && lockedIds.length !== 0 && entireSelection.length - lockedIds.length > 0 ? (
                <Button variant="tertiary" onClick={() => handleModalAction('continue')}>
                  Continue without locked diamonds
                </Button>
              ) : null}
              {lockedIds.length !== 0 ? (
                <Button onClick={() => handleModalAction('unlock')} disabled={selection.length === 0}>
                  Unlock selected diamonds
                </Button>
              ) : null}
              {lockedIds.length === 0 && afterUnlock === 'share' ? (
                <Button onClick={() => handleModalAction('continue')}>Continue to share</Button>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showConfirm}
        title="Confirm diamonds for unlock"
        width={560}
        confirmLabel="Confirm"
        cancelLabel="Go back"
        onConfirm={() => handleModalAction('confirm_unlock')}
        onCancel={() => handleModalAction('back')}
        onClose={() => handleModalAction('close_secondary')}
      >
        <div className={classes.smallModalText}>
          <span>Are you sure?</span> You are about to unlock the {selection.length} selected diamond
          {selection.length > 1 ? 's' : ''}.
          <br />
          <span>This action cannot be undone and fees may apply.</span>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default UnlockMultipleModal
