import React from 'react'
import classnames from 'classnames'

import { getPriorityDataPoints } from 'utils/diamond'

import ThumbnailImage from '../ThumbnailImage'
import classes from './styles.module.css'

export const ShareModal = ({ diamond = {}, isSelected = false, onSelect, onRemove, ...props }) => {
  const fourC = getPriorityDataPoints(diamond, diamond.lifecycle_state).filter((x) => x !== '-')

  const handleRemoveClick = () => {
    if (onRemove) onRemove(diamond.diamond_id)
  }

  const handleSelect = (evt) => {
    if (evt.target.className && evt.target.className.includes('remove')) return

    if (onSelect) onSelect(diamond.diamond_id)
  }

  if (Object.keys(diamond).length === 0) return null

  return (
    <li {...props} className={classes.listItemContainer} onClick={handleSelect}>
      <div className={classnames(classes.wrapper, { [classes.selected]: isSelected })}>
        <div className={classes.image}>
          <ThumbnailImage diamond={diamond} />
        </div>
        <div className={classes.copy}>{fourC.join(', ')}</div>
        <div
          className={classnames(classes.removeBtn, { remove: true, [classes.visible]: onRemove })}
          onClick={handleRemoveClick}
        >
          Remove
        </div>
      </div>
    </li>
  )
}

export default ShareModal
