import API from 'api'
// import showNotification from 'containers/Notifications/controller'
// import { createErrorMessage, createSuccessMessage } from 'utils/notificationMessages'
import { SHIPMENTS_OUT_ACTION_TYPES } from '../actionTypes'
import { sessionExpiredAction } from '.'

export function getShipmentsOutAction({ page, limit, sort } = {}) {
  return (dispatch) => {
    dispatch({ type: SHIPMENTS_OUT_ACTION_TYPES.GET_SHIPMENTS.REQUEST })

    return API.shipments.getShipmentsOut({ page, limit, sort }).then(
      (response) => {
        dispatch({ type: SHIPMENTS_OUT_ACTION_TYPES.GET_SHIPMENTS.COMPLETE, payload: response.data.content })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: SHIPMENTS_OUT_ACTION_TYPES.GET_SHIPMENTS.FAILURE, payload: error.message })
        }
      }
    )
  }
}

export function getShipmentOutAction(id) {
  return (dispatch) => {
    dispatch({ type: SHIPMENTS_OUT_ACTION_TYPES.GET_SHIPMENT.REQUEST })

    return API.shipments.getShipmentOut(id).then(
      (response) => {
        dispatch({ type: SHIPMENTS_OUT_ACTION_TYPES.GET_SHIPMENT.COMPLETE, payload: response.data.content })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: SHIPMENTS_OUT_ACTION_TYPES.GET_SHIPMENT.FAILURE, payload: error.message })
        }
      }
    )
  }
}

export function setNewShipmentOutAction(payload) {
  return {
    type: SHIPMENTS_OUT_ACTION_TYPES.SET_NEW_SHIPMENT,
    payload,
  }
}

// TODO: remove this action and all related code
export function validateShipmentOutCSVAction({ file, fileParsed }) {
  return (dispatch) => {
    dispatch({ type: SHIPMENTS_OUT_ACTION_TYPES.VALIDATE_CSV.REQUEST, payload: fileParsed })

    return API.shipments.validateTransferOutCSV(file).then(
      (response) => {
        dispatch({ type: SHIPMENTS_OUT_ACTION_TYPES.VALIDATE_CSV.COMPLETE, payload: response.data.session_id })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: SHIPMENTS_OUT_ACTION_TYPES.VALIDATE_CSV.FAILURE, payload: error.response })
        }

        if (error && error.response) return error.response
        else return error
      }
    )
  }
}

export function sendNewShipmentOutAction(shipment) {
  return (dispatch) => {
    dispatch({ type: SHIPMENTS_OUT_ACTION_TYPES.SEND_NEW_SHIPMENT.REQUEST })

    return API.shipments
      .sendNewShipmentOut({
        shipment_name: shipment.shipment_name,
        receiver: shipment.receiver,
        shipment_type: shipment.shipment_type,
        diamonds: shipment.diamonds,
      })
      .then(
        (response) => {
          dispatch({ type: SHIPMENTS_OUT_ACTION_TYPES.SEND_NEW_SHIPMENT.COMPLETE, payload: null })

          return response
        },
        (error) => {
          if (error.response && error.response.status === 401) {
            dispatch(sessionExpiredAction())
          } else {
            dispatch({ type: SHIPMENTS_OUT_ACTION_TYPES.SEND_NEW_SHIPMENT.FAILURE, payload: error })
          }

          if (error && error.response) return error.response
          else return error
        }
      )
  }
}

export function setShipmentOutCancelDiamondIdAction(id) {
  return {
    type: SHIPMENTS_OUT_ACTION_TYPES.SET_CANCEL_DIAMOND_ID,
    payload: id,
  }
}

export function cancelShipmentOutDiamondsAction(id, diamonds) {
  return (dispatch) => {
    dispatch({ type: SHIPMENTS_OUT_ACTION_TYPES.CANCEL_DIAMONDS.REQUEST })

    return API.shipments.cancelShipmentOutDiamonds(id, diamonds).then(
      (response) => {
        dispatch({
          type: SHIPMENTS_OUT_ACTION_TYPES.CANCEL_DIAMONDS.COMPLETE,
          payload: { id, diamonds: response.data.content },
        })

        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(sessionExpiredAction())
        } else {
          dispatch({ type: SHIPMENTS_OUT_ACTION_TYPES.CANCEL_DIAMONDS.FAILURE, payload: error })
        }
      }
    )
  }
}

export function setShipmentsOutStaticFiltersAction(payload) {
  return {
    type: SHIPMENTS_OUT_ACTION_TYPES.SET_STATIC_FILTERS,
    payload: payload,
  }
}

/*
export function updateTransfers(payload) {
  return {
    type: DIAMONDS_ACTION_TYPES.UPDATE_SELECTED_DIAMONDS,
    payload,
  }
}
*/
