import React from 'react'
import classnames from 'classnames'
import camelCase from 'camelcase'

import CheckboxFilter from '../CheckboxFilter'
import SliderFilter from '../SliderFilter'
import classes from './styles.module.css'
import { testIds } from 'config'
import { testId } from 'utils/testHelper'

export const FilterGroup = ({
  title,
  path,
  slider = false,
  unit,
  step,
  filters = [],
  options = [],
  value = [],
  mapper,
  isLast,
  testIdPrefix = '',
  onChange,
}) => {
  const handleSliderValueChange = (key, value) => {
    onChange(key, null, value)
  }

  const handleCheckoxValueChange = (key, option, value) => {
    onChange(key, option, value)
  }
  const groupTestIdPrefix = testId(testIdPrefix, testIds.filters.infix, camelCase(title ?? ''));
  return (
    <div className={classes.filterGroupContainer} data-test-id={groupTestIdPrefix}>
      <div className={classes.wrapper}>
        {filters.length ? (
          <div className={classes.multipleFilters}>
            <div className={classes.title}>{title}</div>
            <div className={classes.padding}>
              {filters.map((filter) =>
                filter.slider ? (
                  <div key={filter.title} className={classnames(classes.filter, classes.sliderFilter)}>
                    <SliderFilter
                      key={filter.title}
                      path={filter.key}
                      {...filter}
                      testIdPrefix={groupTestIdPrefix}
                      onChange={handleSliderValueChange}
                    />
                  </div>
                ) : (
                  <div key={filter.title} className={classnames(classes.filter, classes.checkboxFilter)}>
                    <CheckboxFilter
                      key={filter.title}
                      path={filter.key}
                      {...filter}
                      testIdPrefix={groupTestIdPrefix}
                      onChange={handleCheckoxValueChange}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        ) : null}

        {options.length ? (
          <div className={classes.singularFilter}>
            <div className={classes.title}>{title}</div>
            {slider ? (
              <div className={classnames(classes.filter, classes.sliderFilter)}>
                <SliderFilter
                  key={title}
                  path={path}
                  unit={unit}
                  step={step}
                  options={options}
                  value={value}
                  mapper={mapper}
                  testIdPrefix={groupTestIdPrefix}
                  onChange={handleSliderValueChange}
                />
              </div>
            ) : (
              <div className={classnames(classes.filter, classes.checkboxFilter)}>
                <CheckboxFilter
                  key={title}
                  path={path}
                  options={options}
                  value={value}
                  testIdPrefix={groupTestIdPrefix}
                  onChange={handleCheckoxValueChange}
                />
              </div>
            )}
          </div>
        ) : null}
        <div className={classnames(classes.border, { [classes.hidden]: isLast })}></div>
      </div>
    </div>
  )
}

export default FilterGroup
