export const resolveInboundShipmentStatusToState = (status) => {
  switch (status) {
    case 'empty':
      return { status: 'warning', text: 'Empty', color: '#FFF', fill: '#FFF' }
    case 'action_required':
      return { status: 'warning', text: 'Action required', color: '#FFF', fill: '#FFF' }
    case 'complete':
      return { status: 'success', text: 'Complete', color: '#00B707', fill: '#00B707' }
    case 'incomplete':
      return { status: 'warning', text: 'Incomplete', color: '#FFF', fill: '#FFF' }
    case 'cancelled':
      return { status: 'error', text: 'Cancelled', color: '#979797', fill: '#979797' }
    case 'rejecting':
      return { status: 'rejected', text: 'Rejecting', color: '#EC123B', fill: '#EC123B' }
    case 'rejected':
      return { status: 'rejected', text: 'Rejected', color: '#EC123B', fill: '#EC123B' }
    case 'processing':
      return { status: 'warning', text: 'Processing', color: '#F99B0D', fill: '#F99B0D' }
    case 'accepting':
      return { status: 'success', text: 'Accepting', color: '#00B707', fill: '#00B707' }
    default:
      return { status: 'error', text: 'Unknown', color: '#979797', fill: '#979797' }
  }
}


export const resolveOutboundShipmentStatusToState = (status) => {
  switch (status) {
    case 'empty':
      return { status: 'warning', text: 'Empty', color: '#FFF', fill: '#FFF' }
    case 'sending':
      return { status: 'success', text: 'Sent', color: '#00B707', fill: '#00B707' }
    case 'sent':
      return { status: 'success', text: 'Sent', color: '#00B707', fill: '#00B707' }
    case 'cancelling':
      return { status: 'error', text: 'Cancelling', color: '#979797', fill: '#979797' }
    case 'cancelled':
      return { status: 'error', text: 'Cancelled', color: '#979797', fill: '#979797' }
    case 'rejecting':
      return { status: 'rejected', text: 'Rejecting', color: '#EC123B', fill: '#EC123B' }
    case 'rejected':
      return { status: 'rejected', text: 'Rejected', color: '#EC123B', fill: '#EC123B' }
    case 'complete':
      return { status: 'success', text: 'Complete', color: '#00B707', fill: '#00B707' }
    case 'accepting':
      return { status: 'success', text: 'Accepting', color: '#00B707', fill: '#00B707' }
    case 'in_progress':
      return { status: 'warning', text: 'In progress', color: '#F99B0D', fill: '#F99B0D' }
    default:
      return { status: 'error', text: 'Unknown', color: '#979797', fill: '#979797' }
  }
}



export const resolveInboundDiamondStatusToState = (statuses, status, shipmentType) => {
  const state = statuses[shipmentType].find((statusData) => statusData.key === status)

  if (!state) return { status: 'not-found', text: 'icon not found' }

  switch (state.key) {
    case 'transferred':
      return { status: 'success', text: state.label, color: '#00B707', fill: '#00B707' }
    case 'cancelled':
      return { status: 'cancel', text: state.label, color: '#979797', fill: '#979797' }
    case 'rejected':
    case 'verification_failed':
      return { status: 'deny', text: state.label, color: '#EC123B', fill: '#EC123B' }
    case 'owned':
    case 'accepted':
    case 'pending_verification':
    case 'verified':
    case 'pending_reject':
    case 'transfer_in_pending':
      return { status: 'warning', text: state.label, color: '#F99B0D', fill: '#F99B0D' }
    default:
      return { status: state.icon, text: state.label, color: '#FFF', fill: '#FFF', stroke: '#FFF' }
  }
}

export const resolveOutboundDiamondStatusToState = (statuses, status, shipmentType) => {
  const state = statuses[shipmentType].find((statusData) => statusData.key === status)

  if (!state) return { status: 'not-found', text: 'icon not found' }

  switch (state.key) {
    case 'transferred':
      return { status: 'success', text: state.label, color: '#00B707', fill: '#00B707' }
    case 'cancelled':
      return { status: 'cancel', text: state.label, color: '#979797', fill: '#979797' }
    case 'rejected':
      return { status: 'deny', text: state.label, color: '#EC123B', fill: '#EC123B' }
    case 'accepted':
    case 'pending_cancel':
    case 'receiver_informed_of_cancel':
    case 'pending_reject':
      return { status: 'warning', text: state.label, color: '#F99B0D', fill: '#F99B0D' }
    case 'transfer_out_pending':
    case 'transfer_out_confirmed':
      return { status: 'success', text: state.label, color: '#FFF', fill: '#FFF' }
    default:
      return { status: state.icon, text: state.label, color: '#979797', fill: '#979797', stroke: '#979797' }
  }
}
