import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import camelCase from 'camelcase'

import {
  getShipmentInAction,
  acceptShipmentInDiamondsAction,
  rejectShipmentInDiamondsAction,
  setShipmentsInStaticFiltersAction,
} from 'store/actions'
import { selectShipmentInById, selectShipmentInDiamondStatuses, selectShipmentsInStaticFilters } from 'store/selectors'
import { Table, ActionsBar, SubNav, StatusLabel, Counter, Button } from 'components/system'
import { CheckboxCell, EmptyState } from 'components/system/TableElements'
import { RoughPolishingRotatedIcon } from 'components/Svg'
import { timestampFormatter } from 'utils/dates'
import { formatNumber } from 'utils/diamond'
import { resolveInboundDiamondStatusToState } from 'utils/shipments'
import { sorter, sorterFloat } from 'utils/sorting'
import { getActualType } from 'utils'

import Nav from '../../containers/Nav'
import DiamondPropCell from '../../components/DiamondPropCell'
import ShapeCell from '../../components/ShapeCell'
import Filters from './Filters'
import ConfirmModal from './ConfirmModal'
import Buttons from './Buttons'
import classes from './styles.module.css'
import testIds from 'config/testIds'
import { testId } from 'utils/testHelper'

export const ConfirmOwnership = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const preloadedShipment = useSelector(selectShipmentInById(id))
  const diamondStatuses = useSelector(selectShipmentInDiamondStatuses)
  const appliedFilters = useSelector(selectShipmentsInStaticFilters('confirm'))
  const [shipment, setShipment] = useState(preloadedShipment)
  const [selectionLength, setSelectionLength] = useState(0)
  const [filteredOutIds, setFilteredOutIds] = useState([])
  const [points, setPoints] = useState([])
  const [rows, setRows] = useState([])
  const [counters, setCounters] = useState([])
  const [modalShown, setModalShown] = useState(false)
  const [modalType, setModalType] = useState('')
  const [diamondsInAction, setDiamondsInAction] = useState([])

  const columns = [
    {
      key: 'checkbox',
      resizable: false,
      sortable: false,
      minWidth: '40px',
      width: '40px',
      headerComponent: CheckboxCell,
      cellComponent: CheckboxCell,
      props: {
        onSelectionChange: (newSelection) => {
          setRows(
            rows.map((row) => {
              if (newSelection.includes(row.id)) row.selected = true
              else row.selected = false

              return row
            })
          )
          setSelectionLength(newSelection.length)
        },
      },
    },
    {
      key: 'diamond_id',
      title: 'Tracr ID',
    },
    {
      key: 'shape',
      title: 'Shape',
      cellComponent: ShapeCell,
      sorter,
    },
    {
      key: 'carats',
      title: 'Carat',
      cellComponent: DiamondPropCell,
      rightAlign: true,
      props: {
        customMapper: (value) => (getActualType(value) !== 'null' ? `${formatNumber(value)}ct` : '-'),
      },
      sorter: sorterFloat,
    },
    {
      key: 'clarity',
      title: 'Clarity',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'colour',
      title: 'Colour',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'cut_grade',
      title: 'Cut',
      cellComponent: DiamondPropCell,
      sorter,
    },
    {
      key: 'state',
      title: 'Status',
      cellComponent: StatusLabel,
      sorter: (rows, key, order) => {
        return rows.sort((a, b) => {
          const propA = a.data[key]
          const propB = b.data[key]

          if (propA.text > propB.text) return order[0]
          else if (propA.text < propB.text) return order[1]
          else return 0
        })
      },
    },
    {
      key: 'Buttons',
      title: 'Actions',
      cellComponent: Buttons,
      minWidth: '200px',
      sortable: false,
      props: {
        onClick: (row, key) => {
          if (key === 'reject') {
            setDiamondsInAction([row.data.diamond_id])

            setModalType('reject')
            setModalShown(true)
          } else {
            setDiamondsInAction([row.data.diamond_id])

            setModalType('accept')
            setModalShown(true)
          }
        },
      },
    },
  ]
  const actions = [
    {
      key: 'reject',
      label: 'Reject',
      icon: 'block',
      onClick: () => {
        setDiamondsInAction(rows.filter((row) => row.selected).map((row) => row.data.diamond_id))

        setModalType('reject')
        setModalShown(true)
      },
    },
    {
      key: 'accept',
      label: 'Accept',
      icon: 'accept',
      onClick: () => {
        setDiamondsInAction(rows.filter((row) => row.selected).map((row) => row.data.diamond_id))

        setModalType('accept')
        setModalShown(true)
      },
      style: 'primary'
    },
  ]

  const getPoints = (shipment) => {
    if (!shipment) return []

    return [
      { key: 'From', value: shipment.sender },
      { key: 'Shipment name', value: shipment.name },
      { key: 'Date sent', value: timestampFormatter(shipment.created_at) },
    ]
  }

  const getRows = (shipment) => {
    if (!shipment) return []

    return shipment.diamonds.map((row, index) => {
      const status = diamondStatuses[shipment.shipment_type].find((status) => status.key === row.transfer_state)
      const state = resolveInboundDiamondStatusToState(diamondStatuses, row.transfer_state, shipment.shipment_type)

      return {
        id: row.diamond_id,
        rowIndex: index,
        selected: false,
        disabled: status ? status.excluded : false,
        actionDisabled: status ? !status.actionable : false,
        data: { ...row, state },
      }
    })
  }

  const filterOut = (rows, ids) => {
    return rows.filter((row) => !ids.includes(row.data.diamond_id))
  }

  const getCounters = (shipment) => {
    const counters = [
      { key: 'Action required', value: 0, color: 'white' },
      { key: 'Transferred', value: 0, color: 'green' },
      { key: 'Processing', value: 0, color: 'orange' },
      { key: 'Rejected', value: 0, color: 'red' },
      { key: 'Cancelled', value: 0, color: 'grey' },
    ]

    if (!shipment) return counters

    const total = counters.find((c) => c.key === 'Action required')
    total.value = shipment.diamonds.filter((d) => ['transfer_in_confirmed'].includes(d.transfer_state)).length

    const accept = counters.find((c) => c.key === 'Transferred')
    accept.value = shipment.diamonds.filter((d) => ['transferred'].includes(d.transfer_state)).length

    const pending = counters.find((c) => c.key === 'Processing')
    pending.value = shipment.diamonds.filter((d) =>
      ['transfer_in_pending', 'accepted', 'owned', 'pending_verification', 'verified', 'pending_reject'].includes(
        d.transfer_state
      )
    ).length

    const reject = counters.find((c) => c.key === 'Rejected')
    reject.value = shipment.diamonds.filter((d) => ['rejected'].includes(d.transfer_state)).length

    const cancelled = counters.find((c) => c.key === 'Cancelled')
    cancelled.value = shipment.diamonds.filter((d) => ['cancelled'].includes(d.transfer_state)).length

    return counters
  }

  const updateSelection = (newSelection = []) => {
    setRows(
      rows.map((row) => {
        if (newSelection.includes(row.id)) row.selected = true
        else row.selected = false

        return row
      })
    )
    setSelectionLength(newSelection.length)
  }

  const handleModalAction = (type) => {
    switch (type) {
      case 'confirm_accept': {
        dispatch(acceptShipmentInDiamondsAction(shipment.id, diamondsInAction))
        break
      }
      case 'confirm_reject': {
        dispatch(rejectShipmentInDiamondsAction(shipment.id, diamondsInAction))
        break
      }
      default:
        break
    }

    return setModalShown(false)
  }

  const handleFiltersChange = (filteredOutIds) => {
    setFilteredOutIds(filteredOutIds)
  }

  const handleClearFilters = () => {
    dispatch(setShipmentsInStaticFiltersAction({ page: 'confirm', filters: {} }))
  }

  useEffect(() => {
    setShipment(preloadedShipment)
  }, [JSON.stringify(preloadedShipment)]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!shipment) return

    setPoints(getPoints(shipment))
    setRows(getRows(shipment))
    setCounters(getCounters(shipment))
    setSelectionLength(0)
  }, [JSON.stringify(shipment)]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shipment) return

    dispatch(getShipmentInAction(id))
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.confirmOwnershipContainer}>
      <div className={classes.wrapper}>
        <Nav title={'accept Ownership of diamonds'} buttonTestId={testIds.shipmentsInbound.overview.backButton} />
        <div className={classes.counters}>
          {counters.map((counter) => {
            return (
              <div data-test-id={testId(testIds.shipmentsInbound.overview.header.prefix, `${camelCase(counter.key)}Counter`)} key={counter.key} className={classnames(classes.counter, classes[counter.color])}>
                <div className={classes.number}>{counter.value}</div>
                <div className={classes.label}>{counter.key}</div>
              </div>
            )
          })}
        </div>
        <Filters
          testIdPrefix={testIds.shipmentsInbound.overview.prefix}
          rows={rows} shipmentType={shipment ? shipment.shipment_type : null} onChange={handleFiltersChange} />

        <div className={classes.filterCounter}>
          <Counter
            testId={testIds.shipmentsInbound.overview.diamondsCounter}
            total={shipment ? shipment.diamonds.length : null}
            filtered={Object.keys(appliedFilters).length ? rows.length - filteredOutIds.length : null}
          />
        </div>

        <div className={classes.overview}>
          <div data-test-id={testIds.shipmentsInbound.overview.shipmentDetails} className={classes.subNav}>
            <SubNav points={points} />
          </div>
          <div className={classes.list}>
            <Table
              columns={columns}
              rows={filterOut(rows, filteredOutIds)}
              isEmpty={filterOut(rows, filteredOutIds).length === 0}
              testIdPrefix={testIds.shipmentsInbound.overview.prefix}
            >
              <EmptyState
                icon={<RoughPolishingRotatedIcon />}
                label={'Nothing to display'}
                subLabel={'Adjust or clear all filters to view results'}
                height="100%"
                className={classes.emptyState}
              >
                <Button variant="secondary" className={classes.emptyStateButton} onClick={handleClearFilters}>
                  Clear all filters
                </Button>
              </EmptyState>
            </Table>
          </div>
        </div>
      </div>
      <ConfirmModal
        open={!!modalShown}
        type={modalType}
        onCancel={() => handleModalAction('cancel')}
        onConfirm={() => handleModalAction(modalType === 'accept' ? 'confirm_accept' : 'confirm_reject')}
      />

      <ActionsBar
        label="Selected"
        selectionLength={selectionLength}
        actions={actions}
        onClear={updateSelection}
        testIdPrefix={testIds.shipmentsInbound.overview.actionBar}
      />
    </div>
  )
}

export default ConfirmOwnership
