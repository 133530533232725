import { clone } from 'ramda'
import { createErrorMessage } from 'utils/notificationMessages'
import { LOCATION_ACTION_TYPES, SEARCH_ACTION_TYPES } from '../actionTypes'

const initialState = {
  isLoading: false,
  error: null,
  searchedFor: null,
  data: null,
}

const searchReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case SEARCH_ACTION_TYPES.DIAMOND_SEARCH.REQUEST: {
      const newState = clone(state)
      return { ...newState, isLoading: true, error: null, searchedFor: payload, data: null }
    }
    case SEARCH_ACTION_TYPES.DIAMOND_SEARCH.FAILURE: {
      const newState = clone(state)
      const message = createErrorMessage({ error: { custom: true }, key: 'search' })

      return {
        ...newState,
        isLoading: false,
        error: message,
      }
    }
    case SEARCH_ACTION_TYPES.DIAMOND_SEARCH.COMPLETE: {
      const newState = clone(state)
      return {
        ...newState,
        isLoading: false,
        data: payload,
      }
    }
    case SEARCH_ACTION_TYPES.CLEAR_SEARCH: {
      return initialState
    }
    case LOCATION_ACTION_TYPES.CHANGE: {
      return initialState
    }
    default:
      return state
  }
}

export default searchReducer
