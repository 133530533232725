import { forEach } from 'lodash'

export const filtersMapper = filters => {
  const mappedFilters = {}
  const colours = {
    Colorless: ['D', 'E', 'F'],
    'Near Colorless': ['G', 'H', 'I', 'J'],
    Faint: ['K', 'L', 'M'],
    'Very Light': ['N', 'O', 'P', 'Q', 'R'],
    Light: ['S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
  }
  // quality and clarity shares the same grouping
  const quality = {
    'FL - IF': ['FL', 'IF', 'LC'],
    'VVS1 - VVS2': ['VVS', 'VVS1', 'VVS2'],
    'VS1 - VS2': ['VS', 'VS1', 'VS2'],
    'SI1 - SI2 - SI3': ['SI', 'SI1', 'SI2', 'SI3'],
    'I1 - I2 - I3': ['I', 'I1', 'I2', 'I3', 'P', 'P1', 'P2', 'P3'],
  }

  forEach(filters, (value, key) => {
    if (/\.colour/.test(key)) {
      mappedFilters[key] = value.reduce((acc, color) => {
        let list

        if (colours[color]) {
          list = colours[color]
        } else {
          list = [color]
        }

        return acc.concat(list)
      }, [])
    } else if (/\.(quality|clarity)/.test(key)) {
      mappedFilters[key] = value.reduce((acc, color) => {
        let list

        if (quality[color]) {
          list = quality[color]
        } else {
          list = [color]
        }

        return acc.concat(list)
      }, [])
    } else {
      mappedFilters[key] = value
    }
  })

  return mappedFilters
}
