import React from 'react'
import classnames from 'classnames'

import testids from 'config/testIds'

import classes from './styles.module.css'

export const ProgressButton = ({ inProgress = false, children }) => {
  const testIds = testids.dit

  return (
    <button
      className={classnames(classes.progressButtonContainer, { [classes.inProgress]: inProgress })}
      data-test-id={testIds.uploadingFilesButton}
    >
      {children}
      {inProgress && <div className={classes.progressGradient}></div>}
    </button>
  )
}

export default ProgressButton
