import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { isNil } from 'ramda'

import EmptyStateOverlay from 'pages/dit/EmptyStateOverlay'
import { Button, Table } from 'components/system'
import { TumbleweedIcon } from 'components/Svg'
import { getSessionHistoryAction, newSessionAction, DITResetSettingsAction } from 'store/actions'
import { selectDITSessions, selectDITFilters } from 'store/selectors'
import { TZAwareTimestampFormatter } from 'utils/dates'
import { testIds as testids } from 'config'

import PageHeader from '../../PageHeader'
import Filters from './Filters'
import StatusCell from './StatusCell'
import ActionCell from './ActionCell'
import NewSessionModal from './NewSessionModal'
import classes from './styles.module.css'

export const SessionHistoryPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const sessions = useSelector(selectDITSessions)
  const filters = useSelector(selectDITFilters)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [sort, setSort] = useState({ start: 'desc' })
  const [isLoading, setIsLoading] = useState(true)
  const [newSessionModal, setNewSessionModal] = useState(location.pathname.includes('/new-session'))
  const sessionsPerPage = 40
  const testIds = testids.dit

  const mapStatusToText = ({ row }) => {
    const sessionStatus = row.status.toLowerCase()

    switch (sessionStatus) {
      case 'saved':
        return 'Session incomplete, continue to submit'
      case 'complete':
        return 'Data uploaded, diamonds are now being processed'
      case 'abandoned':
        return 'This session was abandoned'
      default:
        break
    }
  }

  const handleNewSessionClick = () => {
    dispatch(newSessionAction())
    dispatch(DITResetSettingsAction())

    // navigate(`/integration/dit/new-session`)
    setNewSessionModal(true)
  }

  const handleLoadMore = () => {
    setIsLoading(true)

    const nextPage = page + 1
    setPage(nextPage)

    dispatch(getSessionHistoryAction({ page: nextPage, limit: sessionsPerPage, sort, filters })).then((response) => {
      if (response && response.data) {
        if (response.data.length < sessionsPerPage) setHasMore(false)
      }
      setIsLoading(false)
    })
  }

  const handleSort = (sort) => {
    setIsLoading(true)
    setHasMore(true)
    setPage(1)
    setSort(sort)

    dispatch(getSessionHistoryAction({ page: 1, limit: sessionsPerPage, sort, filters })).then((response) => {
      if (response && response.data) {
        if (response.data.length < sessionsPerPage) setHasMore(false)
      }

      setIsLoading(false)
    })
  }

  // sorts '-' to alwasy be at the end of the list
  const sortDashes = (list, key, order) => {
    let sortedList = list.filter((item) => item[key] !== '-')

    sortedList = sortedList.sort((a, b) => {
      const valA = !isNil(a[key]) ? a[key].valueOf() : a
      const valB = !isNil(b[key]) ? b[key].valueOf() : b

      if (valA > valB) {
        return order[0]
      } else if (valA < valB) {
        return order[1]
      } else {
        return 0
      }
    })

    sortedList = sortedList.concat(list.filter((item) => item[key] === '-'))

    return sortedList
  }

  const statusMapper = {
    abandoned: 'Abandoned',
    complete: 'Submitted',
    saved: 'In progress',
  }

  const columns = [
    { title: 'Session ID', sortable: false, key: 'session_id' },
    { title: 'Session start', key: 'start', mapper: TZAwareTimestampFormatter },
    { title: 'Session submitted', key: 'end', mapper: TZAwareTimestampFormatter, sorter: sortDashes },
    { title: 'Integration type', key: 'stone_state' },
    { title: 'Diamonds submitted', key: 'diamonds_submitted', sorter: sortDashes, rightAlign: true },
    { title: 'Diamonds excluded', key: 'diamonds_excluded', sorter: sortDashes, rightAlign: true },
    { title: 'Status', key: 'status', minWidth: '131px', cellComponent: StatusCell },
    { title: 'Details', key: 'details', sortable: false, minWidth: '131px' },
    {
      title: 'Action',
      sortable: false,
      minWidth: '141px',
      cellComponent: ActionCell,
      key: 'action',
    },
  ]

  const rows = sessions.map((row, index) => {
    return {
      id: row.session_id,
      data: {
        rowIndex: index,
        session_id: row.session_id,
        start: row.start,
        end: row.end || '-',
        stone_state: row.stone_state,
        diamonds_submitted: Number.isInteger(row.diamonds_submitted) ? row.diamonds_submitted : '-',
        diamonds_excluded: Number.isInteger(row.diamonds_excluded) ? row.diamonds_excluded : '-',
        details: mapStatusToText({ row }),
        status: statusMapper[row.status.toLowerCase()],
      },
    }
  })

  const handleNewSessionModalClose = () => {
    setNewSessionModal(false)

    // clear new session from reducer
  }

  useEffect(() => {
    if (page > 1) setPage(1)
    setIsLoading(true)

    dispatch(getSessionHistoryAction({ page: 1, limit: sessionsPerPage, sort, filters })).then((response) => {
      if (response) return setIsLoading(false)
    })
  }, [JSON.stringify(filters)]) // eslint-disable-line react-hooks/exhaustive-deps

  const isHistoryEmpty = !isLoading && (rows.length === 0 || sessions.length === 0)

  const emptyTable = () => {
    return (
      <EmptyStateOverlay
        icon={<TumbleweedIcon />}
        label={filters.length > 0 ? 'No sessions to display' : 'Nothing to see'}
        withTabs={false}
        subLabel={
          filters.length > 0 ? 'To view sessions adjust or clear filters' : 'Sessions you create will be displayed here'
        }
      />
    )
  }

  return (
    <div className={classes.sessionHistoryPageContainer}>
      <PageHeader title="SESSION HISTORY">
        <Button onClick={handleNewSessionClick} data-test-id={testIds.newSessionButton}>
          New session
        </Button>
      </PageHeader>
      <div className={classes.filters}>
        <Filters />
      </div>
      <div className={classes.content}>
        <Table
          columns={columns}
          rows={rows}
          sorted={sort}
          paginated
          virtualized
          hasMore={hasMore}
          isLoading={isLoading}
          columnTestIdPrefix={testIds.sessionHistoryColumn}
          rowTestId={testIds.sessionHistoryRow}
          onLoadMore={handleLoadMore}
          onSort={handleSort}
          emptyState={emptyTable()}
          isEmpty={isHistoryEmpty}
        />
      </div>
      <NewSessionModal visible={newSessionModal} onClose={handleNewSessionModalClose} />
    </div>
  )
}

export default SessionHistoryPage
