import user from './user'
import diamonds from './diamonds'
import filters from './filters'
import monitoring from './monitoring'
import settings from './settings'
import demoDataset from './demoDataset'
import sharing from './sharing'
import dit from './dit'
import share from './share'

// plan move all endpoints under its namespaces
import shipments from './shipments'
import tracking from './tracking'

const API = {
  ...user,
  ...diamonds,
  ...filters,
  ...monitoring,
  ...settings,
  ...demoDataset,
  ...sharing,
  ...dit,
  ...monitoring,
  ...share,

  // TODO: move all endpoints under its namespaces like transfer
  shipments,
  tracking,
}

export default API
