import translation from 'text/en'

const resolveAPIError = ({ error, key, operation, defaultMessage }) => {
  const apiError = error && error.response && error.response.data ? error.response.data.error : {}
  const messages = translation.errors[key] ? translation.errors[key] : {}
  let message = ''

  switch (apiError.area) {
    case 'DB':
      if (messages[apiError.code]) {
        message = messages[apiError.code].replace(/\{\w+\}/, apiError.description)
      } else if (messages[`DEFAULT_${operation}`]) {
        message = messages[`DEFAULT_${operation}`]
      } else {
        message = messages.DEFAULT_DB
      }
      break
    case 'PARAMS':
      if (apiError.params.length) {
        apiError.params.forEach(param => {
          message = message ? message.concat('\n') : ''
          message = message.concat(`${param.error} for ${param.name}`)
        })
      } else {
        message = defaultMessage || messages.DEFAULT
      }
      break
    default:
      if (messages[`DEFAULT_${operation}`]) {
        message = messages[`DEFAULT_${operation}`]
      } else {
        message = defaultMessage || messages.DEFAULT
      }

      break
  }

  return message
}

const resolveHTTPError = ({ error, key, defaultMessage }) => {
  const messages = translation.errors[key] ? translation.errors[key] : {}
  const status = error.response.status
  let message = ''

  switch (status) {
    case 404: {
      message = messages[status] ? messages[status] : 'Not found'
      break
    }
    default:
      message = defaultMessage || messages.DEFAULT
  }

  return message
}

const resolveCustomError = ({ error, key, defaultMessage }) => {
  const messages = translation.errors[key] ? translation.errors[key] : {}
  const code = error.code
  let message = ''

  switch (code) {
    case 101: {
      message = messages[code] ? messages[code] : 'Not found'
      break
    }
    default:
      message = defaultMessage || messages.DEFAULT
  }

  return message
}

export const createErrorMessage = ({ error, key, operation, defaultMessage }) => {
  // check if api response is present (not timed out) and response data object is there
  const isAPIError = error && error.response && error.response.data
  const isHTTPError = error && error.response && error.response.status !== 200
  const isCustomError = error && error.custom
  let message = ''

  if (isAPIError) {
    message = resolveAPIError({ error, key, operation, defaultMessage })
  } else if (isHTTPError) {
    message = resolveHTTPError({ error, key, defaultMessage })
  } else if (isCustomError) {
    message = resolveCustomError({ error, key, defaultMessage })
  }

  return message
}

export const createSuccessMessage = ({ key, operation, defaultMessage }) => {
  const messages = translation.success[key] ? translation.success[key] : {}

  return messages[operation] || defaultMessage || messages.DEFAULT
}
