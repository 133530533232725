import React, { useEffect, useState } from 'react'

import { getRootDiamond, getOrderedMedia } from 'utils/diamond'

import DiamondGalleryCanvas from './DiamondGalleryCanvas'
import DiamondGalleryThumbnails from './DiamondGalleryThumbnails'
import DiamondGalleryPlaceholder from './DiamondGalleryPlaceholder'

const DiamondGallery = ({ diamond, section, className, testIdPrefix }) => {
  const [, setGalleryDiamond] = useState(null)
  const [orderedAssets, setOrderedAssets] = useState(null)
  const [activeAsset, setActiveAsset] = useState(null)
  const [isAnimating, setIsAnimating] = useState(false)
  const [navDisabled, setNavDisabled] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const mapAssets = (diamond, lifecycle) => {
    const assetsList = getOrderedMedia({ ...diamond, lifecycle_state: lifecycle === 'split' ? 'rough' : lifecycle }, [
      'videos',
      'images',
      'scans',
      'plans',
    ])

    // add diamond id to each asset
    return assetsList.map((asset) => ({ id: diamond.id, ...asset, local: !!diamond.is_mock }))
  }

  const initiateGallery = (diamond) => {
    if (!diamond) return

    const assets = mapAssets(diamond, section)

    const activeSlide = assets[0]

    setNavDisabled(false)
    setGalleryDiamond(diamond)
    setOrderedAssets(assets)
    setActiveAsset(activeSlide)
  }

  const onAssetLoaded = (result) => {
    if (!result) {
      // TODO: add logic for next slide query
    }
  }

  const onThumbsLoaded = (successfulCount) => {
    // hide gallery
    if (!successfulCount) return setDisabled(true)

    // show gallery but disable arrows nav
    if (successfulCount === 1) return setNavDisabled(true)
  }

  const onArrowClick = (direction) => {
    // prevent slide change while animating is ongoing
    if (isAnimating) return

    const moveValue = direction === 'left' ? -1 : 1
    const currentAssetIndex = orderedAssets.indexOf(activeAsset)
    let nextActiveAsset = orderedAssets[currentAssetIndex + moveValue]

    if (!nextActiveAsset) {
      if (moveValue < 1) nextActiveAsset = orderedAssets[orderedAssets.length - 1]
      else nextActiveAsset = orderedAssets[0]
    }

    if (nextActiveAsset) setActiveAsset(nextActiveAsset)
  }

  const onThumbClick = (asset) => {
    // prevent slide change while animating is ongoing
    if (isAnimating) return

    const nextActiveAsset = orderedAssets[orderedAssets.indexOf(asset)]

    if (nextActiveAsset) setActiveAsset(nextActiveAsset)
  }

  useEffect(() => {
    if (!diamond) return

    if (section === 'rough') initiateGallery(getRootDiamond(diamond))
    else if (section === 'split') initiateGallery(diamond.current)
    else if (diamond.current.lifecycle_state === 'polished') initiateGallery(diamond.current)
  }, [JSON.stringify(diamond)]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!diamond || disabled) return null

  if (!activeAsset) {
    return (
      <DiamondGalleryPlaceholder
        lifecycle={section}
        diamond={section === 'rough' ? getRootDiamond(diamond) : diamond.current}
      />
    )
  }

  return (
    <div className={className}>
      <DiamondGalleryCanvas
        asset={activeAsset}
        disableNav={navDisabled}
        section={section}
        onArrowClick={onArrowClick}
        onAnimation={setIsAnimating}
        onAssetLoad={onAssetLoaded}
        testIdPrefix={testIdPrefix}
      />
      <DiamondGalleryThumbnails
        thumbnails={orderedAssets}
        activeThumbnail={activeAsset}
        section={section}
        onThumbClick={onThumbClick}
        onThumbsLoad={onThumbsLoaded}
      />
    </div>
  )
}

export default DiamondGallery
