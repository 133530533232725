import React from 'react'
import classnames from 'classnames'

import { Button } from 'components/system'
import { UploadCSVIcon } from 'components/Svg'
import testids from 'config/testIds'

import classes from './styles.module.css'

export const CSVOverlay = ({ persistent, semiTransparent, isDraggedOver, onButtonClick }) => {
  const testIds = testids.dit

  return (
    <div
      className={classnames(classes.csvOverlayContainer, {
        [classes.persistent]: persistent,
        [classes.semiTransparent]: semiTransparent,
        [classes.draggedOver]: isDraggedOver,
      })}
    >
      <div className={classes.overlayInfo}>
        <UploadCSVIcon />
        <span className={classes.overlayHint}>Drop your csv here to begin integrating diamond data</span>
        {!isDraggedOver && (
          <Button
            className={classes.overlayButton}
            onClick={onButtonClick}
            data-test-id={testIds.uploadCSVSelectFileButton}
          >
            Or, select from device
          </Button>
        )}
      </div>
    </div>
  )
}

export default CSVOverlay
