import createActionSet from 'utils/createActionSet'

const namespace = 'LIFECYCLE'
const STAGE_CHANGE = `${namespace}_LIFECYCLE_STAGE_CHANGE`
const GET_AVAILABLE = createActionSet(namespace, 'GET_AVAILABLE')

export const LIFECYCLE_ACTION_TYPES = {
  STAGE_CHANGE,
  GET_AVAILABLE,
}
