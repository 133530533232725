import React from 'react'

export const ArrowUpIcon = () => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0725 6.75L6.57251 1.25L1.07251 6.75" stroke="#18BC98" />
    </svg>
  )
}

export const ArrowDownIcon = () => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.572754 1.25L6.07275 6.75L11.5728 1.25" stroke="#18BC98" />
    </svg>
  )
}

export const LongArrowRightSmallIcon = () => {
  return (
    <svg width="34" height="11" viewBox="0 0 34 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 5.66667H33M33 5.66667L29.413 1M33 5.66667L29.413 10" stroke="#18BC98" />
    </svg>
  )
}

export const LongArrowRightMediumIcon = () => {
  return (
    <svg width="54" height="13" viewBox="0 0 54 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.441895 6.63011H53.2457M53.2457 6.63011L47.5061 1M53.2457 6.63011L47.5061 11.8581" stroke="#18BC98" />
    </svg>
  )
}

export const LongArrowRightLargeIcon = () => {
  return (
    <svg width="62" height="13" viewBox="0 0 62 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 6.63011H60.3391M60.3391 6.63011L53.7805 1M60.3391 6.63011L53.7805 11.8581" stroke="#18BC98" />
    </svg>
  )
}

export const InfoIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        stroke="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6914 11.7305C18.8919 11.5299 18.9922 11.2891 18.9922 11.0078C18.9922 10.8151 18.9479 10.6445 18.8594 10.4961C18.7708 10.3477 18.6497 10.2279 18.4961 10.1367C18.3424 10.0456 18.1719 10 17.9844 10C17.6927 10 17.4492 10.1003 17.2539 10.3008C17.0586 10.5013 16.9609 10.737 16.9609 11.0078C16.9609 11.2891 17.0586 11.5299 17.2539 11.7305C17.4492 11.931 17.6927 12.0312 17.9844 12.0312C18.2552 12.0312 18.4909 11.931 18.6914 11.7305ZM17.4609 20.2969C17.8932 19.8229 18.2109 19.3594 18.4141 18.9062H17.6953C17.4453 19.3542 17.237 19.6615 17.0703 19.8281C16.9036 19.9948 16.7422 20.0781 16.5859 20.0781C16.5286 20.0781 16.4753 20.0482 16.4258 19.9883C16.3763 19.9284 16.3516 19.8672 16.3516 19.8047C16.3516 19.6641 16.3932 19.4818 16.4766 19.2578L17.6953 15.9844C17.8099 15.6823 17.8672 15.3828 17.8672 15.0859C17.8672 14.8047 17.7539 14.5794 17.5273 14.4102C17.3008 14.2409 17.0156 14.1562 16.6719 14.1562C16.1823 14.1562 15.6914 14.3451 15.1992 14.7227C14.707 15.1003 14.3073 15.6328 14 16.3203H14.6875C14.7917 16.1016 14.9492 15.8542 15.1602 15.5781C15.3711 15.3021 15.5651 15.1641 15.7422 15.1641C15.7995 15.1641 15.8516 15.1875 15.8984 15.2344C15.9453 15.2813 15.9688 15.3333 15.9688 15.3906C15.9688 15.4792 15.9193 15.6484 15.8203 15.8984L14.8125 18.6562C14.6198 19.1927 14.5234 19.612 14.5234 19.9141C14.5234 20.2109 14.6328 20.4674 14.8516 20.6836C15.0703 20.8997 15.3984 21.0078 15.8359 21.0078C16.487 21.0078 17.0286 20.7708 17.4609 20.2969Z"
        fill="white"
      />
    </svg>
  )
}

export const HelpIcon = () => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 19C14.4706 19 18.5 14.9706 18.5 10C18.5 5.02944 14.4706 1 9.5 1C4.52944 1 0.5 5.02944 0.5 10C0.5 14.9706 4.52944 19 9.5 19Z"
        stroke="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.47772 10.4255C8.31838 10.746 8.2226 11.139 8.19037 11.6045L9.51703 11.5991C9.58864 11.13 9.78916 10.7433 10.1186 10.439L10.8061 9.86963C11.4578 9.32177 11.8051 8.74349 11.8481 8.13476C11.8946 7.51529 11.7281 7.01937 11.3486 6.64697C10.969 6.27457 10.4409 6.08121 9.7641 6.06689C9.04079 6.05257 8.44013 6.24503 7.9621 6.64428C7.48407 7.04354 7.22178 7.58333 7.17523 8.26367L8.69525 8.25293C8.75254 7.96289 8.86892 7.73372 9.04437 7.56543C9.21983 7.39713 9.43467 7.31657 9.6889 7.32373C10.0577 7.33447 10.26 7.5135 10.2958 7.86084C10.3352 8.24398 10.2009 8.60563 9.89301 8.9458C9.77842 9.07112 9.55373 9.26896 9.21893 9.5393C8.88413 9.80965 8.63707 10.1051 8.47772 10.4255ZM7.92181 12.6733C7.76068 12.8381 7.6837 13.0404 7.69086 13.2803C7.69802 13.5166 7.78306 13.7082 7.94598 13.855C8.10891 14.0018 8.31032 14.0752 8.55023 14.0752C8.80088 14.068 9.00498 13.9803 9.16254 13.812C9.32009 13.6437 9.39528 13.4468 9.38812 13.2212C9.38096 12.9813 9.29323 12.7852 9.12494 12.6331C8.95664 12.4809 8.75792 12.4084 8.52875 12.4155C8.28526 12.4227 8.08295 12.5086 7.92181 12.6733Z"
        fill="white"
      />
    </svg>
  )
}
