import http from '../utils/http'

const namespace = 'tracking'

const logHelp = (page, link) => {
  return http.put(`${namespace}/help`, { page, link })
}

export const tracking = {
  logHelp,
}

export default tracking
