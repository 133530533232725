import createActionSet from 'utils/createActionSet'

const namespace = 'DIT'
const NEW_SESSION = `${namespace}_NEW_SESSION`
const APPLY_FILTERS = `${namespace}_APPLY_FILTERS`
const MATCH_COMPLETE = `${namespace}_MATCH_COMPLETE`
const UPLOAD_COMPLETE = `${namespace}_UPLOAD_COMPLETE`
const COMPLETE_SESSION = `${namespace}_COMPLETE_SESSION`
const QUIT_SESSION = `${namespace}_QUIT_SESSION`
const GET_SESSION_HISTORY = createActionSet(namespace, 'GET_SESSION_HISTORY')
const GET_INTEGRATION_TYPES = createActionSet(namespace, 'GET_INTEGRATION_TYPES')
const GET_SESSION = createActionSet(namespace, 'GET_SESSION')
const UPLOAD_CSV_FILE = createActionSet(namespace, 'UPLOAD_CSV_FILE')
const VALIDATE_CSV_FILE = createActionSet(namespace, 'VALIDATE_CSV_FILE')
const PARSE_CSV_FILE = createActionSet(namespace, 'PARSE_CSV_FILE')
const HIDE_UNHIDE_DIAMOND = createActionSet(namespace, 'HIDE_UNHIDE_DIAMOND')
const SUBMIT_SESSION = createActionSet(namespace, 'SUBMIT_SESSION')
const ABANDON_SESSION = createActionSet(namespace, 'ABANDON_SESSION')
const SET_CSV_FILE = `${namespace}_SET_CSV_FILE`
const SET_CSV_ERRORS = `${namespace}_SET_CSV_ERRORS`

export const DIT_ACTION_TYPES = {
  NEW_SESSION,
  APPLY_FILTERS,
  MATCH_COMPLETE,
  UPLOAD_COMPLETE,
  COMPLETE_SESSION,
  QUIT_SESSION,
  GET_SESSION_HISTORY,
  GET_INTEGRATION_TYPES,
  GET_SESSION,
  UPLOAD_CSV_FILE,
  VALIDATE_CSV_FILE,
  PARSE_CSV_FILE,
  HIDE_UNHIDE_DIAMOND,
  SUBMIT_SESSION,
  ABANDON_SESSION,
  SET_CSV_FILE,
  SET_CSV_ERRORS,
}
