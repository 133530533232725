import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import env from '@beam-australia/react-env'

import { sessionStartAction } from 'store/actions'
import { selectSession } from 'store/selectors'
import { Checkbox, Button } from 'components/system'
import { CircleLogoSVG, LogoSVG } from 'components/Svg'
import testIds from 'config/testIds'

import classes from './styles.module.css'
import { getDefaultRoute } from 'config/routes'

const Login = function () {
  const dispatch = useDispatch()
  const location = useLocation()
  const loginStatus = new URLSearchParams(location.search).get('status')
  const session = useSelector(selectSession)
  const [termsAccepted, changeTermsAccepted] = useState(false)
  const currentYear = new Date().getFullYear()

  /**
   * Login redirects to API endpoint which then redirects to jumpcloud and back to FE with a token attached.
   * In dev mode jumpcloud step is skipped.
   */
  const handleLogin = () => {
    // TODO: move login to api request instead of redirect
    const apiUrl = env('API_URL') || 'http://localhost:4000/api/v1'
    const loginEndpoint = '/user/login'

    window.location.href = `${apiUrl}${loginEndpoint}`
  }

  const findTokenCookie = () => {
    const cookies = document.cookie.split(';')
    const cookieRE = /^\s?SID=/i
    const cookie = cookies.find((cookie) => cookieRE.test(cookie))

    if (!cookie) return null

    const token = cookie.split('=')[1]

    return token
  }

  const toggleAcceptTerms = () => {
    changeTermsAccepted(!termsAccepted)
  }

  // TODO: Replace with custom hook that checks both, local storage and
  // cookie, and sets the session reducer value if localstorage is empty
  useEffect(() => {
    if (session) window.location = getDefaultRoute()
  }, [session]) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * If login status is success update store and redirect to home page
   */
  // TODO: Replace with custom hook that checks both, local storage and
  // cookie, and sets the session reducer value if localstorage is empty
  useEffect(() => {
    if (!loginStatus) return

    const cookie = findTokenCookie()

    if (!cookie) return

    dispatch(sessionStartAction(cookie))
  }, [loginStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.loginContainer}>
      <div className={classes.content}>
        <div className={classes.formSide}>
          <div className={classes.logo}>
            <LogoSVG />
          </div>
          <div className={classes.form}>
            <div className={classes.heading}>
              Log in to the
              <br /> Tracr Platform,
              <br /> please authenticate via
              <br /> JumpCloud
            </div>
            <div className={classes.terms}>
              <Checkbox
                alignTop
                value={0}
                onChange={(value) => toggleAcceptTerms()}
                data-test-id={testIds.login.checkbox}
              />

              <div className={classes.termsCopy}>I accept Terms &amp; Conditions and Privacy Policy</div>
            </div>
            <div className={classes.button}>
              <Button disabled={!termsAccepted} onClick={handleLogin} data-test-id={testIds.login.continue}>
                Login with JumpCloud
              </Button>
            </div>
          </div>
          <div className={classes.copyright}>&copy;Tracr {currentYear}. All rights reserved.</div>
        </div>
        <div className={classes.logoSide}>
          <div className={classes.positioner}>
            <div className={classes.circleLogo}>
              <CircleLogoSVG />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
