import React from 'react'
import classnames from 'classnames'

import classes from './styles.module.css'

export const EmptyState = ({ icon, label, subLabel, height = 200, className, children }) => {
  return (
    <div className={classnames(classes.emptyStateContainer, className)}>
      <div className={classnames(classes.wrapper)} style={{ height: height }}>
        <div className={classes.icon}>{icon}</div>
        <div className={classes.label}>{label}</div>
        <div className={classes.subLabel}>{subLabel}</div>
        {children}
      </div>
    </div>
  )
}

export default EmptyState
