import React from 'react'

import { ButtonsCell } from 'components/system/TableElements'
import testIds from 'config/testIds'

export const Buttons = ({ row, ...props }) => {
  const getItems = () => {
    const items = []

    if (row.data.shipment_type === 'rough') {
      if (['action_required'].includes(row.data.status)) {
        items.push({
          key: 'reject',
          label: 'Reject',
          type: 'secondary',
          testId: testIds.shipmentsInbound.active.rejectButton,
        })
        items.push({
          key: 'accept',
          label: 'Accept',
          type: 'primary',
          testId: testIds.shipmentsInbound.active.acceptButton,
        })
      } else if (['incomplete'].includes(row.data.status)) {
        items.push({
          key: 'upload',
          label: 'Upload diamonds',
          type: 'primary',
          testId: testIds.shipmentsInbound.active.uploadButton,
        })
      }
    } else {
      items.push({ key: 'view', label: 'View', type: 'primary', testId: testIds.shipmentsInbound.active.viewButton })
    }

    return items
  }

  return <ButtonsCell row={row} buttons={getItems()} {...props} />
}

export default Buttons
